import styled from 'styled-components'

export const Wrapper = styled.div`

 table {
   border-collapse: separate;
   border-spacing: 0;
 }

 th, td{
   border : 0.1px solid #b28e70;
   padding : 5px 10px;
   text-align: center;
   @media print{
    padding: 3px;
    font-size: 12px;
   }
 }
 th{
  background: #b39983;
 }
 td{
  background: #f5d68259;
 }

 @media screen and (max-width: 1620px){
  p{
    font-size: 14px;
  }
}
 .container_market {
    width: 100%;
    padding: 6rem 0 10rem;
    @media screen and (max-width: 1620px){
      padding: 4rem 0 10rem;
    }
    @media screen and (max-width: 991px){
      padding: 1rem 0 10rem;
    }
    .print{
      display: none;
    }
    @media print{
    padding:2rem 0;
    .noprint{
      display: none;
    }
    .print{
      display: block;
    }
  }
    p{
        line-height: 1.6;
        font-size: 19px;
        @media print{
          font-size: 14px;
        }
        @media screen and (max-width: 1620px) {
          font-size: 18px;
        }
        @media screen and (max-width: 1280px) {
          font-size: 16px;
        }
        @media screen and (max-width: 580px) {
          font-size: 14px;
        }
      }
      h2{
        font-size: 24px;
        color: #4472c4;
        text-align: center;
        @media screen and (max-width: 1620px) {
          font-size: 20px;
        }
        @media print{
          font-size: 16px;
        }
      }
    .section-1 {
      width:100%;
      margin: auto;
      .content{
        width:1150px;
        margin:auto;
        z-index:99;
        @media screen and (max-width: 1620px) {
          width: 100%;
        }
        @media print{
          width: 100%;
          margin: 0;
          margin-left: -5px;
        }
        h1{
          margin-bottom: 3rem;
        }
        .mt_6r{
          margin-top: 6rem;
          @media print{
            margin-top: 3rem;
          }
        }
        .mr_05{
          margin-right: 0.5rem;
          @media print{
            margin-right: 2px;
          }
        }
        .ml_05{
          margin-left: 0.5rem;
          @media print{
            margin-left: 2px;
          }
        }
        .title{
          padding: 10px 7rem;
          margin: auto;
          margin-top: -5.5rem;
          margin-bottom: 2rem;
          width: fit-content;
          border-radius: 10px;
          background: #eddeb6;
          box-shadow: rgb(0 0 0 / 0%) 0px 14px 28px, rgb(0 0 0 / 11%) 0px 10px 10px;
          @media print{
            padding: 10px 20px;
          }
        }
        .item{
          padding: 2rem 0rem;
          background-color: white;
          border-radius: 40px;
          transition: 1s all ease-in-out;
          box-shadow: rgb(0 0 0 / 0%) 0px 14px 28px, rgb(0 0 0 / 11%) 0px 10px 10px;
           .m_center{
            width: 90%;
            margin: auto;
            @media screen and (max-width: 1620px) {
              width: 90%;
              margin: auto;
            }
            @media print{
              width: 98%;
              padding: 0 10px;
            }
           }
        }
      }
    }
    .section-2 {
      width: 100%;
      display : block; 
      padding-bottom: 2rem;
      .content{
        width: 100%;
        margin: auto;
        @media print{
          width: 100%;
        }
        text-align: -webkit-center;
        @media print{
          margin-left: 1rem;
        }
        .table_{
          justify-content: space-between;
          width: 80%;
          @media screen and (max-width: 1620px) {
            justify-content: space-between;
            width: 95%;
          }
          @media print{
            width: 95%;
          }
          .ml_46{
            margin-left: 4.6rem;
            @media print{
              margin-left: 1rem;
            }
          }
        }
      }
    }

    .section-3 {
      width: 100%;
      display : flex;
      .content{
        width :1100px;
        margin: auto;
        @media print{
          width: 80%;
          margin: 0;
          margin-bottom: 6rem;
        }
        @media screen and (max-width: 1620px) {
          width: 1000px;
        }
        @media screen and (max-width: 1280px) {
          width: 100%;
        }
        .mt_3{
          margin-top: 3rem;
          @media print{
            margin-top: 1rem;
          }
        }
      }
    }

    @media screen and (max-width: 991px) {
      margin-top: 80px;

      .section-1 {
        .content {
          .title {
            padding: 10px;
            width: 70%;
            text-align: center;
          }
        }
      }

      .section-2 {
        .content {
          img {
            padding: 10px;
          }
        }
      }

      .section-3 {
        .content {
          >div {
            &.flex {
              flex-direction: column-reverse;

              >div {
                &:last-child {
                  text-align: center;
                  margin: 10px 0;
                }
              }
            }
          }
        }
      }
    } 

    @media screen and (max-width: 767px) {
      .section-1 {
        .content {
          .block-1{
           margin-top: 2rem;
           margin-left: 0;
           margin-right: 0;
          }
          .block-2 {
            flex-direction: column;

            .item {
              width: 100%;
              margin-bottom: 40px;
              .item {
                .title {
                  p {
                    margin-bottom: 0;
                  }
                }
              }
            }
          }
        }
      }

      .section-2 {
        .content {
          .flex {
            flex-direction: column;
            .block {
              width: 100%;

              &:last-child {
                margin-left: 0;
              }

              table {
                margin: 10px 0;
                thead {
                  tr {
                    th {
                      font-size: 13px;
                    }
                  }
                }

                tbody {
                  tr {
                    td {
                      font-size: 13px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`