import React from 'react'
import { Wrapper } from './styled'
import {
   GoogleMap,
   useJsApiLoader,
   MarkerClusterer,
   MarkerF
} from "@react-google-maps/api";
import useScreenWidth from 'src/Hook/useScreenWidth';

const containerStyle = {
   width: "65%",
   height: "60vh",
   margin: "auto",
};
const center = { lat: 35.7435144, lng: 139.641244 };
const locations = [
   { lat: 35.7435144, lng: 139.641244 }
];

const AccessScreen = () => {
   const screenWidth = useScreenWidth()
   const { isLoaded } = useJsApiLoader({
      id: "google-map-script",
      googleMapsApiKey: "AIzaSyA5mCRZyI27Avzwzqlum-6KWCXIjty-ASo"
   });

   function createKey(location: any) {
      return location.lat + location.lng;
   }

   return (
      <>
         <Wrapper id="intro">
            {isLoaded && (
               <div className='container-access block'>
                  <div className='left'>
                     <GoogleMap
                        id="marker-example"
                        mapContainerStyle={containerStyle}
                        zoom={16}
                        center={center}
                     >
                        <MarkerClusterer>
                           {(clusterer) => (
                              <>
                                 {locations.map((location: any) => (
                                    <MarkerF
                                       key={createKey(location)}
                                       position={location}
                                       clusterer={clusterer}
                                    />
                                 ))}
                              </>
                           )}
                        </MarkerClusterer>
                     </GoogleMap>
                  </div>
                  {screenWidth > 600 ? (
                     <div className='py-[2rem] w-[65%] m-auto flex'>
                        <div className='block text-1xl md:text-[20px] font-bold leading-normal md:leading-relaxed mb-2 mr-8'>
                           <p>企業名</p>
                           <p>所在地</p>
                           <p>電話番号</p>
                        </div>
                        <div className='block text-1xl md:text-[20px] leading-normal md:leading-relaxed mb-2 mr-8'>
                           <p>アートメタバースランド株式会社　 ART METAVERSE LAND, Inc.</p>
                           <p>176-0022 東京都練馬区向山3-15-19</p>
                           <p>03-6636-7893</p>
                           <p className='text-[#0972c1]'>お電話のお問い合わせは、平日１０：００～１７：００でお願い致します。</p>
                        </div>
                     </div>
                  ) : (
                     <div className='py-[2rem] w-[65%] m-auto flex'>
                     <div className='block text-1xl md:text-[20px] leading-normal md:leading-relaxed mb-2 mr-8'>
                        <p className='font-bold'>企業名</p>
                        <p className='description'>　アートメタバースランド株式会社　 ART METAVERSE LAND, Inc.</p>
                        <p className='font-bold'>所在地</p>
                        <p className='description'>　176-0022 東京都練馬区向山3-15-19</p>
                        <p className='font-bold'>電話番号</p>
                        <p className='description'>　03-6636-7893</p>
                        <div className='ml-4'>
                           <p className='text-[#0972c1]'>お電話のお問い合わせは、平日１０：００～１７：００でお願い致します。</p>
                        </div>
                     </div>
                  </div>
                  )}

               </div>
            )}
         </Wrapper>
      </>
   )
}
export default AccessScreen
