import React from 'react'
import { Wrapper } from './styled'
import {Image_63} from '@assets'
import useScreenWidth from '../../../Hook/useScreenWidth'

const SeminarRoom = () => {
   const screenWidth = useScreenWidth()
   return (
      <>
      <Wrapper>
         <div className='container_seminar block text-justify'>
            <div className='content'>
               <div className='mt-4'>
                  <div className='item'>
                     <div className={`flex ${ (screenWidth > 580) ? 'mb-[3rem]' : 'mb-[1rem]'}`}>
                        <h1 className="text-[24px] leading-normal md:leading-relaxed font-bold" id="id2">会議室・マンション{screenWidth < 581 &&(<br/>)} オーナーの皆様へ</h1>
                     </div>
                     <div className={`w-full ${ (screenWidth > 580) ? 'pl_6' : 'ml-0'}`}>
                        <p className=''>2023年2月1日 ： アートメタバースランドは、２０２４年７月１日開園を予定しております。</p>
                        <div className={`block mt-8 ${ (screenWidth > 580) ? 'pl-[3rem]' : 'ml-0'}`}>
                           <p className='font-bold text-lg md:text-1xl'>利用開始日</p>
                           <div className={` ${ (screenWidth > 580) ? 'ml-6' : 'ml-0'}`}>
                              <p >会議室・マンションのご利用開始は、開園日２０２４年７月１日からとなります。</p>
                           </div>                            
                        </div>
                        <div className={`block mt-8 ${ (screenWidth > 580) ? 'pl-[3rem]' : 'ml-0'}`}>
                           <p className='font-bold text-lg md:text-1xl'>利用開始前の手続き</p>
                           <div className={` ${ (screenWidth > 580) ? 'ml-6' : 'ml-0'}`}>
                              {screenWidth > 580 ? 
                                 <>
                                    <p >会議室・マンションのオーナー様には、２０２４年４月からＩＤ付与ならびにパスワード</p>
                                    <p>設定を行って頂く予定です。</p>
                                 </>
                                 :<>
                                    <p>会議室・マンションのオーナー様には、２０２４年４月からＩＤ付与ならびにパスワード設定を行って頂く予定です。</p>
                                 </>
                              }
                           </div>                            
                        </div>
                        <div className={`block mt-8 ${ (screenWidth > 580) ? 'pl-[3rem]' : 'ml-0'}`}>
                           <p className='font-bold text-lg md:text-1xl'>転売</p>
                           <div className={` ${ (screenWidth > 580) ? 'ml-6' : 'ml-0'}`}>
                              <p >会議室・マンションの転売については、２０２４年７月１日より可能といたします。</p>
                              <p>ただし、新しいオーナー様が、当社と新たな契約を締結頂く必要がございます。</p>
                              {screenWidth > 580 ? 
                                 <>
                                    <p>会議室・マンションの転売を行う際は、当社ホームページお問合せ欄より、</p>
                                    <p>転売前のオーナー様から、転売する旨のご連絡をお願いします。</p>
                                 </>
                                 :<>
                                    <p>会議室・マンションの転売を行う際は、当社ホームページお問合せ欄より、転売前のオーナー様から、転売する旨のご連絡をお願いします。</p>
                                 </>
                              }
                              {screenWidth > 580 ? 
                                 <>
                                     <p>弊社所定の手続きを行って頂き、新しいオーナー様との契約手続きが完了いたしましたら、</p>
                                    <p>新しいオーナー様あてにＩＤの付与ならびにパスワードの設定をご案内いたします。</p>
                                 </>
                                 :<>
                                    <p>弊社所定の手続きを行って頂き、新しいオーナー様との契約手続きが完了いたしましたら、新しいオーナー様あてにＩＤの付与ならびにパスワードの設定をご案内いたします。</p>
                                 </>
                              }
                           </div>                            
                        </div>
                        <div className={`block mt-8 ${ (screenWidth > 580) ? 'pl-[3rem]' : 'ml-0'}`}>
                           <p className='font-bold text-lg md:text-1xl'>転貸</p>
                           <div className={` ${ (screenWidth > 580) ? 'ml-6' : 'ml-0'}`}>
                              <p >会議室・マンションの転貸については、開園日２０２４年７月１日より可能といたします。</p>
                              {screenWidth > 580 ? 
                                 <>
                                    <p>会議室・マンションの転貸を行う際は、当社ホームページお問合せ欄より、</p>
                                    <p>オーナー様から、転貸する旨のご連絡をお願いします。</p>
                                 </>
                                 :<>
                                    <p>会議室・マンションの転貸を行う際は、当社ホームページお問合せ欄より、オーナー様から、転貸する旨のご連絡をお願いします。</p>
                                 </>
                              }
                              <p>弊社所定の手続き完了後に、賃借人様用のＩＤの付与ならびにパスワードの設定をご案内いたします。</p>
                           </div>                            
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div className='section2'>
            <div className='content'>
               <img src={Image_63} alt='' />
            </div>
         </div>
      </Wrapper>
      </>
   )
}
export default SeminarRoom
