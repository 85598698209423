import { FunctionComponent } from 'react'
import video from "@assets/Metarverse_video_720.mp4"
import "keen-slider/keen-slider.min.css"
interface ModalVideoProps {
	event: any;
}

const ModalVideo: FunctionComponent<ModalVideoProps> = ({event}) => {
	return (
		<div className="flex justify-center fixed z-[999] w-screen h-screen bg-black top-0 left-0">
			<div className="fixed flex justify-center leading-[42px] left-0 h-[42px] w-full bg-[#12131338] p-16">
				<span
					className="absolute flex justify-center items-center w-10 h-10 cursor-pointer transition-all duration-200 right-2 text-gray-400 hover:text-gray-600"
				>
                    <div onClick={event}>
                        <i className="fas fa-times fa-2x mr-2" ></i>
                    </div>
				</span>
			</div>
            <div className="w-full h-full pt-10">
                <video
                className="w-[1880px] h-screen object-contain pt-10"
                src={video} 
                muted={true}
                loop
                /> 
            </div> 
		</div>
	 );
}

export default ModalVideo;

