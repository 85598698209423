import React, {useRef, useEffect} from 'react'
import { Wrapper } from './styled'
import { Image_29 } from '@assets'
import useScreenWidth from '../../../../Hook/useScreenWidth'
const AIAvatar = () => {
   const divOne = useRef<HTMLInputElement | null>(null);
   const scrolLWithUseRef = () => {
   divOne.current?.scrollIntoView({ block: "center", behavior: "smooth" });
   };
 
   useEffect(() => {
    scrolLWithUseRef()
   },[])
   const screenWidth = useScreenWidth()
   return (
      <>
      <Wrapper>
         <div className='container_ai block'>
            <div className='content block'>
               <h1 className="text-[30px] leading-normal md:leading-relaxed mb-2 text-center font-bold" ref={divOne}>ＡＩアバター店員の賃貸</h1>
               <p className={`text-1xl md:text-2xl leading-normal md:leading-relaxed text-center text-[#c01600] font-bold  ${ (screenWidth > 580) ? 'mb-[3rem]' : 'mb-[1rem]'}`}>※ 特許申請中・審査請求済</p>
               <div className='text'>
                 <h2>当社が独自に開発した「ＡＩを搭載し自律的にお客様対応できるアバター店員」を賃貸いたします。</h2>
                  <div className='flex mt-8'>
                     <p className='text-yellow-400'>■ </p>
                     <div className='block'>
                        <p>ＡＩアバター店員の特徴</p>
                        <div className={`${ (screenWidth > 580) ? 'ml-4 mt-4' : 'ml-0 mt-2'}`}>
                           <div className='flex'>
                              <p className='text-[#f4b084]'>● </p>
                              <div className='block'>
                                 {screenWidth > 580 ? 
                                 <>
                                   <p>当社のＡＩアバター店員は、「ルールベース型のチャットボット」に「ＡＩ型のチャットボット」を加えて、</p>
                                    <p>アバターの頭脳として搭載し、自律的に顧客対応が行えるようにしたアバターです。</p>
                                 </>
                                 :
                                 <>
                                   <p>当社のＡＩアバター店員は、「ルールベース型のチャットボット」に「ＡＩ型のチャットボット」を加えて、アバターの頭脳として搭載し、自律的に顧客対応が行えるようにしたアバターです。</p>
                                 </>
                                 }
                              </div>
                           </div>
                           <div className='flex mt-4'>
                              <p className='text-[#f4b084]'>● </p>
                              <div className='block'>
                                 <p>ＡＩアバター店員は、事前学習が可能な商品知識等については、高い能力を発揮する特徴があります。</p>
                                 <p>人間が操作するアバター店員と併用いただくことで、顧客対応力が格段に向上いたします。</p>
                              </div>
                           </div>
                           <div className='flex mt-4'>
                              <p className='text-[#f4b084]'>● </p>
                              <div className='block'>
                                 <p>店舗運営にかかる店員の人件費を大幅に削減することが可能です。</p>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className='flex mt-8'>
                     <p className='text-yellow-400'>■ </p>
                     <div className='block'>
                        <p>ＡＩ店員アバターの利用にあたりご負担いただく費用</p>
                        <div className={`${ (screenWidth > 580) ? 'ml-4 mt-4' : 'ml-0 mt-2'}`}>
                           <div className='flex mt-2'>
                              <p className='text-[#f4b084]'>● </p>
                              <div className='block'>
                                 <p>ＡＩアバターの賃貸料については、一体あたり月間１０万円で賃貸する予定です。</p>
                                 <p>２４時間３６５日、稼働することができるので、時給換算すると時給１５０円未満です。</p>
                              </div>
                           </div>
                           <div className='flex mt-4'>
                              <p className='text-[#f4b084]'>● </p>
                              <div className='block'>
                                 <p>開園直後は、学習レベルが充分でないことも予想されるため、賃貸料を割引価格にて提供する予定です。</p>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className='flex mt-8'>
                     <p className='text-yellow-400'>■ </p>
                     <div className='block'>
                        <p>ＡＩ店員アバターの利用にあたりご留意頂くこと</p>
                        <div className={`${ (screenWidth > 580) ? 'ml-4 mt-4' : 'ml-0 mt-2'}`}>
                           <div className='flex mt-4'>
                              <p className='text-[#f4b084]'>● </p>
                              <div className='block'>
                                 {screenWidth > 580 ? 
                                 <>
                                   <p>ＡＩアバター店員のご利用に際しては、「個別ルールのインプット」と「個別実例のインプット」を</p>
                                    <p>出店者様自身にて行っていただき、ＡＩアバター店員に事前データ学習をさせていただく必要があります。</p>
                                 </>
                                 :
                                 <>
                                   <p>ＡＩアバター店員のご利用に際しては、「個別ルールのインプット」と「個別実例のインプット」を出店者様自身にて行っていただき、ＡＩアバター店員に事前データ学習をさせていただく必要があります。</p>
                                 </>
                                 }
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            <div className='imgprint mt-4'>
               <h2 className='my-[2rem] text_black text-center'>自学習機能付きＡＩチャットボット３Ｄアバターの仕組み図</h2>
               <div className='noprint'>
                  <img src={Image_29} alt='' className='m-auto ' />
               </div>
               <div className='print'>
                  <img src={Image_29} alt='' className='m-auto my-[2rem]' />
               </div>
            </div>
            </div>
         </div>
      </Wrapper>
      </>
   )
}
export default AIAvatar
