/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Footer, Header, Modal } from '@components'
import { useTranslation } from "react-i18next";

import HeaderMobile from '@components/header/header-mobile'
import Menu from '@components/header/menu'

import { PRIMARY } from '@assets/colors';

import useScreenWidth from '../Hook/useScreenWidth'
import { useHistory } from 'react-router-dom';

const MENU_WIDTH = '300px'

const Wrapper: any = styled.div`
  position: absolute;
  width: 100%;
  left: 0px;
  top: 0px;
  display: block;
  background-position:center;
  background-repeat : no-repeat;
  background-size : cover;
  .modal{
    margin-left: 0.2%;
  }
`
const Main = styled.main`
  width: 1250px;
  @media screen and (max-width: 1620px){
    width: 1100px;
  }
  @media print {
    width: 100%;
    margin-top: -4rem;
    padding: 4rem 2% 0;
    background: #f4f3ed;;
  }
  padding: 0;
  margin: auto;
  display: flex;
  justify-content: center;

  &.open {
    width: 100%;
    .header-mobile-container {
      margin-left: 240px;
      -moz-animation: fadeOut 1.6s ease;
      -webkit-animation: fadeOut 1.6s ease;
      animation: fadeOut 1.6s ease;
      transition: margin 100ms ease;
    }
    #intro {
      /* margin-left: ${MENU_WIDTH}; */

      .bg_slide {
        left:${MENU_WIDTH};
       
      }
    }
  }
  @media screen and (max-width: 1280px){
    width: 70%;
  }
  @media screen and (max-width: 991px){
    width: 100%;
    flex-direction: column;

    #intro, .modal {
      width: 100%;
    }

    #intro {
      .container-service {
        margin-top: 50px; 
      }
    }

    .modal {
      margin-bottom: 50px;

      header {
        position: initial;
        display: flex;

        >div {
          margin-bottom: 30px;
          flex: 1;
          padding: 10px;

          a {
            display: block;
            width: 100%;

            .item {
              width: 100%;
              height: 380px;
            }
          }

          &:first-child {
            img {
              margin-top: 5px !important;
              height: 74%;
            }
          }
          &:last-child {
            img {
              margin-top: 5px !important;
              height: 74%;
            }
          }
        }

        a {
          display: block;

          .item {
            height: auto;
            transform: scale(1);
            .title {
              font-size: 15px;
              padding-bottom: 10px;
            }
            p {
              font-size: 14px;
              &:last-child {

              }
            }
            img {
              position: initial !important;
              width: 100%;
              margin: 0;
              // height: auto;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    .modal {
      header {
        a {
          .item {
            .title {
              font-size: 14px;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 580px) {
    .modal {
      header {
        flex-direction: column;
        >div {
          &:last-child {
            img {
              margin-top: 0 !important;
              height: 77%;
            }
          }
          &:first-child {
            height: 70%;
            margin-top: 0 !important;
          }
        }
        
        a {
          .item {
            .title {
              font-size: 14px;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 480px) {
    &.about-page {
      width: 100%;
    }
  }

  @media screen and (max-width: 339px) {
    &.about-page {
      padding: 15px;
    }
  }

  @media screen and (max-width: 329px) {
    &.about-page {
      padding: 10px;
    }
  }

`
const Content = styled.div`
  width: 100%;
  overflow: hidden;
  display: flex;

  @media screen and (max-width: 991px){
    width: 100%;

    .child {
      width: 100%;
      padding: 20px 30px;
    }
  }

  .slide-bar {
    -moz-animation: fadeOut 1.6s ease;
    -webkit-animation: fadeOut 1.6s ease;
    animation: fadeOut 1.6s ease;
    transition: margin 100ms ease;
    margin-left: -${MENU_WIDTH};
    position: fixed;
    height: 100%;
    overflow: hidden;
    padding: 15px;
    z-index: 1;
    top: 0;

    &.open {
      width: ${MENU_WIDTH};
      margin-left: 0;
      background: ${PRIMARY};
    }

    &.close {
      display: none;
    }

    header {
      flex-direction: column;
    }
  }
`

const HomeLayout = ({ children }: any) => {
  const [language, setLanguage] = useState<any>(false);
  const [isShowMenu, setIsShowMenu] = useState(false)
  const [check, setCheck] = useState(false)

  const { i18n } = useTranslation();
  const screenWidth = useScreenWidth()
  const history = useHistory();

  const isAboutPage = history.location.pathname === '/about'

  useEffect(() =>{
    if(history.location.pathname === '/SeminarRooms'){
      setCheck(true)
    }
    if(history.location.pathname === '/ECMall'){
      setCheck(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[check])

  useEffect(() => {
    if (i18n.language === "en") {
      setLanguage(true)
    }
    if (i18n.language === "jp") {
      setLanguage(false)
    }
  }, [i18n.language])

  const onSetShowMenu = () => {
    setIsShowMenu(!isShowMenu)
  }

  return (
    <Wrapper >
      <div className='container_'>
        {screenWidth > 991 && (
          <Header language={language} setLanguage={setLanguage}/>
        )}
        <Main className={`${isShowMenu ? 'open' : 'close'} ${isAboutPage ? 'about-page' : ''}`}>
          <Content>
            {screenWidth <= 991 && (
              <>
                <HeaderMobile
                  onClick={onSetShowMenu}
                  isOpen={isShowMenu}
                />
                <div className={`slide-bar ${isShowMenu ? 'open' : 'close'}`}>
                  <Menu language={language} onScrollMenu={onSetShowMenu} />
                </div>
              </>
            )}
            <div className='child w-full'>
              {children}
            </div>
          </Content>
          <div className='modal'>
            {check ? <></> : <>  <Modal /></>}
          </div>
        </Main>    
        <Footer language={language} setLanguage={setLanguage} />
      </div>
    </Wrapper>
  )
}

export default HomeLayout
