import React, {useRef, useEffect} from 'react'
import { IMAGEKH_41} from '@assets';
import { Wrapper } from './styled'
import useScreenWidth from '../../../Hook/useScreenWidth'

const ManagePolicy = () => {
   const divOne = useRef<HTMLInputElement | null>(null);
   const scrolLWithUseRef = () => {
   divOne.current?.scrollIntoView({ block: "center", behavior: "smooth" });
   };
 
   useEffect(() => {
    scrolLWithUseRef()
   },[])
   const screenWidth = useScreenWidth()
   return (
      <>
      <Wrapper>
         <div className='container_policy text-justify'>
            <div className='block mb-[2rem]'>
               <h1 className='text-[30px] leading-normal md:leading-relaxed font-bold text-center' ref={divOne}>経営方針</h1>
               <h2 className="leading-normal md:leading-relaxed mb-2 font-bold mt-[3rem]">私たちは、メタバースを活用し、これまでにない新しい生活空間、生活様式を提供し、社会に貢献いたします。</h2>
               <div className={`${ (screenWidth > 580) ? 'ml-4 mt-4' : 'ml-2 mt-2'}`}>
                  <div className='flex'>
                     <p className='text-yellow-400'>■ </p>
                     <div className='block'>
                        <p>美しい仮想都市空間アートメタバースランドを創成し、表現力豊かでビジュアルな新しい生活空間を実現します。</p>
                     </div>
                  </div>
                  <div className='flex mt-4'>
                     <p className='text-yellow-400'>■ </p>
                     <div className='block'>
                        <p>お客様が、アバターをとおして、リアルなコミュニケーションを楽しんでいただける環境・施設を提供します。</p>
                     </div>
                  </div>
                  <div className='flex mt-4'>
                     <p className='text-yellow-400'>■ </p>
                     <div className='block'>
                        <p>お客様が、収益を獲得できる仕組みを多くご用意し、お客様を支援できる環境を整えます。</p>
                     </div>
                  </div>
                  <div className='flex mt-4'>
                     <p className='text-yellow-400'>■ </p>
                     <div className='block'>
                        <p>ＥＣモールに出店いただける事業主様には、リアル店舗に近い接客販売ができるバーチャル３Ｄ店舗、３Ｄアバターを提供します。</p>
                        <p>また、人件費を抑え低コストで店舗運営が可能となるＡＩ３Dアバターも提供します。</p>
                     </div>
                  </div>
                  <div className='flex mt-4'>
                     <p className='text-yellow-400'>■ </p>
                     <div className='block'>
                        <p>会議室・マンションをご購入いただいたお客様には、確かな資産価値と活用スキームを提供します。</p>
                     </div>
                  </div>
               </div>
               <img src={IMAGEKH_41} alt='' className='w-1/2 img1 mt-[3rem] m-auto' />
            </div>
         </div>             
      </Wrapper>
      </>
   )
}
export default ManagePolicy
