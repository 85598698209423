import styled from "styled-components"



const RoadmapItemContainer = styled.div(
  ({ color }: any) => {
    return (
      `
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        margin-bottom: 30px;

        &.last {
          .line {
            height: 100px;
          }
        }

        .line {
          width: 1px;
          height: 250px;
          background: #D9D9D9;
          position: absolute;
          top: 0;
          left: 50%;
          margin-left: -6px;
        }
        
        .circle {
          background: ${color};
          overflow: hidden;
          z-index: 1;
        }

        .text_r{
          text-align: -webkit-right;
        }
      
        .mr_{
          margin-right: 2.1rem;
          text-align: initial;
        }
      
        p {
          font-style: normal;
          font-size: 14px;
          line-height: 20px;
          color: #2F1B11;
        }
      
        h3 {
            background: ${color};
            box-shadow: 2px 2px 15px rgb(0 0 0 / 4%);
            display: inline-block;
            color: #fff;
            font-size: 20px;
            line-height: 19px;
            text-align: center;
            width: 356px;
            height: 50px;
            padding: 17px;
            font-weight: 500;
            position: relative;
            border-top-right-radius: 2rem;
            border-bottom-right-radius: 2rem;
            @media print {
             font-size: 16px;
             height:40px;
             padding: 10px;
            }
        }
        h3:before {
            content: '';
            height: 0;
            width: 0;
            border: 30px solid #ededed00;
            display: inherit;
            position: absolute;
            border-right: 13px solid #24415c;
            bottom: -30px;
            left: -30px;
            z-index: 0;
        }
        h3:after {
            content: '';
            display: inline-block;
            position: absolute;
            width: 15px;
            height: 40px;
            background: ${color};
            left: 0;
            bottom: 0;
        }
      
        .item{
          width: 309px;
          background: #E8E2D2;
          margin-left: 14px;
          padding : 23px 13px;
          box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
        }
        .circle{
          width: 25px;
          height: 25px;
          border-radius: 50%;
          margin-bottom: 20px;
          @media print {
            width: 15px;
           height: 15px;
          }
          .child{
            width: 10px;
            height: 10px;
            background: #D9D9D9;
            position: absolute;
            border-radius: 50%;
            margin :8px;
            @media print {
              width: 8px;
             height: 8px;
              margin:4px;
            }
          }
        }

        @media screen and (max-width: 400px){
          h3 {
            width: 300px;
          }

          .item {
            width: 283px;
          }
        }
      `
    )
  })

interface ItemProp {
  color: string;
  title: string;
  date: string;
  className: any;
}

const RoadmapItem = ({
  color,
  title,
  date,
  className

}: ItemProp) => {

  return (
    <RoadmapItemContainer color={color} className={className}>
      <div className="line" />
      <div className={`circle -ml-3 bg-[${color}]`}>
        <div className='child'></div>
      </div>

      <div className="items-center relative">
        <div className="md:block w-1/2 t-1">
          <h3 className="font-bold">{date}</h3>
          <div className='item'>
            <p>{title}</p>
          </div>
        </div>

      </div>
    </RoadmapItemContainer>
  )
}

export default RoadmapItem