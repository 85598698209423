import { Wrapper } from './styled'
import {Image_modal01, Image_modal02} from '@assets'
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
const Modal = ()  => {
  const { t} = useTranslation();
  return (
    <Wrapper >
        <div>
            <NavLink
              to='/SeminarRooms'
            >
            <div className='item block'>
              <div className='bg-[#2f5597] text-white py-2'>
                <p className='title font-bold'>{t("Seminarroom.title")}</p>
                <p className='title font-bold'>{t("Seminarroom.content2")}</p>
              </div>
              <img src={Image_modal01} alt='' className='image' style={{position: "absolute"}}/>
            </div>
           </NavLink>
        </div>
        <div>
            <NavLink
              to='/ECMall'
            >
            <div className='item block'>
              <div className='bg-[#843c0c] text-white py-2'>
                <p className='title font-bold'>{t("CommercialShops.title")}</p>
                <p className='title font-bold'>{t("CommercialShops.content2")} </p>
              </div>
              <img src={Image_modal02} alt='' className='image1' />
            </div>
            </NavLink>
        </div>
    </Wrapper>
  )
}

export default Modal
