export const items = [
    // {
    //     id:'0',
    //     namejp: "企業倫理",
    //     nameen: "Corporate ethics",
    //     path: "/",
    //     isActive: false,
    //     child : [
    //        {namejp: "企業倫理",
    //         nameen: "Corporate ethics"
    //         },
    //        {namejp: "企業倫理",
    //         nameen: "Corporate ethics"
    //         },
    //        {namejp: "企業倫理",
    //         nameen: "Corporate ethics"
    //         },
    //     ]
    // },
    // {
    //     id:'1',
    //     namejp: "ガバナンス",
    //     nameen: "Governance",
    //     path: "/",
    //     isActive: false,
    //     child : [
    //        {namejp: "ガバナンス",
    //         nameen: "Governance"
    //         },
    //        {namejp: "ガバナンス",
    //         nameen: "Governance"
    //         },
    //        {namejp: "ガバナンス",
    //         nameen: "Governance"
    //         },
    //     ]
    // },
    // {
    //     id:'2',
    //     namejp: "リスクマネジメント",
    //     nameen: "Risk management",
    //     path: "/",
    //     isActive: false,
    //     child : [
    //        {namejp: "リスクマネジメント",
    //         nameen: "Risk management"
    //         },
    //        {namejp: "リスクマネジメント",
    //         nameen: "Risk management"
    //         },
    //        {namejp: "リスクマネジメント",
    //         nameen: "Risk management"
    //         },
    //     ]
    // },
    // {
    //     id:'3',
    //     namejp: "コンプライアンス",
    //     nameen: "Compliance",
    //     path: "/",
    //     isActive: false,
    //     child : [
    //        {namejp: "コンプライアンス",
    //         nameen: "Compliance"
    //         },
    //        {namejp: "コンプライアンス",
    //         nameen: "Compliance"
    //         },
    //        {namejp: "コンプライアンス",
    //         nameen: "Compliance"
    //         },
    //     ]
    // },
    // {
    //     id:'4',
    //     namejp: "内部統制システム",
    //     nameen: "Internal control system",
    //     path: "/",
    //     isActive: false,
    //     child : [
    //        {namejp: "内部統制システム",
    //         nameen: "Internal control system"
    //         },
    //        {namejp: "内部統制システム",
    //         nameen: "Internal control system"
    //         },
    //        {namejp: "内部統制システム",
    //         nameen: "Internal control system"
    //         },
    //     ]
    // },
    // {
    //     id:'5',
    //     namejp: "関係団体とのかかわり",
    //     nameen: "Relations with Related Organizations",
    //     path: "/",
    //     isActive: false,
    //     child : [
    //        {namejp: "関係団体とのかかわり",
    //         nameen: "Relations with Related Organizations"
    //         },
    //        {namejp: "関係団体とのかかわり",
    //         nameen: "Relations with Related Organizations"
    //         },
    //        {namejp: "関係団体とのかかわり",
    //         nameen: "Relations with Related Organizations"
    //         },
    //     ]
    // }
]