import React, { useRef, useEffect} from 'react'
import styled from 'styled-components';
const Wrapper = styled.div `
width: 100%;
padding: 6rem 0 10rem;
@media screen and (max-width: 1620px){
   padding: 4rem 0 10rem;
}
@media screen and (max-width: 991px){
   padding: 1rem 0 10rem;
   }
@media print{
   padding:2rem 0;
   h1{
      font-size: 20px;
   }
   .a_none{
      display: none;
   }
}
.container_main{
   width: 70%;
   @media screen and (max-width: 1620px) {
      width: 75%;
   }
   @media screen and (max-width: 1280px) {
      width: 90%;
   }
   margin: auto;
   @media print {
      width: 65%;  
      margin-bottom: 10rem;
   }
   .wprint{
      display: none;
      @media print{
         display: block;
      }
   } 
   .item{
      width: 280px;
      height: 238px;
      background-color: white;
      text-align: center;
      -webkit-clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);
      clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);
   }
   .w-fisrt{
      width: 40%;
      @media screen and (max-width: 1280px) {
         width: 34%;
      }
      @media screen and (max-width: 600px) {
         width: 80%;
      }
      @media print {
         width :200px;
      }
   }
   p{
      line-height: 1.6;
      font-size: 19px;
      @media print{
        font-size: 14px;
      }
      @media screen and (max-width: 1620px) {
         font-size: 18px;
      }
      @media screen and (max-width: 1280px) {
         font-size: 16px;
      }
      @media screen and (max-width: 580px) {
         font-size: 14px;
      }
   }
   .flex-item{
      display: flex;
      @media screen and (max-width: 600px) {
         display: block;
      }
   }

   @media screen and (max-width: 991px) {
      margin-top: 80px;
      width: 100%;
      p {
         font-size: 15px;
      }
   }

   @media screen and (max-width: 767px) {
      .flex {
         width: 100%;

         div {
            width: 50%;
         }
      }
   }
}

`
const MainBusinessPartner = () => {
   const divOne = useRef<HTMLInputElement | null>(null);
   const scrolLWithUseRef = () => {
   divOne.current?.scrollIntoView({ block: "center", behavior: "smooth" });
   };
 
   useEffect(() => {
    scrolLWithUseRef()
   },[])
   return (
      <>
      <Wrapper>
      <div className='container_main'>
         <h1 className='text-[30px] leading-normal md:leading-relaxed mb-2 font-bold text-center' ref={divOne}>主なお取引先</h1>
         <div className='block mt-[3rem]'>
            <p className='text-1xl md:text-1xl font-bold leading-normal md:leading-relaxed'>取引銀行</p>
            <div className='block ml-8 mt-2'>
                <div className='flex-item'>
                  <div className='w-fisrt flex items-center'> <p></p></div>
                  <div>
                     <p>三井住友銀行（本店営業部）</p>
                     <p>東京信用金庫（練馬支店）</p>
                  </div>
               </div>
            </div>
         </div>
         <div className='block mt-4 text-justify'>
            <p className='text-1xl md:text-1xl font-bold leading-normal md:leading-relaxed'>取引先</p>
            <div className='block ml-8 mt-2'>
               <div className='flex-item'>
                  <div className='w-fisrt flex'> <p>システム開発会社</p></div>
                  <div>
                     <p>BAP.Inc、BAP Solution Japan</p>
                     <a href='https://bap-software.net/' className='underline text-[#4472c4]'>https://bap-software.net/</a><br/>
                     <a href='https://bap-software.net/about/' className='underline text-[#4472c4]'>https://bap-software.net/about/</a>
                  </div>
               </div>
               <div className='flex-item mt-4'>
                  <div className='w-fisrt flex '> <p>顧問弁理士事務所</p></div>
                  <div>
                     <p>特許業務法人　酒井国際特許事務所</p>
                     <a href='https://sakai-pat.com/ja/' className='underline text-[#4472c4]'>https://sakai-pat.com/ja/</a>
                  </div>
               </div>
               <div className='flex-item mt-4'>
                  <div className='w-fisrt flex'> <p>顧問弁護士事務所</p></div>
                  <div>
                     <p>法律事務所ZeLo・外国法共同事業</p>
                     <a href='https://zelojapan.com/' className='underline text-[#4472c4]'>https://zelojapan.com/</a>
                  </div>
               </div>
               <div className='flex-item mt-4'>
                  <div className='w-fisrt flex'> <p>顧問税理士事務所</p></div>
                  <div>
                     <p>ベンチャーサポート税理士法人</p>
                     <a href='https://vs-group.jp/tax/' className='underline text-[#4472c4]'>https://vs-group.jp/tax/</a>
                  </div>
               </div>
               <div className='flex-item mt-4'>
                  <div className='w-fisrt flex'> <p>業務委託先</p></div>
                  <div>
                     <p>ＳＭＢＣクラウドサイン株式会社</p>
                     <a href='https://www.smbc-cloudsign.co.jp/' className='underline text-[#4472c4]'>https://www.smbc-cloudsign.co.jp/</a>
                  </div>
               </div>
               <div className='flex-item mt-4'>
                  <div className='w-fisrt flex'> <p>業務提携先(予定)</p></div>
                  <div>
                     <p>MTテクノロジーズ株式会社</p>
                     <a href='https://www.mt-technologies.co.jp/' className='underline text-[#4472c4]'>https://www.mt-technologies.co.jp/</a>
                  </div>
               </div>
            </div>
         </div>
         <div className='mt-4 text-justify'>
            <p className='text-1xl md:text-1xl font-bold leading-normal md:leading-relaxed'>仕入先等</p>
            <div className='block ml-8 mt-2'>
               <div className='flex-item mt-4'>
                  <div className='w-fisrt flex'> <p>暗号資産ジパングコイン(ZPG)</p></div>
                  <div>
                     <p>三井物産デジタルコモディティーズ株式会社</p>
                     <a href='https://www.mitsuidc.com/' className='underline text-[#4472c4]'>https://www.mitsuidc.com/</a>
                  </div>
               </div>
               <div className='flex-item mt-4'>
                  <div className='w-fisrt flex'> <p>法人ゴールドパートナー</p></div>
                  <div>
                     <p>三菱マテリアル株式会社</p>
                     <a href='https://www.mmc.co.jp/corporate/ja/product/metals/mgp.html' className='underline text-[#4472c4]  a_none'>https://www.mmc.co.jp/corporate/ja/product/metals/mgp.html</a>
                     <a href='https://www.mmc.co.jp/corporate/ja/product/metals/mgp.html' className='underline text-[#4472c4] wprint'>https://www.mmc.co.jp/corporate/ja/<br/>product/metals/mgp.html</a>
                  </div>
               </div>
            </div> 
         </div>
      </div>
      </Wrapper>
      </>
   )
}
export default MainBusinessPartner
