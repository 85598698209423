import styled from 'styled-components'

export const Wrapper = styled.div`
  height: 100%;
  display: block;
  .container-home{
    width: 100%;
    margin-top: 4rem;
     .right{
      width: 20%;
      height: 79%;
      margin-left: 80%;
      @media screen and (max-width: 1280px){
        margin-left: 77%;
      }
      
      }
      .start-selling{
        margin : auto;
        width: 58%;
        padding-bottom: 3rem;
        .line-up{
          position: absolute;
          width: 7px;
          margin-left: -4px;
          background: #2F1B11;
          transform: skewY(-52deg);
        }
        .line-down{
          position: absolute;
          width: 1px;
          background: #2F1B11;
        }
        .title{
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 35px;
          color: #2F1B11;
        }
        .item_fi{
          width:100%;
          background: rgba(217, 197, 181, 0.3);
        }
        .item_se{
          width:100%;
          height: 105px;
          background: rgba(217, 197, 181, 0.3);
        }
        .text{
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 25px;
          color: black;
        }
      }
  }
 
`
