/* eslint-disable react/prop-types */
import {
  Route
} from 'react-router-dom'

import HomeLayout from '@layouts/home'

function PrivateRoute({
  component: Component,
  layout: Layout = HomeLayout,
  ...rest
}: any) {
  return (
    <Route
      {...rest}
      render={(props: any) => 
        <Layout>
          <Component {...props} />
        </Layout>}
    />
  )
}

export default PrivateRoute
