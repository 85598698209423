
import styled from 'styled-components'

export const Wrapper = styled.div`
width: 1100px;
@media screen and (max-width: 1620px) {
width: 1000px;
}
@media screen and (max-width: 1280px) {
width: 70%;
}
@media screen and (max-width: 991px) {
width: 80%;
}
margin: auto;
@media screen and (max-width: 991px) {
  margin-top: 80px;
}
.container_policy{
    width: 100%;
    padding: 6rem 0 10rem;
    @media screen and (max-width: 1620px){
    padding: 4rem 0 10rem;
    }
    @media print{
      padding:2rem 0 6rem;
      h1{
          font-size: 20px;
        }
    }
    .content{
      width: 100%;
      margin: auto;
      @media print{
        width: 80%;
      }
      p{
        line-height: 1.6;
        font-size: 20px;
        @media print{
          font-size: 14px;
        }
        @media screen and (max-width: 1620px) {
          font-size: 18px;
        }
        @media screen and (max-width: 1280px) {
          font-size: 16px;
        }
        @media screen and (max-width: 580px) {
          font-size: 14px;
        }
      }
      h2{
        font-size: 24px;
        font-weight: 700;
        color: #4472c4;
        @media screen and (max-width: 1620px) {
          font-size: 20px;
        }
        @media print{
          font-size: 16px;
        }
      }
    }

    @media screen and (max-width: 991px) {
      h1{
        font-size: 1.5rem;
      }
    }

  }
 
`