import styled from 'styled-components'

export const Wrapper = styled.div`
 h2{
    font-size: 24px;
    color: #4472c4;
    @media screen and (max-width: 1620px) {
      font-size: 20px;
    }
    @media print{
      font-size: 16px;
    }
  }
  .text_black{
    color : black !important;
  }
  .w_infor{
    width: 1080px;
    margin: auto;
    @media screen and (max-width: 1620px){
      width: 800px;
    }
    @media screen and (max-width: 1280px){
      width: 100%;
    }
    @media screen and (max-width: 580px){
      width: 95%;
    }
  }
  .h_diag02_45{
    height: 600px;
    @media screen and (max-width: 1620px){
      height: 550px;
    }
    @media screen and (max-width: 1280px){
      height: auto;
    }
    @media screen and (max-width: 580px){
      height: auto;
    }
  }
.container_con0{
    width: 100%;
    padding: 6rem 0 0;
    @media screen and (max-width: 1620px){
    padding: 4rem 0 0;
    }
    @media screen and (max-width: 580px){
      padding: 8rem 0 0;
    }
    .print{
      display: none;
    }
    .mb_30r{
      margin-bottom: 30rem;
      @media screen and (max-width: 580px){
        margin-bottom :25rem;
        }
      @media print{
        margin-bottom :20rem;
      }
    }
    .text_16{
      font-size: 16px !important;
    }
    .ml_bt{
      margin-left: 1100px;
      @media screen and (max-width: 1620px){
        margin-left: 1000px;
      }
      @media screen and (max-width: 1280px){
        margin-left: 840px;
      }
    }
    @media print{
      padding:2rem 0 0;
      width: 80%;
      margin: auto;
      .noprint{
        display: none;
      }
      .print{
        display: block;
      }
    }
    .ml_1100{
        margin-left: 1100px;
        @media screen and (max-width: 1280px){
          margin-left: 70%;
        }
      }
    .section1{
    width: 100%;
    margin-top: -2rem;
    margin: auto;
   }
   h2{
    font-size: 24px;
    color: #4472c4;
    @media screen and (max-width: 1620px) {
      font-size: 20px;
    }
    @media print{
      font-size: 16px;
    }
  }
  h3{
    font-size: 20px;
    @media screen and (max-width: 1620px) {
      font-size: 18px;
    }
    @media print{
      font-size: 16px;
    }
  }
    .content{
      width: 100%;
      @media screen and (max-width: 1620px){
        width: 90%;
      }
      @media screen and (max-width: 1280px){
        width: 100%;
      }
      @media screen and (max-width: 991px){
        width: 85%;
      }
      margin: auto;
      .mt_5r{
        margin-top: 5rem;
        @media print{
          margin-top: 1rem;
        }
      }
      .mt_3r{
        margin-top: 3rem;
        @media print{
          margin-top: 1rem;
        }
      }
      .mt_2r{
        margin-top: 2rem;
        @media print{
          margin-top: 1rem;
        }
      }
      @media print{
        .btNoPrint{
          display: none;
        }
        h1{
          font-size: 20px;
        }
        .imgprint{
          height: 230px;
          margin-left: 5rem;
        }
        .img02Print,.img01Print{
          height : 400px;
          width: 100%;
        }
        .img_diag01{
          width: 50%;
          margin: auto;
        }
        .img03Print{
          width: 60%;
          margin: auto;
        }
      }
      .title{
      font-style: normal;
      font-weight: 700;
      line-height: 35px;
      color: #0972c1;
      letter-spacing: 2px;
      }
      h2{
        font-size: 24px;
        color: #4472c4;
        @media screen and (max-width: 1620px) {
          font-size: 20px;
        }
        @media print{
          font-size: 16px;
        }
      }
      button,p{
        line-height: 1.6;
        font-size: 19px;
        @media print{
          font-size: 14px;
        }
        @media screen and (max-width: 1620px) {
          font-size: 18px;
        }
        @media screen and (max-width: 1280px) {
          font-size: 16px;
        }
        @media screen and (max-width: 580px) {
          font-size: 14px;
        }
      }
      button{
        padding:10px 30px;
        border-radius: 10px;
        margin-top: 1rem;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        text-transform: uppercase;
        color: #FFFFFF;
        box-shadow: rgb(0 0 0 / 25%) 0px 14px 28px, rgb(0 0 0 / 22%) 0px 10px 10px;
        @media screen and (max-width: 1280px){
          padding: 10px 20px;
        }
        @media screen and (max-width: 580px) {
          padding: 10px 15px;
        }
        @media print{
          font-size: 12px;
          padding: 5px 20px;
        }
      }
      .btn_v{
        padding: 8px !important;
      }
      .h_4{
        height: 450px;
        @media print{
          height: 350px;
        }
        @media screen and (max-width: 1620px) {
          height: 400px;
        }
        @media screen and (max-width: 991px) {
          height: 350px;
        }
        @media screen and (max-width: 580px) {
          height: 200px;
        }
      }
      .w_table{
        @media screen and (max-width: 991px) {
          width: 70%;
        }
        @media screen and (max-width: 580px) {
          width: 95%;
        }
      }
      @media screen and (max-width: 580px) {
        .flex_item{
          flex-direction: column;
        }
      }
      .w_table-01{
        width: 50%;
        @media screen and (max-width: 1620px) {
          width: 65%;
        }
        @media screen and (max-width: 580px) {
          width: 90%;
        }
      }
      @media screen and (max-width: 767px) {
        .number_company{
          flex-direction: column;
          >div{
            &:nth-child(1){
              width: 70%;
              margin: auto;
            }
            &:nth-child(2){
              width: 16%;
              margin: auto;
            }
          }
        }
      }
      @media screen and (max-width: 580px) {
        .img_diag01{
          width: 100%;
        }
        .number_company{
          >div{
            &:nth-child(1){
              width: 90%;
              margin: auto;
            }
          }
        }
      }
    }
  }
  .start-selling-contact{
      margin : auto;
      width: 70%;
      padding-bottom: 6rem;
      @media print{
        margin-top: 1rem;
        margin-bottom: 1rem;
        height: 1200px;
      }
      .mt_6{
        margin-top: 1.5rem;
        @media print{
          margin-top: 0.5rem;
        }
      }
      .text_16{
        font-size: 16px ;
        @media screen and (max-width: 1280px) {
          font-size: 14px;
        }
      }
      .line-up{
          position: absolute;
          width: 7px;
          margin-left: -4px;
          background: #2F1B11;
          transform: skewY(-52deg);
        }
        .line-down{
          position: absolute;
          width: 1px;
          background: #2F1B11;
        }
        .item_fi{
          width: 100%;
          background: rgba(217, 197, 181, 0.3);
        }
        .item_se{
          width: 100%;
          height: 105px;
          background: rgba(217, 197, 181, 0.3);
        }
        .text{
          line-height: 25px;
          letter-spacing: 2px;
        }
        .title{
          font-style: normal;
          font-weight: 700;
          line-height: 35px;
          color: #0972c1;
          letter-spacing: 2px;
        }
        .title_{
          font-style: normal;
          font-weight: 700;
          line-height: 35px;
          color: #0972c1;
          letter-spacing: 2px;
          width:90%;
          border-bottom :2px solid #0972c1;
        }
        textarea,input{
          width:92%;
          background : #f4f3ed;
          border: 0.5px solid #7e7e7e4d;
          padding : 5px 10px;
          transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 25px;
          color: black;
          letter-spacing: 2px;
          @media print{
            height: 30px;
          }
        }
        textarea{
          height:150px;
        }
        textarea,input:focus {
          border: 1px solid;
          border-color: #8c9395c7;
          outline: none;
        }
        input[type="radio"] {
          width: 2.5%;
          padding: 5px;
          }
        button{
          width: 350px;
          height: 50px;
          background: #c55a11;
          border-radius: 10px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px;
          text-transform: uppercase;
          color: #FFFFFF;
          box-shadow: rgb(0 0 0 / 25%) 0px 14px 28px, rgb(0 0 0 / 22%) 0px 10px 10px;
          @media print{
            height: 30px;
            font-size: 12px;
            width: 250px;
          }
        }
        button: hover{
          border: 1px solid;
          border-color: #8c9395c7;
        }
        .font_small{
          font-size: smaller;
        }
        h2{
        font-size: 24px;
        color: #4472c4;
        @media screen and (max-width: 1620px) {
          font-size: 20px;
        }
        @media print{
          font-size: 16px;
        }
      }
      button,p,label{
        line-height: 1.6;
        font-size: 19px;
        @media print{
          font-size: 12px;
        }
        @media screen and (max-width: 1620px) {
          font-size: 18px;
        }
        @media screen and (max-width: 1280px) {
          font-size: 16px;
        }
        @media screen and (max-width: 580px) {
          font-size: 14px;
        }

        &.m-auto {
          @media screen and (max-width: 480px) {
            width: auto;
            padding: 10px;
          }
        }

      }
      }
      .container_{
      margin-left:3rem;
      @media screen and (min-width: 1920px){
          margin-left: 3rem;
      }
      @media print{
        margin-left: 2rem;
      }
    }

    @media screen and (max-width: 991px){
      .start-selling-contact {
        width: 100%;
        .radio-box {
          justify-content: center;
          align-items: center;
  
          input[type="radio"] {
            width: 20px !important;
            height: 20px;
          }
        }
      }
      h1{
        font-size: 1.5rem;
      }
      >div {
        &.flex {
          margin-bottom: 28rem;
        }

        &:nth-child(2), &:nth-child(3) {
        }
        &:nth-child(3) {
          padding-top: 0;
        }
        &:nth-child(2) {
          .container_con {
            .section1 {
              .content {
                .block-1 {
                  flex-direction: column;
                    >div {
                      width: 100%;
                    }
                }

                .block-5 {
                  img {
                    width: 100%;
                  }
                }
              }
            }
          }
        }
      }
    }

    @media screen and (max-width: 767px) {
    .start-selling-contact {
      .item_fi {
        >div {
          &.block {
            .container_ {
              margin-left: 0;

              >div {

                form {
                  padding: 20px;
                  input[type="radio"] {
                    width: 4%;
                  }

                  >div {
                    width: 100%;

                    &:nth-child(3) {
                      flex-direction: column;
                      >div {
                        width: 100%;

                        &:last-child {
                          // padding-left: 10px;
                        }
                      }
                      input {
                        width: 100%;
                      }
                    }

                      input {
                        width: 100%;
                      }
                  }

                  > p {
                    width: 100%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

    @media screen and (max-width: 767px){
      .start-selling-contact {
        .container_ {
          padding: 20px 15px;
        }
      }
    }
`