import React from 'react';
import { useHistory } from 'react-router-dom';

const NotFound = () => {
    const history = useHistory();
return (
  <div className="not-found text-center font-bold text-lg">
    <img className='w-[50px] m-auto my-[2rem]' src="https://toppng.com/uploads/preview/exclamation-mark-png-exclamation-mark-icon-11563006763v9utxg8tnp.png" alt='' />
    <p className='text-[25px] mb-[1rem]'>情報がありません。</p>
    <button onClick={() => history.goBack()}>Go back</button>
  </div>
)};

export default NotFound;