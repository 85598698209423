import { FC ,useState,useEffect} from 'react'
import styled from "styled-components"
import { LOGO } from '@assets'
//@ts-ignore
import { Dimensions } from 'react-native';

const isPortrait = () => {
  const dim = Dimensions.get('window');
  return dim.height >= dim.width;
};

interface Prop {
  onClick: any;
  isOpen: boolean;
}

const HeaderMobileContainer = styled.div`
  position: fixed;
  top: 0;
  border-bottom: 0.5px solid #deddd7;
  background: #F4F3ED;
  width: 110%;
  z-index: 99;
  justify-content: center;
  padding: 0 15px;
  -moz-animation: fadeOut 1.5s ease;
  -webkit-animation: fadeOut 1.5s ease;
  animation: fadeOut 1.5s ease;
  transition: margin 100ms ease;
  margin-left: -2rem;
  @media only screen and (orientation:landscape) {
    padding: 10px 15px 2px;
  }
  .flex {
    align-items: center;
    width: 100%;
  }

  .logo-mobile {
    width: 80%;
    display: flex;
    justify-content: center;
    @media screen and (max-width: 991px) and (orientation: portrait) {
      .portrait{
        width: 20%;
      }
      .landscape{
        display: none;
      }
    }
    @media screen and (max-width: 580px) and (orientation: portrait) {
      .portrait{
        width: 45%;
      }
      .landscape{
        display: none;
      }
    }

    @media only screen and (orientation:landscape) {
      width: 85%;
      .portrait{
        display: none;
      }
      .landscape{
        width: 15%;
      }
    }
  }

  .line-container  {
    width: 32px;
    .line {
      width: 100%;
      height: 2px;
      background-color: #333;
      margin-bottom: 10px;
      @media only screen and (orientation:landscape) {
        margin-bottom: 6px;
      }
    }

    .open {
      position: relative;
      height: 40px;

      .line {
        position: absolute;
        top: 20px;
        animation-duration: 0.3s, 0.3s;

        &:first-child {
          transform: rotate(45deg);
          animation-name: rotate;
        }
        &:last-child {
          transform: rotate(-45deg);
          animation-name: rotate1;
        }
      }
    }
  }

  @keyframes rotate {
    from {
      transform: rotate(0deg);
      animation-timing-function: ease-out;
    }
    to {
      transform: rotate(45deg);
    } 
  } 

  @keyframes rotate1 {
    from {
      transform: rotate(0deg);
      animation-timing-function: ease-out;
    }
    to {
      transform: rotate(-45deg);
    } 
  } 

  &.open {

  }
`

const HeaderMobile: FC<Prop> = ({ onClick, isOpen }) => {
  const [style, setStyle] = useState({ display: '' });
  const [orientation, setOrientation] = useState<'PORTRAIT' | 'LANDSCAPE'>(
    isPortrait() ? 'PORTRAIT' : 'LANDSCAPE',
  );
  
  useEffect(() => {
    const callback = () => setOrientation(isPortrait() ? 'PORTRAIT' : 'LANDSCAPE');

    Dimensions.addEventListener('change', callback);
    if(orientation === 'LANDSCAPE'){
      const timer = setTimeout(() => {
        setStyle({ display: 'none' });
      }, 3000);
      return () => clearTimeout(timer);
    }else{
      setStyle({ display: '' });
    }
  }, [orientation]);

  return (
    <HeaderMobileContainer className='header-mobile-container'>
      <div className='text-center ml-[2rem]'>
        <div className='flex'>
          <div
            className='line-container'
            onClick={onClick}
          >
            {isOpen ? (
              <div className='open'>
                <div className='line' />
                <div className='line' />
              </div>
            ) : (
              <>
                <div className='line' />
                <div className='line' />
                <div className='line' />
              </>
            )}
          </div>
          <div className='logo-mobile'>
            <img src={LOGO} alt='' className='portrait'/>
            <img src={LOGO} alt='' className='landscape' style={style}/>
          </div>
        </div>
      </div>
    </HeaderMobileContainer >
  )
}

export default HeaderMobile