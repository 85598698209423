import React, {useRef, useEffect} from 'react'
//@ts-ignore
import { useForm } from "react-hook-form";
import { Wrapper } from './styled'
import ModalNoti from '@components/modalNoti';
import { ApplicationContactUsInformation } from '../../../APIs';
import { options2, chooseArea} from '../../../Utils'
import {Image_FlowRegisterEC,Image_InforCompanyEC} from '@assets'
import useScreenWidth from '../../../Hook/useScreenWidth'
import ModalImage from './modalImage';

const CorporateContractsStoreScreen = () => {
     const {
         register,
         handleSubmit,
         setValue,
         formState: { errors }
      } = useForm();
      const [notification, setNotification] = React.useState(false);
      const [isChecked1, setIsChecked1] = React.useState(false);
      const [isChecked2, setIsChecked2] = React.useState(false);
      const [check, setCheck] = React.useState(false);
      const [submit, setSubmit] = React.useState(false);
      const [dataForm, setDataForm] = React.useState({
         nameCompany:'',
         nameRepresent : '',
         nameRepresentSpelling: '',
         address: '',
         nameContact:'',
         nameContactSpelling : '',
         email : '',
         phone :'',
         numberPlots: '',
         chooseArea:''
      });
   
      const [isShowImage, setIsShowImage] = React.useState(false);
      const [image, setImage] = React.useState<any>([])
      const [selectedFile, setSelectedFile] = React.useState('');
      const [file, setFile] = React.useState<any>([]);
      const [checkfile, setchekFile] = React.useState(true);
      const [checkSize, setchekSize] = React.useState(true);
      const [count, setCount] = React.useState(0);
      const handleFileSelect = (event : any) => {
         for(var y=0;y<event.target.files.length;y++){
            file.push(event.target.files[y].name);
         }
         setSelectedFile(event.target.files)
         for(var x=0;x<event.target.files.length;x++){
            image.push(URL.createObjectURL(event.target.files[x]));
            if(event.target.files[x].size > '4000000'){
               setCount(count + 1)
               setSelectedFile('')
            }
         }
      }

      const onCheck = (data : any) => {
         setDataForm({
            nameCompany:`${data.nameCompany}`,
            nameRepresent : `${data.firstnameRepresent}${data.lastnameRepresent}`,
            nameRepresentSpelling: `${data.firstnameRepresentspelling}${data.lastnameRepresentspelling}`,
            address: `${data.address}`,
            nameContact:`${data.firstnameContact}${data.lastnameContact}`,
            nameContactSpelling : `${data.firstnameContactspelling}${data.lastnameContactspelling}`,
            email : `${data.email}`,
            phone :`${data.phonenumber}`,
            numberPlots : `${data.numberPlots}`,
            chooseArea: `${data.chooseArea}`
         })
         if(selectedFile){
            setCheck(!check);
         }else{
            setchekFile(false)
         }

         if(selectedFile.length === 0){
            setchekFile(false)
         }
      }

      useEffect(()=>{
         const _files=[];
         for(var x=0;x<selectedFile.length;x++){
            _files.push(selectedFile[x]);
         }
         const textUser =`${dataForm.nameCompany}<br/>
         ${dataForm.nameRepresent} 様<br/>
         ${dataForm.nameContact} 様<br/>
         <br/>このたびは、弊社ＥＣモール商業店舗の賃貸借の申込をいただき、まことにありがとうございます。
         <br/>追って、弊社から契約書、賃貸借いただく店舗の番号、特別割引き賃貸料（年一括払いのみ）、代金振込先等をご連絡するメールを、別途、送信させていただきます。
         <br/>申込が集中いたしておりますので、一週間程度お時間をいただくことがございます。
         <br/>宜しくお願い申し上げます。         
         <br/>なお、先着順となっておりますので、メタバース不動産が全て賃貸借済みとなり、お申し込みをお受けできない場合もございます。
         <br/>その際は何卒ご容赦賜りたくお願い申し上げます。
         <br/><br/>■記載内容：
         <br/>　　法人名：${dataForm.nameCompany}
         <br/>　　法人の代表者名：${dataForm.nameRepresent}
         <br/>　　法人の代表者名（フリガナ）：${dataForm.nameRepresentSpelling}
         <br/>　　法人の住所：${dataForm.address}
         <br/>　　法人担当者名：${dataForm.nameContact}
         <br/>　　法人担当者名（フリガナ）：${dataForm.nameContactSpelling}
         <br/>　　法人担当者のメールアドレス：${dataForm.email}
         <br/>　　法人担当者の携帯電話番号：${dataForm.phone}
         <br/>　　賃貸する区画数と年一括払い賃貸料：${dataForm.numberPlots}
         <br/>　　出店希望ブロック：${dataForm.chooseArea}
         <br/>　　履歴事項全部証明書の写真を添付：添付あり
         <br/><br/>※当メールは、お客様からの賃貸借申込と同時に、システムによりメールを自動返信しております。当メールの受領のみでは
         <br/>　　契約は完了いたしておりません。ご了承いただきたくお願い申し上げます。
         <br/> ________________________________________________________
         <br/><br/>アートメタバースランド株式会社
         <br/>〒176-0022　東京都練馬区向山３－１５－１９
         <br/>電話番号　０３－６６３６－７８９３
         <br/>メールによるお問い合わせは、弊社ホームページお問い合わせフォームからお願いいたします。
         <br/> ________________________________________________________
         `

         const text =`${dataForm.nameCompany}<br/>
         ${dataForm.nameRepresent} 様<br/>
         ${dataForm.nameContact} 様<br/>
         <br/>このたびは、弊社ＥＣモール商業店舗の賃貸借の申込をいただき、まことにありがとうございます。
         <br/>追って、弊社から契約書、賃貸借いただく店舗の番号、特別割引き賃貸料（年一括払いのみ）、代金振込先等をご連絡するメールを、別途、送信させていただきます。
         <br/>申込が集中いたしておりますので、一週間程度お時間をいただくことがございます。
         <br/>宜しくお願い申し上げます。         
         <br/>なお、先着順となっておりますので、メタバース不動産が全て賃貸借済みとなり、お申し込みをお受けできない場合もございます。
         <br/>その際は何卒ご容赦賜りたくお願い申し上げます。
         <br/><br/>※当メールは、お客様からの賃貸借申込と同時に、システムによりメールを自動返信しております。当メールの受領のみでは
         <br/>　　契約は完了いたしておりません。ご了承いただきたくお願い申し上げます。
         <br/><br/>■記載内容：
         <br/>　　法人名：${dataForm.nameCompany}
         <br/>　　法人の代表者名：${dataForm.nameRepresent}
         <br/>　　法人の代表者名（フリガナ）：${dataForm.nameRepresentSpelling}
         <br/>　　法人の住所：${dataForm.address}
         <br/>　　法人担当者名：${dataForm.nameContact}
         <br/>　　法人担当者名（フリガナ）：${dataForm.nameContactSpelling}
         <br/>　　法人担当者のメールアドレス：${dataForm.email}
         <br/>　　法人担当者の携帯電話番号：${dataForm.phone}
         <br/>　　賃貸する区画数と年一括払い賃貸料：${dataForm.numberPlots}
         <br/>　　出店希望ブロック：${dataForm.chooseArea}
         <br/>　　履歴事項全部証明書の写真を添付：添付あり
         <br/> ________________________________________________________
         <br/><br/>アートメタバースランド株式会社
         <br/>〒176-0022　東京都練馬区向山３－１５－１９
         <br/>電話番号　０３－６６３６－７８９３
         <br/>メールによるお問い合わせは、弊社ホームページお問い合わせフォームからお願いいたします。
         <br/> ________________________________________________________
         `
         if(submit){
            ApplicationContactUsInformation({
               name : `${dataForm.nameContact}`,
               email: dataForm.email,
               data: textUser,
               cc: '',
               subject: 'ＥＣモール商業店舗の賃貸借の申込を受領いたしました',
               attachments:''
            },setNotification)

            ApplicationContactUsInformation({
               name : `${dataForm.nameContact}`,
               email: 'shop@artmetaverseland.co.jp',
               data: text,
               cc: '',
               subject: 'ＥＣモール商業店舗の賃貸借の申込を受領いたしました',
               attachments: _files
            },setNotification)
         }

      },[submit,dataForm,selectedFile]);

      const divOne = useRef<HTMLInputElement | null>(null);
      const scrolLWithUseRef = () => {
      divOne.current?.scrollIntoView({ block: "center", behavior: "smooth" });
      };
      useEffect(() => {
      scrolLWithUseRef()
      if(check){
         scrolLWithUseRef()
      }
      },[check])

      useEffect(() => {
         if(selectedFile.length === 0){
            setSelectedFile('')
         }else{
            setCount(0)
            setchekFile(true)
         }
         if(count > 0){
            setchekSize(false)
         }else{
            setchekSize(true)
         }
      },[checkfile,file,checkSize,selectedFile,count])
   
      const screenWidth = useScreenWidth()
   return (
      <>
      <Wrapper id="intro" >
         <div className='container-cor block'>
            <div className='start-selling-contact block'>
            <div className='item_fi flex'>
               {!check ? 
               <>
               <div className='pl-1 block pb-4 w-full'>
                  <div className='block p-2  mt_6'>
                     <h2 className='title text-center text-[25px] pt-2' ref={divOne}>ＥＣモール商業店舗の賃貸借申込フォーム</h2>
                  </div>
                  <div className='block p-2 container_ '>
                     <p className='title_ text-[20px] mb-2 pt_6'>ご契約者様</p>
                     <div className='ml-4'>
                        <form onSubmit={handleSubmit(onCheck)}>
                           <div className="grid">
                              <label className='text mt_6 font-bold'>法人名<span className='font-bold text-red-500 font_small'>「必須」</span></label>
                              <input className='mt-1' type="text"
                                  {...register("nameCompany", {
                                    required: true
                                 })} 
                                 onChange={e => setValue("nameCompany", e.target.value.trimStart())} 
                              />
                           </div>
                           <div className="grid">
                              <label className='text mt_6 font-bold'>法人の代表者名</label>
                           </div>
                           <div className='flex w-[93%]'>
                              <div className="grid w-1/2">
                                 <label className='text font-bold'>姓<span className='font-bold text-red-500 font_small'>「必須」</span></label>
                                 <input className='mt-1' type="text" 
                                    {...register("firstnameRepresent", {
                                       required: true
                                    })} 
                                    onChange={e => setValue("firstnameRepresent", e.target.value.trimStart())} 
                                 />
                              </div>
                              <div className="grid w-1/2">
                                 <label className='text  font-bold'>名<span className='font-bold text-red-500 font_small'>「必須」</span></label>
                                 <input className='mt-1' type="text" 
                                    {...register("lastnameRepresent", {
                                       required: true
                                    })} 
                                    onChange={e => setValue("lastnameRepresent", e.target.value.trimStart())} 
                                 />
                              </div>
                           </div>
                           <div className="grid">
                              <label className='text mt_6 font-bold'>法人の代表者名（フリガナ）</label>
                           </div>
                           <div className='flex w-[93%]'>
                              <div className="grid w-1/2">
                                 <label className='text font-bold'>姓<span className='font-bold text-red-500 font_small'>「必須」</span></label>
                                 <input className='mt-1' type="text" 
                                    {...register("firstnameRepresentspelling", {
                                       required: true
                                    })} 
                                    onChange={e => setValue("firstnameRepresentspelling", e.target.value.trimStart())} 
                                 />
                              </div>
                              <div className="grid w-1/2">
                                 <label className='text  font-bold'>名<span className='font-bold text-red-500 font_small'>「必須」</span></label>
                                 <input className='mt-1' type="text" 
                                    {...register("lastnameRepresentspelling", {
                                       required: true
                                    })} 
                                    onChange={e => setValue("lastnameRepresentspelling", e.target.value.trimStart())} 
                                 />
                              </div>
                           </div>
                           <div className="grid">
                              <label className='text mt_6 font-bold'>法人の住所<span className='font-bold text-red-500 font_small'>「必須」</span>　{ screenWidth > 580 ? '' : <br/>}<span className='font-bold text-[#0972c1] text_16'>都道府県から入力してください。郵便番号は不要です。</span></label>
                              <input className='mt-1' type="text" 
                                 {...register("address", {
                                    required: true
                                 })} 
                                 onChange={e => setValue("address", e.target.value.trimStart())} 
                               />
                           </div>
                           <div className="grid">
                              <label className='text mt_6 font-bold'>法人担当者名</label>
                           </div>
                           <div className='flex w-[93%]'>
                              <div className="grid w-1/2">
                                 <label className='text font-bold'>姓<span className='font-bold text-red-500 font_small'>「必須」</span></label>
                                 <input className='mt-1' type="text" 
                                    {...register("firstnameContact", {
                                       required: true
                                    })}
                                    onChange={e => setValue("firstnameContact", e.target.value.trimStart())} 
                                 />
                              </div>
                              <div className="grid w-1/2">
                                 <label className='text  font-bold'>名<span className='font-bold text-red-500 font_small'>「必須」</span></label>
                                 <input className='mt-1' type="text" 
                                    {...register("lastnameContact", {
                                       required: true
                                    })}
                                    onChange={e => setValue("lastnameContact", e.target.value.trimStart())} 
                                 />
                              </div>
                           </div>
                           <div className="grid">
                              <label className='text mt_6 font-bold'>法人担当者名（フリガナ）</label>
                           </div>
                           <div className='flex w-[93%]'>
                              <div className="grid w-1/2">
                                 <label className='text font-bold'>姓<span className='font-bold text-red-500 font_small'>「必須」</span></label>
                                 <input className='mt-1' type="text" 
                                    {...register("firstnameContactspelling", {
                                       required: true
                                    })} 
                                    onChange={e => setValue("firstnameContactspelling", e.target.value.trimStart())} 
                                 />
                              </div>
                              <div className="grid w-1/2">
                                 <label className='text  font-bold'>名<span className='font-bold text-red-500 font_small'>「必須」</span></label>
                                 <input className='mt-1' type="text" 
                                    {...register("lastnameContactspelling", {
                                       required: true
                                    })}
                                    onChange={e => setValue("lastnameContactspelling", e.target.value.trimStart())}  
                                 />
                              </div>
                           </div>
                           <div className="grid">
                              <label className='text mt_6 font-bold'>法人担当者のメールアドレス<span className='font-bold text-red-500 font_small'>「必須」</span></label>
                              <input className='mt-1' type="text" 
                                 {...register("email", {
                                    required: true,
                                    pattern:{
                                       value:
                                          /^[A-Z0-9._+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                          message: "無効なメールアドレス",
                                    }
                                 })}
                                 onChange={e => setValue("email", e.target.value.trimStart())} 
                              />
                              {errors.email?.message && (
                                 <h4 className="text-red-500 text_16">{errors.email?.message?.toString()}</h4>
                              )}
                           </div>
                           <div className="grid">
                              <label className='text mt_6 font-bold'>法人担当者の携帯電話番号<span className='font-bold text-red-500 font_small'>「必須」</span></label>
                              <input className='mt-1' type="text" 
                                 {...register("phonenumber", {
                                    required: true
                                  })}
                                  onChange={e => setValue("phonenumber", e.target.value.replace(/\D/,''))}
                              />
                           </div>
                           <div className="grid">
                              <label className='text mt_6 font-bold'>賃貸する区画数と年一括払い賃貸料<span className='font-bold text-red-500 font_small'>「必須」</span></label>
                               <select 
                                 className='mt-1'
                                  defaultValue=''
                                  {...register("numberPlots", {
                                    required: true
                                 })}
                                 >
                                 {options2.map((option : any) => (
                                    <option value={option.value}>
                                       {option.value}
                                    </option>
                                 ))}
                               </select>
                           </div>
                           <div className="grid">
                              <label className='text mt_6 font-bold'>出店希望ブロック</label>
                              <select 
                                 className='mt-1'
                                  defaultValue=''
                                  {...register("chooseArea", {
                                    required: true
                                 })}
                                 >
                                 {chooseArea.map((option : any) => (
                                    <option value={option.value}>
                                       {option.value}
                                    </option>
                                 ))}
                               </select>
                           </div>
                           <div className="grid">
                              <label className='text mt_6 font-bold'>履歴事項全部証明書の写真を添付<span className='font-bold text-red-500 font_small'>「必須」</span>　{ screenWidth > 580 ? '' : <br/>}<span className='font-bold text-[#0972c1] text_16'>１ファイルのサイズ上限は４ＭＢです。</span></label>
                              <div className='upload_img flex items-center'>
                                 <label htmlFor="img" className='w-[100%] break-words'>{selectedFile ? `${file}` : 'ファイルを選ぶ'}</label>
                                 <input className='mt-1' type="file" multiple id='img' style={{ display :"none"}}
                                    {...register("file")} 
                                    onChange={(e) => handleFileSelect(e)}
                                    onClick={() =>
                                       {setImage([])
                                        setFile([])
                                        setSelectedFile('')
                                       }}
                                 />
                              </div>
                              {!checkSize && (
                                 <h4 className="text-red-500 text_16">各添付ファイルの最大サイズは 4 MB です</h4>
                              )}
                              {!checkfile && (
                                 <h4 className="text-red-500 text_16">少なくとも 1 つのファイルを選択してください</h4>
                              )}
                           </div>
                           <p className='text-[12px] text-[#0563c1] mt_6'> <a href='../file/Privacy.pdf' target='_blank'>プライバシーポリシーをお読みいただき、同意の上、賃貸借申し込みをしてください。</a></p>
                           <div className='flex radio-box'>
                              <input className='mt-1' type="checkbox"
                                 onChange={() => setIsChecked1(!isChecked1)}
                              />
                              <p className='text mt-2 ml-2 font-bold'>プライバシーポリシーに同意する</p>
                           </div>
                           <p className='text-[#0972c1] mt-2'>重要なお知らせや関連ニュースを受け取るにチェックし、賃貸借申し込みをしてください。</p>
                           <div className='flex radio-box'>
                              <input className='mt-1' type="checkbox"
                                onChange={() => setIsChecked2(!isChecked2)}
                              />
                              <p className='text mt-2 ml-2 font-bold'>重要なお知らせやＥＣモール商業店舗関連のニュースを受け取ります。</p>
                           </div>
                           {isChecked1 && isChecked2 ?
                              <>
                              <div className="grid mt-3 w-[87%]">
                                 <label></label>
                                 <button type="submit" className='m-auto bg-[#A98260]' >賃貸借を申込する</button>
                              </div>
                              </>
                              :
                              <>
                              <div className="grid mt-3 w-[87%]">
                                 <label></label>
                                 <button type="submit" className='m-auto opacity-70 bg-[#A98260]'  disabled >賃貸借を申込する</button>
                              </div>
                              </>
                           }
                        </form>
                     </div>
                  </div>
               </div>
               </>
               :
               <>
               <div className='w-fit m-auto' ref={divOne}>
               <h2 className='text-center font-bold my-4' >賃貸借申込内容の確認</h2>
                  <div className={`m-auto w-full ${screenWidth > 580 ? 'px-[3rem]' :'px-[1rem]'}`}>
                     <p>法人名： {dataForm.nameCompany}</p>
                     <p>法人の代表者名：{dataForm.nameRepresent}</p>
                     <p>法人の代表者名（フリガナ）：{dataForm.nameRepresentSpelling}</p>
                     <p>法人の住所：{dataForm.address}</p>
                     <p>法人担当者名：{dataForm.nameContact}</p>
                     <p>法人担当者名（フリガナ）：{dataForm.nameContactSpelling}</p>
                     <p>法人担当者のメールアドレス：{dataForm.email}</p>
                     <p>法人担当者の携帯電話番号： {dataForm.phone}</p>
                     <p>賃貸する区画数と年一括払い賃貸料：{dataForm.numberPlots}</p>
                     <p>出店希望ブロック：{dataForm.chooseArea}</p>
                     <p>履歴事項全部証明書の写真を添付：添付あり</p>
                     <div>
                        {image?.map((item :any) => (
                           <div className='my-4 check'>
                              <img src={item} alt="" className='img_check mr-4 mb-2'/>
                           </div>
                         ))}
                        </div>
                     <div className='text-center mt-4'>
                        <button className='bg-[#4472c4]'
                           onClick={()=>{
                           setSubmit(!submit)
                           }
                        }
                           >
                           賃貸借申込を確定する</button>
                     </div>
                     <div className='my-8 flex'>
                        <div className='w-1/3'>
                           <button className='bg-white text_black bt_wh' 
                           onClick={()=>{
                              setCheck(!check)
                              setSubmit(false)
                              setIsChecked1(!isChecked1)
                              setIsChecked2(!isChecked2)
                              }}
                           >戻る</button>
                        </div>
                        <div className='w-2/3 text-right flex justify-end items-center'>
                           <button className='bg-white text_black bt_wh' onClick={()=>{setIsShowImage(!isShowImage)}}>特定商取引法に準じた表示事項</button>
                        </div>
                     </div>
                  </div>
               </div>
               </>}
            </div>
            {!check ? 
            <>
               <h2 className="leading-normal text_black md:leading-relaxed mb-2 text-center font-bold mt-[4rem]">ＥＣモール商業店舗の賃貸借に関するお手続きの流れ</h2>
               <img src={Image_FlowRegisterEC} alt='' className='m-auto' />
               <h2 className="leading-normal text_black md:leading-relaxed mb-2 font-bold mt-[4rem]">特定商取引法に準じた表示事項</h2>
               <img src={Image_InforCompanyEC} alt='' className='m-auto' /></> 
            :<></>}
         </div>
         </div>
         {notification && (
            <ModalNoti 
            event={() => {setNotification(false);
               window.location.reload();
            }
             }
             title1='当申込フォームを送信いただいた時点では、まだ契約は成立しておりません。'
             title2='この後、当社より、賃貸借いただく店舗番号を記載した契約書、賃貸代金と振込先について、メールにてご案内いたします。返信メールをご確認ください。'
            />
         )}
         {isShowImage && (
            <ModalImage 
            event={() => setIsShowImage(false)}
            />
         )}
      </Wrapper>
      </>
   )
}
export default CorporateContractsStoreScreen
