import React from 'react'
import { Wrapper } from './styled'
const Avatar = () => {
   return (
      <>
      <Wrapper>
         <div className='container_Avatar block'>
            <div className='content block'>
               <div className="timeline"> 
               <div className="timeline__event animated fadeInUp delay-1s">
                  <div className="timeline__event__date">アバターとアイテムの提供</div>
                  <div className="timeline__event__content">
                     <div className="timeline__event__avatar text-justify" id='id7'>
                        <p>　お客様は、ＩＤ、ニックネーム、アバター を、お一人ひとつずつ無料で保有できます。</p>
                        <p>　また、有料で別のニックネームとアバターをもう２つまでご利用いただくことができます。</p>
                        <p>　アバターの衣装やその他ファションアイテムは、ベーシックなものは無料で特別仕様のアイテムは有料でご提供いたします。</p>
                     </div>

                  </div>
               </div>
               </div>
            </div>
         </div>
         
      </Wrapper>
      </>
   )
}
export default Avatar
