import { useState } from "react"
import styled from "styled-components"
import { useTranslation } from "react-i18next"

import RoadmapItem from "./roadmap-item"

const RoadmapContainer = styled.div`
  .showmore{
    text-align: center;
    padding: 1rem 0 0;
    z-index: 1;
    position: relative;

    button{
      width: 198px;
      height: 50px;
      background: #A98260;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
      text-transform: uppercase;
      color: #FFFFFF;
      box-shadow: rgb(0 0 0 / 25%) 0px 14px 28px, rgb(0 0 0 / 22%) 0px 10px 10px;
    }
  }
`

const RoadmapMobile = () => {

  const { t } = useTranslation();
  const data = [
    {
      id: 1,
      date: t("pagehome.timeline.date1"),
      title: t("pagehome.timeline.title1"),
      color: '#0A8A80'
    },
    {
      id: 2,
      date: t("pagehome.timeline.date2"),
      title: t("pagehome.timeline.title2"),
      color: '#F0633D'
    },
    {
      id: 3,
      date: t("pagehome.timeline.date3"),
      title: t("pagehome.timeline.title3"),
      color: '#FAA713'
    },
    {
      id: 4,
      date: t("pagehome.timeline.date4"),
      title: t("pagehome.timeline.title4"),
      color: '#64820a'
    },
    {
      id: 5,
      date: t("pagehome.timeline.date5"),
      title: t("pagehome.timeline.title5"),
      color: '#f63300'
    },
    {
      id: 6,
      date: t("pagehome.timeline.date6"),
      title: t("pagehome.timeline.title6"),
      color: '#2996ff'
    },
    {
      id: 7,
      date: t("pagehome.timeline.date7"),
      title: t("pagehome.timeline.title7"),
      color: '#0A8A80'
    },
    {
      id: 8,
      date: t("pagehome.timeline.date8"),
      title: t("pagehome.timeline.title8"),
      color: '#F0633D'
    },
    {
      id: 9,
      date: t("pagehome.timeline.date9"),
      title: t("pagehome.timeline.title9"),
      color: '#FAA713'
    },
    {
      id: 10,
      date: t("pagehome.timeline.date10"),
      title: t("pagehome.timeline.title10"),
      color: '#648209'
    },
    {
      id: 11,
      date: t("pagehome.timeline.date11"),
      title: t("pagehome.timeline.title11"),
      color: '#F60500'
    },
    {
      id: 12,
      date: t("pagehome.timeline.date12"),
      title: t("pagehome.timeline.title12"),
      color: '#2996ff'
    },
    {
      id: 13,
      date: t("pagehome.timeline.date13"),
      title: t("pagehome.timeline.title13"),
      color: '#0A8A80'
    },
    {
      id: 14,
      date: t("pagehome.timeline.date14"),
      title: t("pagehome.timeline.title14"),
      color: '#F0633D'
    }
  ]

  const [showMore, setShowMore] = useState(false)
  const [dataRoadmap, setDataRoadmap] = useState(data.slice(0, 3))

  const onShowMore = () => {
    setShowMore(true)
    setDataRoadmap(data)
  }

  const onShowLess = () => {
    setShowMore(false)
    setDataRoadmap(data.slice(0, 3))
  }


  return (
    <RoadmapContainer>
      {dataRoadmap.map((d: any, index: number) => (
        <RoadmapItem
          title={d.title}
          date={d.date}
          color={d.color}
          key={index}
          className={index === data.length - 1 ? 'last' : ''}
        />
      ))}
      <div className='showmore'>
        <button onClick={!showMore ? onShowMore : onShowLess}>
          {showMore ? t("pagehome.btCloseTimeline") : t("pagehome.btOpenTimeline")}
        </button>
      </div>
    </RoadmapContainer>
  )
}

export default RoadmapMobile