import React, {useState, useEffect, useRef} from 'react'
import styled from 'styled-components';
import { Image_34 } from '@assets';
import useScreenWidth from '../../../Hook/useScreenWidth'
import ModalImage from './modalImage'
const Wrapper = styled.div `
   width: 100%;
   padding: 6rem 0 10rem;
   @media screen and (max-width: 1620px){
      padding: 4rem 0 10rem;
   }
   @media screen and (max-width: 991px){
    padding: 1rem 0 10rem;
    }
   h1{
      padding-bottom: 6rem;
      @media screen and (max-width: 1620px){
         padding-bottom: 4rem;
      }
      @media screen and (max-width: 991px) {
         font-size: 1.5rem;
      }
   }    
   .container_main{
      width: 100%;
      margin: auto;
   }

   @media screen and (max-width: 991px) {
      margin-top: 80px;
   }
   button{
        padding:10px 30px;
        border-radius: 10px;
        margin-top: 1rem;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        text-transform: uppercase;
        color: #FFFFFF;
        box-shadow: rgb(0 0 0 / 25%) 0px 14px 28px, rgb(0 0 0 / 22%) 0px 10px 10px;
        @media print{
          padding: 5px 20px;
        }
      }
`
const ScheduleUntil = () => {
   const [isShowImage, setIsShowImage] = useState(false);
   const screenWidth = useScreenWidth()
   const divOne = useRef<HTMLInputElement | null>(null);
   const scrolLWithUseRef = () => {
   divOne.current?.scrollIntoView({ block: "center", behavior: "smooth" });
   };
 
   useEffect(() => {
    scrolLWithUseRef()
   },[])
   return (
      <>
      <Wrapper>
         <div className='container_main'>
            <h1 className='text-[30px] leading-normal md:leading-relaxed font-bold text-center' ref={divOne}>アートメタバースランド開園までのスケジュール</h1>
            <img src={Image_34} alt='' id ='id3' className='w-full'/>
            {screenWidth > 991 &&(
               <div className='text-center mt-4'>
                  <button  onClick={() => setIsShowImage(!isShowImage)} className="bg-[#a98260]">クリックすると拡大</button>
               </div>
            )}
         </div>
         {isShowImage && (
            <ModalImage 
            event={() => setIsShowImage(false)}
            />
         )}
      </Wrapper>
      </>
   )
}
export default ScheduleUntil
