import { Wrapper } from './styled'
import { FC ,useState ,useEffect,useRef} from 'react';
import { useTranslation } from "react-i18next";

interface Prop{
  list : any; 
  setList : any;
  isClick:any;
}
const SiderBar:FC<Prop> = ({list,setList, isClick })  => {
  const [language, setLanguage] = useState(false);
  const {i18n } = useTranslation();
  useEffect (() => {
     if(i18n.language === "en")
     {
       setLanguage(true)
     }
     if(i18n.language === "jp")
     {
       setLanguage(false)
     }
  },[i18n.language])


  const handleClick = (item:any) => {
    const newList = list.map((_item: any) => {
      if (_item.id === item.id) {
        return { ..._item, isActive: true };
      } else {
        return { ..._item, isActive: false };
      }
    });
    setList(newList);
  }
  const scrollFun = (id: string) => {
    document
      .querySelector(`#id${id}`)
      ?.scrollIntoView({ block: "center", behavior: "smooth" });
  }

  const divOne = useRef<HTMLInputElement | null>(null);
  const scrolLWithUseRef = () => {
  divOne.current?.scrollIntoView({ block: "center", behavior: "smooth" });
  };

  useEffect(() => {
   scrolLWithUseRef()
  },[])

  return (
    <Wrapper >
      <div className={`siderbar ${isClick ? 'ml-0 w-[19rem]' : 'ml-[-21rem]'}`}> 
        <div className='container block'>
              <div className='menu ' ref={divOne}>
                <div className='item block'>
                    {list?.map((item :any, index :any) => (
                      <>
                      <div className='_item '>
                          <div className='h-[4rem] _name'>
                             <p className=' cursor-pointer text-left pt-[1.3rem] pl-8' key={index} 
                             onClick = {() => 
                             {
                              handleClick(item)
                              scrollFun(item.id)
                             }}>
                             {language ? 
                              <> {item.nameen}</>
                              :
                              <>{item.namejp}</>}
                            </p>
                          </div>
                        </div>
                      </>
                    ))}
                  </div>
              </div>
          </div>
       </div>
    </Wrapper>
  )
}

export default SiderBar
