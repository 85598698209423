import styled from 'styled-components'

export const Wrapper = styled.div`

.container_seminar{
  width: 100%;
  margin-top:0rem;
  @media screen and (max-width: 1620px){
      p{
        font-size: 14px;
      }
    }
    .content{
      width :100%;
      padding : 2rem 0 0;
      display: block;
      .text{
        display: flex;
      }
      .pl_6{
        padding-left: 2rem;
        @media print{
          padding-left: 0;
        }
      }
      .w_160{
        width: 160px;
        @media print{
          width: 120px;
        }
      }
      .w_90{
        width: 90%;
        @media print{
          width: 100%;
        }
      }
    }
    h1{
      background: #fbe5d6;
      padding: 15px 0 15px 20px;
      width: 100%;
      @media screen and (max-width: 1280px){
          font-size: 20px;
      }
      @media screen and (max-width: 991px){
          width: 100%;
          font-size: 20px;
      }
      @media print{
        font-size: 16px;
        width: 100%;
        padding: 10px 15px;
      }
    }
    p{
        line-height: 1.6;
        font-size: 19px;
        @media print{
        font-size: 14px;
      }
        @media screen and (max-width: 1620px) {
          font-size: 18px;
        }
        @media screen and (max-width: 1280px) {
          font-size: 16px;
        }
        @media screen and (max-width: 580px) {
          font-size: 14px;
        }
      }
    .right{
        width: 150px;
        border-right: 50px solid transparent;
	      border-bottom: 69px solid rgb(251 229 214);
        margin-left: -1px;
        margin-bottom: -1px;
      }
    @media screen and (max-width: 767px) {
      .content {
        flex-direction: column;
        .right{
          border-right: 40px solid transparent;
          border-bottom: 40px solid rgb(251 229 214);
        }
        .text{
          display: block;
        }
        >div {
          width: 100%;
          &:first-child {
            margin-bottom: 10px;
          }
        }
      }
    }
    @media screen and (max-width: 580px) {
      .content {
        .right{
            border-right: 40px solid transparent;
            border-bottom: 68px solid rgb(251 229 214);
          }
      }
    }
    @media screen and (max-width: 390px) {
      .content {
        .right{
            border-right: 40px solid transparent;
            border-bottom: 96px solid rgb(251 229 214);
          }
      }
    }
}    
 
`