export const options =[
    {value : ''},
    {value : '1'},
    {value : '2'},
    {value : '3'},
    {value : '4'},
    {value : '5'},
    {value : '6'},
    {value : '7'},
    {value : '8'},
    {value : '9'},
    {value : '10'},
    {value : '11'},
    {value : '12'},
    {value : '13'},
    {value : '14'},
    {value : '15'},
    {value : '16'},
    {value : '17'},
    {value : '18'},
    {value : '19'},
    {value : '20'},
    {value : '21'},
    {value : '22'},
    {value : '23'},
    {value : '24'},
    {value : '25'},
    {value : '26'},
    {value : '27'},
    {value : '28'},
    {value : '29'},
    {value : '30'},
    {value : '31'},
    {value : '32'},
    {value : '33'},
    {value : '34'},
    {value : '35'},
    {value : '36'},
    {value : '37'},
    {value : '38'},
    {value : '39'},
    {value : '40'},
 ]

 export const options2 =[
    {value : ''},
    {value : '一区画：　年一括払い賃貸料（税込み）　110万円／戸'},
    {value : '二区画：　年一括払い賃貸料（税込み）　220万円／戸'},
    {value : '四区画：　年一括払い賃貸料（税込み）　440万円／戸'}
]


export const chooseArea=[
    {value : ''},
    {value : 'Ｓ0２ａ'},
    {value : 'Ｓ0３ａ'},
    {value : 'Ｓ0３ｂ'},
    {value : 'Ｓ0４ａ'},
    {value : 'Ｓ0５ａ'},
    {value : 'Ｓ0５ｂ'},
    {value : 'Ｓ0５c'},
    {value : 'Ｓ0５ｄ'},
    {value : 'Ｓ0６ａ'},
    {value : 'Ｓ0６c'},
    {value : 'Ｓ07ａ'},
    {value : 'Ｓ0７ｂ'},
    {value : 'Ｓ0７c'},
    {value : 'Ｓ0８ａ'},
    {value : 'Ｓ0８ｂ'},
    {value : 'Ｓ0８c'},
]
