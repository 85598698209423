import styled from 'styled-components'

export const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: block;
  .container-access{
    width: 100%;
     .left{
      left: 0px;
      width: 100%;
      height: 100%;
      margin-top: 3rem;
      visibility: visible;
      overflow: hidden;
    }
  }

  @media screen and (max-width: 991px) {
    margin-top: 80px;

    .container-access {
      >div {
        &.left {
          #marker-example {
            width: 90% !important;
          }
        }

        &.flex {
          width: 100%;
          padding: 40px;
          p {
            font-size: 14px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    .container-access {
      >div {
        &.flex {
          padding: 30px;
          p {
            font-size: 14px;

            &.description {
              padding-left: 5px;
              margin-bottom: 5px;
            }
          }
        }
      }
    }
  }
`
