import styled from 'styled-components'
import { items } from './item'
import { LOGO } from '@assets'
import { useState } from 'react'
import { FC } from 'react';
import { NavLink } from 'react-router-dom';
interface Prop {
  language: any;
}
const Wrapper = styled.header`
  width: 100%;
  height: 120px;
  left: 0px;
  top: 0px;
  .header{
    @media screen {
      position: fixed;
    }
    @media print {
      position: relative;
      top:0px;
    }
    border-bottom: 0.5px solid #deddd7;
    width: 100%;
    z-index: 99;
    background: #F4F3ED;
    @media screen {
    .title{
      width: 1250px;
      margin: auto;
      justify-content: space-between;
      @media screen and (max-width: 1620px){
        width: 1100px;
      }
      @media screen and (max-width: 1280px){
        width: 70%;
      }
      @media screen and (max-width: 1100px){
        width: 75%;
      }
      @media screen and (max-width: 991px){
        width: 100%;
      }
     }
    }
    @media print {
      .title{
      width: 90%;
      margin: auto;
      justify-content: space-between;
      padding: 15px 0;
     }
    }
  }
  .name{ 
    letter-spacing: 0.02em;
    -webkit-text-stroke: 1px #5C5040;
  }
  .title_{
    height: 100%;
    left: 270px;
    align-items: center;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    text-align: center;
    /* @media screen and (max-width: 1620px){
      font-size: 14px;
    }*/
    @media screen and (max-width: 1280px){
      left: 220px;
      font-weight: 600;
      font-size: 15px;
    } 
    @media screen and (max-width: 1100px){
      font-size: 13px;
    }
  }
  .main{    
    position: inherit;
    width: 23rem;
    height:767px;
    margin-top: 62px;
    transition: all 1s ease-in-out;
  }
  .main:hover .button{
    color: white;
  }
  @media screen {
  .header__nav {
    float: left;
    padding: 0.75rem 1.2rem;
    @media screen and (max-width: 1620px){
      padding: 0.75rem 1rem;
    }
    @media screen and (max-width: 1280px){
      padding: 0.25rem 0.5rem;
    }
    &:hover {
      background: #2F1B11;
      color: white;
    }
    .is-active {
      font-weight: bold;
      background: radial-gradient(#e66465, #9198e6);
    }
  }
 }
 @media print {
  .header__nav {
    float: left;
    padding: 0.75rem 0.6rem;
    margin-left: 4px;
    font-size: 16px;
    &:hover {
      background: #2F1B11;
      color: white;
    }
    .is-active {
      font-weight: bold;
      background: radial-gradient(#e66465, #9198e6);
    }
  }
 }
  .logo{
    width: 170px;
    .w_logo{
      @media screen {
      width: 115%;
      }
      @media print {
      width: 85%;
      }
      max-width: 100%;
      @media screen and (max-width: 1620px){
        width: 100%;
        max-width: 110%;
      }
      @media screen and (max-width: 1280px){
        width: 90%;
        max-width: 100%;
      }
      @media screen and (max-width: 580px){
        width: 70%;
        max-width: 100%;
      }
    }
  }
  .translate{
    width: 86%;
    margin: auto;
    justify-content: right;
    display: flex;
    margin-top: -5.2rem;
    padding: 1.5rem 0;
    @media screen and (max-width: 1620px){
      width: 90%;
    }
    .bt_lang{
    box-sizing: border-box;
    width: 126px;
    height: 30px;
    opacity: 0.8;
    border: 2px solid #2e75b6;
    transition: 1s all ease-in-out;
    color: #2e75b6;
    font-weight: 700;
    &:hover{
      background: #2e75b6;
      color: white;
      border: none;
      transition: 1s all ease-in-out;
    }
     .title{
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;
      display: flex;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
          }
    }
  }
  .menu{
    background: white;
    transition: 1s all ease-in-out;
    border-top: 0.5px solid #deddd7;
    font-size: 14px;
    @media screen and (max-width: 1280px){
        font-size: 12px;
      } 
    @media print {
      display: none;
    }
    /* @media screen and (max-width: 1620px){
      font-size: 13px;
    }
    @media screen and (max-width: 1280px){
      font-size: 11px;
    } */
    .main_m{
      width: 76%;
      @media screen and (max-width: 1620px){
        width :900px;
      }
      @media screen and (max-width: 1280px){
        width :75%;
      } 
    }
    .content{
      width: 1250px;
      margin: auto;
      padding: 1.5rem 0;
      color: #002060;
      font-weight: 700;
      @media screen and (max-width: 1620px){
        width: 1100px;
      }
      @media screen and (max-width: 1280px){
        width: 70%;
      }
      .item {
        float: left;
        padding-top: 0.75rem ;
        padding-bottom: 0.75rem ;
        padding-left: 10px;
        margin-right: 10px;
        @media screen and (max-width: 1280px){
          padding-top: 0.4rem ;
          padding-bottom: 0.4rem ;
        }
        &:hover {
          background: #a98260d9;
          color: white;
          border-radius: 50px;
        }
      }
      .pr{
        padding-right: 2.5rem;
        padding-left: 2.5rem;
        /* @media screen and (max-width: 1620px){
          padding-right: 1.4rem;
          padding-left: 1.4rem;
        }*/
        @media screen and (max-width: 1280px){
          padding-right: 1.5rem;
          padding-left: 1.5rem;
        } 
      }
      .pr2{
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        justify-content: center;
        display: flex;
        /* @media screen and (max-width: 1620px){
          padding-right: 0.5rem;
          padding-left: 0.5rem;
        }
        @media screen and (max-width: 1280px){
          padding-right: 0.3rem;
          padding-left: 0.3rem;
        } */
      }
      .pr6{
        padding-right: 2rem;
        padding-left: 2rem;
        /* @media screen and (max-width: 1620px){
          padding-right: 1rem;
          padding-left: 1rem;
        }*/
        @media screen and (max-width: 1280px){
          padding-right: 1rem;
          padding-left: 1rem;
        } 
      }
    }
  }
`
interface Prop{
  language : any; 
  setLanguage : any; 
}
const Header: FC<Prop> = ({ language ,setLanguage}) => {
  const [list, setList] = useState(items);
  // const {  i18n } = useTranslation();
  // const handleLangChange =(lang :any) => {
  //   i18n.changeLanguage(lang);
  // };
  const handleClick = (item: any) => {
    if (item.path === "/") {
      window.location.replace('/');
    }
    if (item.path === "/ServiceProduct") {
      window.location.replace('/ServiceProduct');
    }
    if (item.path === "/RecruitmentInformation") {
      window.location.replace('/RecruitmentInformation');
    }
    if (item.path === "/category/news") {
      window.location.replace('/category/news');
    }
    if (item.path === "/inquiry") {
      window.location.replace('/inquiry');
    }
    if (item.path === "/Access") {
      window.location.replace('/Access');
    }
  };

  const setIsActive = (item: any) => {
    const newList = list.map((_item: any) => {

      return {
        ..._item,
        isActive: _item.id === item.id
      }
    });
    setList(newList);
  };

  const setAll = () => {
    setList(items)
  }

  const scrollFun = (id: string) => {
    document
      .querySelector(`#id${id}`)
      ?.scrollIntoView({ block: "center", behavior: "smooth" });
  }
   
  return (
    <Wrapper className='block'>
      <div className='block header'>
        <div className='flex title'>
          <div className=' logo text-center'>
            <img src={LOGO} alt='' className='w_logo' />
          </div>
          <div className='flex'>
            <div className='title_ flex'>
              {list?.map((item, index) => (
                <div
                  className="header__nav"
                  onClick={() => handleClick(item)}
                  onMouseEnter={() => setIsActive(item)}
                  key={index}
                >
                  <p className=' cursor-pointer item' >
                    {language ?
                      <> {item.nameen}</>
                      :
                      <>{item.namejp}</>}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* <div className='translate'>
          <div className='bt_lang' onClick={() => {setLanguage(!language)}}>
            {language ? 
            <><button className='title' onClick ={() => {handleLangChange("jp")}}> Japanese </button></>
            :<><button className='title'  onClick ={() => {handleLangChange("en")}}>English</button></>}
          </div> 
        </div> */}
        {list?.map((item: any, index) => (
          <div key={index}>
            {item.isActive && (
              <div className='menu' onMouseLeave={() => setAll()}>
                {item.menu &&(
                  <div className='content flex justify-between'>
                    <div className='w-[10%]'></div>
                    <div className='main_m'>
                      <div className={`flex-wrap   ${ item.id === '2' ? 'grid grid-cols-3 w-full' : 'flex w-full' }`}>
                          {item.menu?.map((item_: any, index: number) => (
                            <NavLink to={item_.path} key={index} className={`item ${item.id === '6' ? 'pr6' : item.id === '2' ? 'pr2' : 'pr'}`}>
                              <p className=' cursor-pointer flex' onClick={() => {scrollFun(item_.id)}} >
                                {language ?
                                  <> {item_.nameen}</>
                                  :
                                  <>{item_.namejp}</>}
                              </p>
                            </NavLink>
                          ))}
                      </div>
                    </div>
                  </div>
                )} 
              </div>
            )}
          </div>
        ))}
      </div>
    </Wrapper>
  )
}

export default Header
