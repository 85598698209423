import React from 'react'
import { Wrapper } from './styled'
import { useEffect, useRef } from 'react'
import useScreenWidth from '../../Hook/useScreenWidth'

const RecruitmentInforScreen = () => {
     const divOne = useRef<HTMLInputElement | null>(null);
     const scrolLWithUseRef = () => {
     divOne.current?.scrollIntoView({ block: "center", behavior: "smooth" });
     };

     const screenWidth = useScreenWidth()
   
     useEffect(() => {
      scrolLWithUseRef()
     },[])

   return (
      <>
      <Wrapper>
         <div className='container-recruit block'>
            <div className='start-selling block'>
               <div className='item_fi flex h-[27rem]'>
                  <div className={`${screenWidth > 991 ?' mt-[5px' : 'mt-[-20px]'}`}>
                     <div className='line-up h-[65px]'></div>
                     <div className='line-down h-[26.7rem]'></div>
                  </div>
                  <div className='pl-4 block pb-4 w-full text-center' ref={divOne}>
                     <div className='block p-2'>
                       <p>現在のところ採用の公募は致しておりません。</p>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </Wrapper>
      </>
   )
}
export default RecruitmentInforScreen
