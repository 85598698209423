import { useEffect, useState } from "react"

const useScreenWidth = () => {
  const screenWidth = window.innerWidth
  const [width, setWidth] = useState(screenWidth)

  useEffect(() => {
    const onSetWidthResize = () => {
      const screenWidthResize = window.innerWidth
      setWidth(screenWidthResize)
    }

    window.addEventListener('resize', onSetWidthResize)

    return () => {
      window.removeEventListener('resize', onSetWidthResize)
    }
  }, [])

  return width
}

export default useScreenWidth