import { useState, FC } from 'react'
import { NavLink, useHistory } from "react-router-dom";

import styled from 'styled-components'

import {
  CHEVRON_BOTTOM_ICON,
  CHEVRON_RIGHT_ICON,
  LOGO_MOBILE_1,
  LOGO_MOBILE_2
} from '@assets'
import { SUB_PRIMARY } from '@assets/colors';

import { items } from './item'

interface Prop {
  language: any;
  onScrollMenu: () => void
}
const MenuContainer = styled.header`
  -moz-animation: fadeIn 1.5s ease;
  -webkit-animation: fadeIn 1.5s ease;
  animation: fadeIn 1s ease;
  transition: margin 100ms ease;

  .menu-active {
    background-color: #E8E2D2;
  }

  .logo-mobile {
    padding: 21px 0;
    position: relative;
    margin-bottom: 20px;
    @media only screen and (orientation:landscape) {
      padding: 0;
    }
    .row {
      display: flex;

      img {
        width: 50%;
        &:first-child {
          width: 30%;
        }
      }
    }

    .line {
      position: absolute;
      bottom: -8px;
      height: 1px;
      width: 259px;
      background-color: rgba(29, 14, 3, 0.4);
    }
  }

  .header__nav {
    float: left;
    padding: 8px 0;
    padding-left: 5px;
    width: 100%;

    .cursor-pointer {
      -webkit-tap-highlight-color: transparent;
      &.menu-active {
        background: ${SUB_PRIMARY};
      }
    }
    
    .sub-menu {
      /* background: ${SUB_PRIMARY}; */
      padding: 8px;
      width: 100%;

      &.active {
        background: ${SUB_PRIMARY};
      }
    }
    .is-active {
      font-weight: bold;
      background: radial-gradient(#e66465, #9198e6);
    }

    p {
      font-size: 13px;
    }

    .row {
      display: flex;
      justify-content: space-between;
      padding: 5px;
      padding-right: 20px;
      padding-left: 10px;

      &.active {
        background: ${SUB_PRIMARY};
      }
    }
  }

  a {
    &.header__nav {
      padding-left: 15px;
    }
  }
`
const Menu: FC<Prop> = ({ language, onScrollMenu }) => {
  const [list] = useState(items);
  const [menuIndex, setMenuIndex] = useState(0)
  const [isActive, setIsActive] = useState(false)

  const history = useHistory();

  // useEffect(() => {
  //   const newList = list.map((_item: any) => {
  //     return {
  //       ..._item,
  //       isActive: _item.path === history.location.pathname
  //     }
  //   });
  //   setList(newList);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [history.location.pathname])

  const _onShowSubmenu = (menuIndexInside: number) => {
    setIsActive(menuIndexInside === menuIndex ? !isActive : true)
    setMenuIndex(menuIndexInside)
  }

  const scrollFun = (id: string) => {
    onScrollMenu()
    setIsActive(false)
    setMenuIndex(0)
    setTimeout(function () {
      document
        .querySelector(`#id${id}`)
        ?.scrollIntoView({ block: "center", behavior: "smooth" });
    }, 100);
  }

  const isHome = history.location.pathname === '/'

  return (
    <MenuContainer className='title flex'>
      <div className='logo-mobile'>
        <div className='row'>
          <img src={LOGO_MOBILE_1} alt='' />
          <img src={LOGO_MOBILE_2} alt='' />
        </div>
        <div className='line' />
      </div>
      {list?.map((item: any, index: any) => {
        let isClickMenu = false

        if (history.location.pathname === item.path) {
          isClickMenu = true
        } else {
          isClickMenu = !isHome && item.path.includes(history.location.pathname)
        }

        return (
          <div key={index} className={`${(isClickMenu && !item.menu) ? 'menu-active' : ''}`}>
            {item.menu ? (
              <div className="header__nav ">
                <div className={`cursor-pointer item ${isClickMenu ? 'menu-active' : ''}`}>
                  <div
                    className={`row ${history.location.pathname.includes(item.path) ? 'active' : ''}`}
                    onClick={() => _onShowSubmenu(index)}
                  >
                    <p>{language ? item.nameen : item.namejp}</p>
                    <img src={(menuIndex === index && isActive) ? CHEVRON_BOTTOM_ICON : CHEVRON_RIGHT_ICON} alt='icon' className='icon' />
                  </div>

                  {(menuIndex === index && isActive) && (
                    <>
                      {item.menu.map((sub: any, indexSub: number) => (
                        <NavLink
                          to={sub.pathMobile || sub.path}
                          className="header__nav sub-menu"
                          exact={true}
                          key={indexSub}
                        // onClick={() => window.scrollTo(0, 0)}
                        >
                          <p className=' cursor-pointer item' onClick={() => { scrollFun(sub.id) }} >
                            {language ?
                              <> {sub.nameen}</>
                              :
                              <>{sub.namejp}</>
                            }
                          </p>
                        </NavLink>
                      ))}
                    </>
                  )}
                </div>
              </div>
            ) : (
              <NavLink
                to={item.path}
                className="header__nav"
                exact={true}
                key={index}
                onClick={() => window.scrollTo(0, 0)}
              >
                <p className=' cursor-pointer item' >
                  {language ?
                    <> {item.nameen}</>
                    :
                    <>{item.namejp}</>}
                </p>
              </NavLink>
            )}
          </div>
        )
      })}
    </MenuContainer>
  )
}

export default Menu
