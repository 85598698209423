import React , {useRef, useEffect} from 'react'
import { Wrapper } from './styled'
const Policy = () => {
   const divOne = useRef<HTMLInputElement | null>(null);
   const scrolLWithUseRef = () => {
   divOne.current?.scrollIntoView({ block: "center", behavior: "smooth" });
   };
 
   useEffect(() => {
    scrolLWithUseRef()
   },[])

   return (
      <>
      <Wrapper>
         <div className='container_policy block'>
            <div className='content'>
               <h1 className="text-[30px] leading-normal md:leading-relaxed text-center font-bold mb-[3rem] " ref={divOne}>プライバシーポリシー </h1>
               <p>アートメタバースランド株式会社（以下「当社」といいます。）は、当社のサービスを利用
                  する方（以下「利用者」といいます。）の個人情報の取扱いについて、以下のとおりプライバ
                  シーポリシー（以下「本ポリシー」といいます。）を定め、個人情報保護の仕組みを構築し、
                  全従業員に個人情報保護の重要性を認識させるとともにその取組みを徹底させることにより、
                  個人情報の保護を推進します。
               </p>
               <div className='mt-8'>
                  <h2>第 1 条（個人情報）</h2>
                  <div className='ml-10 mt-4'>
                     <p>「個人情報」とは、個人情報の保護に関する法律（平成十五年法律第五十七号、以下「個
                        人情報保護法」といいます。）にいう「個人情報」を指し、生存する個人に関する情報であ
                        って、当該情報に含まれる氏名、生年月日その他の記述等により特定の個人を識別できる
                        もの又は個人識別符号が含まれるものを指します。
                        </p>
                  </div>
               </div>
               <div className='mt-8'>
                  <h2>第 2 条（個人情報の利用目的） </h2>
                  <div className='ml-10 mt-4'>
                     <p>当社は、以下の目的に必要な範囲で、利用者の個人情報を取得し、取得した情報を利用さ
                        せていただきます。以下の目的の範囲を超えて個人情報を利用する場合には、事前に適切
                        な方法で利用者からの同意を得るものとします。
                        </p>
                      <div className='ml-10 mt-4'>
                        <div className='flex mt-4'>
                           <p>(1)</p>
                           <p>当社のサービス（以下「本サービス」といいます。）を提供するため</p>
                        </div>
                        <div className='flex mt-4'>
                           <p>(2)</p>
                           <p>本サービスの内容を改良・改善し、又は新サービスを開発するため</p>
                        </div>
                        <div className='flex mt-4'>
                           <p>(3)</p>
                           <p>本サービスの新機能、更新情報、キャンペーン等及び当社が提供する他のサービスのご案内（電子メール、チラシ、その他のダイレクトメールの送付を含む）のため</p>
                        </div>
                        <div className='flex mt-4'>
                           <p>(4)</p>
                           <p>メンテナンス、重要なお知らせなど必要に応じたご連絡のため</p>
                        </div>
                        <div className='flex mt-4'>
                           <p>(5)</p>
                           <p>本サービスに関する利用者からのご意見、お問い合わせ等に回答するため（本人確認を行うことを含む）</p>
                        </div>
                        <div className='flex mt-4'>
                           <p>(6)</p>
                           <p>本サービスの利用状況を利用者にご報告するため</p>
                        </div>
                        <div className='flex mt-4'>
                           <p>(7)</p>
                           <p>本サービスに関するアンケート・取材等のご協力依頼や各種イベントへのご参加をお願いし、又はその結果などをご報告するため</p>
                        </div>
                        <div className='flex mt-4'>
                           <p>(8)</p>
                           <p>本サービスの利用履歴等を調査・分析し、その結果を本サービスの改良・開発や広告の配信に利用するため</p>
                        </div>
                        <div className='flex mt-4'>
                           <p>(9)</p>
                           <p>利用規約に違反した利用者や、不正・不当な目的で本サービスを利用しようとする利用者の特定をし、ご利用をお断りするため</p>
                        </div>
                      </div>
                  </div>
               </div>
               <div className='mt-8'>
                  <h2>第 3 条（個人情報の管理と保護） </h2>
                  <div className='ml-10 mt-4'>
                     <p>個人情報の管理は、厳重に行うこととし、次に掲げる場合を除き、利用者の同意がない限
                        り、第三者に対しデータを開示・提供することはいたしません。また、安全性を考慮し、
                        個人情報への不正アクセス、個人情報の紛失、破壊、改ざん及び漏えい等のリスクに対す
                        る予防並びに是正に関する対策を講じます。
                        </p>
                      <div className='ml-10 mt-4'>
                        <div className='flex mt-4'>
                           <p>(1)</p>
                           <p>人の生命、身体又は財産の保護のために必要がある場合であって、利用者の同意を得ることが困難である場合</p>
                        </div>
                        <div className='flex mt-4'>
                           <p>(2)</p>
                           <p>公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、利用者の同意を得ることが困難である場合</p>
                        </div>
                        <div className='flex mt-4'>
                           <p>(3)</p>
                           <p>国の機関若しくは地方公共団体又はその委託を受けた者が法令の定める事務を遂
                              行することに対して協力する必要がある場合であって、利用者の同意を得ることに
                              より当該事務の遂行に支障を及ぼすおそれがある場合
                              </p>
                        </div>
                        <div className='flex mt-4'>
                           <p>(4)</p>
                           <p>その他法令で認められる場合</p>
                        </div>
                     </div>
                  </div>
               </div>
               <div className='mt-8'>
                  <h2>第 4 条（個人情報の取扱いの委託）</h2>
                  <div className='ml-10 mt-4'>
                     <p>当社は、利用目的の達成に必要な範囲内において、個人データの取扱いの全部又は一部を
                        委託する場合がございます。この場合、当社は、委託先としての適格性を十分審査すると
                        ともに、契約にあたって守秘義務に関する事項等を定め、委託先に対する必要かつ適切な
                        監督を行います。
                        </p>
                  </div>
               </div>
               <div className='mt-8'>
                  <h2>第 5 条（個人情報の開示）</h2>
                  <div className='ml-10 mt-4'>
                     <p>当社は、利用者（本人に限る。本条において以下同じ）から当社の保有する個人情報の開 示を求められたときは、利用者に対し、
                        遅滞なくこれを開示します。その場合、開示手数 料として１請求につき 1000 円を申し受けます。但し、
                        開示することにより次のいずれか に該当する場合は、その全部又は一部を開示しないこともあり、
                        開示しない決定をした場 合には、その旨を遅滞なく通知します。
                        </p>
                      <div className='ml-10 mt-4'>
                        <div className='flex mt-4'>
                           <p>(1)</p>
                           <p>利用者又は第三者の生命、身体、財産その他の権利利益を害するおそれがある場合</p>
                        </div>
                        <div className='flex mt-4'>
                           <p>(2)</p>
                           <p>当社の業務の適正な実施に著しい支障を及ぼすおそれがある場合</p>
                        </div>
                        <div className='flex mt-4'>
                           <p>(3)</p>
                           <p>その他法令に違反することとなる場合</p>
                        </div>
                     </div>
                  </div>
               </div>
               <div className='mt-8'>
                  <h2>第 6 条（保有個人データの訂正等）</h2>
                  <div className='ml-10 mt-4'>
                     <div className='flex mt-4'>
                        <p>1. </p>
                        <p>利用者は、当社の保有する個人情報が誤った情報である場合には、当社に対し、当該個
                           人情報の訂正、追加又は削除（以下「訂正等」といいます。）を請求することができます。</p>
                     </div>
                     <div className='flex mt-4'>
                        <p>2. </p>
                        <p> 前項の請求を受けた場合、当社は遅滞なく必要な調査を行い、その結果前項の請求に理
                            由があると判断した場合には、遅滞なく当該個人情報の訂正等を行います。</p>
                     </div>
                     <div className='flex mt-4'>
                        <p>3. </p>
                        <p>当社は、前項に基づき訂正等の実施・不実施について判断した場合には、遅滞なく、利用者ご本人に対してご連絡いたします。</p>
                     </div>
                  </div>
               </div>
               <div className='mt-8'>
                  <h2>第 7 条（個人情報の利用停止等）</h2>
                  <div className='ml-10 mt-4'>
                     <div className='flex mt-4'>
                        <p>1. </p>
                        <p>利用者は、当社に対し、当社の保有する個人データの利用の停止、消去又は第三者提供の停止（以下「利用停止等」といいます。）を請求することができます。</p>
                     </div>
                     <div className='flex mt-4'>
                        <p>2. </p>
                        <p> 当社は、前項の請求を受けた場合には、遅滞なく必要な調査を行い、その結果前項の請
                           求に理由があると判断した場合には、当該個人データの利用停止等を行うものとしま
                           す。但し、個人情報の利用停止等に多額の費用を要する場合その他利用停止等を行うこ
                           とが困難な場合であって、利用者の権利利益を保護するために必要なこれに代わるべき
                           措置をとれる場合は、この代替策を講じます。</p>
                     </div>
                     <div className='flex mt-4'>
                        <p>3. </p>
                        <p>当社は、前項に基づき利用停止等の実施・不実施について判断した場合には、遅滞なく、利用者ご本人に対してご連絡いたします。</p>
                     </div>
                  </div>
               </div>
               <div className='mt-8'>
                  <h2>第 8 条（プライバシーポリシーの変更手続）</h2>
                  <div className='ml-10 mt-4'>
                     <p >当社は本ポリシーの内容を適宜見直し、その改善に努めます。本ポリシーの内容は、法令
                        その他本ポリシーに別段の定めのある事項を除いて、変更することができるものとします。
                        変更後のプライバシーポリシーは、当社所定の方法により、利用者に通知し、又は当社ウ
                        ェブサイトに掲載したときから効力を生じるものとします。</p>
                  </div>
               </div>
               <div className='mt-8'>
                  <h2>第 9 条（法令、規範の遵守）</h2>
                  <div className='ml-10 mt-4'>
                     <p >当社は、保有する個人情報に関して適用される日本の法令、その他規範を遵守します。</p>
                  </div>
               </div>
               <div className='mt-8'>
                  <h2>第 10 条（苦情及び相談への対応）</h2>
                  <div className='ml-10 mt-4'>
                     <p >当社は、個人情報の取扱いに関する利用者からの苦情、相談を受け付け、適切かつ迅速に
                        対応いたします。また、利用者からの当該個人情報の開示、訂正、追加、削除、利用又は
                        提供の拒否などのご要望に対しても、迅速かつ適切に対応いたします。
                        </p>
                  </div>
               </div>
               <div className='mt-8'>
                  <h2>第 11 条（安全管理措置）</h2>
                  <div className='ml-10 mt-4'>
                     <p >当社が利用者よりお預かりした個人情報は、個人情報ファイルへのアクセス制限の実施、
                        アクセスログの記録及び外部からの不正アクセス防止のためのセキュリティ対策の実施等、
                        組織的、物理的、人的、技術的施策を講じることで個人情報への不正な侵入、個人情報の
                        紛失、破壊、改ざん、及び漏えい等を防止いたします。万一、利用者の個人情報の漏えい
                        等の事故が発生した場合、当社は、個人情報保護法及び関連するガイドラインに則り、速
                        やかに監督官庁への報告を行うとともに、当該監督官庁の指示に従い、類似事案の発生防
                        止措置及び再発防止措置等の必要な対応を行います。</p>
                  </div>
               </div>
               <div className='mt-8'>
                  <h2>第 12 条（当社住所・代表者氏名・個人情報保護管理者）</h2>
                  <div className='ml-10 mt-4'>
                     <p>当社住所、代表者および個人情報保護管理者の氏名は以下のとおりです。</p>
                     <p >住所：東京都練馬区向山 3 丁目 15-19</p>
                     <p>代表者：代表取締役 宮川 武揚</p>
                     <p>個人情報保護管理者：宮川 武揚</p>
                  </div>
               </div>
               <div className='mt-8'>
                  <h2>第 13 条（お問い合わせ窓口）</h2>
                  <div className='ml-10 mt-4'>
                     <p>当社の個人情報の取扱に関するお問い合せは下記までご連絡ください。</p>
                     <p>アートメタバースランド株式会社 お客様対応窓口</p>
                     <p>住所: 〒 176-0022 東京都練馬区向山 3-15-19</p>
                     <p>TEL: 03-6636-7893</p>
                     <p>Mail: https://artmetaverseland.co.jp</p>
                  </div>
               </div>
               <div className='mt-8'>
                  <div className='ml-10 mt-4'>
                     <p >2023 年 2 月 15 日制定・施行</p>
                  </div>
               </div>
            </div>
         </div>
       </Wrapper>
      </>
   )
}
export default Policy