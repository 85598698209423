import styled from 'styled-components'

export const Wrapper = styled.div`
width: 100%;
  .container_plan{
    width: 1100px;
    @media screen and (max-width: 1620px) {
      width: 1050px;
    }
    @media screen and (max-width: 1280px) {
      width: 100%;
    }
    padding: 6rem 0 10rem;
    @media screen and (max-width: 1620px){
      padding: 4rem 0 10rem;
    }
    @media screen and (max-width: 991px){
      padding: 1rem 0 10rem;
    }
    @media print{
      padding:2rem 0 10rem;
      width: 100%;
      h1{
        font-size: 20px;
      }
      img{
        height: 200px;
      }
    }
    margin: auto;
    .content{
      width: 100%;
      margin: auto;
      display: block;
      .item{
        padding: 2rem;
        margin: 1rem;
        background-color: #f4f3ed;
        border-radius: 10px;
        transition: 1s all ease-in-out;
      }
      
    }
    p{
    line-height: 1.6;
    font-size: 19px;
    @media print{
      font-size: 14px;
    }
    @media screen and (max-width: 1620px) {
      font-size: 18px;
    }
    @media screen and (max-width: 1280px) {
      font-size: 16px;
    }
    @media screen and (max-width: 580px) {
      font-size: 14px;
    }
  }
  h2{
    font-size: 24px;
    color: #4472c4;
    font-weight: 700;
    @media screen and (max-width: 1620px) {
      font-size: 20px;
    }
    @media print{
      font-size: 16px;
    }
  }
    button{
      padding:0 20px ;
      height: 50px;
      background: #A98260;
      font-style: normal;
      font-weight: 700;
      font-size: 15px;
      line-height: 24px;
      border-radius: 10px;
      text-transform: uppercase;
      color: #FFFFFF;
      box-shadow: rgb(0 0 0 / 25%) 0px 14px 28px, rgb(0 0 0 / 22%) 0px 10px 10px;
    }

    @media screen and (max-width: 991px) {
      margin-top: 80px;
      h1{
        font-size: 1.5rem;
      }
      .content {
        button {
          font-size: 14px;
        }
      }
    }

    @media screen and (max-width: 767px) {
      margin-top: 80px;

      .content {
        >div {
          &.flex {
            flex-direction: column;

            >div {
              width: 100%;
              &:first-child {
                margin-bottom: 20px;
              }
            }
          }
        }
      }
    }
  }
`