import styled from 'styled-components'

export const Wrapper = styled.header`
position: absolute;
 .siderbar{
    position: inherit;
    width: 22rem;
    height:767px;
    margin-left: -19rem;
    margin-top: 0.6rem;
    transition: all 1s ease-in-out;
    @media screen and (max-width: 1280px){
      margin-top: -40px;
      margin-top: 0.4rem;
      }
      @media screen and (min-width: 1700px){
        width: 24rem;
       margin-left: -21rem;
     }
     @media screen and (min-width: 1920px){
        width: 25rem;
       margin-left: -22rem;
     }
    &:hover{
        width: 19rem;
        margin-left: 0rem;
    }
 }
 .container{
    width: 18rem;
    position: absolute;
    height: 100%;
    @media screen and (min-width: 1700px){
       width: 19rem;
    }
    @media screen and (min-width: 1920px){
       width: 20.5rem;
    }
 }
  .posting_SNS{
    width: 19rem;
    height: 86px;
    top: 0;
    cursor: pointer;
    background: #FBF7F1;
     .title_name{
      width: 220px;
      height: 100%;
      margin-top: 2.2rem;
      font-style: normal;
      font-weight: 700;
      font-size: 15px;
      line-height: 20px;
      color: white;
     }
  }
  .menu{
    background: rgba(251, 247, 241, 0.85);
  .item{
    width: 100%;
    left: 25px;
    top: 17%;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 20px;
    color: #604A38;
      ._item:hover .child{
        display: block;
      }
      ._item:hover ._name{
          background: #A98260;
          color: white;
      }
      .child{
        display: none;
        position: absolute;
        width: 220px;
        left: 18rem;
        text-align: center;
        margin-top: -4rem;
        background: rgba(251, 247, 241, 0.85);
        backdrop-filter: blur(2px);
        @media screen and (min-width: 1700px){
          left: 19rem;
        }
        @media screen and (min-width: 1920px){
          left: 20.5rem;
        }
        ._item_child{
          width: 220px;
          height: 4rem;
    
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 20px;
          padding-top: 1.4rem;
          color: black;
        }
        ._child:hover ._item_child{
          background: #A98260;
          color: white;
        }
      }
   }
  }
`
