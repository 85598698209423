import React from 'react'
import { Wrapper } from './styled'
import { NavLink } from "react-router-dom";
const OperationMarriage = () => {
   return (
      <>
      <Wrapper>
         <div className='container_story block'>
            <div className='content block'>
               <div className="timeline"> 
               <div className="timeline__event  animated fadeInUp delay-3s">
                  <div className="timeline__event__date">結婚相手マッチングサービス<br className='brPrint'/>の運営</div>
                  <div className="timeline__event__content text-center" >
                     <div className="timeline__event__description">
                        <div className='cont text-justify'>
                           <p>　リーズナブルな費用で、本気でお付き合いできる結婚相手とめぐり会えるマッチングサービスを運営します。</p>
                           <p>　ＡＩがあなたにぴったりの結婚相手候補を提案してくれます。</p>
                        </div>
                        <NavLink to='/ServiceProduct/OperationMarriage'>
                           <button>詳細</button>
                        </NavLink>
                     </div>
                  </div>
               </div>
               </div>
            </div>
         </div>
       </Wrapper>
      </>
   )
}
export default OperationMarriage
