import React from 'react'
import { Wrapper } from './styled'
import { Modal, SiderBar } from '@components'
import {items } from './items'
import { useEffect, useState } from 'react'
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
const SustinaAbilityScreen = () => {
   const [isClick, setIsClick] = useState(false);
   const [list, setList] = useState(items);
   const history = useHistory();
   const { t} = useTranslation();
   useEffect (() => { 
      if ( (history.location.pathname) === '/SustinaAbility') {
         setTimeout(() => {
            setIsClick(true);
         }, 200); 
      } 
     },[history.location.pathname])
   return (
      <>
       <Wrapper id="intro" onMouseEnter={() => setIsClick(false)}>
         <div className='container-home block'>
            <div className='flex mb-[9rem]'>
               <div className='left'>
                 <SiderBar list={list} setList={setList} isClick={isClick} />
               </div>
               <div className='right'>
                   <Modal />
               </div>
            </div>
            <div className='start-selling block'>
               <div className='item_fi flex h-[27rem]'>
                  <div className='mt-[5px]'>
                     <div className='line-up h-[65px]'></div>
                     <div className='line-down h-[26.7rem]'></div>
                  </div>
                  <div className='pl-4 block pb-4'>
                     <div className='block p-2'>
                     <p className='title pt-2'>{t("BusinessPurpose.Business")}</p>
                        <p className='text pt-3'>{t("BusinessPurpose.content1")}
                           <br/>{t("BusinessPurpose.content2")}
                           <br/>{t("BusinessPurpose.content3")}</p>
                        <p className='text pt-1'>{t("BusinessPurpose.content4")}
                           <br/>{t("BusinessPurpose.content5")}
                           <br/>{t("BusinessPurpose.content6")}</p>
                     </div>
                  </div>
               </div>
         </div>
         </div>
      </Wrapper>
      </>
   )
}
export default SustinaAbilityScreen
