import React, { useState, useRef } from "react";
import { Wrapper } from "./styled";
import { ModalHome } from "@components";
import ModalVideo from "./modal";
import {
  BG_Slide1,
  BG_Slide2,
  BG_Slide3,
  BG_Slide4,
  BG_Slide5,
  BG_Slide6,
  Person01_home,
  Image_Video,
  Image_Expo,
} from "@assets";
import video from "@assets/Metarverse_video_720.mp4";
import videoMobile from "@assets/MetarverseVideo_Mobile.mp4";
//@ts-ignore
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
//@ts-ignore
// import VideoPlayer from "react-background-video-player"
import { useTranslation } from "react-i18next";

import useScreenWidth from "../../Hook/useScreenWidth";
import RoadmapMobile from "./roadmap-mobile";

const HomeScreen = () => {
  const bgr = [
    BG_Slide6,
    BG_Slide5,
    BG_Slide4,
    BG_Slide3,
    BG_Slide2,
    BG_Slide1,
  ];
  const [isShowVideo, setIsShowVideo] = useState(false);
  const [buttonShow, setButtonShow] = useState(false);
  const { t } = useTranslation();
  const screenWidth = useScreenWidth();

  const divOne = useRef<HTMLInputElement | null>(null);
  const scrolLWithUseRef = () => {
    divOne.current?.scrollIntoView({ block: "center", behavior: "smooth" });
  };

  const [sliderRef] = useKeenSlider<HTMLDivElement>(
    {
      loop: true,
    },
    [
      (slider: any) => {
        let timeout: ReturnType<typeof setTimeout>;
        let mouseOver = false;
        function clearNextTimeout() {
          clearTimeout(timeout);
        }
        function nextTimeout() {
          clearTimeout(timeout);
          if (mouseOver) return;
          timeout = setTimeout(() => {
            slider.next();
          }, 15000);
        }
        slider.on("created", () => {
          slider.container.addEventListener("mouseout", () => {
            mouseOver = false;
            nextTimeout();
          });
          nextTimeout();
        });
        slider.on("dragStarted", clearNextTimeout);
        slider.on("animationEnded", nextTimeout);
        slider.on("updated", nextTimeout);
      },
    ]
  );

  return (
    <>
      <Wrapper id="intro">
        <div className="bg_slide">
          <div ref={sliderRef} className="keen-slider h-full">
            {bgr.map((image, index) => (
              <img
                key={index}
                className="keen-slider__slide"
                src={image}
                alt=""
              />
            ))}
          </div>
        </div>
        <div className="container-home block">
          <div className="flex mb-[8rem] modal-home m-auto">
            <div className="left"></div>
            <div className="right">
              <ModalHome />
            </div>
          </div>
          <div className="section-1">
            <div className="content">
              <div className="text">
                <p className="text_20">{t("pagehome.contentVideo.01")}</p>
                <p className="text_20">{t("pagehome.contentVideo.02")}</p>
                <p className="text_20">{t("pagehome.contentVideo.03")}</p>
                <p className="text_20">{t("pagehome.contentVideo.04")}</p>
                <p className="text_20">{t("pagehome.contentVideo.05")}</p>
              </div>
              {screenWidth > 991 ? (
                <>
                  <video
                    autoPlay
                    playsInline
                    controls
                    muted
                    loop
                    className="border-white border-[5px] my-[3rem] w-full"
                  >
                    <source src={video} type="video/mp4"></source>
                  </video>
                </>
              ) : (
                <>
                  <video
                    autoPlay
                    playsInline
                    controls
                    muted
                    loop
                    className="border-white border-[5px] my-[3rem] w-full"
                  >
                    <source src={videoMobile} type="video/mp4"></source>
                  </video>
                </>
              )}
              <div className="imageVideo my-[2rem]">
                <img
                  src={Image_Video}
                  alt=""
                  className="h-full m-auto w-[80%]"
                />
              </div>
              <div>
                <p>{t("pagehome.contentVideo.06")}</p>
                <p>{t("pagehome.contentVideo.07")}</p>
                <p>{t("pagehome.contentVideo.08")}</p>
              </div>
            </div>
          </div>
          <div className="section-2">
            <div className="section-2-child1">
              <div className="w-full">
                <img
                  src={Person01_home}
                  alt=""
                  className="rounded-xl m-auto img_person"
                />
              </div>
            </div>
            <div className="what_roadmap">
              <h3 className="rounded-xl">{t("pagehome.btWhat")}</h3>
            </div>
            <div className="directory">
              <img src={Image_Expo} alt=""/>
              <div className="content">
                <p>
                  <span className="text-[21px]">第一回メタバース活用ＥＸＰＯ春に当社の出展が決定しました！</span>
                  <br />
                  ４／５（水）～４／７（金）東京ビッグサイト開催！
                </p>
                <a
                  href="https://www.japan-it.jp/spring/ja-jp/search/2023/directory/directory-details.org-4837e50e-649d-4457-a658-7fed3fd62a6d.html#/"
                  target="_blank"
                  rel="noreferrer"
                  className="underline"
                >
                  <p>アートメタバースランド (株) - 出展社詳細 (japan-it.jp)</p>
                </a>
                <p className="mt-12">招待状発行URL当社専用</p>
                <a
                  href="https://www.japan-it.jp/spring/ja-jp/visit/e-ticket-ex/meta.html?=coits2023?co=ml_meta-s-85tbok"
                  target="_blank"
                  rel="noreferrer"
                  className="text-[14px]"
                >
                  <p>https://www.japan-it.jp/spring/ja-jp/visit/e-ticket-ex/meta.html?=coits2023?co=ml_meta-s-85tbok</p>
                </a>
              </div>
              <img src={Image_Expo} alt="" className="img02" />
            </div>
            {screenWidth > 991 ? (
              <div className=" road_map">
                <div className="relative mt-5 text-left ">
                  <div className="flex items-center relative">
                    <div className="w-1/2 t-1">
                      <h3 className="font-bold">
                        {t("pagehome.timeline.date1")}
                      </h3>
                      <div className="item">
                        <p>{t("pagehome.timeline.title1")}</p>
                      </div>
                    </div>
                    <div className="border-r-2 border-[#D9D9D9] absolute h-full left-[50%] top-2 z-10">
                      <div className="circle -ml-3 bg-[#0A8A80]">
                        <div className="child"></div>
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center relative mt-[-4rem]">
                    <div className="border-r-2 border-[#D9D9D9] absolute h-full left-[50%] top-2 z-10">
                      <div className="circle -ml-3 bg-[#F0633D]">
                        <div className="child"></div>
                      </div>
                    </div>

                    <div className="ml-[55%] w-1/2 t-2 text_r">
                      <h3 className="font-bold">
                        {t("pagehome.timeline.date2")}
                      </h3>
                      <div className="item mr_">
                        <p>{t("pagehome.timeline.title2")}</p>
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center relative  mt-[-2rem]">
                    <div className="w-1/2 t-3">
                      <h3 className="font-bold">
                        {t("pagehome.timeline.date3")}
                      </h3>
                      <div className="item">
                        <p>{t("pagehome.timeline.title3")}</p>
                      </div>
                    </div>

                    <div className="border-r-2 border-[#D9D9D9] absolute h-full left-[50%] top-2 z-10">
                      <div className="circle -ml-3 bg-[#FAA713]">
                        <div className="child"></div>
                      </div>
                    </div>
                  </div>
                  {buttonShow ? (
                    <>
                      <div className="flex items-center relative pt-[6rem]">
                        <div className="border-r-2 border-[#D9D9D9] absolute h-full left-[50%] top-2 z-10 pt-[6rem]">
                          <div className="circle -ml-3 bg-[#648209]">
                            <div className="child"></div>
                          </div>
                        </div>

                        <div className="ml-[50%] w-1/2 t-4 text_r">
                          <h3 className="font-bold" ref={divOne}>
                            {t("pagehome.timeline.date4")}
                          </h3>
                          <div className="item mr_">
                            <p>{t("pagehome.timeline.title4")}</p>
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center relative mt-[-6rem]">
                        <div className="w-1/2 t-5">
                          <h3 className="font-bold">
                            {t("pagehome.timeline.date5")}
                          </h3>
                          <div className="item">
                            <p>{t("pagehome.timeline.title5")}</p>
                          </div>
                        </div>

                        <div className="border-r-2 border-[#D9D9D9] absolute h-full left-[50%] top-2 z-10">
                          <div className="circle -ml-3 bg-[#F60500]">
                            <div className="child"></div>
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center relative">
                        <div className="border-r-2 border-[#D9D9D9] absolute h-full left-[50%] top-2 z-10">
                          <div className="circle -ml-3 bg-[#2996ff]">
                            <div className="child"></div>
                          </div>
                        </div>

                        <div className="ml-[50%] w-1/2 t-6 text_r">
                          <h3 className="font-bold">
                            {t("pagehome.timeline.date6")}
                          </h3>
                          <div className="item mr_">
                            <p>{t("pagehome.timeline.title6")}</p>
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center relative">
                        <div className="w-1/2 t-1">
                          <h3 className="font-bold">
                            {t("pagehome.timeline.date7")}
                          </h3>
                          <div className="item">
                            <p>{t("pagehome.timeline.title7")}</p>
                          </div>
                        </div>

                        <div className="border-r-2 border-[#D9D9D9] absolute h-full left-[50%] top-2 z-10">
                          <div className="circle -ml-3 bg-[#0A8A80]">
                            <div className="child"></div>
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center relative">
                        <div className="border-r-2 border-[#D9D9D9] absolute h-full left-[50%] top-2 z-10">
                          <div className="circle -ml-3 bg-[#F0633D]">
                            <div className="child"></div>
                          </div>
                        </div>

                        <div className="ml-[50%] w-1/2 t-2 text_r">
                          <h3 className="font-bold">
                            {t("pagehome.timeline.date8")}
                          </h3>
                          <div className="item mr_">
                            <p>{t("pagehome.timeline.title8")}</p>
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center relative pt-[2rem] mt-[-7rem]">
                        <div className="w-1/2 t-3">
                          <h3 className="font-bold">
                            {t("pagehome.timeline.date9")}
                          </h3>
                          <div className="item">
                            <p>{t("pagehome.timeline.title9")}</p>
                          </div>
                        </div>

                        <div className="border-r-2 border-[#D9D9D9] absolute h-full left-[50%] top-2 z-10 pt-[2rem]">
                          <div className="circle -ml-3 bg-[#FAA713]">
                            <div className="child"></div>
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center relative mt-[-3rem]">
                        <div className="border-r-2 border-[#D9D9D9] absolute h-full left-[50%] top-2 z-10">
                          <div className="circle -ml-3 bg-[#648209]">
                            <div className="child"></div>
                          </div>
                        </div>

                        <div className="ml-[50%] w-1/2 t-4 text_r">
                          <h3 className="font-bold">
                            {t("pagehome.timeline.date10")}
                          </h3>
                          <div className="item mr_">
                            <p>{t("pagehome.timeline.title10")}</p>
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center relative pt-[2rem]">
                        <div className="w-1/2 t-5">
                          <h3 className="font-bold">
                            {t("pagehome.timeline.date11")}
                          </h3>
                          <div className="item">
                            <p>{t("pagehome.timeline.title11")}</p>
                          </div>
                        </div>

                        <div className="border-r-2 border-[#D9D9D9] absolute h-full left-[50%] top-2 z-10  pt-[2rem]">
                          <div className="circle -ml-3 bg-[#F60500]">
                            <div className="child"></div>
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center relative mt-[-4rem]">
                        <div className="border-r-2 border-[#D9D9D9] absolute h-full left-[50%] top-2 z-10">
                          <div className="circle -ml-3 bg-[#2996ff]">
                            <div className="child"></div>
                          </div>
                        </div>

                        <div className="ml-[50%] w-1/2 t-6 text_r">
                          <h3 className="font-bold">
                            {t("pagehome.timeline.date12")}
                          </h3>
                          <div className="item mr_">
                            <p>{t("pagehome.timeline.title12")}</p>
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center relative mt-[-4rem]">
                        <div className="w-1/2 t-1">
                          <h3 className="font-bold">
                            {t("pagehome.timeline.date13")}
                          </h3>
                          <div className="item">
                            <p>{t("pagehome.timeline.title13")}</p>
                          </div>
                        </div>

                        <div className="border-r-2 border-[#D9D9D9] absolute h-full left-[50%] top-2 z-10">
                          <div className="circle -ml-3 bg-[#0A8A80]">
                            <div className="child"></div>
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center relative">
                        <div className="border-r-2 border-[#D9D9D9] absolute h-full left-[50%] top-2 z-10">
                          <div className="circle -ml-3 bg-[#F0633D]">
                            <div className="child"></div>
                          </div>
                        </div>

                        <div className="ml-[50%] w-1/2 t-2 text_r">
                          <h3 className="font-bold">
                            {t("pagehome.timeline.date14")}
                          </h3>
                          <div className="item mr_">
                            <p>{t("pagehome.timeline.title14")}</p>
                          </div>
                        </div>
                      </div>
                      <div className="showmore">
                        <button
                          onClick={() => {
                            setButtonShow(!buttonShow);
                            scrolLWithUseRef();
                          }}
                        >
                          {t("pagehome.btCloseTimeline")}
                        </button>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="showmore">
                        <button onClick={() => setButtonShow(!buttonShow)}>
                          {t("pagehome.btOpenTimeline")}
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            ) : (
              <RoadmapMobile />
            )}
          </div>
          {/* <div className='section-3'>
              <div className='image'>
                 <img src={IMAGE_S3} alt='' className='m-auto' />
              </div>
              <div className='content'>
                 <h1>Explore LANDs</h1>
                 <p>owned by users to experience incredible scenes and structures. From a space adventure to a medieval dungeon maze to entire villages crafted from the mind</p>
              </div>
            </div>
            <div className='section-4'>
               <img src={IMAGE_S4} alt='' className='w-full absolute z-[-2] h-[450px]'/>
               <div className='content'>
                  <h1>Explore</h1>
                  <h1>Lose yourself in an amazing, evolving world</h1>
                  <p>Explore LANDs owned by users to experience incredible scenes and structures. From a space adventure to a 
                  medieval dungeon maze to entire villages crafted from the minds of community members.</p>
                  <div className='text-center mt-8'>
                    <button>start exploring</button>
                  </div>
               </div>
            </div>
            <div className='section-5'>
               <h1>Can't decide where to start?</h1>
               <div className='s5_button'>
                  <button className='bg-[#A98260] text-white mr-8'>upcoming events</button>
                  <button className='bg-[#FFFFFF] text-[#A98260]'>point of interest</button>
               </div>
               <div className='s5_image'>
                  <div className='container mr-[3%]'>
                    <img src={IMAGE_S5_1} alt='' className='p-[6px] h-[199px] w-full' />
                    <p className='title'>Usual channels</p>
                  </div>
                  <div className='container mr-[3%]'>
                    <img src={IMAGE_S5_2} alt=''  className='p-[6px] h-[199px] w-full'/>
                    <p className='title'>Usual channels</p>
                  </div>
                  <div className='container'>
                    <img src={IMAGE_S5_3} alt=''  className='p-[6px] h-[199px] w-full' />
                    <p className='title'>Usual channels</p>
                  </div>
               </div>
               <div className='s5_cont'>
                  <div className='w-[50%]'>
                     <div className='h-full auto-h'>
                        <h1>Contact us</h1>
                        <p className='w-[62%]'>You'll find us at all hours on Discord. You can also reach us through the usual channels.</p>
                        <button>get in touch</button>
                     </div>
                  
                  </div>
                  <div className='w-[50%]'>
                     <div className='h-full'>
                        <h1>Get the latest updates</h1>
                        <p className='w-[78%]'>Breaking news on events, competitions and new developments in Decentraland, delivered straight to your inbox</p>
                        <button>get in touch</button>
                     </div>
                   
                  </div>
               </div>
            </div> */}
        </div>
      </Wrapper>
      {isShowVideo && <ModalVideo event={() => setIsShowVideo(false)} />}
    </>
  );
};
export default HomeScreen;
