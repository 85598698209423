import React , {useRef, useEffect}from 'react'
import { Wrapper } from './styled'
import useScreenWidth from '../../../Hook/useScreenWidth'

const AllVisitors = () => {
   const divOne = useRef<HTMLInputElement | null>(null);
   const scrolLWithUseRef = () => {
      divOne.current?.scrollIntoView({ block: "center", behavior: "smooth" });
   };

   useEffect(() => {
      scrolLWithUseRef()
   }, [])

   const screenWidth = useScreenWidth()
   return (
      <>
      <Wrapper>
          <div className='container_visitor block text-justify'>
            <div className='content'>
               <div className='mt-4'>
                  <div className='item'>
                     <div className={`flex ${ (screenWidth > 580) ? 'mb-[3rem]' : 'mb-[1rem]'}`}>
                        <h1 className="text-[24px] leading-normal md:leading-relaxed font-bold" id="id0">来園者の皆様</h1>
                     </div>
                     <div className={`w-full ${ (screenWidth > 580) ? 'pl_6' : 'ml-0'}`}>
                        <p className=''>2023年2月1日 ： アートメタバースランドは、２０２４年７月１日開園を予定しております。</p>
                        <div className={`block mt-8 ${ (screenWidth > 580) ? 'pl-[3rem]' : 'ml-0'}`}>
                           <p className='font-bold text-lg md:text-1xl'>各種サービスのご紹介</p>
                           <div className={`${ (screenWidth > 580) ? 'ml-6' : 'ml-0'}`}>
                              {screenWidth > 580 ? 
                                 <>
                                    <p >アートメタバースランドでは、アバターアイテムショップ、お話しサロン、ビデオ フォト鑑賞ルーム、</p>
                                    <p>結婚相手マッチングサービス、公園、ゲームサロン、ＥＣモールの各種商店、会議室・マンション等、</p>
                                    <p>数多くの楽しいサービスを展開いたします。</p>
                                 </>
                                 :<>
                                    <p>アートメタバースランドでは、アバターアイテムショップ、お話しサロン、ビデオ フォト鑑賞ルーム、結婚相手マッチングサービス、公園、ゲームサロン、ＥＣモールの各種商店、会議室・マンション等、数多くの楽しいサービスを展開いたします。</p>
                                 </>
                              }
                              <p>また、アルバイトをしたり、チップを受け取ったり、いろいろな所得獲得機能もご用意しています。</p>
                              <p>楽しみに待っていてください。</p>
                           </div>                            
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </Wrapper>
      </>
   )
}
export default AllVisitors
