import React , {useRef, useEffect} from 'react'
import { Wrapper } from './styled'
import {Person01_23, Person02_23} from '@assets'
const OperationStory = () => {
   const divOne = useRef<HTMLInputElement | null>(null);
   const scrolLWithUseRef = () => {
   divOne.current?.scrollIntoView({ block: "center", behavior: "smooth" });
   };
 
   useEffect(() => {
    scrolLWithUseRef()
   },[])
   return (
      <>
      <Wrapper>
         <div className='container_story block'>
            <div className='content'>
               <h1 className="text-[30px] leading-normal md:leading-relaxed text-center font-bold mb-[3rem] " ref={divOne}>お話しサロンの運営</h1>
               <div className='text'>
                  <div className='flex mt-2'>
                     <p className='text-yellow-400'>■ </p>
                     <div className='block'>
                        <p>誰かに話を聞いてほしい時に、話し相手を見つけることができるサロンを運営します。</p>
                        <div className='ml-4 mt-4'>
                           <div className='flex'>
                              <p className='text-[#f4b084]'>● </p>
                              <div className='block'>
                                 <p>お話ししたい人・・・１０分につきＡＲＴコイン３００円分を費用負担。</p>
                              </div>
                           </div>
                           <div className='flex mt-2'>
                              <p className='text-[#f4b084]'>● </p>
                              <div className='block'>
                                 <p>お話しを聞く人・・・当社との間の業務委託契約に基づく報酬を受取る。</p>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className='flex mt-8'>
                     <p className='text-yellow-400'>■ </p>
                     <div className='block'>
                        <p>獲得した報酬は、「LINEPAY」や「PayPalペイアウト」を使って引き出すことができます。</p>
                     </div>
                  </div>
                  <div className='flex mt-8'>
                     <p className='text-yellow-400'>■ </p>
                     <div className='block'>
                        <p>双方の意思確認とプライバシー確保を行い、安心して利用できる環境を提供します。</p>
                        <div className='ml-4 mt-4'>
                           <div className='flex'>
                              <p className='text-[#f4b084]'>● </p>
                              <div className='block'>
                                 <p>「テキストチャット」と「ボイスチャット」のいずれも選択できます。</p>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div className='flex img-box mt-[3em] h_1'>
                  <div className='w-1/3 pr-[2rem]'>
                     <img src={Person02_23} alt='' className='w-full m-auto rounded-xl h-full h_2' />
                  </div>
                  <div className='w-2/3 '>
                     <img src={Person01_23} alt='' className='m-auto rounded-xl h-full w-full h_3' />
                  </div>
               </div>
            </div>
         </div>
       </Wrapper>
      </>
   )
}
export default OperationStory
