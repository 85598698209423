import styled from 'styled-components'

export const Wrapper = styled.div`
  height: 100%;
  display: block;
  margin: auto;
  width: 1100px;
  @media screen and (max-width: 1620px) {
    width: 1000px;
  }
  @media screen and (max-width: 1280px) {
    width: 100%;
  }
  .container-home {
    width: 100%;
    .center{
      width: 100%;
      height: 79%;
      display: block;
      background-color: rgb(10 10 10 / 43%);
      margin-top: -4rem;
      padding: 4rem 0 15.6rem;
      text-align: -webkit-center;
      .run {
        color: red;
        font-weight: bold;
        letter-spacing: 2px;
        overflow: hidden;
        white-space: nowrap;
        animation: typewriter 5s steps(30) 1s 1 normal both,
          blinkTextCursor 500ms infinite;
      }
      @keyframes typewriter {
        from {
          width: 0;
        }
        to {
          width: 100%;
        }
      }
      @keyframes blinkTextCursor {
        from {
          border-right-color: red;
        }
        to {
          border-right-color: transparent;
        }
      }
    }
    .right{
      width: 20%;
      height: 79%;
      margin-left: 80%;
      @media screen and (max-width: 1280px){
        margin-left: 77%;
      }
    }
    
    @media screen and (max-width: 991px) {
      padding-top: 50px;

      p {
        font-size: 14px;
      }

      h1 {
        font-size: 1.2rem;
      }
    }

  }
 
`
