import styled from 'styled-components'

export const Wrapper = styled.div`
width: 100%;
 .image_{
   @media screen and (min-width: 1920px){
     margin-left: 7rem;
     margin-top : -20px;
   }
 }
 .img_house{
   width : 70%;
   @media screen and (min-width: 1920px){
      width :90%;
   }
 }
 .img_person{
   width : 70%;
 }
 .img_unit{
   width :90%;
   margin: auto;
   padding :3rem 0 0 5rem;
 }
 .h_45{
    height: 400px;
    @media screen and (max-width: 580px){
      height: 300px;
   }
   @media print{
      height: 300px;
      margin-bottom: 4rem;
    }
  }
  p{
    line-height: 1.6;
    font-size: 19px;
    @media print{
      font-size: 14px;
    }
    @media screen and (max-width: 1620px) {
      font-size: 18px;
    }
    @media screen and (max-width: 1280px) {
      font-size: 16px;
    }
    @media screen and (max-width: 580px) {
      font-size: 14px;
    }
  }
  h2{
    font-size: 24px;
    color: #4472c4;
    @media screen and (max-width: 1620px) {
      font-size: 20px;
    }
    @media print{
      font-size: 16px;
    }
  }
.container_cor{
    width: 100%;
    .section-1{
      padding: 6rem 0 10rem;
      @media screen and (max-width: 1620px){
        padding: 4rem 0 10rem;
      }  
      @media screen and (max-width: 991px){
      padding: 1rem 0 10rem;
      }  
      @media print{
        padding:2rem 0 0rem;
        h1{
          font-size: 20px;
        }
      }
      .content{
        width: 75%;
        @media screen and (max-width: 1620px){
          width: 80%;
        } 
        @media screen and (max-width: 1280px){
          width: 100%;
        } 
        margin : auto;
      }
    }
    .section-2{
      width:100%;
      margin: auto;
      .content{
        width:80%;
        margin:auto;
        padding: 3rem 0 2rem;
      }
    }
    @media screen and (max-width: 991px) {
      margin-top: 80px;
      h1{
        font-size: 20px;
      }
    }

    @media screen and (max-width: 767px) {
      .section-1 {
        padding-bottom: 0;

        .content {
          
          >div {
            &.flex {
              flex-direction: column;

              div {
                width: 100%;
                padding-right: 0;
                .img1 {
                  width: 40%;
                }
                .img2{
                  width: 60%;
                  margin: auto;
                  padding: 2rem 0;
                }
              }
            }
          }
        }
      }

      .section-2 {
        padding-top: 0;
        .content {
          width: 100%;
          padding-top: 0;

          .flex {
            flex-direction: column;
            div {
              width: 100%;
            }

            .img-box {
                padding: 0;
                img {
                  margin-top: 60px;
              }
            }
          }
        }
      }
    }
    @media screen and (max-width: 580px) {
      .section-1 {

        .content {
          
          >div {
            &.flex {

              div {
                .img1 {
                  width: 60%;
                }
                .img2{
                  width: 100%;
                  margin: auto;
                  padding: 2rem 0;
                }
              }
            }
          }
        }
      }
    }
}
`