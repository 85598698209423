/* eslint-disable react/prop-types */
import styled from 'styled-components'

const Wrapper: any = styled.div`
  position: absolute;
  width: 100%;
  height: 829px;
  left: 0px;
  top: 0px;
  display: block;
  background-position:center;
  background-repeat : no-repeat;
  background-size : cover;
`
const Main = styled.main`
  width: 100%;
  padding: 0;
  justify-content: center;
`
const Content = styled.div`
  width: 100%;
  overflow: hidden;
`

const VideoLayout = ({ children }: any) => {
  return (
    <Wrapper >
      <div className='container_'>
        <Main>
          <Content>
            {children}
          </Content>
        </Main>
      </div>
    </Wrapper>
  )
}

export default VideoLayout
