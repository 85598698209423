import React, {useRef, useEffect} from 'react'
//@ts-ignore
import { useForm } from "react-hook-form";
import { Wrapper } from './styled'
import ModalNoti from '@components/modalNoti';
import { ApplicationContactUsInformation } from '../../../APIs';
import { options } from '../../../Utils'
import {Image_FlowRegister,Image_InforCompany} from '@assets'
import useScreenWidth from 'src/Hook/useScreenWidth';
import ModalImage from './modalImage';
//@ts-ignore
import Select from "react-select";
const IndividualContractNoPayerScreen = () => {
     const {
         register,
         handleSubmit,
         setValue,
         formState: { errors }
      } = useForm();
      const [notification, setNotification] = React.useState(false);
      const [check, setCheck] = React.useState(false);
      const [submit, setSubmit] = React.useState(false);
      const [dataForm, setDataForm] = React.useState({
         namePayer:'',
         namePayerSpelling : '',
         addressPayer: '',
         emailPayer : '',
         phonePayer :'',
         nameSigning:'',
         nameSigningSpelling : '',
         addressSigning: '',
         emailSigning : '',
         phoneSigning :'',
         nameRaiser:'',
         nameRaiserSpelling : '',
         addressRaiser: '',
         emailRaiser : '',
         phoneRaiser :'',
         numberApartment : '',
      });
      const [numberApartment, setNumber] = React.useState('');

      const [isChecked1, setIsChecked1] = React.useState(false);
      const [isChecked3, setIsChecked3] = React.useState(false);
   
      const [selectedFile1, setSelectedFile1] = React.useState('');
      const [selectedFile2, setSelectedFile2] = React.useState('');
      const [selectedFile3, setSelectedFile3] = React.useState('');
      const [selectedFile4, setSelectedFile4] = React.useState('');
      const [selectedFile5, setSelectedFile5] = React.useState('');
      const [selectedFile6, setSelectedFile6] = React.useState('');
      const [isShowImage, setIsShowImage] = React.useState(false);
      const [image1, setImage1] = React.useState<any>(null)
      const [image2, setImage2] = React.useState<any>(null)
      const [image3, setImage3] = React.useState<any>(null)
      const [image4, setImage4] = React.useState<any>(null)
      const [image5, setImage5] = React.useState<any>(null)
      const [image6, setImage6] = React.useState<any>(null)
      const [file1, setFile1] = React.useState('');
      const [file2, setFile2] = React.useState('');
      const [file3, setFile3] = React.useState('');
      const [file4, setFile4] = React.useState('');
      const [file5, setFile5] = React.useState('');
      const [file6, setFile6] = React.useState('');
      const [checkfile1, setchekFile1] = React.useState(true);
      const [checkfile2, setchekFile2] = React.useState(true);
      const [checkfile3, setchekFile3] = React.useState(true);
      const [checkfile4, setchekFile4] = React.useState(true);
      const [checkfile5, setchekFile5] = React.useState(true);
      const [checkfile6, setchekFile6] = React.useState(true);
      const [size1, setSize1] = React.useState('');
      const [size2, setSize2] = React.useState('');
      const [size3, setSize3] = React.useState('');
      const [size4, setSize4] = React.useState('');
      const [size5, setSize5] = React.useState('');
      const [size6, setSize6] = React.useState('');
      const handleFileSelect1 = (event : any) => {
         setFile1(event.target.value.replace(/^.*\\/,""))
         setSize1(event.target.files[0].size)
         setSelectedFile1(event.target.files[0])
         setImage1(URL.createObjectURL(event.target.files[0]));
      }
      const handleFileSelect2 = (event : any) => {
         setFile2(event.target.value.replace(/^.*\\/,""))
         setSize2(event.target.files[0].size)
         setSelectedFile2(event.target.files[0])
         setImage2(URL.createObjectURL(event.target.files[0]));
      }
      const handleFileSelect3 = (event : any) => {
         setFile3(event.target.value.replace(/^.*\\/,""))
         setSize3(event.target.files[0].size)
         setSelectedFile3(event.target.files[0])
         setImage3(URL.createObjectURL(event.target.files[0]));
      }
      const handleFileSelect4 = (event : any) => {
         setFile4(event.target.value.replace(/^.*\\/,""))
         setSize4(event.target.files[0].size)
         setSelectedFile4(event.target.files[0])
         setImage4(URL.createObjectURL(event.target.files[0]));
      }
      const handleFileSelect5 = (event : any) => {
         setFile5(event.target.value.replace(/^.*\\/,""))
         setSize5(event.target.files[0].size)
         setSelectedFile5(event.target.files[0])
         setImage5(URL.createObjectURL(event.target.files[0]));
      }
      const handleFileSelect6 = (event : any) => {
         setFile6(event.target.value.replace(/^.*\\/,""))
         setSize6(event.target.files[0].size)
         setSelectedFile6(event.target.files[0])
         setImage6(URL.createObjectURL(event.target.files[0]));
      }
      const optionsSelect1 = [
         { value: "1", label: "ファイルを添付します。" },
         { value: "2", label: "後日、契約書の締結時に本人確認書類を提出します。" },
       ];
       const optionsSelect2 = [
         { value: "1", label: "ファイルを添付します。" },
         { value: "2", label: "契約者は成年のため、親権者の本人確認書類の添付は不要です。"},
         { value: "3", label: "後日、契約書の締結時に本人確認書類を提出します。" },
       ];
      const [mySelect1, setMySelect1] = React.useState('1');
      const [mySelect2, setMySelect2] = React.useState('1');

      useEffect(()=>{
         if(mySelect1 === '2'){
            setSelectedFile3("");
            setSelectedFile4("");
            setImage3('');
            setImage4('');
            setchekFile3(true)
            setchekFile4(true)
         }
         if(mySelect2 === '2'){
            setSelectedFile5("");
            setSelectedFile6("");
            setImage5('');
            setImage6('')
            setchekFile5(true)
            setchekFile6(true)
         }
      },[mySelect1,mySelect2])

      const onCheck = (data : any) => {
         if(mySelect1 === '1' && mySelect2==='1'){
            if(selectedFile1 && selectedFile2 && selectedFile3 && selectedFile4 &&selectedFile5 && selectedFile6 ){
               setCheck(!check);
            }
         }
         if(mySelect1 === '1' && (mySelect2==='2' || mySelect2==='3')){
            if(selectedFile1 && selectedFile2 && selectedFile3 && selectedFile4){
               setCheck(!check);
            }
         }
         if(mySelect1==='2' && mySelect2==='1'){
            if(selectedFile1 && selectedFile2 && selectedFile5 && selectedFile6){
               setCheck(!check);
            }
         }
         if(mySelect1==='2' && (mySelect2==='2' || mySelect2==='3')){
            if(selectedFile1 && selectedFile2){
               setCheck(!check);
            }
         }
         if(!selectedFile1) {
            setchekFile1(false)
         }
         if(!selectedFile2) {
            setchekFile2(false)
         }
         if(!selectedFile3) {
            setchekFile3(false)
         }
         if(!selectedFile4) {
            setchekFile4(false)
         }
         if(!selectedFile5) {
            setchekFile5(false)
         }
         if(!selectedFile6) {
            setchekFile6(false)
         }
         setDataForm({
            namePayer:`${data.firstnamePayer}${data.lastnamePayer}`,
            namePayerSpelling : `${data.firstnamePayerspelling}${data.lastnamePayerspelling}`,
            addressPayer: `${data.addressPayer}`,
            emailPayer : `${data.emailPayer}`,
            phonePayer :`${data.phonenumberPayer}`,
            nameSigning:`${data.firstnameSigning}${data.lastnameSigning}`,
            nameSigningSpelling : `${data.firstnameSigningspelling}${data.lastnameSigningspelling}`,
            addressSigning: `${data.addressSigning}`,
            emailSigning : `${data.emailSigning}`,
            phoneSigning :`${data.phonenumberSigning}`,
            nameRaiser:`${data.firstnameRaiser}${data.lastnameRaiser}`,
            nameRaiserSpelling : `${data.firstnameRaiserspelling}${data.lastnameRaiserspelling}`,
            addressRaiser: `${data.addressRaiser}`,
            emailRaiser : `${data.emailRaiser}`,
            phoneRaiser :`${data.phonenumberRaiser}`,
            numberApartment : `${data.numberApartment}`,
         })
      }

      useEffect(()=>{
         const text =`${dataForm.namePayer} 様<br/>
         ${dataForm.nameSigning} 様<br/>
         ${dataForm.nameRaiser ? `${dataForm.nameRaiser} 様<br/>` : ''}
         <br/>このたびは、弊社会議室・マンションの購入申込をいただき、まことにありがとうございます。
         <br/>追って、弊社から契約書、購入いただく部屋の番号、購入代金、代金振込先等をご連絡するメールを、別途送信させていただきます。
         <br/>申込が集中いたしておりますので、一週間程度お時間をいただくことがございます。宜しくお願い申し上げます。
         <br/>なお、先着順となっておりますので、メタバース不動産が完売となり、お申し込みをお受けできない場合もございます。
         <br/>その際は何卒ご容赦賜りたくお願い申し上げます。
         <br/><br/>■記載内容：
         <br/>　　代金負担者の氏名：${dataForm.namePayer}
         <br/>　　代金負担者の氏名（フリガナ）：${dataForm.namePayerSpelling}
         <br/>　　代金負担者の住所：${dataForm.addressPayer}
         <br/>　　代金負担者のメールアドレス：${dataForm.emailPayer}
         <br/>　　代金負担者の携帯電話番号：${dataForm.phonePayer}
         <br/>　　契約者の氏名：${dataForm.nameSigning}
         <br/>　　契約者の氏名（フリガナ）：${dataForm.nameSigningSpelling}
         <br/>　　契約者の住所：${dataForm.addressSigning}
         <br/>　　契約者のメールアドレス：${dataForm.emailSigning}
         <br/>　　契約者の携帯電話番号：${dataForm.phoneSigning}
         <br/>　　契約者の親権者氏名：${dataForm.nameRaiser}
         <br/>　　契約者の親権者氏名（フリガナ）：${dataForm.nameRaiserSpelling}
         <br/>　　契約者の親権者住所：${dataForm.addressRaiser}
         <br/>　　契約者の親権者メールアドレス：${dataForm.emailRaiser}
         <br/>　　契約者の親権者の携帯電話番号：${dataForm.phoneRaiser}
         <br/>　　会議室・マンション販売価格：110万円／戸（税込み）
         <br/>　　購入会議室・マンション戸数：${dataForm.numberApartment}
         <br/>　　購入代金の合計金額：${110*Number(numberApartment)}万円（税込み）
         <br/>　　代金負担者の本人確認書類の表裏写真を添付：添付あり
         <br/>　　契約者の本人確認書類の表裏写真を添付　※未成年の場合は健康保険証の写真を添付：${ (selectedFile3 || selectedFile4) ? '添付あり' : '添付なし。後日ご提出ください。'}
         <br/>　　契約者の親権者の本人確認書類の表裏写真を添付：${ (selectedFile5 || selectedFile6) ? '添付あり' : '添付不要。'}
         <br/><br/>※当メールは、お客様からの購入申込と同時に、システムによりメールを自動返信しております。当メールの受領のみでは契約は完了いたしておりません。ご了承いただきたくお願い申し上げます。
         <br/> ________________________________________________________
         <br/><br/>アートメタバースランド株式会社
         <br/>〒176-0022　東京都練馬区向山３－１５－１９
         <br/>電話番号　０３－６６３６－７８９３
         <br/>メールによるお問い合わせは、弊社ホームページお問い合わせフォームからお願いいたします。
         <br/> ________________________________________________________
         `       
         const formData = new FormData();
         formData.append("name", `${dataForm.namePayer}`);
         formData.append("email", 'maison@artmetaverseland.co.jp');
         formData.append("data",text);
         formData.append("cc", '');
         formData.append("subject", '会議室・マンション購入の申込を受領いたしました');
         formData.append("attachments[]", selectedFile1);
         formData.append("attachments[]", selectedFile2);
         if(selectedFile3){
            formData.append("attachments[]", selectedFile3);
         }
         if(selectedFile4){
            formData.append("attachments[]", selectedFile4);
         }
         if(selectedFile5){
            formData.append("attachments[]", selectedFile5);
         }
         if(selectedFile6){
            formData.append("attachments[]", selectedFile6);
         }
         if(submit){
            ApplicationContactUsInformation({
               name : `${dataForm.namePayer}`,
               email: dataForm.emailPayer,
               data: text,
               cc: dataForm.emailRaiser ? [`${dataForm.emailSigning}`,`${dataForm.emailRaiser}`] : dataForm.emailSigning,
               subject: '会議室・マンション購入の申込を受領いたしました',
               attachments: ''
            },setNotification)
            ApplicationContactUsInformation(formData,setNotification)
         }
      },[dataForm, selectedFile1,selectedFile2,selectedFile3
      ,selectedFile4,selectedFile5,selectedFile6,submit,numberApartment])

      const divOne = useRef<HTMLInputElement | null>(null);
      const scrolLWithUseRef = () => {
      divOne.current?.scrollIntoView({ block: "center", behavior: "smooth" });
      };

   
      useEffect(() => {
         scrolLWithUseRef()
         if(check){
            scrolLWithUseRef()
         }
      },[check])
   
      useEffect(() => {
         if(file1 ){
            setchekFile1(true)
         }
         if(file2){
            setchekFile2(true)
         }
         if(file3){
            setchekFile3(true)
         }
         if(file4){
            setchekFile4(true)
         }
         if(file5){
            setchekFile5(true)
         }
         if(file6){
            setchekFile6(true)
         }
         if(!file1 || size1 > '4000000'){
            setSelectedFile1('')
            setFile1('')
         }
         if(!file2 || size2 > '4000000'){
            setSelectedFile2('')
            setFile2('')
         }
         if(!file3 || size3 > '4000000'){
            setSelectedFile3('')
            setFile3('')
         }
         if(!file4 || size4 > '4000000'){
            setSelectedFile4('')
            setFile4('')
         }
         if(!file5 || size5 > '4000000'){
            setSelectedFile5('')
            setFile5('')
         }
         if(!file6 || size6 > '4000000'){
            setSelectedFile6('')
            setFile6('')
         }
      },[checkfile1,checkfile2,checkfile3,checkfile4,checkfile5,checkfile6,
         file1,file2,file3,file4,file5,file6,
         size1,size2,size3,size4,size5,size6
      ])

      const screenWidth = useScreenWidth()
   return (
      <>
      <Wrapper id="intro" >
         <div className='container-nopayer block'>
            <div className='start-selling-contact block'>
               <div className='item_fi flex'>
                  {!check ? 
                  <>
                  <div className='pl-1 block pb-4 w-full'>
                     <div className='block p-2  mt_6'>
                        <h1 className="text-[24px] leading-normal md:leading-relaxed mb-2 font-bold text-center"  ref={divOne}>会議室・マンション購入申込フォーム</h1>
                        <h3 className='title text-center pt-2'>個人契約用</h3>
                     </div>
                     <div className='block p-2 container_ '>
                        <p className='title_ text-[20px] mb-2 pt_6 font_12'>ご契約者様（≠代金負担者様）</p>
                        <div className='ml-4'>
                           <form onSubmit={handleSubmit(onCheck)}>
                              <div className="grid">
                                 <label className='text mt_6 font-bold'>代金負担者の氏名</label>
                              </div>
                              <div className='flex w-[93%]'>
                                 <div className="grid w-1/2">
                                    <label className='text font-bold'>姓<span className='font-bold text-red-500 font_small'>「必須」</span></label>
                                    <input className='mt-1' type="text" 
                                       {...register("firstnamePayer", {
                                          required: true
                                       })} 
                                       onChange={e => setValue("firstnamePayer", e.target.value.trimStart())}
                                    />
                                 </div>
                                 <div className="grid w-1/2">
                                    <label className='text  font-bold'>名<span className='font-bold text-red-500 font_small'>「必須」</span></label>
                                    <input className='mt-1' type="text" 
                                       {...register("lastnamePayer", {
                                          required: true
                                       })} 
                                       onChange={e => setValue("lastnamePayer", e.target.value.trimStart())}
                                    />
                                 </div>
                              </div>
                              <div className="grid">
                                 <label className='text mt_6 font-bold'>代金負担者の氏名（フリガナ）</label>
                              </div>
                              <div className='flex w-[93%]'>
                                 <div className="grid w-1/2">
                                    <label className='text font-bold'>姓<span className='font-bold text-red-500 font_small'>「必須」</span></label>
                                    <input className='mt-1' type="text" 
                                       {...register("firstnamePayerspelling", {
                                          required: true
                                       })} 
                                       onChange={e => setValue("firstnamePayerspelling", e.target.value.trimStart())}
                                    />
                                 </div>
                                 <div className="grid w-1/2">
                                    <label className='text  font-bold'>名<span className='font-bold text-red-500 font_small'>「必須」</span></label>
                                    <input className='mt-1' type="text" 
                                       {...register("lastnamePayerspelling", {
                                          required: true
                                       })} 
                                       onChange={e => setValue("lastnamePayerspelling", e.target.value.trimStart())}
                                    />
                                 </div>
                              </div>
                              <div className="grid">
                                 <label className='text mt_6 font-bold'>代金負担者の住所<span className='font-bold text-red-500 font_small'>「必須」　{ screenWidth > 580 ? '' : <br/>}<span className='font-bold text-[#0972c1] text_16'>都道府県から入力してください。郵便番号は不要です。</span></span></label>
                                 <input className='mt-1' type="text"
                                    {...register("addressPayer", {
                                       required: true
                                    })} 
                                    onChange={e => setValue("addressPayer", e.target.value.trimStart())}
                                 />
                              </div>
                              <div className="grid">
                                 <label className='text mt_6 font-bold'>代金負担者のメールアドレス<span className='font-bold text-red-500 font_small'>「必須」</span></label>
                                 <input className='mt-1' type="text" 
                                    {...register("emailPayer", {
                                       required: true,
                                       pattern:{
                                          value:
                                             /^[A-Z0-9._+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                             message: "無効なメールアドレス",
                                       }
                                    })}
                                    onChange={e => setValue("emailPayer", e.target.value.trimStart())}
                                 />
                                 {errors.emailPayer?.message && (
                                    <h4 className="text-red-500 text_16">{errors.emailPayer?.message?.toString()}</h4>
                                 )}
                              </div>
                              <div className="grid">
                                 <label className='text mt_6 font-bold'>代金負担者の携帯電話番号<span className='font-bold text-red-500 font_small'>「必須」</span></label>
                                 <input className='mt-1' type="text" 
                                    {...register("phonenumberPayer", {
                                       required: true
                                    })}
                                    onChange={e => setValue("phonenumberPayer", e.target.value.replace(/\D/,''))}
                                 />
                              </div>
                              <div className="grid">
                                 <label className='text mt_6 font-bold'>契約者の氏名</label>
                              </div>
                              <div className='flex w-[93%]'>
                                 <div className="grid w-1/2">
                                    <label className='text font-bold'>姓<span className='font-bold text-red-500 font_small'>「必須」</span></label>
                                    <input className='mt-1' type="text" 
                                       {...register("firstnameSigning", {
                                          required: true
                                       })} 
                                       onChange={e => setValue("firstnameSigning", e.target.value.trimStart())}
                                    />
                                 </div>
                                 <div className="grid w-1/2">
                                    <label className='text  font-bold'>名<span className='font-bold text-red-500 font_small'>「必須」</span></label>
                                    <input className='mt-1' type="text" 
                                       {...register("lastnameSigning", {
                                          required: true
                                       })} 
                                       onChange={e => setValue("lastnameSigning", e.target.value.trimStart())}
                                    />
                                 </div>
                              </div>
                              <div className="grid">
                                 <label className='text mt_6 font-bold'>契約者の氏名（フリガナ）</label>
                              </div>
                              <div className='flex w-[93%]'>
                                 <div className="grid w-1/2">
                                    <label className='text font-bold'>姓<span className='font-bold text-red-500 font_small'>「必須」</span></label>
                                    <input className='mt-1' type="text" 
                                       {...register("firstnameSigningspelling", {
                                          required: true
                                       })} 
                                       onChange={e => setValue("firstnameSigningspelling", e.target.value.trimStart())}
                                    />
                                 </div>
                                 <div className="grid w-1/2">
                                    <label className='text  font-bold'>名<span className='font-bold text-red-500 font_small'>「必須」</span></label>
                                    <input className='mt-1' type="text" 
                                       {...register("lastnameSigningspelling", {
                                          required: true
                                       })} 
                                       onChange={e => setValue("lastnameSigningspelling", e.target.value.trimStart())}
                                    />
                                 </div>
                              </div>
                              <div className="grid">
                                 <label className='text mt_6 font-bold'>契約者の住所<span className='font-bold text-red-500 font_small'>「必須」　{ screenWidth > 580 ? '' : <br/>}<span className='font-bold text-[#0972c1] text_16'>都道府県から入力してください。郵便番号は不要です。</span></span></label>
                                 <input className='mt-1' type="text"
                                    {...register("addressSigning", {
                                       required: true
                                    })} 
                                    onChange={e => setValue("addressSigning", e.target.value.trimStart())}
                                 />
                              </div>
                              <div className="grid">
                                 <label className='text mt_6 font-bold'>契約者のメールアドレス<span className='font-bold text-red-500 font_small'>「必須」</span>{ screenWidth > 580 ? '' : <br/>}<span className='font-100 text-[#0972c1] text_16'>代金負担者とは、別のメールアドレスを入力してください。</span></label>
                                 <input className='mt-1' type="text" 
                                    {...register("emailSigning", {
                                       required: true,
                                       pattern:{
                                          value:
                                             /^[A-Z0-9._+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                             message: "無効なメールアドレス",
                                       }
                                    })}
                                    onChange={e => setValue("emailSigning", e.target.value.trimStart())}
                                 />
                                 {errors.emailSigning?.message && (
                                    <h4 className="text-red-500 text_16">{errors.emailSigning?.message?.toString()}</h4>
                                 )}
                              </div>
                              <div className="grid">
                                 <label className='text mt_6 font-bold'>契約者の携帯電話番号<span className='font-bold text-red-500 font_small'>「必須」</span></label>
                                 <input className='mt-1' type="text" 
                                    {...register("phonenumberSigning", {
                                       required: true
                                    })}
                                    onChange={e => setValue("phonenumberSigning", e.target.value.replace(/\D/,''))}
                                 />
                              </div>
                              <p className='title text-[18px] mt_6'>※契約者が未成年の場合全項目入力してください。</p>
                              <div className="grid">
                                 <label className='text mt_6 font-bold'>契約者の親権者氏名</label>
                              </div>
                              <div className='flex w-[93%] block-11'>
                                 <div className="grid w-1/2">
                                    <label className='text font-bold'>姓</label>
                                    <input className='mt-1' type="text" 
                                       {...register("firstnameRaiser", {
                                       })} 
                                       onChange={e => setValue("firstnameRaiser", e.target.value.trimStart())}
                                    />
                                 </div>
                                 <div className="grid w-1/2">
                                    <label className='text  font-bold'>名</label>
                                    <input className='mt-1' type="text" 
                                       {...register("lastnameRaiser", {
                                       })} 
                                       onChange={e => setValue("lastnameRaiser", e.target.value.trimStart())}
                                    />
                                 </div>
                              </div>
                              <div className="grid">
                                 <label className='text mt_6 font-bold'>契約者の親権者氏名（フリガナ）</label>
                              </div>
                              <div className='flex w-[93%] block-11'>
                                 <div className="grid w-1/2">
                                    <label className='text font-bold'>姓</label>
                                    <input className='mt-1' type="text" 
                                       {...register("firstnameRaiserspelling", {
                                       })} 
                                       onChange={e => setValue("firstnameRaiserspelling", e.target.value.trimStart())}
                                    />
                                 </div>
                                 <div className="grid w-1/2">
                                    <label className='text  font-bold'>名</label>
                                    <input className='mt-1' type="text" 
                                       {...register("lastnameRaiserspelling", {
                                       })}
                                       onChange={e => setValue("lastnameRaiserspelling", e.target.value.trimStart())} 
                                    />
                                 </div>
                              </div>
                              <div className="grid">
                                 <label className='text mt_6 font-bold'>契約者の親権者住所　{ screenWidth > 580 ? '' : <br/>}<span className='font-bold text-[#0972c1] text_16'>都道府県から入力してください。郵便番号は不要です。</span></label>
                                 <input className='mt-1' type="text"
                                    {...register("addressRaiser")} 
                                    onChange={e => setValue("addressRaiser", e.target.value.trimStart())}
                                 />
                              </div>
                              <div className="grid">
                                 <label className='text mt_6 font-bold'>契約者の親権者メールアドレス　{ screenWidth > 580 ? '' : <br/>}<span className='font-bold text-[#0972c1] text_16'>契約者と同じメールアドレスも入力可能です。</span></label>
                                 <input className='mt-1' type="text" 
                                    {...register("emailRaiser",{
                                       pattern:{
                                          value:
                                             /^[A-Z0-9._+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                             message: "無効なメールアドレス",
                                       }
                                    })}
                                    onChange={e => setValue("emailRaiser", e.target.value.trimStart())}
                                 />
                                 {errors.emailRaiser?.message && (
                                    <h4 className="text-red-500 text_16">{errors.emailRaiser?.message?.toString()}</h4>
                                 )}
                              </div>
                              <div className="grid">
                                 <label className='text mt_6 font-bold'>契約者の親権者の携帯電話番号</label>
                                 <input className='mt-1' type="text" 
                                    {...register("phonenumberRaiser")}
                                    onChange={e => setValue("phonenumberRaiser", e.target.value.replace(/\D/,''))}
                                 />
                              </div>
                              <div className="grid">
                                 <label className='text mt_6 font-bold'>会議室・マンション販売価格（税込み）</label>
                                 <input className='mt-1' type="text" 
                                    value='110万円／戸'
                                    disabled
                                 />
                              </div>
                              <div className="grid">
                                 <label className='text mt_6 font-bold'>購入会議室・マンション戸数<span className='font-bold text-red-500 font_small'>「必須」</span></label>
                                 <select 
                                  className='mt-1'
                                  defaultValue=''
                                  {...register("numberApartment", {
                                    required: true
                                 })}
                                 onChange={(e : any)=> {setNumber(e.target.value)}}
                                 >
                                 {options.map((option : any) => (
                                    <option value={option.value}>
                                       {option.value}
                                    </option>
                                 ))}
                               </select>
                              </div>
                              <div className="grid">
                                 <label className='text mt_6 font-bold'>購入代金の合計金額（税込み）</label>
                                 <input className='mt-1' type="text" 
                                    value={`${110*Number(numberApartment)}万円`}
                                    disabled
                                 />
                              </div>
                              <div className="grid">
                                 <label className='text mt_6 font-bold'>代金負担者の本人確認書類の表裏写真を添付　{ screenWidth > 580 ? '' : <br/>}<span className='font-bold text-[#0972c1] text_16'>１ファイルのサイズ上限は４ＭＢです。</span></label>
                              </div>
                              <div className='flex w-[93%]'>
                                 <div className="grid w-1/2">
                                    <label className='text font-bold'>表面<span className='font-bold text-red-500 font_small'>「必須」</span></label>
                                    <div className='upload_img flex items-center'>
                                       <label htmlFor="img1">{selectedFile1 ? file1 : 'ファイルを選ぶ'}</label>
                                       <input className='mt-1' id='img1' type="file" style={{ display :"none"}} 
                                          {...register("front1")}
                                          onChange={(e) => handleFileSelect1(e)}
                                       />
                                    </div>
                                 </div>
                                 <div className="grid w-1/2">
                                    <label className='text  font-bold'>裏面<span className='font-bold text-red-500 font_small'>「必須」</span></label>
                                    <div className='upload_img flex items-center'>
                                       <label htmlFor="img2">{selectedFile2 ? file2 : 'ファイルを選ぶ'}</label>
                                       <input className='mt-1' id='img2' type="file" style={{ display :"none"}} 
                                          {...register("after1")}
                                          onChange={(e) => handleFileSelect2(e)}
                                       />
                                    </div>
                                 </div>
                              </div>
                              <div className='flex w-[93%]'>
                                 <div className="grid w-1/2">
                                    {(size1 > '4000000') && (
                                       <h4 className="text-red-500 text_16">添付ファイルの最大サイズは 4 MB です</h4>
                                    )}
                                     {!checkfile1 && (
                                       <h4 className="text-red-500 text_16">ファイルを選択してください</h4>
                                    )}
                                 </div>
                                 <div className="grid w-1/2">
                                    {(size2 > '4000000') && (
                                       <h4 className="text-red-500 text_16">添付ファイルの最大サイズは 4 MB です</h4>
                                    )}
                                    {!checkfile2 && (
                                       <h4 className="text-red-500 text_16">ファイルを選択してください</h4>
                                    )}
                                 </div>
                              </div>
                              <div className="grid">
                                 <label className='text mt_6 font-bold'>契約者の本人確認書類の表裏写真を添付　{screenWidth > 580 ? '': <br/>}<span className='text_16'>※未成年の場合は健康保険証の写真を添付</span></label>
                              </div>
                              <div  className="grid mt-2">
                                 <Select options={optionsSelect1} defaultValue={!mySelect1 ? optionsSelect1[0]: (mySelect1==='1' ?  optionsSelect1[0]: optionsSelect1[1])} onChange={(val : any)  => setMySelect1(val.value)} />
                              </div>
                              {mySelect1=== '1' ? 
                              <>
                                 <div className='flex w-[93%] mt_6'>
                                    <div className="grid w-1/2">
                                       <label className='text font-bold'>表面<span className='font-bold text-red-500 font_small'>「必須」</span></label>
                                       <div className='upload_img flex items-center'>
                                          <label htmlFor="img3">{selectedFile3 ? file3 : 'ファイルを選ぶ'}</label>
                                          <input className='mt-1' id='img3' type="file" style={{ display :"none"}} 
                                             {...register("front2")} 
                                             onChange={(e) => handleFileSelect3(e)}
                                          />
                                       </div>
                                    </div>
                                    <div className="grid w-1/2">
                                       <label className='text  font-bold'>裏面<span className='font-bold text-red-500 font_small'>「必須」</span></label>
                                       <div className='upload_img flex items-center'>
                                          <label htmlFor="img4">{selectedFile4 ? file4 : 'ファイルを選ぶ'}</label>
                                          <input className='mt-1' id='img4' type="file" style={{ display :"none"}} 
                                             {...register("after2")}
                                             onChange={(e) => handleFileSelect4(e)}
                                          />
                                       </div>
                                    </div>
                                 </div>
                                 <div className='flex w-[93%]'>
                                    <div className="grid w-1/2">
                                       {(size3 > '4000000') && (
                                          <h4 className="text-red-500 text_16">添付ファイルの最大サイズは 4 MB です</h4>
                                       )}
                                       {!checkfile3 && (
                                          <h4 className="text-red-500 text_16">ファイルを選択してください</h4>
                                       )}
                                    </div>
                                    <div className="grid w-1/2">
                                       {(size4 > '4000000') && (
                                          <h4 className="text-red-500 text_16">添付ファイルの最大サイズは 4 MB です</h4>
                                       )}
                                       {!checkfile4 && (
                                          <h4 className="text-red-500 text_16">ファイルを選択してください</h4>
                                       )}
                                    </div>
                                 </div>
                              </> 
                              : 
                              <></>}
                              <div className="grid">
                                 <label className='text mt_6 font-bold'>契約者の親権者の本人確認書類の表裏写真を添付</label>
                              </div>
                              <div  className="grid mt-2">
                                 <Select options={optionsSelect2} defaultValue={!mySelect2 ? optionsSelect2[0]: (mySelect2==='1' ?  optionsSelect2[0]: (mySelect2==='2' ?  optionsSelect2[1]: optionsSelect2[2]))} onChange={(val : any)  => setMySelect2(val.value)} />
                              </div>
                              {mySelect2=== '1' ? 
                              <>
                                 <div className='flex w-[93%] end_flex mt_6'>
                                    <div className="grid w-1/2">
                                       <label className='text font-bold'>表面<span className='font-bold text-red-500 font_small'>「必須」</span></label>
                                       <div className='upload_img flex items-center'>
                                          <label htmlFor="img5">{selectedFile5 ? file5 : 'ファイルを選ぶ'}</label>
                                          <input className='mt-1' id='img5' type="file" style={{ display :"none"}} 
                                             {...register("front3")}  
                                             onChange={(e) => handleFileSelect5(e)}
                                          />
                                       </div>
                                    </div>
                                    <div className="grid w-1/2">
                                       <label className='text  font-bold'>裏面<span className='font-bold text-red-500 font_small'>「必須」</span></label>
                                       <div className='upload_img flex items-center'>
                                          <label htmlFor="img6">{selectedFile6 ? file6 : 'ファイルを選ぶ'}</label>
                                          <input className='mt-1' id='img6' type="file" style={{ display :"none"}} 
                                             {...register("after3")}
                                             onChange={(e) => handleFileSelect6(e)}
                                          />
                                       </div>
                                    </div>
                                 </div>
                                 <div className='flex w-[93%]'>
                                    <div className="grid w-1/2">
                                       {(size5 > '4000000') && (
                                          <h4 className="text-red-500 text_16">添付ファイルの最大サイズは 4 MB です</h4>
                                       )}
                                       {!checkfile5 && (
                                          <h4 className="text-red-500 text_16">ファイルを選択してください</h4>
                                       )}
                                    </div>
                                    <div className="grid w-1/2">
                                       {(size6 > '4000000') && (
                                          <h4 className="text-red-500 text_16">添付ファイルの最大サイズは 4 MB です</h4>
                                       )}
                                       {!checkfile6 && (
                                          <h4 className="text-red-500 text_16">ファイルを選択してください</h4>
                                       )}
                                    </div>
                                 </div>
                              </> 
                              :<></>}
                              <div className="grid">
                                 <label className='text mt_6 font-bold text-[#385723]'>【本人確認書類】いずれかひとつの書類の表裏を添付してください。</label>
                                 <div className={`mt-2 text-[#385723] font-bold ${screenWidth > 580 ? 'flex' : 'grid grid-cols-2'}`}>
                                    <div className={`block ${screenWidth >580 ? 'ml-8' : 'ml-4'}`}>
                                       <p>運転免許証</p>
                                       <p>パスポート</p>
                                    </div>
                                    <div className={`block ${screenWidth >580 ? 'ml-10' : 'ml-2'}`}>
                                       <p>運転経歴証明書</p>
                                       <p>特別永住者証明書</p>
                                    </div>
                                    <div className={`block ${screenWidth >580 ? 'ml-10' : 'ml-4 mt-2'}`}>
                                       <p>健康保険証</p>
                                       <p>在留カード</p>
                                    </div>
                                 </div>
                              </div>
                              <p className='text-[12px] text-[#0563c1] mt_6'> <a href='../file/Privacy.pdf' target='_blank' className='text_16'>プライバシーポリシーをお読みいただき、同意の上、購入申し込みをしてください。</a></p>
                              <div className='flex radio-box'>
                                 <input className='mt-1' type="checkbox"
                                    onChange={() => setIsChecked1(!isChecked1)}
                                 />
                                 <p className='text mt-2 ml-2 font-bold'>プライバシーポリシーに同意する</p>
                              </div>
                              <p className=' text-[#0972c1] mt-2'>重要なお知らせや関連ニュースを受け取るにチェックし、購入申し込みをしてください。</p>
                              <div className='flex radio-box'>
                                 <input className='mt-1' type="checkbox"
                                    onChange={() => setIsChecked3(!isChecked3)}
                                 />
                                 <p className='text mt-2 ml-2 font-bold'>重要なお知らせや会議室・マンション関連のニュースを受け取ります。</p>
                              </div>
                              {isChecked1 && isChecked3 ?
                              <>
                              <div className="grid mt-3 w-[87%]">
                                 <label></label>
                                 <button type="submit" className='m-auto bg-[#A98260]'>購入を申し込む</button>
                              </div>
                              </>
                              :
                              <>
                              <div className="grid mt-3 w-[87%]">
                                 <label></label>
                                 <button type="submit" className='m-auto opacity-70 bg-[#A98260]' disabled >購入を申し込む</button>
                              </div>
                              </>
                           }
                           </form>
                        </div>
                     </div>
                  </div>
                  </>
                  :
                  <>
                  <div className='w-fit m-auto' ref={divOne}>
                     <h2 className='text-center font-bold my-4'>購入申込内容の確認</h2>
                     <div className={`m-auto w-full ${screenWidth > 580 ? 'px-[2rem]' :'px-[1rem]' }`}>
                        <p>代金負担者の氏名: {dataForm.namePayer}</p>
                        <p>代金負担者の氏名（フリガナ）：{dataForm.namePayerSpelling}</p>
                        <p>代金負担者の住所：{dataForm.addressPayer}</p>
                        <p>代金負担者のメールアドレス：{dataForm.emailPayer}</p>
                        <p>代金負担者の携帯電話番号：{dataForm.phonePayer}</p>
                        <p>契約者の氏名：{dataForm.nameSigning}</p>
                        <p>契約者の氏名（フリガナ）：{dataForm.nameSigningSpelling}</p>
                        <p>契約者の住所：{dataForm.addressSigning}</p>
                        <p>契約者のメールアドレス：{dataForm.emailSigning}</p>
                        <p>契約者の携帯電話番号：{dataForm.phoneSigning}</p>
                        <p>契約者の親権者氏名：{dataForm.nameRaiser}</p>
                        <p>契約者の親権者氏名（フリガナ）：{dataForm.nameRaiserSpelling}</p>
                        <p>契約者の親権者住所：{dataForm.addressRaiser}</p>
                        <p>契約者の親権者メールアドレス：{dataForm.emailRaiser}</p>
                        <p>契約者の親権者の携帯電話番号：{dataForm.phoneRaiser}</p>
                        <p>会議室・マンション販売価格：110万円／戸（税込み）</p>
                        <p>購入会議室・マンション戸数： {dataForm.numberApartment}</p>
                        <p>購入代金の合計金額： {110*Number(numberApartment)}万円（税込み）</p>
                        <p>代金負担者の本人確認書類の表裏写真を添付：添付あり</p>
                        <div className='my-4 check'>
                           <img src={image1} alt="" className='img_check mr-4 mb-2'/>
                           <img src={image2} alt="" className='img_check'/>
                        </div>
                        <p>契約者の本人確認書類の表裏写真を添付　※未成年の場合は健康保険証の写真を添付：{image3 ? <><p>添付あり</p></>:<></>}</p>
                        {image3 ? <></>:<><p className='ml-0'>添付なし。後日ご提出ください。</p></>}
                        <div className='my-4 check'>
                           <img src={image3} alt="" className='img_check mr-4 mb-2'/>
                           <img src={image4} alt="" className='img_check'/>
                        </div>
                        <p>契約者の親権者の本人確認書類の表裏写真を添付：{image5 ? <><p>添付あり</p></>:<></>}</p>
                        {image5 ? <></>:<><p className=''>添付不要。</p></>}
                        <div className='my-4 check'>
                           <img src={image5} alt="" className='img_check mr-4 mb-2'/>
                           <img src={image6} alt="" className='img_check'/>
                        </div>
                        <div className='text-center mt-4'>
                           <button className='bg-[#4472c4]'
                            onClick={()=>{
                              setSubmit(!submit)
                              }
                           }
                            >
                              購入申し込みを確定する</button>
                        </div>
                        <div className='my-8 flex'>
                           <div className={`${screenWidth > 580 ? 'w-1/3' : 'w-[18%] mr-4'}`}>
                              <button className='bg-white text_black bt_wh' 
                               onClick={()=>{
                                 setCheck(!check)
                                 setSubmit(false)
                                 setIsChecked1(!isChecked1)
                                 setIsChecked3(!isChecked3)
                                 }}
                              >戻る</button>
                           </div>
                           <div className={`text-right flex justify-end items-center ${screenWidth > 580 ? 'w-2/3' : 'w-[80%]'}`}>
                              <button className='bg-white text_black bt_wh' onClick={()=>{setIsShowImage(!isShowImage)}}>特定商取引に関する法律に基づく表示</button>
                           </div>
                        </div>
                     </div>
                     </div>
                  </>}
               </div>
               {!check ? 
               <>
                  <h2 className="leading-normal text_black md:leading-relaxed mb-2 text-center font-bold mt-[4rem]">マンション（セミナールーム）ご購入に関するお手続きの流れ</h2>
                  <img src={Image_FlowRegister} alt='' className='m-auto' />
                  <h2 className="leading-normal text_black md:leading-relaxed mb-2 font-bold mt-[4rem]">特定商取引に関する法律に基づく表示</h2>
                  <img src={Image_InforCompany} alt='' className='m-auto' />
               </>:<></>}
            </div>
         </div>
         {notification && (
            <ModalNoti 
               event={() => {setNotification(false);
                  window.location.reload();
               }
               }
               title1='当申込フォームを送信いただいた時点では、まだ契約は成立しておりません。'
               title2='この後、当社より、ご購入いただく部屋番号を記載した契約書、購入代金と振込先について、メールにてご案内いたします。返信メールをご確認ください。'
            />
         )}
         {isShowImage && (
            <ModalImage 
            event={() => setIsShowImage(false)}
            />
         )}
      </Wrapper>
      </>
   )
}
export default IndividualContractNoPayerScreen 
