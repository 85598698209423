import React, { useRef, useEffect } from 'react'
import { Image_Cor3, Person_32, Image_unit } from '@assets';
import { Wrapper } from './styled'
import useScreenWidth from '../../../Hook/useScreenWidth'
const CorporatePhilosophyVisionandMission = () => {
   const divOne = useRef<HTMLInputElement | null>(null);
   const scrolLWithUseRef = () => {
   divOne.current?.scrollIntoView({ block: "center", behavior: "smooth" });
   };
 
   useEffect(() => {
    scrolLWithUseRef()
   },[])

   const screenWidth = useScreenWidth()
   return (
      <>
      <Wrapper>
         <div className='container_cor block'>
            <div className='section-1'>
                 <div className='content'>
                 <h1 className='text-[30px] leading-normal md:leading-relaxed font-bold text-center' ref={divOne}>企業理念　 {screenWidth < 581 &&(<br/>)}（私たちのビジョンとミッション）</h1>
                 <div className='mt-[3rem] '>
                  <h2 className="mb-2 font-bold ">ビジョン</h2>
                     <div className={`${ (screenWidth > 580) ? 'ml-6' : 'ml-2'}`}>
                        <div className='flex mt-4'>
                           <p className='text-yellow-400'>■</p>
                           <div className='block'>
                              {screenWidth > 580 ? 
                              <>
                                 <p> 私たちは、最先端技術を組み合わせてメタバース空間を創製し、「双方向で多様なサービス」、</p>
                                 <p >「便利で快適な社会インフラ」を提供します。</p></> 
                              : 
                              <>
                               <p> 私たちは、最先端技術を組み合わせてメタバース空間を創製し、「双方向で多様なサービス」、「便利で快適な社会インフラ」を提供します。</p>
                              </>
                              }
                           </div>
                        </div>
                     
                        <div className='flex mt-4'>
                           <p className='text-yellow-400'>■</p>
                           <div className='block'>
                              {screenWidth > 580 ? 
                              <>
                                 <p>私たちの美しいメタバースの中で、来園者の皆さんはアバターと一緒に闊歩し、表現力豊かで、</p>
                                 <p>リアルなコミュニケーションを楽しむことができます。そこでは、ＷＥＢ2.0 検索エンジン万能</p>
                                 <p>の中にあって、充分に光が届いていなかった『やさしさ、感謝、協調』に光が当ります。</p>
                              </> 
                              : 
                              <>
                               <p> 私たちの美しいメタバースの中で、来園者の皆さんはアバターと一緒に闊歩し、表現力豊かで、リアルなコミュニケーションを楽しむことができます。そこでは、ＷＥＢ2.0 検索エンジン万能の中にあって、充分に光が届いていなかった『やさしさ、感謝、協調』に光が当ります。</p>
                              </>
                              }
                           </div>
                        </div>
                       
                        <div className='flex mt-4'>
                           <p className='text-yellow-400'>■</p>
                           <div className='block'>
                              {screenWidth > 580 ? 
                              <>
                                 <p>新しい生活空間の中では、楽しいハーモニーが織りなされ、魅力的な世界がひろがります。</p>
                                 <p>これが私たちのビジョンです。</p>
                              </> 
                              : 
                              <>
                               <p> 新しい生活空間の中では、楽しいハーモニーが織りなされ、魅力的な世界がひろがります。これが私たちのビジョンです。</p>
                              </>
                              }
                           </div>
                        </div>
                     </div>
                 </div>
                 <div className='mt-8 '>
                  <h2 className="mb-2 font-bold ">ミッション</h2>
                     <div className={`${ (screenWidth > 580) ? 'ml-6' : 'ml-2'}`}>
                        <div className='flex mt-4'>
                           <p className='text-yellow-400'>■</p>
                           <p> 新しいアートメタバースランドの生活空間の中には、新しい労働の形が生まれます。</p>
                        </div>
                        <p className='ml-4'>そこで輝くのは、今までは充分に光が届かなかった個性です。人々の個性に新しい価値が生まれます。</p>
                        <div className='flex mt-4 ml-4'>
                           <p className='text-[#f4b084]'>●</p>
                           <p> コミュニティーサロン</p>
                        </div>
                        <p className='ml-8'>話し相手の話を、やさしく聞いてあげることができれば、所得を得ることができるサロンです。</p>
                        <div className='flex mt-4 ml-4'>
                           <p className='text-[#f4b084]'>●</p>
                           <p> ビデオ・フォト鑑賞ルーム</p>
                        </div>
                        <p className='ml-8'>優良なコンテンツ制作者に、共感したみんなから、感謝のチップが送られてくる鑑賞ルームです。</p>
                        <div className='flex mt-4 ml-4'>
                           <p className='text-[#f4b084]'>●</p>
                           <p> ３Ｄショップとアバターの店員さん</p>
                        </div>
                        {screenWidth > 580 ? 
                           <>
                              <p className='ml-8'>親切で協調的な接客ができる人は、アバターの店員さんを操作し、自宅からでも所得を得ることが</p>
                              <p className='ml-8'>できる３Ｄのショップです。</p>
                           </> 
                           : 
                           <>
                              <p className='ml-8'> 親切で協調的な接客ができる人は、アバターの店員さんを操作し、自宅からでも所得を得ることができる３Ｄのショップです。</p>
                           </>
                           }
                        <div className='flex mt-4'>
                           <p className='text-yellow-400'>■</p>
                           <p> この新しい生活空間を建設し、健全に運営していくこと、それが私たちのミッションです。</p>
                        </div>
                     </div>
                 </div>
                 <div className='mt-[3rem] flex'>
                     <div className='w-1/2'>
                        <img  src={Image_unit} alt='' className='rounded-xl w-[65%] m-auto img1' />
                     </div>
                     <div className='w-1/2'>
                        <img  src={Person_32} alt='' className='rounded-xl w-full img2' />
                     </div>
                 </div>
                 <div className='w-100% mt-[3rem]'>
                     <img src={Image_Cor3} alt='' className='w-[100%] h_45 m-auto rounded-xl'/>
                  </div>
                 </div>
            </div>
         </div>
         
      </Wrapper>
      </>
   )
}
export default CorporatePhilosophyVisionandMission
