import HomeLayout from '@layouts/home'
import PageLayout from '@layouts/page'
import ECLayout from '@layouts/EC'
import Page01Layout from '@layouts/page01'
import VideoLayout from '@layouts/video'
import HomeScreen from '@modules/home'
import ServiceProductScreen from '@modules/serviceproduct'
import CondominiumScreen from '@modules/serviceproduct/condominium/details'
import ECMallServiceScreen from '@modules/serviceproduct/ECMall/details'
import OperationStoryScreen from '@modules/serviceproduct/operationStory/details'
import OperationVideoPhotoScreen from '@modules/serviceproduct/operationVideoPhoto/details'
import OperationMarriageScreen from '@modules/serviceproduct/operationMarriage/details'
import AIAvatarScreen from '@modules/serviceproduct/AIAvatar/details'
import AboutUsScreen from '@modules/corporateIntelligence/aboutUs'
import CorporatePhilosophyVisionandMissionScreen from '@modules/corporateIntelligence/corPhilosophyVisionAndMisson'
import MainBusinessPartnerScreen from '@modules/corporateIntelligence/mainBusiness'
import ScheduleUntilScreen from '@modules/corporateIntelligence/scheduleuntil'
import OfficersAndOrganizationScreen from '@modules/corporateIntelligence/officersAndOrganization'
import ManagePolicyScreen from '@modules/corporateCustomers/ManagePolicy'
import MarketsAndCustomersScreen from '@modules/corporateCustomers/MarketsandCustomers'
import ManagementGoalsandBenchmarksScreen from '@modules/corporateCustomers/ManagementGoalsandBenchmarks'
import ManageBalancePlanScreen from '@modules/corporateCustomers/ManagementBalancePlan'
import OurStrengthsScreen from '@modules/corporateCustomers/OurStrengths'
import InformationScreen from '@modules/information'
import SustinaAbilityScreen from '@modules/sustinaAbility'
import RecruitmentInforScreen from '@modules/recruitmentInfor'
import QuestionScreen from '@modules/question'
import AccessScreen from '@modules/access'
// import SeminarRoomScreen from '@modules/seminarRooms'
import IndividualContractPayerScreen from '@modules/seminarRooms/individualPayer'
import IndividualContractNoPayerScreen from '@modules/seminarRooms/individualNoPayer'
import CorporateContractsCondominiumsScreen from '@modules/seminarRooms/corporateContractsCondominiums'
// import ECMallScreen from '@modules/ECMail'
import CorporateContractsStoreScreen from '@modules/ECMail/corporateContractsStore'
import VideoScreen from '@modules/seminarRooms/video'
import NotFound from '@components/notFound'
import PolicyScreen from '@modules/policy'


const RoutesName = {
    HOME: '/',
    SERVICEPRODUCT : '/ServiceProduct',
    CONDOMINIUM : '/SeminarRooms',
    ECMALLSERVICE : '/ECMall',
    OPERATIONSTORY : '/ServiceProduct/OperationStory',
    OPERATIONVIDEOPHOTO : '/ServiceProduct/OperationVideoPhoto',
    OPERATIONMARRIAGE : '/ServiceProduct/OperationMarriage',
    AIAVATAR : '/ServiceProduct/AIAvatar',
    ABOUTUS : '/about',
    CORPORATE : '/CorporateIntelligence/Corporate', 
    MAINBUSINESSPARTNER : '/CorporateIntelligence/MainBusinessPartner',
    SCHEDULEUNTIL : '/CorporateIntelligence/ScheduleUntil',
    OFFICERSANDORGANIZATION : '/CorporateIntelligence/OfficesAndOrganizations',
    MANAGEPOLICY : '/CorporateCustomers/ManagePolicy',
    MARKETSANDCUSTOMERS : '/CorporateCustomers/MarketsAndCustomers',
    MANAGEMENTGOALSANDBENCHMARKS : '/CorporateCustomers/ManagementGoalsAndBenchmarks',
    MANAGEBALANCEPLAN : '/CorporateCustomers/ManagementBalancePlan',
    OURSTRENGTHS : '/CorporateCustomers/OurLengths',
    SUSTINAABILITY : '/SustinaAbility',
    INFORMATION : '/category/news',
    RECRUITMENTINFORMATION :'/RecruitmentInformation',
    QUESTION : '/inquiry',
    ACCESS : '/Access',
    // SEMINARROOMS : '/SeminarRooms',
    INDIVIDUALCONTRACTPAYER : '/SeminarRooms/IndividualContractPayer',
    INDIVIDUALCONTRACTNOPAYER : '/SeminarRooms/IndividualContractNoPayer',
    CORPORATECONTRACTSCONDOMINIUMS :'/SeminarRooms/CorporateContractsCondominiums',
    // ECMALL :'/ECMall',
    CORPORATECONTRACTSSTRORE :'/ECmall/CorporateContractsStore',
    VIDEO : '/SeminarRooms/video',
    POLICY : '/Privacy',
    SITEMAP : '/sitemap-page',

    // route custom on mobile
    MOBILESERVICESEMINAR : '/ServiceProduct/SeminarRooms',
    MOBILEECMALLSERVICE : '/ServiceProduct/ECMall',
    MOBILELEASING: '/ServiceProduct/Leasing',
    MOBILERENTALS: '/ServiceProduct/Rentals',
    MOBILEMEDIA: '/ServiceProduct/Media',

    MOBILEINFORMATIONVISITORS : '/Information/Visitors',
    MOBILEINFORMATIONROOMOWNERS : '/Information/RoomOwners',
    MOBILEINFORMATIONSHOPOWNERS : '/Information/ShopOwners',
    MOBILEINFORMATIONADVERTISERS : '/Information/Advertisers',

    NOTFOUND : '*'
  }
  
export const ROUTES = [
  {
    path: RoutesName.HOME,
    component: HomeScreen,
    layout: HomeLayout,
    exact: true,
  },
  {
    path: RoutesName.SERVICEPRODUCT,
    component: ServiceProductScreen,
    layout: Page01Layout,
    exact: true,
  },
  {
    path: RoutesName.MOBILELEASING,
    component: ServiceProductScreen,
    layout: Page01Layout,
    exact: true,
  },
  {
    path: RoutesName.MOBILERENTALS,
    component: ServiceProductScreen,
    layout: Page01Layout,
    exact: true,
  },
  {
    path: RoutesName.MOBILEMEDIA,
    component: ServiceProductScreen,
    layout: Page01Layout,
    exact: true,
  },
  {
    path: RoutesName.CONDOMINIUM,
    component: CondominiumScreen,
    layout: PageLayout,
    exact: true,
  },
  {
    path: RoutesName.MOBILESERVICESEMINAR,
    component: CondominiumScreen,
    layout: PageLayout,
    exact: true,
  },
  {
    path: RoutesName.ECMALLSERVICE,
    component: ECMallServiceScreen,
    layout: ECLayout,
    exact: true,
  },
  {
    path: RoutesName.MOBILEECMALLSERVICE,
    component: ECMallServiceScreen,
    layout: ECLayout,
    exact: true,
  },
  {
    path: RoutesName.OPERATIONSTORY,
    component: OperationStoryScreen,
    layout: Page01Layout,
    exact: true,
  },
  {
    path: RoutesName.OPERATIONVIDEOPHOTO,
    component: OperationVideoPhotoScreen,
    layout: Page01Layout,
    exact: true,
  },
  {
    path: RoutesName.OPERATIONMARRIAGE,
    component: OperationMarriageScreen,
    layout: Page01Layout,
    exact: true,
  },
  {
    path: RoutesName.AIAVATAR,
    component: AIAvatarScreen,
    layout: Page01Layout,
    exact: true,
  },
  {
    path: RoutesName.ABOUTUS,
    component: AboutUsScreen,
    layout: Page01Layout,
    exact: true,
  },
  {
    path: RoutesName.CORPORATE,
    component: CorporatePhilosophyVisionandMissionScreen,
    layout: Page01Layout,
    exact: true,
  },
  {
    path: RoutesName.MAINBUSINESSPARTNER,
    component: MainBusinessPartnerScreen,
    layout: Page01Layout,
    exact: true,
  },
  {
    path: RoutesName.SCHEDULEUNTIL,
    component: ScheduleUntilScreen,
    layout: Page01Layout,
    exact: true,
  },
  {
    path: RoutesName.OFFICERSANDORGANIZATION,
    component: OfficersAndOrganizationScreen,
    layout: Page01Layout,
    exact: true,
  },
  {
    path: RoutesName.MANAGEPOLICY,
    component: ManagePolicyScreen,
    layout: Page01Layout,
    exact: true,
  },
  {
    path: RoutesName.MARKETSANDCUSTOMERS,
    component: MarketsAndCustomersScreen,
    layout: Page01Layout,
    exact: true,
  },
  {
    path: RoutesName.MANAGEMENTGOALSANDBENCHMARKS,
    component: ManagementGoalsandBenchmarksScreen,
    layout: Page01Layout,
    exact: true,
  },
  {
    path: RoutesName.MANAGEBALANCEPLAN,
    component: ManageBalancePlanScreen,
    layout: Page01Layout,
    exact: true,
  },
  {
    path: RoutesName.OURSTRENGTHS,
    component: OurStrengthsScreen,
    layout: Page01Layout,
    exact: true,
  },
  {
    path: RoutesName.INFORMATION,
    component: InformationScreen,
    layout: Page01Layout,
    exact: true,
  },

  {
    path: RoutesName.MOBILEINFORMATIONVISITORS,
    component: InformationScreen,
    layout: Page01Layout,
    exact: true,
  },

  {
    path: RoutesName.MOBILEINFORMATIONROOMOWNERS,
    component: InformationScreen,
    layout: Page01Layout,
    exact: true,
  },
  {
    path: RoutesName.MOBILEINFORMATIONSHOPOWNERS,
    component: InformationScreen,
    layout: Page01Layout,
    exact: true,
  },
  {
    path: RoutesName.MOBILEINFORMATIONADVERTISERS,
    component: InformationScreen,
    layout: Page01Layout,
    exact: true,
  },
   {
    path: RoutesName.SUSTINAABILITY,
    component: SustinaAbilityScreen,
    layout: HomeLayout,
    exact: true,
  },
  {
   path: RoutesName.RECRUITMENTINFORMATION,
   component: RecruitmentInforScreen,
   layout: Page01Layout,
   exact: true,
  },
  {
   path: RoutesName.QUESTION,
   component: QuestionScreen,
   layout: Page01Layout,
   exact: true,
  },
  {
    path: RoutesName.ACCESS,
    component: AccessScreen,
    layout: HomeLayout,
    exact: true,
  },
  {
    path: RoutesName.POLICY,
    component: PolicyScreen,
    layout: HomeLayout,
    exact: true,
  },
  {
    path: RoutesName.SITEMAP,
    component: HomeScreen,
    layout: HomeLayout,
    exact: true,
  },

  // {
  //   path: RoutesName.SEMINARROOMS,
  //   component: SeminarRoomScreen,
  //   layout: PageLayout,
  //   exact: true,
  // },
  {
    path: RoutesName.INDIVIDUALCONTRACTPAYER,
    component: IndividualContractPayerScreen,
    layout: PageLayout,
    exact: true,
  },
  {
    path: RoutesName.INDIVIDUALCONTRACTNOPAYER,
    component: IndividualContractNoPayerScreen,
    layout: PageLayout,
    exact: true,
  },
  {
    path: RoutesName.CORPORATECONTRACTSCONDOMINIUMS,
    component: CorporateContractsCondominiumsScreen,
    layout: PageLayout,
    exact: true,
  },
  // {
  //   path: RoutesName.ECMALL,
  //   component: ECMallScreen,
  //   layout: ECLayout,
  //   exact: true,
  // },
  {
    path: RoutesName.CORPORATECONTRACTSSTRORE,
    component: CorporateContractsStoreScreen,
    layout: ECLayout,
    exact: true,
  },
  {
    path: RoutesName.VIDEO,
    component: VideoScreen,
    layout: VideoLayout,
    exact: true,
  },
  {
    path: RoutesName.NOTFOUND,
    component: NotFound,
    layout: VideoLayout,
    exact: true,
  },
]

export default RoutesName