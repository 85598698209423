import React, {useRef, useEffect} from 'react'
import { Wrapper } from './styled'
import {Person_25} from '@assets'
import useScreenWidth from '../../../../Hook/useScreenWidth'
const OperationMarriage = () => {
   const divOne = useRef<HTMLInputElement | null>(null);
   const scrolLWithUseRef = () => {
   divOne.current?.scrollIntoView({ block: "center", behavior: "smooth" });
   };
 
   useEffect(() => {
    scrolLWithUseRef()
   },[])
   
   const screenWidth = useScreenWidth()
   return (
      <>
      <Wrapper>
         <div className='container_story block'>
            <div className='content'>
               <h1 className="text-[30px] text-center leading-normal md:leading-relaxed mb-[3rem] font-bold" ref={divOne}>結婚相手マッチングサービスの運営</h1>
               <div className='text'>
                  <div className='flex mt-2'>
                     <p className='text-yellow-400'>■ </p>
                     <div className='block'>
                        <p>リーズナブルな費用で、本気でお付き合いできる結婚相手とめぐり会えるマッチングサービスを運営します。</p>
                        <div className={`${ (screenWidth > 580) ? 'ml-4 mt-4' : 'ml-0 mt-2'}`}>
                           <div className='flex'>
                              <p className='text-[#f4b084]'>● </p>
                              <div className='block'>
                                 <p className='leading-normal'>入会費は、ARTコイン100,000円分。　<span>※開園から一定期間は入会費を５０%オフします。</span></p>
                              </div>
                           </div>
                           <div className='flex mt-2'>
                              <p className='text-[#f4b084]'>● </p>
                              <div className='block'>
                                 <p>入会と同時にプレゼントARTコイン20,000円分をキャッシュバック。</p>
                              </div>
                           </div>
                           <div className='flex mt-2'>
                              <p className='text-[#f4b084]'>● </p>
                              <div className='block'>
                                 <p>「月会費」、「お見合い料」、「成婚料」等の追加費用は一切不要です。</p>
                              </div>
                           </div>
                           <div className='flex mt-2'>
                              <p className='text-[#f4b084]'>● </p>
                              <div className='block'>
                                 <p>成婚の有無にかかわらず、退会時にはプレゼントARTコイン10,000円を差し上げます。</p>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className='flex mt-8'>
                     <p className='text-yellow-400'>■ </p>
                     <div className='block'>
                        <p>ＡＩがあなたにぴったりの結婚相手候補を提案します。気に入ったお相手にお付合いの申込みをしてください。</p>
                        <p>お相手から了解をいただけたら、お付き合いの始まりです。</p>
                        <div className={`${ (screenWidth > 580) ? 'ml-4 mt-4' : 'ml-0 mt-2'}`}>
                           <div className='flex mt-2'>
                              <p className='text-[#f4b084]'>● </p>
                              <div className='block'>
                                 <p>申込者自身のプロフィールをフォーマットに従って入力。</p>
                              </div>
                           </div>
                           <div className='flex mt-2'>
                              <p className='text-[#f4b084]'>● </p>
                              <div className='block'>
                                 <p>次に結婚候補者に対して希望するプロフィールをフォーマットに従って入力。</p>
                              </div>
                           </div>
                           <div className='flex mt-2'>
                              <p className='text-[#f4b084]'>● </p>
                              <div className='block'>
                                 <p>ＡＩがあなたの希望する結婚候補プロフィールとお相手のプロフィールをマッチング。お相手候補を提案。</p>
                              </div>
                           </div>
                           <div className='flex mt-2'>
                              <p className='text-[#f4b084]'>● </p>
                              <div className='block'>
                                 <p>気に入ったお相手にお付き合いの申込み。お相手から了解をいただけたら、お付き合いの始まりです。</p>
                              </div>
                           </div>
                           <div className='flex mt-2'>
                              <p className='text-[#f4b084]'>● </p>
                              <div className='block'>
                                 <p>プレゼントARTコイン20,000円分を使って、ランド内でデート。良い関係が築けたらプロポーズ。</p>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className='flex mt-8'>
                     <p className='text-yellow-400'>■ </p>
                     <div className='block'>
                        <p>プライバシー確保の為に、プロフィール公開可否は全て選択できるシステムです。安心してご利用いただけます。</p>
                     </div>
                  </div>
               </div>
                  <div className='image mt_3r'>
                     <img src={Person_25} alt='' className='w-[30%] rounded-xl' />
                  </div>
               </div>
         </div>
       </Wrapper>
      </>
   )
}
export default OperationMarriage
