export const items = [
  {
    id: "1",
    namejp: "ホーム",
    nameen: "Home",
    path: "/",
    isActive: false,
  },
  {
    id: "2",
    namejp: "サービス 製品",
    nameen: "Service Product",
    path: "/ServiceProduct",
    isActive: false,
    menu: [
      {
        id: "0",
        namejp: "会議室・マンション販売",
        nameen: "Condominium (seminar room) sales",
        path: "/SeminarRooms",
        pathMobile: "/ServiceProduct/SeminarRooms",
        isActive: false,
        child: [
          {
            namejp: "会議室・マンション販売",
            nameen: "Condominium (seminar room) sales",
          },
          {
            namejp: "会議室・マンション販売",
            nameen: "Condominium (seminar room) sales",
          },
          {
            namejp: "会議室・マンション販売",
            nameen: "Condominium (seminar room) sales",
          },
        ],
      },
      {
        id: "1",
        namejp: "ＥＣモール商業店舗の賃貸",
        nameen: "Lease of EC Mall Commercial Store",
        path: "/ECMall",
        pathMobile: "/ServiceProduct/ECMall",
        isActive: false,
        child: [
          {
            namejp: "ＥＣモール商業店舗の賃貸",
            nameen: "Lease of EC Mall Commercial Store",
          },
          {
            namejp: "ＥＣモール商業店舗の賃貸",
            nameen: "Lease of EC Mall Commercial Store",
          },
          {
            namejp: "ＥＣモール商業店舗の賃貸",
            nameen: "Lease of EC Mall Commercial Store",
          },
        ],
      },
      {
        id: "2",
        namejp: "イベントホールの賃貸",
        nameen: "Event Hall Leasing",
        path: "/ServiceProduct",
        pathMobile: "/ServiceProduct/Leasing",
        isActive: false,
        child: [
          { namejp: "イベントホールの賃貸", nameen: "Event Hall Leasing" },
          { namejp: "イベントホールの賃貸", nameen: "Event Hall Leasing" },
          { namejp: "イベントホールの賃貸", nameen: "Event Hall Leasing" },
        ],
      },
      {
        id: "3",
        namejp: "お話しサロンの運営",
        nameen: "Operation of story salons",
        path: "/ServiceProduct/OperationStory",
        isActive: false,
        child: [
          { namejp: "お話しサロンの運営", nameen: "Operation of story salons" },
          { namejp: "お話しサロンの運営", nameen: "Operation of story salons" },
          { namejp: "お話しサロンの運営", nameen: "Operation of story salons" },
        ],
      },
      {
        id: "4",
        namejp: "ビデオ・フォト鑑賞ルームの運営",
        nameen: "Operation of video/photo viewing rooms",
        path: "/ServiceProduct/OperationVideoPhoto",
        isActive: false,
        child: [
          {
            namejp: "ビデオ・フォト鑑賞ルームの運営",
            nameen: "Operation of video/photo viewing rooms",
          },
          {
            namejp: "ビデオ・フォト鑑賞ルームの運営",
            nameen: "Operation of video/photo viewing rooms",
          },
          {
            namejp: "ビデオ・フォト鑑賞ルームの運営",
            nameen: "Operation of video/photo viewing rooms",
          },
        ],
      },
      {
        id: "5",
        namejp: "結婚相手マッチングサービスの運営",
        nameen: "Operation of a marriage partner matching service",
        path: "/ServiceProduct/OperationMarriage",
        isActive: false,
        child: [
          {
            namejp: "結婚相手マッチングサービスの運営",
            nameen: "Operation of a marriage partner matching service",
          },
          {
            namejp: "結婚相手マッチングサービスの運営",
            nameen: "Operation of a marriage partner matching service",
          },
          {
            namejp: "結婚相手マッチングサービスの運営",
            nameen: "Operation of a marriage partner matching service",
          },
        ],
      },

      // {
      // 	id:'6',
      // 	namejp: "その他施設の運営",
      // 	nameen: "Operation of other facilities",
      // 	path: "/ServiceProduct",
      // 	isActive: false,
      // 	child : [
      // 	   {namejp: "その他施設の運営",
      // 		nameen: "Operation of other facilities"
      // 	   },
      // 	   {namejp: "その他施設の運営",
      // 		nameen: "Operation of other facilities"
      // 	   },
      // 	   {namejp: "その他施設の運営",
      // 		nameen: "Operation of other facilities"
      // 	   },
      // 	]
      // },
      {
        id: "7",
        namejp: "アバターとアイテムの賃貸",
        nameen: "Avatar and item rentals",
        path: "/ServiceProduct",
        pathMobile: "/ServiceProduct/Rentals",
        isActive: false,
        child: [
          {
            namejp: "アバターとアイテムの賃貸",
            nameen: "Avatar and item rentals",
          },
          {
            namejp: "アバターとアイテムの賃貸",
            nameen: "Avatar and item rentals",
          },
          {
            namejp: "アバターとアイテムの賃貸",
            nameen: "Avatar and item rentals",
          },
        ],
      },
      {
        id: "8",
        namejp: "ＡＩアバター店員の賃貸",
        nameen: "ＡＩ Avatar Clerk for Rent",
        path: "/ServiceProduct/AIAvatar",
        isActive: false,
        child: [
          {
            namejp: "ＡＩアバター店員の賃貸",
            nameen: "ＡＩ Avatar Clerk for Rent",
          },
          {
            namejp: "ＡＩアバター店員の賃貸",
            nameen: "ＡＩ Avatar Clerk for Rent",
          },
          {
            namejp: "ＡＩアバター店員の賃貸",
            nameen: "ＡＩ Avatar Clerk for Rent",
          },
        ],
      },
      {
        id: "9",
        namejp: "広告媒体の提供",
        nameen: "Providing advertising media",
        path: "/ServiceProduct",
        pathMobile: "/ServiceProduct/Media",
        isActive: false,
        child: [
          { namejp: "広告媒体の提供", nameen: "Providing advertising media" },
          { namejp: "広告媒体の提供", nameen: "Providing advertising media" },
          { namejp: "広告媒体の提供", nameen: "Providing advertising media" },
        ],
      },
    ],
  },
  {
    id: "3",
    namejp: "会社情報",
    nameen: "Corporate Information",
    path: "/CorporateIntelligence",
    isActive: false,
    menu: [
      {
        id: "0",
        namejp: "会社概要",
        nameen: "Company overview",
        path: "/about",
        isActive: false,
        child: [
          { namejp: "会社概要", nameen: "Company overview" },
          { namejp: "会社概要", nameen: "Company overview" },
          { namejp: "会社概要", nameen: "Company overview" },
        ],
      },
      {
        id: "1",
        namejp: "企業理念",
        nameen: "Our Philosophy",
        path: "/CorporateIntelligence/Corporate",
        isActive: false,
        child: [
          { namejp: "企業理念", nameen: "Our Philosophy" },
          { namejp: "企業理念", nameen: "Our Philosophy" },
          { namejp: "企業理念", nameen: "Our Philosophy" },
        ],
      },
      {
        id: "4",
        namejp: "役員",
        nameen: "Officers and organization",
        path: "/CorporateIntelligence/OfficesAndOrganizations",
        isActive: false,
        child: [
          { namejp: "役員", nameen: "Officers and organization" },
          { namejp: "役員", nameen: "Officers and organization" },
          { namejp: "役員", nameen: "Officers and organization" },
        ],
      },
      {
        id: "2",
        namejp: "主なお取引先",
        nameen: "Main Business Partners",
        path: "/CorporateIntelligence/MainBusinessPartner",
        isActive: false,
        child: [
          { namejp: "主なお取引先", nameen: "Main Business Partners" },
          { namejp: "主なお取引先", nameen: "Main Business Partners" },
          { namejp: "主なお取引先", nameen: "Main Business Partners" },
        ],
      },
      {
        id: "3",
        namejp: "開園までのスケジュール",
        nameen: "Schedule until the opening",
        path: "/CorporateIntelligence/ScheduleUntil",
        isActive: false,
        child: [
          {
            namejp: "開園までのスケジュール",
            nameen: "Schedule until the opening",
          },
          {
            namejp: "開園までのスケジュール",
            nameen: "Schedule until the opening",
          },
          {
            namejp: "開園までのスケジュール",
            nameen: "Schedule until the opening",
          },
        ],
      },
      // {
      //     id:'5',
      //     namejp: "主な事業所",
      //     nameen:"Main office",
      //     path: "/",
      //     isActive: false,
      //     child : [
      //        {namejp: "主な事業所",
      //         nameen:"Main office"
      //        },
      //        {namejp: "主な事業所",
      //         nameen:"Main office"
      //         },
      //        {namejp: "主な事業所",
      //         nameen:"Main office"
      //         },
      //     ]
      // },
      // {
      //     id:'6',
      //     namejp: "ヒストリー",
      //     nameen:"History",
      //     path: "/",
      //     isActive: false,
      //     child : [
      //        {namejp: "ヒストリー",
      //         nameen:"History"
      //        },
      //        {namejp: "ヒストリー",
      //         nameen:"History"
      //         },
      //        {namejp: "ヒストリー",
      //         nameen:"History"
      //         },
      //     ]
      // },
    ],
  },
  {
    id: "4",
    namejp: "投資家情報",
    nameen: "Corporate Customers",
    path: "/CorporateCustomers",
    isActive: false,
    menu: [
      {
        id: "0",
        namejp: "経営方針",
        nameen: "Management Policy",
        path: "/CorporateCustomers/ManagePolicy",
        isActive: false,
        child: [
          { namejp: "経営方針", nameen: "Management Policy" },
          { namejp: "経営方針", nameen: "Management Policy" },
          { namejp: "経営方針", nameen: "Management Policy" },
        ],
      },
      {
        id: "1",
        namejp: "マーケットとお客様",
        nameen: "Our Market and Customers",
        path: "/CorporateCustomers/MarketsAndCustomers",
        isActive: false,
        child: [
          { namejp: "マーケットとお客様", nameen: "Our Market and Customers" },
          { namejp: "マーケットとお客様", nameen: "Our Market and Customers" },
          { namejp: "マーケットとお客様", nameen: "Our Market and Customers" },
        ],
      },
      {
        id: "2",
        namejp: "経営目標",
        nameen: "Management Goals and Benchmarks",
        path: "/CorporateCustomers/ManagementGoalsAndBenchmarks",
        isActive: false,
        child: [
          { namejp: "経営目標", nameen: "Management Goals and Benchmarks" },
          { namejp: "経営目標", nameen: "Management Goals and Benchmarks" },
          { namejp: "経営目標", nameen: "Management Goals and Benchmarks" },
        ],
      },
      {
        id: "3",
        namejp: "経営収支計画",
        nameen: "Management balance plan",
        path: "/CorporateCustomers/ManagementBalancePlan",
        isActive: false,
        child: [
          { namejp: "経営収支計画", nameen: "Management balance plan" },
          { namejp: "経営収支計画", nameen: "Management balance plan" },
          { namejp: "経営収支計画", nameen: "Management balance plan" },
        ],
      },
      {
        id: "4",
        namejp: "当社の強み",
        nameen: "Our Strengths (Market Competitiveness)",
        path: "/CorporateCustomers/OurLengths",
        isActive: false,
        child: [
          {
            namejp: "当社の強み",
            nameen: "Our Strengths (Market Competitiveness)",
          },
          {
            namejp: "当社の強み",
            nameen: "Our Strengths (Market Competitiveness)",
          },
          {
            namejp: "当社の強み",
            nameen: "Our Strengths (Market Competitiveness)",
          },
        ],
      },
      // {
      //     id:'5',
      //     namejp: "行程表",
      //     nameen: "Road Map",
      //     path: "/",
      //     isActive: false,
      //     child : [
      //        {namejp: "行程表",
      //         nameen: "Road Map"
      //         },
      //        {namejp: "行程表",
      //         nameen: "Road Map"
      //         },
      //        {namejp: "行程表",
      //         nameen: "Road Map"
      //         },
      //     ]
      // },
      // {
      //     id:'6',
      //     namejp: "事業の見通し",
      //     nameen: "Business Outlook",
      //     path: "/",
      //     isActive: false,
      //     child : [
      //        {namejp: "事業の見通し",
      //         nameen: "Business Outlook"
      //         },
      //        {namejp: "事業の見通し",
      //          nameen: "Business Outlook"
      //         },
      //        {namejp: "事業の見通し",
      //          nameen: "Business Outlook"
      //         },
      //     ]
      // },
      // {
      //     id:'7',
      //     namejp: "知的財産権",
      //     nameen: "Intellectual property right",
      //     path: "/",
      //     isActive: false,
      //     child : [
      //        {namejp: "知的財産権",
      //         nameen: "Intellectual property right"
      //         },
      //        {namejp: "知的財産権",
      //         nameen: "Intellectual property right"
      //         },
      //        {namejp: "知的財産権",
      //         nameen: "Intellectual property right"
      //         },
      //     ]
      // },
      // {
      //     id:'8',
      //     namejp: "株主情報",
      //     nameen: "Shareholder Information",
      //     path: "/",
      //     isActive: false,
      //     child : [
      //        {namejp: "株主情報",
      //         nameen: "Shareholder Information"
      //         },
      //        {namejp: "株主情報",
      //         nameen: "Shareholder Information"
      //         },
      //        {namejp: "株主情報",
      //         nameen: "Shareholder Information"
      //         },
      //     ]
      // },
      // {
      //     id:'9',
      //     namejp: "ＩＲニュース",
      //     nameen: "IR News",
      //     path: "/",
      //     isActive: false,
      //     child : [
      //        {namejp: "ＩＲニュース",
      //         nameen: "IR News"
      //         },
      //        {namejp: "ＩＲニュース",
      //         nameen: "IR News"
      //         },
      //        {namejp: "ＩＲニュース",
      //         nameen: "IR News"
      //         },
      //     ]
      // },
      // {
      //     id:'10',
      //     namejp: "決算情報",
      //     nameen: "Financial Information",
      //     path: "/",
      //     isActive: false,
      //     child : [
      //        {namejp: "決算情報",
      //         nameen: "Financial Information"
      //         },
      //        {namejp: "決算情報",
      //         nameen: "Financial Information"
      //         },
      //        {namejp: "決算情報",
      //         nameen: "Financial Information"
      //        },
      //     ]
      // }
    ],
  },
  // {
  // 	id:'5',
  // 	namejp: "サスティナ ビリティ",
  // 	nameen: 'Sustainability',
  // 	path: "/SustinaAbility",
  // 	isActive: false,
  // },
  {
    id: "6",
    namejp: "お知らせ",
    nameen: "Notices",
    path: "/category/news",
    isActive: false,
    menu: [
      {
        id: "0",
        namejp: "来園者の皆様へ",
        nameen: "To all visitors",
        path: "/category/news",
        pathMobile: "/Information/Visitors",
        isActive: false,
        child: [
          { namejp: "来園者の皆様へ", nameen: "To all visitors" },
          { namejp: "来園者の皆様へ", nameen: "To all visitors" },
          { namejp: "来園者の皆様へ", nameen: "To all visitors" },
        ],
      },
      {
        id: "2",
        namejp: "会議室・マンションオーナーの皆様へ",
        nameen: "To all seminar room owners",
        path: "/category/news",
        pathMobile: "/Information/RoomOwners",
        isActive: false,
        child: [
          {
            namejp: "会議室・マンションオーナーの皆様へ",
            nameen: "To all seminar room owners",
          },
          {
            namejp: "会議室・マンションオーナーの皆様へ",
            nameen: "To all seminar room owners",
          },
          {
            namejp: "会議室・マンションオーナーの皆様へ",
            nameen: "To all seminar room owners",
          },
        ],
      },
      {
        id: "1",
        namejp: "ＥＣモール商店主の皆様へ",
        nameen: "To EC mall shop owners",
        path: "/category/news",
        pathMobile: "/Information/ShopOwners",
        isActive: false,
        child: [
          {
            namejp: "ＥＣモール商店主の皆様へ",
            nameen: "To EC mall shop owners",
          },
          {
            namejp: "ＥＣモール商店主の皆様へ",
            nameen: "To EC mall shop owners",
          },
          {
            namejp: "ＥＣモール商店主の皆様へ",
            nameen: "To EC mall shop owners",
          },
        ],
      },
      {
        id: "3",
        namejp: "広告主の皆様へ",
        nameen: "Advertisers",
        path: "/category/news",
        pathMobile: "/Information/Advertisers",
        isActive: false,
        child: [
          { namejp: "広告主の皆様へ", nameen: "Advertisers" },
          { namejp: "広告主の皆様へ", nameen: "Advertisers" },
          { namejp: "広告主の皆様へ", nameen: "Advertisers" },
        ],
      },
      // {
      //     id:'4',
      //     namejp: "投資家の皆様へ",
      //     nameen: "Investor Relations",
      //     path: "/category/news",
      //     isActive: false,
      //     child : [
      //        {namejp: "投資家の皆様へ",
      //         nameen: "Investor Relations"
      //         },
      //        {namejp: "投資家の皆様へ",
      //         nameen: "Investor Relations"
      //         },
      //        {namejp: "投資家の皆様へ",
      //         nameen: "Investor Relations"
      //         },
      //     ]
      // }
    ],
  },
  {
    id: "7",
    namejp: "採用情報",
    nameen: "Recruitment Information",
    path: "/RecruitmentInformation",
    isActive: false,
  },
  {
    id: "8",
    namejp: "お問い合わせ",
    nameen: "Contact Us",
    path: "/inquiry",
    isActive: false,
  },
  {
    id: "9",
    namejp: "アクセス",
    nameen: "Access",
    path: "/Access",
    isActive: false,
  },
];
