import { FunctionComponent } from 'react'
import {Table_43} from '@assets'
import "keen-slider/keen-slider.min.css"
interface ModalImageProps {
	event: any;
}

const ModalImage: FunctionComponent<ModalImageProps> = ({event}) => {
	return (
        <div className="block items-center fixed z-[999] bg-[#0000003b] w-full h-full top-0 left-0">
			    <div className="flex h-full m-auto p-2 items-center top-0 left-0 w-[80%]  ">
            <div className='block w-full bg-white p-2 pb-5'>
               <img src='https://uxwing.com/wp-content/themes/uxwing/download/checkmark-cross/close-icon.png' className='w-[15px] cursor-pointer ml-[99%]' alt=''onClick={event}/>
              <div className='block mx-4'>
                <img src={Table_43} alt='' className='w-full' />
              </div>
            </div>
             
            </div>
        </div>
	 );
}

export default ModalImage;

