import React, {useEffect} from 'react'
import { Wrapper } from './styled'
import { Image_diagram01, Diagram_42} from '@assets';
import useScreenWidth from '../../../Hook/useScreenWidth'

const MarketsAndCustomers = () => {

   useEffect(() => {
    window.scrollTo(0, 0)
   },[])

   const screenWidth = useScreenWidth()
   return (
      <>
      <Wrapper>
         <div className='container_market block'>
            <div className='section-1 relative'>
               <div className='content'>
                  <h1 className='text-[30px] leading-normal md:leading-relaxed font-bold text-center ' >マーケットとお客様</h1>
                  <h2 className="leading-normal md:leading-relaxed font-bold mt_3">マーケット規模について</h2>
                  <div className='flex block-2 mb-12 mt_6r text-justify'>
                     <div className='p-2 w-1/2  my-[1rem] item mr_05 block-1'>
                        <div className='title'>
                           <p className='text font-bold'>B to C 領域 </p>
                        </div>
                        <div >
                           <div className='m_center'>
                              <div className='flex'>
                                 <p className='text-yellow-400'>■ </p>
                                 <div className='block'>
                                    {screenWidth > 580 ? 
                                    <>
                                       <p>Ｂ to Ｃの市場規模は、2019年度実績で19兆3609億円と</p>
                                       <p className='mt-2'>なっている。市場規模は対前年比で7.65%伸びており、</p>
                                       <p className='mt-2'>今後10年間でほぼ倍の規模に達することが見込まれる</p>
                                       <p className='mt-2'>引続き有望な成長市場である。</p>
                                    </>
                                    :
                                    <>
                                      <p>Ｂ to Ｃの市場規模は、2019年度実績で19兆3609億円となっている。市場規模は対前年比で7.65%伸びており、今後10年間でほぼ倍の規模に達することが見込まれる引続き有望な成長市場である。</p>
                                    </>
                                    }
                                 </div>
                              </div>
                              <div className='flex mt-8'>
                                 <p className='text-yellow-400'>■ </p>
                                 <div className='block'>
                                  {screenWidth > 580 ? 
                                    <>
                                       <p>当社は、Ｂ to Ｃ事業において、ＥＣモールのプラット</p>
                                       <p className='mt-2'>フォームを提供し、事業主のお手伝いを行うと同時に</p>
                                       <p className='mt-2'>自社サービスとしてアミューズメントを提供します。</p>
                                    </>
                                    :
                                    <>
                                      <p>当社は、Ｂ to Ｃ事業において、ＥＣモールのプラットフォームを提供し、事業主のお手伝いを行うと同時に自社サービスとしてアミューズメントを提供します。</p>
                                    </>
                                    }
                                 </div>
                              </div>
                            </div>
                        </div>
                     </div>
                     <div className='p-2 w-1/2 my-[1rem] item ml_05 block-1'>
                        <div className='title'>
                           <p className='text font-bold'>C to C 領域 </p>
                        </div>
                        <div >
                         <div className='m_center'>
                           <div className='flex'>
                              <p className='text-yellow-400'>■ </p>
                              <div className='block'>
                                 {screenWidth > 580 ? 
                                    <>
                                       <p>Ｃ  to Ｃ の市場規模は、2019年度実績で1兆7,404億円と</p>
                                       <p className='mt-2'>なっている。2012年ころから、ネットオークション等の</p>
                                       <p className='mt-2'>Ｃ to Ｃ事業が出現し、急速に市場が拡大しており、</p>
                                       <p className='mt-2'>今後も拡大が期待できる魅力的な分野である。</p>
                                    </>
                                    :
                                    <>
                                      <p>Ｃ  to Ｃ の市場規模は、2019年度実績で1兆7,404億円となっている。2012年ころから、ネットオークション等のＣ to Ｃ事業が出現し、急速に市場が拡大しており、今後も拡大が期待できる魅力的な分野である。</p>
                                    </>
                                    }
                              </div>
                            </div>
                            <div className='flex mt-8'>
                              <p className='text-yellow-400'>■ </p>
                              <div className='block'>
                                 {screenWidth > 580 ? 
                                    <>
                                       <p>当社は、Ｃ to Ｃ 領域において、個人のお客様の提供</p>
                                       <p className='mt-2'>するサービスを別の個人のお客様が購入できるＳＮＳ</p>
                                       <p className='mt-2'>系サービスを展開します。</p>
                                    </>
                                    :
                                    <>
                                      <p>当社は、Ｃ to Ｃ 領域において、個人のお客様の提供するサービスを別の個人のお客様が購入できるＳＮＳ系サービスを展開します。</p>
                                    </>
                                    }
                              </div>
                            </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div className='section-2'>
              <div className='content'>
                  <div className=' pt-3'>
                     <img src={Diagram_42} alt='' className='w-[100%]'/>
                  </div>
              {/* <div className='flex mt-6 table_ '>
               <div className='block '>
                  <p className='title_ w-[150px]'>BtoC-EC市場規模</p>
                  <table className="">
                  <thead>
                     <tr>
                        <th></th>
                        <th>2018年</th>
                        <th>2019年</th>
                        <th>伸び率</th>
                     </tr>
                  </thead>
                  <tbody>
                     <tr>
                        <td>物販系分野</td>
                        <td>9兆2,992億円 </td>
                        <td>10兆0,515億円</td>
                        <td>8,09%</td>
                     </tr>
                     <tr>
                        <td>サービス系分野</td>
                        <td>6兆6,471億円</td>
                        <td>7兆1,672億円</td>
                        <td>7,82%</td>
                     </tr>
                     <tr>
                        <td>デジタル系分野</td>
                        <td>2兆0,382億円</td>
                        <td>2兆1,422億円</td>
                        <td>5,11%</td>
                     </tr>
                     <tr>
                        <td>総　計</td>
                        <td>17兆9,845億円</td>
                        <td>19兆3,609億円</td>
                        <td>7,65%</td>
                     </tr>
                  </tbody>
                  </table>
                  <h5 className='text-[12px] mt-1'>2019年度『電子商取引市場の調査の結果を取りまとめ』（経済産業省）</h5>
               </div>
               <div className='block ml_46'>
                  <p className='title_ w-[150px]'>CtoC-EC市場規模</p>
                  <table className="">
                  <thead>
                     <tr>
                        <th></th>
                        <th>2018年</th>
                        <th>2019年</th>
                        <th>伸び率</th>
                     </tr>
                  </thead>
                  <tbody>
                     <tr>
                        <td>CtoC-EC</td>
                        <td>1兆5,891億円 </td>
                        <td>1兆7,407億円</td>
                        <td>9,5%</td>
                     </tr>
                  </tbody>
                  </table>
                  <h5 className='text-[12px] mt-1'>2019年度『電子商取引市場の調査の結果を取りまとめ』（経済産業省）</h5>
                </div>
               </div> */}
            </div>
           </div>
           <div className='section-3' >
               <div className='content block'>
               <div className='mt_3 m-auto block-5'>
                  <h2 className="leading-normal md:leading-relaxed mb-2 font-bold mt_3">当社メタバースが解決できる課題とご提供できる価値</h2>
                  <div className='ml-4 mt-[2rem]'>
                     <div className='flex'>
                        <p className='text-yellow-400'>■ </p>
                        <div className='block'>
                           <p>ＥＣモール出店者様の課題意識（グラフの ＡゾーンとＢゾーンの事業主様）</p>
                           <div className='ml-4'>
                              <p className='mt-4'>【Ａゾーンの事業主様】　購入したお客様がリピートしてくれない。ブランドを認知してほしい。</p>
                              <p className='mt-1'>【Ｂゾーンの事業主様】　検索エンジンで下位表示され来店してくれない。商品の良さを説明したい。</p>
                           </div>
                        </div>
                     </div>
                     <div className='flex mt-8'>
                        <p className='text-yellow-400'>■ </p>
                        <div className='block'>
                           <p>出店者様にご提供できる価値</p>
                           <div className='ml-4'>
                              <p className='mt-4'>リアルで美しい店舗内で、アバター店員による丁寧な接客や詳しい商品説明を行うことができます。</p>
                              <p>貴社のブランド価値と優秀な商材に対するお客様の認知を高め、売上アップとリピート率向上につなげます。</p>
                           </div>
                        </div>
                     </div>
                     <div className='noprint'>
                        <img src={Image_diagram01} alt='' className='m-auto w-[85%] img_diag01' />
                     </div>
                     <div className='print'>
                        <img src={Image_diagram01} alt='' className='m-auto w-[85%] img_diag01' />
                     </div>
                     <div className='flex mt-2'>
                        <p className='text-yellow-400'>■ </p>
                        <div className='block'>
                           <p>ＥＣモールでお買い物をするお客様の課題意識</p>
                           <div className='ml-4'>
                              <p className='mt-4'>価格だけでなく、品質やアフターサービスも含めたトータルの価値を重視したい。</p>
                              <p>買い物を家族や友達と楽しみたい、行動体験を大切にしたい。</p>
                           </div>
                        </div>
                     </div>
                     <div className='flex mt-8'>
                        <p className='text-yellow-400'>■ </p>
                        <div className='block'>
                           <p>ＥＣモールでお買い物をするお客様にご提供できる価値</p>
                           <div className='ml-4'>
                              <p className='mt-4'>リアルで美しい店舗内で、ご家族やお友達との楽しいお買物やコミュニケーションをお楽しみいただけます。</p>
                              <p>アバター店員の丁寧な商品説明により、高品質で価値ある商品に出会うことができます。</p>
                           </div>
                        </div>
                     </div>
                  </div>
                  </div>
               </div>
            </div>
         </div>
      </Wrapper>
      </>
   )
}
export default MarketsAndCustomers
