import React from 'react'
import { Wrapper } from './styled'
import AllVisitors from './visitors'
import ECMail from './ECmail'
import SeminarRoom from './seminar'
import Advertiser from './advertiser'

const InformationScreen = () => {

   return (
      <>
     <Wrapper id="intro">
         <div className='container-home block'>
            <AllVisitors />
            <SeminarRoom/> 
            <ECMail/> 
            <Advertiser/> 
         </div>
      </Wrapper>
      </>
   )
}
export default InformationScreen
