import styled from 'styled-components'

export const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: block;
  h2{
    font-size: 24px;
    @media screen and (max-width: 1620px) {
      font-size: 20px;
    }
    @media print{
      font-size: 16px;
    }
  }
  p{
    line-height: 1.6;
    font-size: 18px;
    @media print{
      font-size: 14px;
    }
    @media screen and (max-width: 1620px) {
      font-size: 16px;
    }
    @media screen and (max-width: 991px) {
      font-size: 14px;
    }
  }
  button{
    padding:10px 30px;
    border-radius: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    text-transform: uppercase;
    color: #FFFFFF;
    box-shadow: rgb(0 0 0 / 25%) 0px 14px 28px, rgb(0 0 0 / 22%) 0px 10px 10px;
    @media print{
      padding: 5px 20px;
    }
  }
  .text_16{
    font-size: 16px;
    @media print{
      font-size: 14px;
    }
    @media screen and (max-width: 1620px) {
      font-size: 16px;
    }
    @media screen and (max-width: 580px) {
      font-size: 14px;
    }
  }
  .text_black{
    color: black !important;
  }
  .w_check{
    width: 85%;
    @media screen and (max-width: 580px){
      width: 95%;
    }
  }
  .img_check{
    width: 200px;
  }
  .check{
    display: flex;
    @media screen and (max-width: 580px){
      display: block;
    }
  }
  .container-cor{
    width: 100%;
    padding: 3rem 0;
    @media screen and (max-width: 580px){
      padding: 8rem 0;
      }
     .right{
      width: 20%;
      height: 79%;
      margin-left: 80%;
      @media screen and (max-width: 1280px){
        margin-left: 77%;
      }
      
      }
      .mb_32r{
        margin-bottom: 32rem;
        @media print{
          margin-bottom :18rem;
        }
      }
      .mt_6{
        margin-top: 1.5rem;
        @media print{
          margin-top: 0.5rem;
        }
      }
      .pt_6{
        padding-top: 1.5rem;
        @media print{
          padding-top: 0.5rem;
        }
      }
      .start-selling-contact{
      margin : auto;
      width: 85%;
      padding-bottom: 3rem;
      @media print{
         width: 90%;
      }
      .line-up{
          position: absolute;
          width: 7px;
          margin-left: -4px;
          background: #2F1B11;
          transform: skewY(-52deg);
        }
        .line-down{
          position: absolute;
          width: 1px;
          background: #2F1B11;
        }
        .item_fi{
          width: 100%;
          background: rgba(217, 197, 181, 0.3);
        }
        .item_se{
          width: 100%;
          height: 105px;
          background: rgba(217, 197, 181, 0.3);
        }
        .text{
          line-height: 25px;
          letter-spacing: 2px;
        }
        .title{
          font-style: normal;
          font-weight: 700;
          line-height: 35px;
          color: #0972c1;
          letter-spacing: 2px;
        }
        .title_{
          font-style: normal;
          font-weight: 700;
          line-height: 35px;
          color: #0972c1;
          letter-spacing: 2px;
          width:90%;
          border-bottom :2px solid #0972c1;
        }
        textarea,input,select{
          width:87%;
          background : #f4f3ed;
          border: 0.5px solid #7e7e7e4d;
          padding : 5px 10px;
          transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 25px;
          color: black;
          letter-spacing: 2px;
        }
        .upload_img{
          width:725px;
          background : #f4f3ed;
          border: 0.5px solid #7e7e7e4d;
          padding : 5px 10px;
          transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 25px;
          color: black;
          letter-spacing: 2px;
          @media screen and (max-width: 1280px) {
            width: 87%;
          }
         }
        input{
          @media print{
            height: 30px;
          }
        }
        select{
          height: 36px;
        }
        textarea{
          height:150px;
        }
        textarea,input,select:focus {
          border: 1px solid;
          border-color: #8c9395c7;
          outline: none;
        }
        input[type="checkbox"] {
          width: 2.5%;
          padding: 5px;
          }
        button{
          padding:10px 30px;
          height: 50px;
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 24px;
          text-transform: uppercase;
          color: #FFFFFF;
          box-shadow: rgb(0 0 0 / 25%) 0px 14px 28px, rgb(0 0 0 / 22%) 0px 10px 10px;
          @media screen and (max-width: 580px) {
            padding:10px 14px;
            font-size: 13px !important;
          }
        }
        button: hover{
          border: 1px solid;
          border-color: #8c9395c7;
        }
        .font_small{
          font-size: smaller;
        }
        h2{
        font-size: 24px;
        color: #4472c4;
          @media screen and (max-width: 1620px) {
            font-size: 20px;
          }
          @media print{
          font-size: 16px;
          }
        }
        button,p,label{
          line-height: 1.6;
          font-size: 18px;
          @media print{
          font-size: 14px;
          }
          @media screen and (max-width: 1620px) {
            font-size: 16px;
          }
          @media screen and (max-width: 991px) {
            font-size: 14px;
          }
        }
        .bt_wh{
          line-height: 1.6;
          font-size: 14px !important;
          @media screen and (max-width: 580px) {
            padding:10px 13px;
            font-size: 10px !important;
          }
          @media print{
          font-size: 14px;
          }
        }
      }
      .container_{
      margin-left:4rem;
      @media print{
        margin-left: 2rem;
      }
    }

    @media screen and (max-width: 991px){
      >div {
        &.flex {
          margin-bottom: 32rem;
        }

        &.start-selling-contact {
          width: 90%;
          .container_ {
            margin-left: 0;
            padding: 0 40px;
            >div {
              margin-left: 0;
            }
            form {
            >div {
              width: 100%;

              &:nth-child(3), &:nth-child(5),&:nth-child(8) ,&:nth-child(10){
                .grid {
                  &:last-child {
                    padding-left: 10px;
                  }
                }
                input,.upload_img {
                  width: 100%;
                }
              }

              &.grid {
                input,select,.upload_img {
                  width: 100%;
                }
              }

                &.radio-box {
                  input[type="checkbox"] {
                    width: 12px !important;
                    height: 12px;
                    margin-top: 14px;
                  }
              }
            }
          }
          }
         
        }
      }
    }

    @media screen and (max-width: 767px){
      >div {
        &.start-selling-contact {
          width: 90%;
          .container_ {
            width: 100%;
            margin-left: 0;
            padding: 20px;

            .title_ {
              width: 100%;
            }

            form {
              >div {
                &.grid {
                  width: 100%;
                  input,select,.upload_img {
                    width: 100%;
                  }
                }

                &.flex {
                  &:nth-child(3), &:nth-child(5),&:nth-child(8) ,&:nth-child(10){
                    flex-direction: column;
                    .grid {
                      width: 100%;
                      &:last-child {
                        margin-top: 10px;
                        padding-left: 0;
                      }
                    }
                    input,.upload_img {
                      width: 100%;
                    }
                  }
                 
                }
              }
            }
          }
         
        }
      }
    }
  }
 
`
