import React from "react";
import {
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";
import { ROUTES } from './constant'
import PrivateRoute from '@components/route/privateRoute'

export default function RouteConfigExample() {
  return (
    <Router>
        <Switch>
        {ROUTES.map((routeConfig, index) => (
          <PrivateRoute
            key={index}
            {...routeConfig}
          />
        ))}
        </Switch>
    </Router>
  );
}

