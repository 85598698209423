import React, {useEffect, useRef,useState} from 'react'
import styled from 'styled-components';
import { ApplicationContactUsInformation } from '../../../APIs';
//@ts-ignore
import { useForm } from "react-hook-form";
import ModalNoti from '@components/modalNoti';
import useScreenWidth from '../../../Hook/useScreenWidth'
const Wrapper = styled.div `
   .start-selling-contact{
      margin : auto;
      width: 80%;
      padding-bottom: 3rem;
      @media print{
         width: 100%;
      }
      .mt_6{
        margin-top: 1.5rem;
        @media print{
          margin-top: 0.5rem;
        }
      }
      .text_16{
        font-size: 16px;
        @media print{
         font-size: 14px;
        }
        @media screen and (max-width: 580px) {
          font-size: 14px;
        }
      }
      .line-up{
         position: absolute;
         width: 7px;
         margin-left: -4px;
         background: #2F1B11;
         transform: skewY(-52deg);
      }
      .line-down{
         position: absolute;
         width: 1px;
         background: #2F1B11;
      }
      .item_fi{
         width: 100%;
         background: rgba(217, 197, 181, 0.3);
      }
      .item_se{
         width: 100%;
         height: 105px;
         background: rgba(217, 197, 181, 0.3);
      }
      .text{
         line-height: 25px;
         letter-spacing: 2px;
      }
      .title{
         font-style: normal;
         font-weight: 700;
         line-height: 35px;
         color: #0972c1;
         letter-spacing: 2px;
      }
      .title_{
         font-style: normal;
         font-weight: 700;
         line-height: 35px;
         color: #0972c1;
         letter-spacing: 2px;
         width:90%;
         border-bottom :2px solid #0972c1;
      }
      textarea,input{
         width:87%;
         background : #f4f3ed;
         border: 0.5px solid #7e7e7e4d;
         padding : 5px 10px;
         transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
         font-style: normal;
         font-weight: 500;
         font-size: 14px;
         line-height: 25px;
         color: black;
         letter-spacing: 2px;
      }
      input{
        @media print{
          height: 30px;
        }
      }
      textarea{
         height:150px;
         @media print{
            height: 100px;
         }
      }
      textarea,input:focus {
         border: 1px solid;
         border-color: #8c9395c7;
         outline: none;
      }
      input[type="radio"] {
         width: 2.5%;
         padding: 5px;
         }
      button{
         width: 230px;
         height: 50px;
         background: #A98260;
         font-style: normal;
         font-weight: 700;
         font-size: 14px;
         line-height: 24px;
         text-transform: uppercase;
         color: #FFFFFF;
         box-shadow: rgb(0 0 0 / 25%) 0px 14px 28px, rgb(0 0 0 / 22%) 0px 10px 10px;
      }
      button: hover{
         border: 1px solid;
         border-color: #8c9395c7;
      }
      .font_small{
         font-size: smaller;
      }
      h2{
        font-size: 24px;
        color: #4472c4;
        @media screen and (max-width: 1620px) {
          font-size: 20px;
        }
        @media print{
         font-size: 16px;
        }
      }
      button,p,label{
        line-height: 1.6;
        font-size: 19px;
        @media print{
         font-size: 14px;
        }
        @media screen and (max-width: 1620px) {
          font-size: 18px;
        }
        @media screen and (max-width: 1280px) {
          font-size: 16px;
        }
        @media screen and (max-width: 580px) {
          font-size: 14px;
        }
      }
      .text_14{
         font-size: 14px !important;
      }
   }
   .container_{
      margin-left:4rem;
      @media screen and (min-width: 1920px){
         margin-left: 5rem;
      }
      @media print{
         margin-left: 2rem;
      }
   }

   @media screen and (max-width: 991px) {
      .start-selling-contact {
         margin-top: 150px;
         width: 90%;
      }
     

      .container_ {
         margin-left: 0;

         .ml-4 {
            margin-left: 0;
         }

         input, textarea, .des, .checkbox-box, .button-box {
            width: 100%;
         }

         .checkbox-box {
            justify-content: center;
            align-items: center;

            p, input {
               margin-top: 0;
            }

            input {
               width: 13px;
               height: 13px;
            }
         }

         .name-box {
            width: 100%;
            >div {
               &:last-child {
                  margin-left: 10px;
               }
            }
         }
      }
   }

   @media screen and (max-width: 767px) {
      .start-selling-contact {
         width: 100%;
      }
      .container_ {
         .name-box {
            flex-direction: column;
            >div {
               width: 100%;
               &:last-child {
                  margin-left: 0;
               }

               input {
                  // width: 93.5%;
               }
            }
         }

         .checkbox-box {
            p {
               font-size: 13px;
            }
         }
      }
   }


`
const ContactUs = () => {
   const divOne = useRef<HTMLInputElement | null>(null);
   const scrolLWithUseRef = () => {
   divOne.current?.scrollIntoView({ block: "center", behavior: "smooth" });
   };
 
   useEffect(() => {
    scrolLWithUseRef()
   },[])
   const screenWidth = useScreenWidth()

   const {
      register,
      handleSubmit,
      setValue,
      formState: { errors }
    } = useForm();
    const [notification, setNotification] = useState(false);
    const [isCheckRule, setIsCheckRule] = useState(false);
  
    const onSubmit = (data : any) => {
      const today = new Date();
      let hours = '';
      let minutes = '';
      if(today.getHours() < 10){
         hours = `0${today.getHours()}`
      }else{
         hours = `${today.getHours()}`
      }
      if(today.getMinutes() < 10){
         minutes = `0${today.getMinutes()}`
      }else{
         minutes = `${today.getMinutes()}`
      }
      const text =`※このメールはシステムからの自動返信です<br/>
      <br/>${data.firstname}${data.lastname} 様,<br/>
      <br/>お世話になっております。
      <br/>アートメタバースランド株式会社へのお問い合わせありがとうございました。<br/>
      <br/>以下の内容でお問い合わせを受け付けいたしました。
      <br/><br/>■お問い合わせ内容：
      <br/>　　企業名：${data.nameCompany}
      <br/>　　氏名：${data.firstname}${data.lastname}
      <br/>　　氏名（フリガナ）：${data.firstnamespelling}${data.lastnamespelling}
      <br/>　　電話番号：${data.phonenumber}
      <br/>　　住所：${data.address}
      <br/>　　メールアドレス：${data.email}
      <br/>　　お問い合わせ内容：${data.content}
      <br/>　　作成時間：${today.getFullYear()}/${today.getMonth()+1}/${today.getDate()}   ${hours}:${minutes}
      <br/> ________________________________________________________
      <br/><br/>アートメタバースランド株式会社
      <br/>〒176-0022　東京都練馬区向山３－１５－１９
      <br/>電話番号　０３－６６３６－７８９３
      <br/>メールによるお問い合わせは、弊社ホームページお問い合わせフォームからお願いいたします。
      <br/> ________________________________________________________
      `
      ApplicationContactUsInformation({
         name :`${data.firstname}${data.lastname}`,
         email:`${data.email}`,
         data: text,
         cc: '',
         subject: '【アートメタバースランド株式会社】お問い合わせありがとうございます',
         attachments: ''
      },setNotification)

      ApplicationContactUsInformation({
         name : `${data.firstname}${data.lastname}`,
         email:'contactus@artmetaverseland.co.jp',
         data: text,
         cc: '',
         subject: '【アートメタバースランド株式会社】お問い合わせありがとうございます',
         attachments: ''
      },setNotification)
   }

   return (
      <>
      <Wrapper>
         <div className='start-selling-contact block'>
            <div className='item_fi flex'>
               <div className='mt-[5px]'>
               </div>
               <div className='pl-1 block pb-4 w-full'>
                  <div className='block p-2 mt_6'>
                     <h2 className='title text-center pt-2'>お問い合わせ</h2>
                  </div>
                  <div className='block p-2 container_ '>
                     <p className='text' id='id0'  ref={divOne}>お問い合わせ内容を入力してください。</p>
                     <div className='ml-4'>
                        <form onSubmit={handleSubmit(onSubmit)}>
                           <div className="grid">
                              <label className='text mt_6 font-bold'>企業名</label>
                              <input className='mt-1' type="text"
                                 {...register("nameCompany")}
                                 onChange={e => setValue("nameCompany", e.target.value.trimStart())}
                              />
                           </div>
                           <div className="grid">
                              <label className='text mt_6 font-bold'> 氏名</label>
                           </div>
                           <div className='flex w-[93%] name-box'>
                              <div className="grid w-1/2">
                                 <label className='text mt-2 font-bold'>姓<span className='font-bold text-red-500 font_small'>「必須」</span></label>
                                 <input className='mt-1' type="text" 
                                    {...register("firstname", {
                                       required: true
                                    })} 
                                    onChange={e => setValue("firstname", e.target.value.trimStart())}
                                 />
                              </div>
                              <div className="grid w-1/2">
                                 <label className='text mt-2 font-bold'>名<span className='font-bold text-red-500 font_small'>「必須」</span></label>
                                 <input className='mt-1' type="text" 
                                    {...register("lastname", {
                                       required: true
                                    })} 
                                    onChange={e => setValue("lastname", e.target.value.trimStart())}
                                 />
                              </div>
                           </div>
                           <div className="grid">
                              <label className='text mt_6 font-bold'> 氏名（フリガナ）</label>
                           </div>
                           <div className='flex w-[93%] name-box'>
                              <div className="grid w-1/2">
                                 <label className='text mt-2 font-bold'>姓<span className='font-bold text-red-500 font_small'>「必須」</span></label>
                                 <input className='mt-1' type="text" 
                                    {...register("firstnamespelling", {
                                       required: true
                                    })} 
                                    onChange={e => setValue("firstnamespelling", e.target.value.trimStart())}
                                 />
                              </div>
                              <div className="grid w-1/2">
                                 <label className='text mt-2 font-bold'>名<span className='font-bold text-red-500 font_small'>「必須」</span></label>
                                 <input className='mt-1' type="text" 
                                    {...register("lastnamespelling", {
                                       required: true
                                    })} 
                                    onChange={e => setValue("lastnamespelling", e.target.value.trimStart())}
                                 />
                              </div>
                           </div>
                           <div className="grid">
                              <label className='text mt_6 font-bold'>電話番号<span className='font-bold text-red-500 font_small'>「必須」</span></label>
                              <input className='mt-1' type="text"
                                 {...register("phonenumber", {
                                    required: true
                                  })}
                                  onChange={e => setValue("phonenumber", e.target.value.replace(/\D/,''))}
                              />
                           </div>
                           <div className="grid">
                              <label className='text mt_6 font-bold'>住所<span className='font-bold text-red-500 font_small'>「必須」</span></label>
                              <input className='mt-1' type="text"
                                 {...register("address",{
                                    required: true
                                 })}
                                 onChange={e => setValue("address", e.target.value.trimStart())}
                              />
                           </div>
                           <div className="grid">
                              <label className='text mt_6 font-bold'>メールアドレス<span className='font-bold text-red-500 font_small'>「必須」</span></label>
                              <input className='mt-1' type="text" 
                                 {...register("email", {
                                    required: true,
                                    pattern:{
                                       value:
                                          /^[A-Z0-9._+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                          message: "無効なメールアドレス",
                                    }
                                 })}
                                 onChange={e => setValue("email", e.target.value.trimStart())}
                              />
                              {errors.email?.message && (
                                 <p className="text-red-500 text_16">{errors.email?.message?.toString()}</p>
                              )}
                           </div>
                           <div className="grid">
                              <label className='text mt_6 font-bold'>お問い合わせ内容<span className='font-bold text-red-500 font_small'>「必須」　　　　</span>{screenWidth < 581 ? <br/> : ''}＊ ４００字以内でお願します。</label>
                              <textarea className='mt-1'
                                 {...register("content", {
                                    required: true
                                 })} 
                                 onChange={e => setValue("content", e.target.value.trimStart())}
                               />
                           </div>
                           <p className='text-[12px] text-center text-[#0563c1] mt_6 w-[87%] des'> <a href='file/Privacy.pdf' target='_blank' className={`${screenWidth < 1281 ? 'text_14' : ''}`} >プライバシーポリシーをお読みいただき、予め同意の上、お問い合わせください。</a></p>
                           <div className='flex justify-center w-[87%] checkbox-box'>
                              <input className='mt-1' type="radio"
                                 {...register("policy",{
                                    required: true
                                 })}
                                 onChange={() => setIsCheckRule(!isCheckRule)}
                              />
                              <p className='text mt-2 ml-2 font-bold'>プライバシーポリシーに同意する</p>
                           </div>
                           {isCheckRule ?
                              <>
                               <div className="grid mt-3 w-[87%] button-box">
                                 <label></label>
                                 <button type="submit" className='m-auto'>入力内容を送信する</button>
                              </div>
                              </>
                              :
                              <>
                               <div className="grid mt-3 w-[87%] button-box">
                                 <label></label>
                                 <button type="submit" className='m-auto opacity-70 ' disabled >入力内容を送信する</button>
                              </div>
                              </>
                           }
                        </form>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         {notification && (
            <ModalNoti 
               event={() => {setNotification(false);
                  window.location.reload();
               }
               }
               title1='メールの送信に成功しました。'
               title2=''
            />
         )}
      </Wrapper>
      </>
   )
}
export default ContactUs
