import styled from 'styled-components'

export const Wrapper = styled.div`
width: 100%;
@media screen and (max-width: 991px) {
  margin-top: 80px;
}
padding: 6rem 0 10rem;
@media screen and (max-width: 1620px){
padding: 4rem 0 10rem;
}
@media screen and (max-width: 991px){
  padding: 1rem 0 10rem;
  }
.print{
  display: none;
}
.text_black{
  color : black !important
}
@media print{
  padding:2rem 0 10rem;
  .noprint{
    display: none;
  }
  .print{
    display: block;
  }
  h1{
    font-size: 20px;
  }
  .imgprint{
    width: 100%;
    height: 500px;
    margin: auto;
    margin-bottom: 2rem;
    img{
      width:100%;
    }
  }
}
.container_ai{
    width: 100%;
    .content{
      width: 100%;
      margin: auto;
      .item{
          padding: 1rem;
          margin: 1rem;
          background-color: #f4f3ed;
          border-radius: 10px;
          transition: 1s all ease-in-out;
        }
        .item:hover{
          transition: 1s all ease-in-out;
          scale: 1.1;
        }
        p{
          line-height: 1.6;
          font-size: 19px;
          @media print{
            font-size: 14px;
          }
          @media screen and (max-width: 1620px) {
            font-size: 18px;
          }
          @media screen and (max-width: 1280px) {
            font-size: 16px;
          }
          @media screen and (max-width: 580px) {
            font-size: 14px;
          }
        }
      button{
        width: 198px;
        height: 50px;
        border-radius: 10px;
        margin-top: 1rem;
        background: #A98260;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 24px;
        text-transform: uppercase;
        color: #FFFFFF;
        box-shadow: rgb(0 0 0 / 25%) 0px 14px 28px, rgb(0 0 0 / 22%) 0px 10px 10px;
      }
      h2{
        font-size: 24px;
        color: #4472c4;
        font-weight: 700;
        @media screen and (max-width: 1620px) {
          font-size: 20px;
        }
        @media print{
          font-size: 16px;
        }
      }
      .text{
        width: 87%;
        @media screen and (max-width: 1280px) {
          width: 100%;
        }
        @media print{
          width: 90%;
        }
        margin: auto;
      }
    }
  
    .content, .block {
      @media screen and (max-width: 991px) {
        h1 {
          font-size: 1.5rem;
        }
        .content-box {
          padding: 20px;
          flex-direction: column;
          .item {
            width: 100%;
            margin: 0;
            margin-bottom: 20px;
          }
        }
      }
    }
  }
 
`