import styled from 'styled-components'

export const Wrapper = styled.header`
  @media screen and (max-width: 991px){
    display: flex;
  }
  .content-box {
    flex: 1;

    @media screen and (max-width: 991px){
      &:last-child {
        img {
          margin-top: 5px !important;
        }
      }
      a {
        display: inline-block;
        width: 100%;
        height: 100%;
  
        .item {
          width: 100%;
          height: 100%;
  
          p {  
            &:first-child {
              font-weight: bold;
            }
          }
  
          img {
            margin: 0;
            width: 100%;
            height: 240px;
          }
        }
      }
    }

    @media screen and (max-width: 767px){
      a {
        .item {
          p {
            font-size: 12px;
          }
  
          img {
            height: 180px;
          }
        }
      }
    }

    @media screen and (max-width: 400px){
      a {
        .item {
          p {
            font-size: 12px;
          }
  
          img {
            height: 150px;
          }
        }
      }
    }
    
  }
  .item{
    
    @media print {
      width: 160px;
      height: 160px;

    }
    width: 270px;
    height: 290px;
    @media screen and (max-width: 1620px){
      width: 220px;
      height: 270px;
    }
    left: 1112px;
    top: 137px;
    background: #EEE8E0;
    transform: scale(0.9);
    cursor : pointer;
    transition: all 0.6s ease-in-out;
    &:hover{
      transform: scale(1);
    }
    
    .title{  
      font-style: normal;
      text-align: center;
      line-height: 1.3;
      @media screen {
        font-size: 19px;
      }
      @media print {
        font-size: 12px;
      }
      @media screen and (max-width: 1620px) {
        font-size: 18px;
      }
      @media screen and (max-width: 1280px) {
        font-size: 16px;
      }
      @media screen and (max-width: 580px) {
        font-size: 14px;
      }
      }
      @media print {
        .image{
        height: 80px;
        width: 150px;
        margin: 3px 10px;
      }
      .image1{
        height: 80px;
        width: 150px;
        margin: auto;
        margin-top:5px;
      }
      }

        @media screen {
        .image{
          height: 170px;
          width: 250px;
          margin: 3px 10px;
          @media screen and (max-width: 1620px) {
            height: 160px;
            width: 230px;
            margin: 11px 0px;
          }
        }
        .image1{
          height: 170px;
          width: 200px;
          margin: auto;
          margin-top:11px;
          @media screen and (max-width: 1620px) {
            height: 160px;
            width: 170px;
          }
        }
        }
    }
`

