import React from 'react'
import { Wrapper } from './styled'
import useScreenWidth from '../../../Hook/useScreenWidth'
const Advertiser = () => {
   const screenWidth = useScreenWidth()
   return (
      <>
      <Wrapper>
         <div className='container_advertiser block text-justify'>
            <div className='content'>
               <div className='mt-4'>
                  <div className='item'>
                     <div className={`flex ${ (screenWidth > 580) ? 'mb-[3rem]' : 'mb-[1rem]'}`}>
                        <h1 className="text-[24px] leading-normal md:leading-relaxed font-bold" id="id3">広告掲載を検討いただいている皆様へ</h1>
                     </div>
                     <div className={`w-full ${ (screenWidth > 580) ? 'pl_6' : 'ml-0'}`}>
                        <p className=''>2023年2月1日 ： アートメタバースランドは、２０２４年７月１日開園を予定しております。</p>
                        <div className={`block mt-8 ${ (screenWidth > 580) ? 'pl-[3rem]' : 'ml-0'}`}>
                           <p className='font-bold text-lg md:text-1xl'>大型サイネージ（動画対応）広告</p>
                           <div className={`${ (screenWidth > 580) ? 'ml-6' : 'ml-0'}`}>
                              <p>大型サイネージ（動画対応）を、駅前や市街地に４台設置する予定です。</p>
                              <p>大型サイネージ（動画対応）への広告掲載は、ＥＣモール商店主様に優先利用いただく計画です。</p>
                              <p>ただし、一般広告の掲載も受付ております。</p>
                              <p>当社ホームページ「お問い合わせ」より、メールにてお問い合わせください。</p>
                           </div>                            
                        </div>
                        <div className={`block mt-8 ${ (screenWidth > 580) ? 'pl-[3rem]' : 'ml-0'}`}>
                           <p className=' font-bold text-lg md:text-1xl'>その他の広告媒体</p>
                           <div className={`${ (screenWidth > 580) ? 'ml-6' : 'ml-0'}`}>
                              <p>アートメタバースランド内に設置する広告媒体については、現在を開発中です。</p>
                              <p>詳細が固まり次第、当ページにて告知してまいります。</p>
                           </div>                            
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </Wrapper>
      </>
   )
}
export default Advertiser
