//@ts-ignore
import axios from  "axios"

function ApplicationContactUsInformation (Data : any, setNotification : any) {
    axios({
     method : "POST",
     url : 'https://api.artmetaverseland.co.jp/api/contact-us/information',
     data :  Data,
     headers: { "Content-Type": "multipart/form-data" },
    })
    .then((res : any) => {
     setNotification(true);
     return res.data;
    })
    .catch((err :any ) => {
     console.warn(err)
    })
 }


export {
   ApplicationContactUsInformation,
}