import styled from 'styled-components'

export const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: block;
  .container-recruit{
    width: 100%;
    margin: 4rem 0 2rem;
    @media screen and (max-width: 991px) {
      margin: 8rem 0 2rem;
        }
      .start-selling{
        margin : auto;
        width: 80%;
        padding-bottom: 3rem;
        @media print{
          width: 100%;
        }
        .line-up{
          position: absolute;
          width: 7px;
          margin-left: -4px;
          background: #2F1B11;
          transform: skewY(-52deg);
        }
        .line-down{
          position: absolute;
          width: 1px;
          background: #2F1B11;
        }
        .item_fi{
          width: 100%;
          background: rgba(217, 197, 181, 0.3);
        }
        p{
        line-height: 1.6;
        font-size: 19px;
        margin-top: 4rem;
        @media print{
          font-size: 16px;
        }
        @media screen and (max-width: 1620px) {
          font-size: 18px;
        }
        @media screen and (max-width: 991px) {
          font-size: 16px;
        }
        @media screen and (max-width: 580px) {
          font-size: 14px;
        }
      }
      }
  }
 
`
