/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Footer, Header } from '@components'
import { useTranslation } from "react-i18next";
import useScreenWidth from '../Hook/useScreenWidth'
import HeaderMobile from '@components/header/header-mobile';
import Menu from '@components/header/menu';
import { PRIMARY } from '@assets/colors';

const MENU_WIDTH = '300px'

const Wrapper: any = styled.div`
  position: absolute;
  width: 100%;
  height: 829px;
  left: 0px;
  top: 0px;
  display: block;
  background-position:center;
  background-repeat : no-repeat;
  background-size : cover;
`
const Main = styled.main`
  width: 100%;
  padding: 0;
  justify-content: center;

  &.open {
    
    .header-mobile-container {
      margin-left: 240px;
      -moz-animation: fadeOut 1.6s ease;
      -webkit-animation: fadeOut 1.6s ease;
      animation: fadeOut 1.6s ease;
      transition: margin 100ms ease;
    }
    #intro {
      margin-left: ${MENU_WIDTH};

      .bg_slide {
        left:${MENU_WIDTH};
       
      }
    }
  }
`
const Content = styled.div`
  width: 100%;
  overflow: hidden;
  display: flex;
  @media print {
    margin-top: -4rem;
    padding: 4rem 0 0;
    background: #f4f3ed;;
  }
  .slide-bar {
    -moz-animation: fadeOut 1.6s ease;
    -webkit-animation: fadeOut 1.6s ease;
    animation: fadeOut 1.6s ease;
    transition: margin 100ms ease;
    margin-left: -${MENU_WIDTH};
    position: fixed;
    height: 100%;
    overflow: hidden;
    padding: 15px;
    z-index: 1;
    top: 0;

    &.open {
      width: ${MENU_WIDTH};
      margin-left: 0;
      background: ${PRIMARY};
    }

    &.close {
      display: none;
    }

    header {
      flex-direction: column;
    }
  }
`

const HomeLayout = ({ children }: any) => {
  const [language, setLanguage] = useState<any>(false);
  const { i18n } = useTranslation();
  const screenWidth = useScreenWidth()

  const [isShowMenu, setIsShowMenu] = useState(false)

  useEffect(() => {
    if (i18n.language === "en") {
      setLanguage(true)
    }
    if (i18n.language === "jp") {
      setLanguage(false)
    }
  }, [i18n.language])

  const onSetShowMenu = () => {
    setIsShowMenu(!isShowMenu)
  }

  return (
    <Wrapper >
      <div className='container_'>
        {screenWidth > 991 && (
          <Header language={language} setLanguage={setLanguage}/>
        )}
        <Main className={`${isShowMenu ? 'open' : 'close'}`}>
          <Content>
            {screenWidth <= 991 && (
              <>
                <HeaderMobile
                  onClick={onSetShowMenu}
                  isOpen={isShowMenu}
                />
                <div className={`slide-bar ${isShowMenu ? 'open' : 'close'}`}>
                  <Menu language={language} onScrollMenu={onSetShowMenu} />
                </div>
              </>
            )}
            {children}
          </Content>
        </Main>
        <Footer language={language} setLanguage={setLanguage} />
      </div>
    </Wrapper>
  )
}

export default HomeLayout
