import styled from 'styled-components'

export const Wrapper = styled.div`
width: 100%;
  .container_off {
    width: 100%;
    margin: auto;
    padding: 6rem 0 10rem;
    @media screen and (max-width: 1620px){
      padding: 4rem 0 10rem;
    }
    @media screen and (max-width: 991px){
      padding: 1rem 0 10rem;
    }
    @media print{
        padding:2rem 0 4rem;
        h1{
          font-size: 20px;
        }
        h2{
          font-size: 16px;
        }
      }
    p{
      line-height: 1.6;
      font-size: 19px;
      @media print{
        font-size: 14px;
      }
      @media screen and (max-width: 1620px) {
        font-size: 18px;
      }
      @media screen and (max-width: 1280px) {
        font-size: 16px;
      }
      @media screen and (max-width: 580px) {
        font-size: 14px;
      }
    }
    .w_5r{
      width: 5rem;
      @media screen and (max-width: 580px) {
        width: 7rem;
      }
    }
    .w_5r1{
      width: 5rem;
      @media screen and (max-width: 580px) {
        width: 3.5rem;
      }
    }
    .w_100{
      width: 100px;
      @media screen and (max-width: 580px) {
        width: 150px
      }
    }
    .contain_{
      margin: auto;
      width: 1100px;
      @media screen and (max-width: 1620px) {
        width: 1000px;
      }
      @media screen and (max-width: 1280px) {
        width: 100%;
      }
      display: block;

      h1{
        color: #2F1B11;
      }
      h1,p {
        font-family: 游ゴシック;
      }
      h2 {
        font-family: ＭＳ Ｐ明朝;
      }
      .w-res{
        width: 75%;
        @media screen and (max-width:991px) {
          width: 80%;
        }
      }
    }

    @media screen and (max-width: 991px) {
      margin-top: 80px;
      h1{
        font-size: 1.5rem;
      }
    }

    @media screen and (max-width: 767px) {
      .contain_ {
        .block-1 {
          flex-direction: column;

          img {
            width: 30%;
            margin: 0 auto;
          }

          >div {
            width: 100%;
            margin-top: 20px;
          }
        }
      }
    }

    @media screen and (max-width: 560px) {
      .contain_ {
        .block-1 {
          flex-direction: column;

          img {
            width: 50%;
            margin: 0 auto;
          }

          >div {
            width: 100%;
            margin-top: 20px;
          }
        }
      }
    }
  }
`