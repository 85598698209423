import styled from 'styled-components'

export const Wrapper = styled.div`
width: 100%;
@media screen and (max-width: 991px) {
  margin-top: 80px;
}
padding: 6rem 0 10rem;
@media screen and (max-width: 1620px){
padding: 4rem 0 10rem;
}
@media screen and (max-width: 991px){
  padding: 1rem 0 10rem;
  }
@media print{
  padding:2rem 0 10rem;
  h1{
    font-size: 20px;
  }
}
.container_video{
    width: 100%;
    .content{
      width: 100%;
      margin: auto;
      .text{
        width: 80%;
        @media print{
          width: 84%;
        }
        @media screen and (max-width: 1620px){
          width: 84%;
        }
        @media screen and (max-width: 1280px){
          width: 100%;
        }
        margin: auto;
      }
      .item{
          padding: 1rem;
          margin: 1rem;
          background-color: #f4f3ed;
          border-radius: 10px;
          transition: 1s all ease-in-out;
        }
        p{
        line-height: 1.6;
        font-size: 19px;
        @media print{
          font-size: 14px;
        }
        @media screen and (max-width: 1620px) {
          font-size: 18px;
        }
        @media screen and (max-width: 1280px) {
          font-size: 16px;
        }
        @media screen and (max-width: 580px) {
          font-size: 14px;
        }
      }
      button{
        width: 198px;
        height: 50px;
        border-radius: 10px;
        margin-top: 1rem;
        background: #A98260;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 24px;
        text-transform: uppercase;
        color: white;
        box-shadow: rgb(0 0 0 / 25%) 0px 14px 28px, rgb(0 0 0 / 22%) 0px 10px 10px;
      }
    }
    .image{
        text-align: -webkit-center;
      }

      @media screen and (max-width: 991px) {
        h1 {
        font-size: 1.5rem;
      }
      }
  
      @media screen and (max-width: 767px) {

        .content-box {
          flex-direction: column;

          .item {
            width: 100%;
            margin: 10px 0;
          }
        }
        .image{
          img{
            width: 100%;
          }
        }
      }
  }
 
`