import React from 'react'
import { Wrapper } from './styled'
import { NavLink } from "react-router-dom";
const ECMall = () => {
   return (
      <>
      <Wrapper>
         <div className='container_EC block '>
            <div className='content block'>
               <div className="timeline"> 
               <div className="timeline__event  animated fadeInUp delay-3s">
                  <div className="timeline__event__date">ＥＣモール商業店舗の賃貸<br className='brPrint'/></div>
                  <div className="timeline__event__content text-center" >
                     <div className="timeline__event__description">
                        <div className='cont text-justify'>
                           <p>　実店舗さながらのリアルで美しい店舗を賃貸いたします。また、アバター店員をご利用いただくこともできます。</p>
                           <p className='text-[#b70202] text-center font-bold'>　限定４００区画を賃貸いたします。</p>
                        </div>
                        <NavLink to='/ECMall'>
                           <button>詳細</button>
                        </NavLink>
                     </div>
                  </div>
               </div>
               </div>
            </div>
         </div>
      </Wrapper>
      </>
   )
}
export default ECMall
