import React from 'react'
import { Wrapper } from './styled'
const Providing = () => {
   return (
      <>
      <Wrapper>
         <div className='container_pro block'>
            <div className='content block'>
               <div className="timeline"> 
               <div className="timeline__event  animated fadeInUp delay-3s timeline__event--type1">
                  <div className="timeline__event__date">広告媒体の提供</div>
                  <div className="timeline__event__content " >
                     <div className="timeline__event__providing text-justify" id='id9'>
                        <p>　駅前や港、ＥＣモールの入口など、人通りの多いところに、広告用の動画対応サイネージを設置いたします。</p>
                        <p>　ＥＣモールに出店いただいている事業主様には、優先的に広告利用枠をご案内いたします。</p>
                        <p>　一般企業様からの広告掲載依頼も受託いたします。</p>
                     </div>
                  </div>
               </div>
            </div>
            </div>
         </div>
         
      </Wrapper>
      </>
   )
}
export default Providing
