import { FunctionComponent } from 'react'
import "keen-slider/keen-slider.min.css"
import useScreenWidth from 'src/Hook/useScreenWidth';
interface ModalNotiProps {
	event: any;
    title1: any;
    title2: any;
}

const ModalNoti: FunctionComponent<ModalNotiProps> = ({event, title1, title2}) => {
    const screenWidth = useScreenWidth()
	return (
        <div className="modal fade fixed top-0 left-0 w-full h-full bg-[#0000003b] outline-none overflow-x-hidden overflow-y-auto"
            id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className={`modal-dialog relative pt-[9rem] m-auto pointer-events-none ${screenWidth > 991 ? 'w-[40%]' : 'w-[80%]'}`}>
                <div
                className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
                <div
                    className="modal-header flex flex-shrink-0 justify-end p-4 border-b border-gray-200 rounded-t-md">
                    <img src='https://uxwing.com/wp-content/themes/uxwing/download/checkmark-cross/close-icon.png' className='w-[15px] cursor-pointer' alt=''onClick={event}/>
                </div>
                <div  className="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
                    <h5 className={`leading-normal text-gray-800 font-bold ${screenWidth > 991 ?'text-xl ' :'text-[14px]'}`} id="exampleModalLabel">{title1} <br></br>{title2}</h5>
                </div>
            </div>
          </div>
        </div>
	 );
}

export default ModalNoti;

