import styled from 'styled-components'

export const Wrapper = styled.div`
.container_event{
    width: 100%;
    padding-left: 1rem;
    text-align: center;
    @media screen and (max-width: 991px) {
      padding-right :0.5rem;
      padding-left: 0;
    }
    @media screen and (max-width: 580px){
      padding-right: 0;
    }
    .content{
      width: 100%;
      margin: auto;
      h2 {
      margin: 5%;
      text-align: center;
      font-size: 2rem;
      font-weight: 100;
    }
    .timeline {
      display: flex;
      flex-direction: column;
      position: relative;

      @media (max-width: 786px) {
        margin: 0;
      }

      &__event {
        margin-bottom: 20px;
        position: relative;
        display: block;
        border-radius: 6px;
        align-self: center;
        width: 100%;
        &__content {
          padding: 1rem 20px;
          height: 250px;
          background: #fff;
          width: calc(40vw - 84px);
          border-radius: 0 6px 6px 0;
          width: 100%;
          border-radius:  0 0px 0.75rem 0.75rem;
          @media screen and (max-width: 1280px){
            height: 220px;
          }
          @media screen and (max-width: 991px){
            height : 200px;
          }
          @media screen and (max-width: 787px){
            height : 220px;
           
            .timeline__event__description {
              a {
                display: block;
                margin-top: 20px;
              }
            }
          }
          @media print{
            padding: 1rem 5px;
            height: 180px;
          }
        }
        &__date {
          color: white;
          font-size: 19px;
          font-weight: 600;
          background: #733c15;
          display: flex;
          align-items: center;
          justify-content: center;
          white-space: nowrap;
          padding: 20px;
          border-radius: 0.75rem 0.75rem 0 0px;
          @media screen and (max-width: 1600px){
            font-size: 18px;
          }
          @media screen and (max-width: 1280px){
            font-size: 16px;
          }
         @media print{
            font-size: 16px;
            height: 90px;
            text-align: center;
            padding:10px 0;
          }
        }
        &__description {
          flex-basis: 60%;
          p{
            font-size: 17px;
            @media print{
              font-size: 14px;
            }
            @media screen and (max-width: 1620px){
            font-size: 17px;
            }
            @media screen and (max-width: 1280px){
              font-size: 14px;
            }
          }
        }
      }
    }

    @media (max-width: 786px) {
      .timeline__event {
        flex-direction: column;
        align-self: center;
        width: 100%;
        
        &__content {
          width: 100%;
        }

        &__icon {
          border-radius: 6px 6px 0 0;
          width: 100%;
          margin: 0;
          box-shadow: none;

          &:before,
          &:after {
            display: none;
          }
        }
        &__date {
          border-radius: 0;
          padding: 20px;
        }
    }
  }

    @media (max-width: 400px) {
      .timeline__event {
        .timeline__event__description {
          p {
            font-size: 13px;
          }
        }
      }
    }

    @keyframes fillLeft {
      100% {
        right: 100%;
      }
    }

    @keyframes fillTop {
      100% {
        top: 100%;
      }
    }

    @keyframes fillLeftOdd {
      100% {
        left: 100%;
      }
    }

    }
  }
`