import styled from 'styled-components'

export const Wrapper = styled.div`
width: 100%;
  button,p{
    line-height: 1.6;
    font-size: 20px;
    @media print{
      font-size: 14px;
    }
    @media screen and (max-width: 1620px) {
      font-size: 18px;
    }
    @media screen and (max-width: 1280px) {
      font-size: 16px;
    }
    @media screen and (max-width: 580px) {
      font-size: 14px;
    }
  }
  h2{
    font-size: 24px;
    color: #4472c4;
    font-weight: 700;
    @media screen and (max-width: 1620px) {
      font-size: 20px;
    }
    @media print{
      font-size: 16px;
    }
  }
  button{
      padding:10px 20px ;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      border-radius: 10px;
      text-transform: uppercase;
      color: #FFFFFF;
      box-shadow: rgb(0 0 0 / 25%) 0px 14px 28px, rgb(0 0 0 / 22%) 0px 10px 10px;
      margin: auto;
      display: flex;
      margin-bottom: 3rem;
      @media print{
          padding: 5px 20px;
        }
      }
  .h_diag02_45{
    height: 650px;
    @media screen and (max-width: 1620px){
      height: 550px;
    }
    @media screen and (max-width: 1280px){
      height: auto;
    }
    @media screen and (max-width: 580px){
      height: auto;
    }
  }
.container_our{
  width: 1100px;
  margin: auto;
  margin-bottom: 8rem;
  @media screen and (max-width: 1620px) {
    width: 1000px;
  }
  @media screen and (max-width: 1280px) {
    width: 100%;
  }
  .print{
    display: none;
  }
  @media print{
    width: 98%;
    margin-bottom: 20rem;
    .noprint{
        display: none;
      }
      .print{
        display: block;
      }
    .imgprint01{
      width: 40%;
    }
  }
  .ml_8{
    margin-left: 2rem;
    @media print{
      margin-left: 0;
    }
  }
  .section-1{
    width: 100%;
    margin: auto;
    display : block;
    padding: 6rem 0 3rem;
    @media screen and (max-width: 1620px){
      padding: 4rem 0 3rem;
    }
    @media screen and (max-width: 991px){
      padding: 1rem 0 3rem;
    }
    @media print{
      padding:2rem 0 ;
      h1{
        font-size: 20px;
      }
    }

  }
  .section-2{
    width: 100%;
    display : block; 
    padding-bottom: 2rem;
      .content {
        width: 100%;
      }
      
      .image {
        margin : auto;
        padding: 40px 0px;
      }
  }
  .imgBap{
    display: none;
    @media print{
      display: block;
    }
  }
  .section-3{
    width: 100%;
    display : block;
    padding-bottom: 2rem;
    @media print{
      display: none;
    }
      .content {
        width: 100%;
        margin: auto;
      }
      .offshore_list{   
        position: relative;
        display: flex;
        flex-wrap: wrap;
        width: 650px;
        margin: auto;
        margin-top: 1rem;
        margin-bottom: 1rem;
        .block-left {
          display: flex;
          align-items: center;
          position: relative;

        }
        .block-right {
          display: flex;
          align-items: center;
          position: relative;
          justify-content: right;
          justify-items: center;
          .offshore_list_item:before {
            content: ' ';
            height: 0;
            position: absolute;
            top: 44%;
            width: 0;
            z-index: 1;
            left: -10px;
            border: medium solid #fff;
            border-width: 5px 0 5px 10px;
            border-color: transparent transparent transparent #0a74ff;
          }
          .offshore_list_item:after {
            position: absolute;
            content: '';
            width: 44px;
            height: 1px;
            background: #0a74ff;
            top: 50%;
            left: -258px;
            margin: 0 auto;
            z-index: 1;
          }
        }
        .center-main-block{
          display: grid;
          align-items: center;
          position: relative;
          justify-items: center;
          .offshore_list_item {
          display: block;
          text-align: center;
          background: #3d90fd;
          padding: 10px 15px;
          text-align: -webkit-center;
          .item-content {
                color: #fff;
                text-align: center;
                margin-top: 10px;
            }
          }
          .offshore_list_item:before {
            border-width: 0;
            border-color: none !important;
          }    
          .offshore_list_item:after {
            background: none !important;
          }  
        }
        .center-main-block:before {
          position: absolute;
          content: '';
          width: 1px;
          height: 75%;
          background: #0a74ff;
          left: 0;
          margin: 0 auto;
        }
        .center-main-block:after {
          position: absolute;
          content: '';
          width: 1px;
          height: 75%;
          background: #0a74ff;
          right: 0;
        }
        li{
          width: 100%;
          padding: 10px;
        }
        .offshore_list_item{
            width: 160px;
            border: 0.74387px solid #E7F2FF;
            border-radius: 10px;
            text-align: center;
            background-color: #fff;
            padding:10px ;
            transition: all 0.3s ease-in-out;
            display: flex;
            align-items: center;
            box-shadow: 3px 3px 21px rgb(0 0 0 / 4%);
            position: relative;
            .item-thumb{
              min-width: 50px;
              width: 50px;
              height: 50px;
              display: flex;
              align-items: center;
              -ms-flex-pack: center;
              justify-content: center;
              background-color: #E7F2FF;
              border-radius: 50%;
              color: #1D9EFF;
              font-size: 28px;
              .icon-blockchain-technology{
                transition: 1s all ease-in-out;
                }
            }
            .item-content{
              color: #262626;
              font-weight: 500;
              font-size: 11px;
              margin-left: 10px;
              height: unset!important;
              text-align: left;
              text-transform: uppercase;
            }
          }
          [className^=icon-] {
              font-family: 'icomoon'!important;
              speak: none;
              font-style: normal;
              font-weight: 400;
              font-variant: normal;
              text-transform: none;
              line-height: 1;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
          }
          .offshore_list_item:before{
            content: ' ';
            height: 0;
            position: absolute;
            top: 45%;
            width: 0;
            z-index: 1;
            right: -10px;
            border: medium solid #fff;
            border-width: 5px 10px 5px 0;
            border-color: transparent #0a74ff transparent transparent;
          }
          .offshore_list_item::after{
            position: absolute;
            content: '';
            width: 40px;
            height: 1px;
            background: #0a74ff;
            top: 50%;
            right: -48px;
            margin: 0 auto;
            z-index: 1;
          }
          .offshore_list_item:hover{
            background-color: #1D9EFF;
          }
          .offshore_list_item:hover .item-thumb{
            color:white;
          }
          .offshore_list_item:hover .item-content {
            color:white;
          }
          .offshore_list_item:hover .icon-blockchain-technology {
            transform: scale(1.2);
            transition: 0.6s all ease-in-out;
          }
      }
      .offshore_list:before{
        position: absolute;
        content: '';
        width: 32.8%;
        height: 1px;
        background: #0a74ff;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        margin: 0 auto;
      }
  }

  @media screen and (max-width: 991px) {
    margin-top: 80px;
    h1{
        font-size: 1.5rem;
      }
    .section-1 {
      .block-1 {
        flex-direction: column;
      }
    }

    .section-3 {
      .offshore_list {
        width: 600px;

        li {
          padding: 10px;
        }

        .offshore_list_item {
          width: 130px;
          flex-direction: column;
          .item-thumb{
            min-width: 42px;
            width: 42px;
            height: 42px;
          }
          &:after {
            // width: 19px;
            // right: -29px;

            width: 53px;
            right: -62px;
          }
        }

        .center-main-block {
          &:before {
            top:50px;
            height: 74%;
          }

          &:after {
            height: 50.2%;
            margin-top: 6px;
          }
        }

        .block-right {
          .offshore_list_item {
            &:after {
              margin-top: -2px;
              // left: -196px;
              // width: 18px;

              width: 53px;
              left: -260px;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    .section-1 {
      padding-bottom: 0;
    }
    .block-2{
      flex-direction: column;
    }
    .section-2 {
      padding-bottom: 20px;

      .content {
        >div {
          &:first-child {
            >div {
              flex-direction: column-reverse;
              >div {
                width: 100%;

                &:last-child {
                  width: 50%;
                  margin: 0 auto;

                  img {
                    margin-top: 20px;
                  }
                }
              }
            }
          }

          &:last-child {
            .flex {
              img {
                height: 40px;
              }
            }
           
          }
        }
      }
    }

    .section-3 {
      padding-bottom: 20px;

      .offshore_list {
        width: 500px;
        
        .offshore_list_item {
          width: 130px;

          &:after {
            width: 19px;
            right: -29px;
          }
        }

        .center-main-block {
          &:before {
          }

          &:after {
          }
        }

        .block-right {
          .offshore_list_item {
            &:after {
              margin-top: -2px;
              left: -196px;
              width: 18px;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 650px) {
    .section-2 {
      .content {
        >div {
          &:first-child {
            >div {
              >div {

                &:last-child {
                  width: 70%;
                }
              }
            }
          }

        }
      }
    }
    .section-3 {
      .offshore-box {
        margin-left: 0;

        .offshore-container {
          overflow-x: auto;
          width: 450px;
          margin: 0 auto;

          .offshore_list {
            flex-wrap: nowrap;
          }
        }
      }
    }
  }

  @media screen and (max-width: 560px) {
    .section-2 {
      .content {
        >div {
          &:first-child {
            >div {
              >div {

                &:last-child {
                  width: 80%;
                }
              }
            }
          }

          &:last-child {
            >div {
              .block-1 {
                flex-direction: column;
                width: 100%;
                margin-left: 0;

                img {
                  transform: rotate(90deg);
                  height: 60px;
                  width: 60px;
                  margin: 0 auto;
                }
              }
            }
          }
        }
      }
    }

    .section-3 {
      .offshore-box {
        .offshore-container {
          width: 350px;
          margin-bottom: 20px;
        }
      }
    }
  }

  @media screen and (max-width: 450px) {
    .section-3 {
      .offshore-box {
        .offshore-container {
          width: 300px;
        }
      }
    }
    .leading-normal {
      span {
        display: block;
      }
    }
  }

  @media screen and (max-width: 365px) {
    .section-3 {
      .offshore-box {
        .offshore-container {
          width: 260px;
        }
      }
    }
  }

}
`