import React, {useRef, useEffect} from 'react'
import { Wrapper } from './styled'
import {Person_24} from '@assets'
import useScreenWidth from '../../../../Hook/useScreenWidth'
const OperationVideoPhoto = () => {
   const divOne = useRef<HTMLInputElement | null>(null);
   const scrolLWithUseRef = () => {
   divOne.current?.scrollIntoView({ block: "center", behavior: "smooth" });
   };
 
   useEffect(() => {
    scrolLWithUseRef()
   },[])

   const screenWidth = useScreenWidth()
   return (
      <>
      <Wrapper>
         <div className='container_video block relative'>
            <div className='content '>
               <h1 className="text-[30px] text-center leading-normal md:leading-relaxed font-bold mb-[3rem]" ref= {divOne}>ビデオ・フォト鑑賞ルームの運営</h1>
               <div className='text'>
                  <div className='flex mt-4'>
                     <p className='text-yellow-400'>■ </p>
                     <div className='block'>
                        <p>お友達やご家族が同じ部屋に集まって、一緒にＳＮＳを楽しめるビデオ・フォト鑑賞ルームを運営します。</p>
                     </div>
                  </div>
                  <div className='flex mt-8'>
                     <p className='text-yellow-400'>■ </p>
                     <div className='block'>
                        <p>素晴らしいコンテンツ投稿者には、チップを渡せる仕組みを導入します。</p>
                        <div className={`${ (screenWidth > 580) ? 'ml-4 mt-4' : 'ml-0 mt-2'}`}>
                           <div className='flex'>
                              <p className='text-[#f4b084]'>● </p>
                              <div className='block'>
                                 <p>コンテンツ投稿者は、著作権を自らが所有している２００MB以内、5分以内のコンテンツを投稿できます。</p>
                              </div>
                           </div>
                           <div className='flex'>
                              <p className='text-[#f4b084]'>● </p>
                              <div className='block'>
                                 <p>コンテンツ投稿者には、当社との間の業務委託契約に基づく報酬をお支払いいたします。</p>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className='flex mt-8'>
                     <p className='text-yellow-400'>■ </p>
                     <div className='block'>
                        <p>獲得した報酬は、「LINEPAY」や「PayPalペイアウト」を使って引き出すことができます。</p>
                     </div>
                  </div>
                  <div className='flex mt-8'>
                     <p className='text-yellow-400'>■ </p>
                     <div className='block'>
                        <p>プライバシー確保を行い、利用者やコンテンツ投稿者が安心して利用できる環境を提供します。</p>
                        <div className={`${ (screenWidth > 580) ? 'ml-4 mt-4' : 'ml-0 mt-2'}`}>
                           <div className='flex'>
                              <p className='text-[#f4b084]'>● </p>
                              <div className='block'>
                                 <p>「テキストチャット」と「ボイスチャット」のいずれも選択できます。</p>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div className='image mt-[3rem]'>
                  <img src={Person_24} alt='' className='w-[65%] rounded-xl' />
               </div>
            </div>
         </div>
       </Wrapper>
      </>
   )
}
export default OperationVideoPhoto
