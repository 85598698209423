import VideoNoiThat from '@assets/VideoNoithat.mp4'
const Video = () => {
	return (
        <div className="block items-center fixed z-[999] bg-black w-full h-full top-0 left-0">
			    <div className="flex h-full m-auto p-2 items-center justify-center top-0 left-0 modal ">
              <div className='block mx-4'>
                <video playsInline controls muted loop>
                  <source src={VideoNoiThat} type='video/mp4'></source>
                </video>
              </div>
            </div>
        </div>
	 );
}

export default Video;