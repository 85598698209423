import styled from 'styled-components'

export const Wrapper = styled.div`
width: 100%;
@media screen and (max-width: 991px) {
  margin-top: 80px;
}
.container_story{
    width: 100%;
    padding: 6rem 0 10rem;
    @media screen and (max-width: 1620px){
    padding: 4rem 0 10rem;
    }
    @media screen and (max-width: 991px){
    padding: 1rem 0 10rem;
    }
    @media print{
      padding:2rem 0 10rem;
      h1{
          font-size: 20px;
        }
    }
    .content{
      width: 65%;
        @media print{
          width: 80%;
        }
        @media screen and (max-width: 1620px){
          width:73%;
        }
        @media screen and (max-width: 1280px){
          width:82%;
        }
        @media screen and (max-width: 991px){
          width: 87%;
        }
        @media screen and (max-width: 767px){
          width: 100%;
        }
      margin: auto;
      @media print{
        width: 80%;
      }
      p{
        line-height: 1.6;
        font-size: 19px;
        @media print{
          font-size: 14px;
        }
        @media screen and (max-width: 1620px) {
          font-size: 18px;
        }
        @media screen and (max-width: 1280px) {
          font-size: 16px;
        }
        @media screen and (max-width: 580px) {
          font-size: 14px;
        }
      }
      button{
        width: 198px;
        height: 50px;
        border-radius: 10px;
        margin-top: 1rem;
        background: #A98260;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 24px;
        text-transform: uppercase;
        color: #FFFFFF;
        box-shadow: rgb(0 0 0 / 25%) 0px 14px 28px, rgb(0 0 0 / 22%) 0px 10px 10px;
      }
      .h_1{
        height: 300px;
        @media print {
          height: 200px;
        }
        @media screen and (max-width: 1620px) {
          height: 250px;
        }
        @media screen and (max-width: 991px) {
          height: 200px;
        }
        @media screen and (max-width: 767px) {
          height: 400px;
          .h_2{
            height: 200px;
            width: 50%;
            margin-bottom: 2rem;
          }
          .h_3{
            height: 200px;
            width: 80%;
            margin-bottom: 2rem;
          }
        }
        @media screen and (max-width: 580px) {
          .h_2{
            width: 80%;
          }
        }
      }
    }

    @media screen and (max-width: 991px) {

      h1{
        font-size: 1.5rem;
      }
    }

    @media screen and (max-width: 767px) {

      .img-box {
        flex-direction: column;

        >div {
          width: 100%;
          padding-right: 0;

          img {
            width: 100%;
            height: 350px;
          }
        }
      }
    }

    @media screen and (max-width: 600px) {

      .img-box {
        >div {
          img {
            height: 250px;
          }
        }
      }
    }

    @media screen and (max-width: 400px) {

      p {
        font-size: 13px;
      }

      .img-box {
        >div {
          img {
            height: 200px;
          }
        }
      }
    }
  }
 
`