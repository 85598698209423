import React from 'react'
import { Wrapper } from './styled'
import { Image_62 } from '@assets'
import useScreenWidth from '../../../Hook/useScreenWidth'

const ECMail = () => {
   const screenWidth = useScreenWidth()
   return (
      <>
      <Wrapper>
         <div className='container_EC block text-justify'>
            <div className='content'>
               <div className='mt-4'>
                  <div className='item'>
                     <div className={`flex ${ (screenWidth > 580) ? 'mb-[3rem]' : 'mb-[1rem]'}`}>
                        <h1 className="text-[24px] leading-normal md:leading-relaxed font-bold" id="id1">ＥＣモール商業店舗の商店主の皆様へ</h1>
                     </div>
                     <div className={`w-full ${ (screenWidth > 580) ? 'pl_6' : 'ml-0'}`}>
                        <p className=''>2023年2月1日 ： アートメタバースランドは、２０２４年７月１日開園を予定しております。</p>
                        <div className={`block mt-8 ${ (screenWidth > 580) ? 'pl-[3rem]' : 'ml-0'}`}>
                           <p className='font-bold text-lg md:text-1xl'>利用開始日</p>
                           <div className={`${ (screenWidth > 580) ? 'ml-6' : 'ml-0'}`}>
                              <p >ＥＣモール商業店舗のご利用開始は、開園日２０２４年７月１日からとなります。</p>
                           </div>                            
                        </div>
                        <div className={`block mt-8 ${ (screenWidth > 580) ? 'pl-[3rem]' : 'ml-0'}`}>
                           <p className=' font-bold text-lg md:text-1xl'>利用開始前の手続き</p>
                           <div className={`${ (screenWidth > 580) ? 'ml-6' : 'ml-0'}`}>
                              {screenWidth > 580 ? 
                                 <>
                                    <p >ＥＣモール商業店舗への出店をお申し込みになり、年一括賃貸料をお支払い頂いた商店主様には、</p>
                                    <p>２０２３年１２月から、商品データの入力、店舗内装セッティングを行って頂く予定です。</p>
                                 </>
                                 :<>
                                    <p>ＥＣモール商業店舗への出店をお申し込みになり、年一括賃貸料をお支払い頂いた商店主様には、２０２３年１２月から、商品データの入力、店舗内装セッティングを行って頂く予定です。</p>
                                 </>
                              }
                              <p>お知らせ事項が出てまいりましたら、その都度、当欄およびメールにてご連絡致します。</p>
                           </div>                            
                        </div>
                     </div>
                  </div>
               </div>
               <div >
               <img src={Image_62} alt=''/>
               </div> 
            </div>
         </div>
      </Wrapper>
      </>
   )
}
export default ECMail
