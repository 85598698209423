import React, {useEffect,useState}  from 'react'
import { Wrapper } from './styled'
import ModalImage from './modalImage'
import {Page1_04, Page2_01,PersonMale,Page2_02,Page2_03, DIAGRAM02_45, Store_22, Image_diagram01, Image_41, Page2_05,Table1_EC,Table2_EC,DIAGRAM01_45,Image_FlowRegisterEC,Image_InforCompanyEC} from '@assets'
import useScreenWidth from '../../../../Hook/useScreenWidth'
import ModalNoti from '@components/modalNoti';
import { ApplicationContactUsInformation } from '../../../../APIs';
//@ts-ignore
import { useForm } from "react-hook-form";
const ECMall = () => {
   const [isShowImage, setIsShowImage] = useState(false);
   const [isShowImage01, setIsShowImage01] = useState(false);
   const [isCheckRule, setIsCheckRule] = useState(false);
 
   useEffect(() => {
      window.scrollTo(0, 0)
   },[])

   const scrollFun = () => {
      document
        .querySelector(`#id2`)
        ?.scrollIntoView({ block: "center", behavior: "smooth" });
    }
    const screenWidth = useScreenWidth()
    const {
      register,
      handleSubmit,
      setValue,
      formState: { errors }
   } = useForm();
   const [notification, setNotification] = React.useState(false);

   const onSubmit = (data : any) => {
      ApplicationContactUsInformation({
         name : `${data.firstname}${data.lastname}`,
         email: data.email,
         data: `${data.nameCompany}<br/>
               ${data.firstname}${data.lastname} 様<br/>
               <br/>このたびは、弊社ＥＣモール商業店舗の賃貸借申込フォームをご請求いただき、まことにありがとうございます。
               <br/>以下に賃貸借申込フォームのＵＲＬを記載いたしますので、賃貸借申込フォームのＷｅｂページに遷移いただき、
               <br/>必要事項をご記入のうえ、賃貸借申込をいただきたくお願い申し上げます。
               <br/><br/>１．ＥＣモール商業店舗の賃貸借申込フォーム
               <br/>　　　<a href='https://artmetaverseland.co.jp/ECMall/CorporateContractsStore'>https://artmetaverseland.co.jp/ECMall/CorporateContractsStore</a>
               <br/> ________________________________________________________
               <br/><br/>アートメタバースランド株式会社
               <br/>〒176-0022　東京都練馬区向山３－１５－１９
               <br/>電話番号　０３－６６３６－７８９３
               <br/>メールによるお問い合わせは、弊社ホームページお問い合わせフォームからお願いいたします。
               <br/> ________________________________________________________
               `,
         cc: '',
         subject: 'ＥＣモール商業店舗の賃貸借申込フォームのご連絡および賃貸借申込フォーム入力のお願い',
         attachments: ''
      },setNotification)
      ApplicationContactUsInformation({
         name : `${data.firstname}${data.lastname}`,
         email: 'shop@artmetaverseland.co.jp',
         data: `${data.nameCompany}<br/>
               ${data.firstname}${data.lastname} 様<br/>
               <br/>このたびは、弊社ＥＣモール商業店舗の賃貸借申込フォームをご請求いただき、まことにありがとうございます。
               <br/>以下に賃貸借申込フォームのＵＲＬを記載いたしますので、賃貸借申込フォームのＷｅｂページに遷移いただき、
               <br/>必要事項をご記入のうえ、賃貸借申込をいただきたくお願い申し上げます。
               <br/><br/>１．ＥＣモール商業店舗の賃貸借申込フォーム
               <br/>　　　<a href='https://artmetaverseland.co.jp/ECMall/CorporateContractsStore'>https://artmetaverseland.co.jp/ECMall/CorporateContractsStore</a>
               <br/><br/>■記載内容：
               <br/>　　氏名：${data.firstname}${data.lastname}
               <br/>　　会社名 : ${data.nameCompany}
               <br/>　　メール：${data.email}
               <br/> ________________________________________________________
               <br/><br/>アートメタバースランド株式会社
               <br/>〒176-0022　東京都練馬区向山３－１５－１９
               <br/>電話番号　０３－６６３６－７８９３
               <br/>メールによるお問い合わせは、弊社ホームページお問い合わせフォームからお願いいたします。
               <br/> ________________________________________________________
               `,
         cc: '',
         subject: 'ＥＣモール商業店舗の賃貸借申込フォームのご連絡および賃貸借申込フォーム入力のお願い',
         attachments: ''
      },setNotification)
   }
   return (
      <>
      <Wrapper>
      <div className='container_con0 block'>
            <div className='section1'>
             <div className='content'>
               <h1 className="text-[30px] leading-normal md:leading-relaxed mb-2 font-bold text-center" >ＥＣモール商業店舗の賃貸</h1>
               <div className='flex mt_2r block-1'>
                  <div className='block w-full'>
                     <div className='flex'>
                        <p className='text-yellow-400'>■ </p>
                        <div className='block'>
                           {screenWidth > 580 ? 
                           <>
                             <p>アートメタバースランドの中心部から放射線状に広がる道路に沿って、 実店舗さながらの美しい店舗が立ち並ぶ</p>
                              <p>商業エリアがあります。ここに立地する店舗<span className='text-[#c00000]' >限定４００区画</span>を、開園前に 特別割引料金でテナント募集いたします。</p>
                           </> 
                           : 
                           <>
                             <p>アートメタバースランドの中心部から放射線状に広がる道路に沿って、 実店舗さながらの美しい店舗が立ち並ぶ商業エリアがあります。ここに立地する店舗<span className='text-[#c00000]' >限定４００区画</span>を、開園前に 特別割引料金でテナント募集いたします。</p>
                           </>}
                        </div>
                     </div>
                     <div className='flex mt-4'>
                        <p className='text-yellow-400'>■ </p>
                        <p>店舗の営業を開始いただける時期は、アートメタバースランドが開園する２０２４年７月からとなります。</p>
                     </div>
                     <div className='flex mt-4'>
                        <p className='text-yellow-400'>■ </p>
                        <div className='block'>
                           {screenWidth > 580 ? 
                           <>
                              <p>店舗内にアバター店員を配置いただくことができます。アバター店員からの丁寧な接客や詳しい商品説明により、</p>
                              <p>貴社のブランド価値や優秀な商材に対するお客様の認知を高め、購入率やリピート率を向上させることができます。</p>
                           </> 
                           : 
                           <>
                              <p>店舗内にアバター店員を配置いただくことができます。アバター店員からの丁寧な接客や詳しい商品説明により、貴社のブランド価値や優秀な商材に対するお客様の認知を高め、購入率やリピート率を向上させることができます。</p>
                           </>}
                        </div>
                     </div>
                     <div className='flex mt-4'>
                        <p className='text-yellow-400'>■ </p>
                        <div className='block'>
                           <p>自律的にお客様対応が行えるＡＩアバター店員（特許出願中）を賃貸いたします。</p>
                           <p>事前に学習させることで詳しい商品説明を行うことが可能です。また、大幅な人件費の抑制が実現できます。</p>
                        </div>
                     </div>
                     <div className='flex mt-4'>
                        <p className='text-yellow-400'>■ </p>
                        <div className='block'>
                        {screenWidth > 580 ? 
                           <>
                              <p>当社は、アートメタバースランドに来園するお客様がショッピングを楽しんでいただけるよう、多様な業種の</p>
                              <p>事業主様に出店いただくことを目指しております。この為、一部出店調整をさせていただくことがございます。</p>
                           </> 
                           : 
                           <>
                              <p>当社は、アートメタバースランドに来園するお客様がショッピングを楽しんでいただけるよう、多様な業種の事業主様に出店いただくことを目指しております。この為、一部出店調整をさせていただくことがございます。</p>
                           </>}
                        </div>
                     </div>
                  </div>
                  {screenWidth > 991 &&(
                  <div className='block absolute ml_bt btNoPrint' >
                     <div className='text-center'>
                        <button onClick={() => scrollFun()} className="bg-[#c55a11] "><p className='my-2 mx-[10.2px]'>ＥＣモール商業店舗<br/>賃貸借を申し込む</p></button>
                     </div>
                     <div className='text-center'>
                        <a href="../file/EXPOチラシ原稿　ＥＣモール商業店舗.pdf" download target='_blank'>
                           <button className="bg-white text_black"><p className='my-2'>ＥＣモール商業店舗<br/>広告資料ダウンロード</p></button>
                        </a>
                     </div>
                  </div>
                  )}
               </div>
               {screenWidth < 992 &&(
                  <div className='text-center mt_2r'>
                     <a href="../file/EXPOチラシ原稿　ＥＣモール商業店舗.pdf" download target='_blank'>
                        <button className="bg-white text_black">ＥＣモール商業店舗広告資料ダウンロード</button>
                     </a>
                  </div>
               )}
               <div className='mt_5r block-2'>
                  <h2 className="leading-normal md:leading-relaxed mb-2 font-bold ">【 当社が誘致想定しているＥＣモールの業種ごと出展企業数 】</h2>
                  <div className='flex w-[80%] number_company m-auto mt-4'>
                     <div className='w-1/2 py-4'>
                        <div className='noprint'>
                           <img src={Page2_01} alt=''/>
                        </div>
                        <div className='print'>
                           <img src={Page2_01} alt=''/>
                        </div>
                     </div>
                     <div className='w-1/2 m-auto justify-end flex'>
                        <img src={PersonMale} alt='' className='rounded-xl imgprint'/>
                     </div>
                  </div>
               </div>
               <div className='mt_3r'>
                  <h2 className="leading-normal md:leading-relaxed mb-2 font-bold ">アートメタバースランド全体地図<br/>(２０２４年７月開園時の完成予想図)</h2>
                  <div className='pt-8 text-center'>
                     <img src={Page1_04} alt='' className='m-auto img01Print' />
                  </div>
                  {screenWidth > 991 &&(
                  <div className='text-center mt_2r'>
                     <button  onClick={() => setIsShowImage(!isShowImage)} className="bg-[#a98260]">クリックすると拡大</button>
                  </div>
                  )}
               </div>
               <div className='mt_5r m-auto block-5'>
                  <h2 className="leading-normal md:leading-relaxed mb-2 font-bold">ＥＣモール商業店舗の賃貸区分と賃貸料について</h2>
                  <div className='noprint'>
                     <img src={Page2_05} alt='' className={`mt-[1.5rem] m-auto ${ (screenWidth > 580) ? 'w-auto' : 'w-[95%]'}`} />
                  </div>
                  <div className='print'>
                     <img src={Page2_05} alt='' className={`mt-[1.5rem] img03Print ${ (screenWidth > 580) ? 'w-auto' : 'w-[95%]'}`} />
                  </div>
                  <p className='text-center'>※上記の賃貸料以外、敷金、礼金、更新料等一切かかりません。初期設定費用等もありません。</p>
                  <div className='text-center mt_3r'>
                     <button onClick={() => scrollFun()} className="bg-[#c55a11]">ＥＣモール商業店舗の賃貸借を申し込む</button>
                  </div>
               </div>
               <div className='mt_3r m-auto block-5'>
                  <img src={Page2_02} alt='' className={`rounded-xl h_4 ${ (screenWidth > 580) ? 'w-full' : 'w-[95%]'}`}/>
                  <h2 className="leading-normal md:leading-relaxed mb-2 font-bold mt_5r">既存の商業店舗と当社ECモール商業店舗との賃貸料の比較</h2>
                  <div className='noprint'>
                     <img src={Table1_EC} alt='' className='mt-4 w_table' />
                  </div>
                  <div className='print'>
                     <img src={Table1_EC} alt='' className='mt-4 w_table img03Print' />
                  </div>
                  <p className="text-1xl md:text-1xl leading-normal md:leading-relaxed mb-2 font-bold mt_2r ml-2">店舗相場TOWN記事</p>
                  <a href='https://souba.builds.jp/tenant/13' className='underline text-[#4472c4] ml-2'>東京都店舗賃料相場 - 店舗相場TOWN (builds.jp)</a>
                  <img src={Page2_03} alt='' className={`mt_3r rounded-xl h_4  ${ (screenWidth > 580) ? 'w-full' : 'w-[95%]'}`} />
                  <h2 className="leading-normal md:leading-relaxed mb-2 font-bold mt_5r">ＥＣモール商業店舗の仕様</h2>
                  <div className={`${ (screenWidth > 580) ? 'ml-4 mt-4' : 'ml-2 mt-2'}`}>
                        <div className='flex'>
                           <p className='text-yellow-400'>■ </p>
                           <div className='block'>
                              <p>利用可能デバイス　　パソコンＷＥＢ、スマートフォン、ＶＲゴーグル</p>
                           </div>
                        </div>
                        <div className='flex mt-4 flex_item'>
                           <div className='flex'>
                              <p className='text-yellow-400'>■ </p>
                              <p>賃貸区分</p>
                           </div>
                           <img src={Table2_EC} alt='' className='w_table-01 ml-4 mb-4' />
                        </div>
                        <div className='flex mt-4'>
                           <p className='text-yellow-400'>■ </p>
                           <div className='block'>
                              <p> すべての区画に建物が付帯します。賃貸するフロアは１階のみです。</p>
                              {screenWidth > 580 ? 
                                 <>
                                    <p className='mt-2 ml-4'>※別途費用がかかりますが、お客様オリジナルの建物、内装をオーダーメイド製作することも</p>
                                    <p>　承ります。ご相談ください。</p>
                                 </> 
                                 : 
                                 <>
                                    <p>※別途費用がかかりますが、お客様オリジナルの建物、内装をオーダーメイド製作することも承ります。ご相談ください。</p>
                                 </>}
                           </div>
                        </div>
                        <div className='flex mt-4'>
                           <p className='text-yellow-400'>■ </p>
                           <div className='block'>
                              <p>文字チャットに加えて、ボイスチャットをご利用いただくことができます。</p>
                           </div>
                        </div>
                        <div className='flex mt-4'>
                           <p className='text-yellow-400'>■ </p>
                           <div className='block'>
                              <p>商品説明用ツールとして画像掲示板に加えて動画掲示版もご利用いただくことができます。</p>
                           </div>
                        </div>
                        <div className='flex mt-4'>
                           <p className='text-yellow-400'>■ </p>
                           <div className='block'>
                              <p>ランド内に設置される広告用大型サイネージへのテナント優先広告枠をご利用いただけます。</p>
                              {screenWidth > 580 ? 
                              <>
                                 <p className='mt-4 ml-4 font-bold'>※ランド開園前にテナント契約いただいたすべての事業主様に</p>
                                 <p className='font-bold ml-4'>サイネージ広告枠（動画掲載１５秒／枠）を、開園から３か月間、毎日３枠プレゼントします！</p>
                              </>
                              :<>
                                <p className='font-bold mt-4'>※ランド開園前にテナント契約いただいたすべての事業主様にサイネージ広告枠（動画掲載１５秒／枠）を、開園から３か月間、毎日３枠プレゼントします！</p>
                              </>}
                           </div>
                        </div>
                        <h2 className="leading-normal md:leading-relaxed mb-2 font-bold mt-16 text_black">ご留意頂きたいこと</h2>
                        <div className={`${ (screenWidth > 580) ? 'ml-4 mt-4' : 'ml-2 mt-2'}`}>
                           <div className='flex'>
                              <p className='text-[#f4b084]'>● </p>
                              <div className='block'>
                                 <p>メタバース不動産は、当社のメタバース空間上で不動産として利用できるデータ資産です。</p>
                              </div>
                           </div>
                           <div className={`flex ${ (screenWidth > 580) ? 'mt-4' : 'mt-2'}`}>
                              <p className='text-[#f4b084]'>● </p>
                              <div className='block'>
                                 <p>１区画の広さ100㎡：メタバース空間上のデータ配置に応じた面積であり、現実の面積ではありません。</p>
                              </div>
                           </div>
                        </div>
                  </div>
                  <img src={Store_22} alt='' className={`mt_3r rounded-xl m-auto ${ (screenWidth > 580) ? 'w-[80%]' : 'w-[95%]'}`} />
                  <h2 className="leading-normal md:leading-relaxed mb-2 font-bold mt_5r">ＥＣモール商業店舗の特徴</h2>
                  <div className={`${ (screenWidth > 580) ? 'ml-4 mt-4' : 'ml-2 mt-2'}`}>
                        <div className='flex'>
                           <p className='text-yellow-400'>■ </p>
                           <div className='block'>
                              <p>希少性が高い。今回の提供区画数は<span className='text-[#c00000]' >限定４００区画</span>のみです。</p>
                           </div>
                        </div>
                        <div className='flex mt-4'>
                           <p className='text-yellow-400'>■ </p>
                           <p>地理的な商圏に左右されません。全国のお客様に来店いただくことができます。</p>
                        </div>
                        <div className='flex mt-4'>
                           <p className='text-yellow-400'>■ </p>
                           <div className='block'>
                              <p>アバター店員による丁寧な接客や詳しい商品説明が可能です。</p>
                           </div>
                        </div>
                        <div className='flex mt-4'>
                           <p className='text-yellow-400'>■ </p>
                           <div className='block'>
                              <p> ＡＩアバター店員（特許出願中）を賃貸いたします。人件費の抑制が可能です。</p>
                           </div>
                        </div>
                        <div className='flex mt-4'>
                           <p className='text-yellow-400'>■ </p>
                           <div className='block'>
                              <p>自社ＥＣサイトヘの連携、顧客誘導について、当社システムは許容します。</p>
                           </div>
                        </div>
                        <div className='flex mt-4'>
                           <p className='text-yellow-400'>■ </p>
                           <div className='block'>
                              <p>アートメタバースランド来園者から多くの来店を見込むことができます。</p>
                              <p>ＥＣモール商店に来店したお客様には、ランドで利用できるコインを当社からプレゼントします。</p>
                              <div className='ml-4 mt-4'>
                                 <div className='flex'>
                                    <p className='text-[#f4b084]'>● </p>
                                    <div className='block'>
                                       <p>イベント、セミナーに参加されるお客様</p>
                                    </div>
                                 </div>
                                 <div className='flex'>
                                    <p className='text-[#f4b084]'>● </p>
                                    <div className='block'>
                                       <p>お話しサロン、ビデオ・フォト鑑賞ルーム、結婚相談所を利用されるお客様</p>
                                    </div>
                                 </div>
                                 <div className='flex'>
                                    <p className='text-[#f4b084]'>● </p>
                                    <div className='block'>
                                       <p>収益獲得（アルバイト、チップ）に来園されるお客様</p>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className='flex mt-4'>
                           <p className='text-yellow-400'>■ </p>
                           <div className='block'>
                              <p>万全な開発リソースに支えられた先端ＩＴサービスを利用いただけます。</p>
                              {screenWidth > 580 ? 
                                 <>
                                    <p>当社がシステム開発全行程を包括委託しているＢＡＰ社は、東京大学、日立製作所、ＮＴＴデータ等から</p>
                                    <p>最先端システム開発を受注し、ベトナム国家ＩＴ企業トップ１０でも二部門で表彰されている企業です。</p>
                                 </> 
                                 : 
                                 <>
                                    <p>当社がシステム開発全行程を包括委託しているＢＡＰ社は、東京大学、日立製作所、ＮＴＴデータ等から最先端システム開発を受注し、ベトナム国家ＩＴ企業トップ１０でも二部門で表彰されている企業です。</p>
                                 </>
                              }
                           </div>
                        </div>
                  </div>
               </div>
               <div className='text-center mt-[1rem]'>
                  <button onClick={() => scrollFun()} className="bg-[#c55a11]">ＥＣモール商業店舗の賃貸借を申し込む</button>
               </div>
               <div className='mt_3r m-auto block-5'>
                  <h2 className="leading-normal md:leading-relaxed mb-2 font-bold mt_5r">ＥＣモール商業店舗が解決できる課題とご提供できる価値</h2>
                  <div className={`${ (screenWidth > 580) ? 'ml-4 mt-4' : 'ml-2 mt-2'}`}>
                     <div className='flex'>
                        <p className='text-yellow-400'>■ </p>
                        <div className='block'>
                           <p>ＥＣモール出店者様の課題意識（グラフの ＡゾーンとＢゾーンの事業主様）</p>
                           <div className='ml-4'>
                              <p className='mt-4'>【Ａゾーンの事業主様】　購入したお客様がリピートしてくれない。ブランドを認知してほしい。</p>
                              <p>【Ｂゾーンの事業主様】　検索エンジンで下位表示され来店してくれない。商品の良さを説明したい。</p>
                           </div>
                        </div>
                     </div>
                     <div className='flex mt-4'>
                        <p className='text-yellow-400'>■ </p>
                        <div className='block'>
                           <p>出店者様にご提供できる価値</p>
                           <div className='ml-4'>
                              <p className='mt-4'>リアルで美しい店舗内で、アバター店員による丁寧な接客や詳しい商品説明を行うことができます。</p>
                              <p>貴社のブランド価値と優秀な商材に対するお客様の認知を高め、売上アップとリピート率向上につなげます。</p>
                           </div>
                        </div>
                     </div>
                     <div className='noprint'>
                        <img src={Image_diagram01} alt='' className='m-auto my-[1rem] w-[85%] img_diag01 ' />
                     </div>
                     <div className='print'>
                        <img src={Image_diagram01} alt='' className='m-auto my-[1rem] w-[85%] img_diag01 ' />
                     </div>
                     <div className='flex mt-4'>
                        <p className='text-yellow-400'>■ </p>
                        <div className='block'>
                           <p>ＥＣモールでお買い物をするお客様の課題意識</p>
                           <div className='ml-4'>
                              <p className='mt-4'>価格だけでなく、品質やアフターサービスも含めたトータルの価値を重視したい。</p>
                              <p>買い物を家族や友達と楽しみたい、行動体験を大切にしたい。</p>
                           </div>
                        </div>
                     </div>
                     <div className='flex mt-4'>
                        <p className='text-yellow-400'>■ </p>
                        <div className='block'>
                           <p>ＥＣモールでお買い物をするお客様にご提供できる価値</p>
                           <div className='ml-4'>
                              <p className='mt-4'>リアルで美しい店舗内で、ご家族やお友達との楽しいお買物やコミュニケーションをお楽しみいただけます。</p>
                              <p>アバター店員の丁寧な商品説明により、高品質で価値ある商品に出会うことができます。</p>
                           </div>
                        </div>
                     </div>
                  </div>
                  <img src={Image_41} alt='' className={`rounded-xl py-[3rem] img02Print m-auto ${ (screenWidth > 580) ? 'w-[80%]' : 'w-[95%]'}`} />
                  <h2 className="leading-normal md:leading-relaxed mb-2 font-bold mt_2r text-center">当社メタバースで実現できること（既存のＥＣモール、ＳＮＳとの機能比較）</h2>
                  <img src={DIAGRAM01_45} alt='' className={`rounded-xl py-[1rem] img02Print m-auto ${ (screenWidth > 580) ? 'w-[100%]' : 'w-[95%]'}`} />
               </div>
               <div className='mb-8 block-11'>
                  <h2 className="leading-normal md:leading-relaxed mb-2 font-bold mt_3r text-center">当社メタバースの優位性　(同業メタバースとのサービス比較分析)</h2>
                  <p className={` mt-4 text_16 ${ (screenWidth > 580) ? 'ml-6' : 'ml-2'}`}>当社メタバースは、各サービスが相乗的に好循環を生み出しながら顧客満足を向上させていくように統合的な設計がなされています。</p>
                  <p className={`text_16 ${ (screenWidth > 580) ? 'ml-6' : 'ml-2'}`}>その為、単一機能から徐々にサービスを発展・拡張させてきた他社メタバースと比較すると、機能、質の両面で優位性を獲得しています。</p>
                  <img src={DIAGRAM02_45} alt='' className={`mt-[1.5rem] img02Print h_diag02_45 ${ (screenWidth > 580) ? 'w-auto' : 'w-[93%]'}`} />
                  <h4 className='mt-2 text_16 text-right'>２０２２年4月　当社調査</h4>
                  {screenWidth > 991 &&(
                  <div className='text-center mt-4'>
                     <button  onClick={() => setIsShowImage01(!isShowImage01)} className="bg-[#a98260]">クリックすると拡大</button>
                  </div>
                  )}
               </div>
               <div className='text-center my-[3rem]' >
                  <h2 className="leading-normal md:leading-relaxed mb-2 font-bold mt_2r text-[#4472c4] " id='id2'>ECモール商業店舗の賃貸借の申込フォームを以下よりご請求ください。</h2>
               </div>
            </div>
         </div>
         {isShowImage && (
            <ModalImage 
               event={() => setIsShowImage(false)}
               url={Page1_04}
               width="w-[72%]"
               />
            )}
         {isShowImage01 && (
            <ModalImage 
               event={() => setIsShowImage01(false)}
               url={DIAGRAM02_45}
               width="w-[90%]"
            />
         )}
         </div>
         <div className='start-selling-contact block'>
               <div className='item_fi flex'>
                  <div className='pl-1 block pb-4 w-full'>
                     <div className='block p-2 container_ '>
                        <div className='ml-4'>
                           <form onSubmit={handleSubmit(onSubmit)}>
                              <div className="grid">
                                 <label className='text mt-5 font-bold'>企業名<span className='font-bold text-red-500 font_small'>「必須」</span></label>
                                 <input className='mt-1' type="text"
                                    {...register("nameCompany", {
                                       required: true
                                    })} 
                                    onChange={e => setValue("nameCompany", e.target.value.trimStart())}
                                 />
                              </div>
                              <div className="grid">
                                    <label className='text mt_6 font-bold'>氏名</label>
                                 </div>
                              <div className='flex w-[96%]'>
                                 <div className="grid w-1/2">
                                    <label className='text mt-2 font-bold'>姓<span className='font-bold text-red-500 font_small'>「必須」</span></label>
                                    <input className='mt-1' type="text" 
                                       {...register("firstname", {
                                          required: true
                                       })} 
                                       onChange={e => setValue("firstname", e.target.value.trimStart())}
                                    />
                                 </div>
                                 <div className="grid w-1/2">
                                    <label className='text mt-2 font-bold'>名<span className='font-bold text-red-500 font_small'>「必須」</span></label>
                                    <input className='mt-1' type="text" 
                                       {...register("lastname", {
                                          required: true
                                       })}
                                       onChange={e => setValue("lastname", e.target.value.trimStart())} 
                                    />
                                 </div>
                              </div>
                              <div className="grid">
                                 <label className='text mt_6 font-bold'>メールアドレス<span className='font-bold text-red-500 font_small'>「必須」</span></label>
                                 <input className='mt-1' type="text" 
                                    {...register("email", {
                                       required: true,
                                       pattern:{
                                          value:
                                             /^[A-Z0-9._+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                             message: "無効なメールアドレス",
                                       }
                                    })}
                                    onChange={e => setValue("email", e.target.value.trimStart())}
                                 />
                                 {errors.email?.message && (
                                    <p className="text-red-500 text_16">{errors.email?.message?.toString()}</p>
                                 )}
                              </div>
                              <p className='text_16 text-center text-[#0563c1] mt_6 w-[92%]'> <a href='../file/Privacy.pdf' target='_blank' >プライバシーポリシーをお読みいただき、同意の上、ご請求ください。</a></p>
                              <div className='flex justify-center w-[92%] radio-box'>
                                 <input className='mt-1' type="radio"
                                    {...register("policy",{
                                       required: true
                                    })}
                                    onChange={() => setIsCheckRule(!isCheckRule)}
                                 />
                                 <p className='text mt-2 ml-2 font-bold'>プライバシーポリシーに同意する</p>
                              </div>
                              {isCheckRule ?
                                 <>
                                 <div className="grid mt-3 w-[92%]">
                                    <label></label>
                                    <button type="submit" className='m-auto'>賃貸借申込フォームを請求する</button>
                                 </div>
                                 </>
                                 :
                                 <>
                                 <div className="grid mt-3 w-[92%]">
                                    <label></label>
                                    <button type="submit" className='m-auto opacity-70 ' disabled >賃貸借申込フォームを請求する</button>
                                 </div>
                                 </>
                                 }
                           </form>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <h2 className="leading-normal md:leading-relaxed mb-2 text-center text_black font-bold mt_2r">ＥＣモール商業店舗の賃貸借に関するお手続きの流れ</h2>
            <img src={Image_FlowRegisterEC} alt='' className='m-auto' />
            <div className='w_infor'>
               <h2 className="leading-normal md:leading-relaxed mb-2 text_black font-bold mt-[4rem]">特定商取引法に準じた表示事項</h2>
               <img src={Image_InforCompanyEC} alt='' className='mb-[10rem] w-full' />
            </div>
         {notification && (
            <ModalNoti 
               event={() => {setNotification(false);
                  window.location.reload();
               }
               }
               title1='メールの送信に成功しました。'
               title2=''
            />
         )}
      </Wrapper>
      </>
   )
}
export default ECMall
