/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next"
import styled from 'styled-components'
import { Footer, Header } from '@components'
import HeaderMobile from '@components/header/header-mobile'
import Menu from '@components/header/menu'

import { PRIMARY } from '@assets/colors';
import { BG_EC } from '@assets'

import useScreenWidth from '../Hook/useScreenWidth'

const MENU_WIDTH = '300px'

const Wrapper: any = styled.div`
  position: absolute;
  width: 100%;
  height: 600px;
  left: 0px;
  top: 0px;
  display: block;
  background: url(${BG_EC});
  background-position:center;
  background-repeat : no-repeat;
  background-size : cover;
 
  @media screen and (max-width: 991px) {
    height: 500px;
  }
  @media print{
    height: 400px;
  }
  &.open {
    width: 100%;
    .header-mobile-container {
      margin-left: 240px;
      -moz-animation: fadeOut 1.6s ease;
      -webkit-animation: fadeOut 1.6s ease;
      animation: fadeOut 1.6s ease;
      transition: margin 100ms ease;
    }
    #intro {
      margin-left: ${MENU_WIDTH};

      .bg_slide {
        left:${MENU_WIDTH};
       
      }
    }
  }
`
const Main = styled.main`
  width:1100px;
  padding: 0;
  margin: auto;
  display: flex;
  justify-content: center;
  margin-top: 30rem;
  @media screen and (max-width: 580px){
    margin-top :25rem;
    }
  @media print{
    margin-top :18rem;
  }
  @media screen and (max-width: 1280px){
    width: 70%;
  }
  @media print {
    width: 100%;
    background: #f4f3ed;
    padding-top: 4rem;
  }
  &.open {
    width: 100%;
    .header-mobile-container {
      margin-left: 240px;
      -moz-animation: fadeOut 1.6s ease;
      -webkit-animation: fadeOut 1.6s ease;
      animation: fadeOut 1.6s ease;
      transition: margin 100ms ease;
    }
    #intro {
      margin-left: ${MENU_WIDTH};

      .bg_slide {
        left:${MENU_WIDTH};
       
      }
    }
  }

  @media screen and (max-width: 991px){
    width: 100%;
    flex-direction: column;

    #intro, .modal {
      width: 100%;
    }

    #intro {
      .container-service {
        margin-top: 50px; 
      }
    }

    .modal {
      margin-bottom: 50px;

      header {
        position: initial;
        display: flex;

        >div {
          margin-bottom: 30px;
          flex: 1;
          padding: 10px;

          &:first-child {
            img {
              margin-top: 25px !important;
            }
          }
          &:last-child {
            img {
              margin-top: 5px !important;
            }
          }
        }

        a {
          display: block;

          .item {
            height: auto;
            transform: scale(1);
            .title {
              font-size: 15px;
              padding-bottom: 10px;
            }
            p {
              font-size: 14px;
              &:last-child {

              }
            }
            img {
              position: initial !important;
              width: 100%;
              margin: 0;
              // height: auto;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    .modal {
      header {
        a {
          .item {
            .title {
              font-size: 14px;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 580px) {
    .modal {
      header {
        flex-direction: column;
        >div {
          &:first-child, &:last-child {
            img {
              margin-top: 0 !important;
              height: 220px;
            }
          }
        }
        
        a {
          .item {
            .title {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
  
`
const Content = styled.div`
  width: 100%;
  overflow: hidden;
  display: flex;

  .slide-bar {
    -moz-animation: fadeOut 1.6s ease;
    -webkit-animation: fadeOut 1.6s ease;
    animation: fadeOut 1.6s ease;
    transition: margin 100ms ease;
    margin-left: -${MENU_WIDTH};
    position: fixed;
    height: 100%;
    overflow: hidden;
    padding: 15px;
    z-index: 1;
    top: 0;

    &.open {
      width: ${MENU_WIDTH};
      margin-left: 0;
      background: ${PRIMARY};
    } 
    
    &.close {
      display: none;
    }

    header {
      flex-direction: column;
    }
  }
`

const ECLayout = ({ children }: any) => {
  const [language, setLanguage] = useState<any>(false);
  const [isShowMenu, setIsShowMenu] = useState(false)

  const { i18n } = useTranslation();

  const screenWidth = useScreenWidth()

  useEffect(() => {
    if (i18n.language === "en") {
      setLanguage(true)
    }
    if (i18n.language === "jp") {
      setLanguage(false)
    }
  }, [i18n.language])

  const onSetShowMenu = () => {
    setIsShowMenu(!isShowMenu)
  }

  return (
    <Wrapper >
      <div className='container_'>
        {screenWidth > 991 && (
          <Header language={language} setLanguage={setLanguage}/>
        )}
         <Main className={`${isShowMenu ? 'open' : 'close'}`}>
          <Content>
            {screenWidth <= 991 && (
              <>
                <HeaderMobile
                  onClick={onSetShowMenu}
                  isOpen={isShowMenu}
                />
                <div className={`slide-bar ${isShowMenu ? 'open' : 'close'}`}>
                  <Menu language={language} onScrollMenu={onSetShowMenu} />
                </div>
              </>
            )}
            {children}
          </Content>
        </Main>
        <Footer language={language} setLanguage={setLanguage} />
      </div>
    </Wrapper>
  )
}

export default ECLayout
