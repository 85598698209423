import React from 'react'
import { Wrapper } from './styled'
import { NavLink } from "react-router-dom";
const OperationStory = () => {
   return (
      <>
      <Wrapper>
         <div className='container_story block'>
            <div className='content block'>
               <div className="timeline"> 
               <div className="timeline__event  animated fadeInUp delay-3s">
                  <div className="timeline__event__date">お話しサロンの運営</div>
                  <div className="timeline__event__content text-center" >
                     <div className="timeline__event__description">
                        <div className='cont text-justify'>
                           <p>　話しをしたいけれど、誰か話を聞いてくれないかなという時に、話し相手を見つけることができるサロンを運営します。</p>
                           <p>　お話しを聞いてくれた人には、当社が報酬をお支払いします。</p>
                        </div>
                        <NavLink to='/ServiceProduct/OperationStory'>
                           <button>詳細</button>
                        </NavLink>
                     </div>
                  </div>
               </div>
               </div>
            </div>
         </div>
       </Wrapper>
      </>
   )
}
export default OperationStory
