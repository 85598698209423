import styled from 'styled-components'

export const Wrapper = styled.div`

 .title_{
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 35px;
  }
  .table1{
    th, td{
       border : 0.1px solid black;
       padding : 5px 5px;
       font-size: 12px;
       text-align: center;
     }
  }
 
  .table2{
    th, td{
       border : 0.1px solid black;
       padding : 5px 7px;
       font-size: 12px;
       text-align: center;
     }
  }
  .manage{
    border-left : 1px solid #2f528f;
    border-right : 1px solid #2f528f;
    border-radius: 10px;
    height: 50px;
    margin: 0 10px;
    text-align: center;
    padding: 14px;
  }
  .optimistic{
    border : 0.2px solid #2f528f;
    border-radius: 10px;
    height: 90px;
    margin-left:3px;
    margin-right:3px;
    text-align: center;
    padding: 7px 1px;
    background :#70ad47;
  }
  .basic{
    border : 0.2px solid #2f528f;
    border-radius: 10px;
    height: 100px;
    margin-left:3px;
    margin-right:3px;
    text-align: center;
    padding: 7px 1px;
    background :#4472c4;
  }
  .pessimistic{
    border : 0.2px solid #2f528f;
    border-radius: 10px;
    height: 90px;
    margin-left:3px;
    margin-right:3px;
    text-align: center;
    padding: 7px 1px;
    background :#ed7d31;
  }

  
  .container_g{
    width: 100%;
    padding: 6rem 0 10rem;
    @media screen and (max-width: 1620px){
      padding: 4rem 0 10rem;
    }
    @media screen and (max-width: 991px){
      padding: 1rem 0 10rem;
    }
    .print{
        display: none;
      }
    @media print{
      padding:2rem 0 10rem;
      .noprint{
        display: none;
      }
      .print{
        display: block;
      }
      h1{
        font-size: 20px;
      }
    }
  button,p{
    line-height: 1.6;
    font-size: 19px;
    @media print{
      font-size: 14px;
    }
    @media screen and (max-width: 1620px) {
      font-size: 18px;
    }
    @media screen and (max-width: 1280px) {
      font-size: 16px;
    }
    @media screen and (max-width: 580px) {
      font-size: 14px;
    }
  }
  h2{
    font-size: 24px;
    color: #4472c4;
    text-align: center;
    font-weight: 700;
    @media screen and (max-width: 1620px) {
      font-size: 20px;
    }
    @media print{
      font-size: 16px;
    }
  }
    .section-1{
      width:100%;
      margin: auto;
      .content{
        width:1150px;
        @media print{
          width: 100%;
          margin-bottom: 6rem;
        }
        @media screen and (max-width: 1620px) {
          width: 100%;
        }
        margin:auto;
        z-index:99;
        .title{
          padding: 10px 7rem;
          margin: auto;
          margin-top: -5.5rem;
          margin-bottom: 2rem;
          width: fit-content;
          border-radius: 10px;
          background: #eddeb6;
          box-shadow: rgb(0 0 0 / 0%) 0px 14px 28px, rgb(0 0 0 / 11%) 0px 10px 10px;
          @media print{
            padding: 10px 20px;
          }
        }
        .item{
          padding: 2rem 0;
          background-color: white;
          border-radius: 40px;
          transition: 1s all ease-in-out;
          box-shadow: rgb(0 0 0 / 0%) 0px 14px 28px, rgb(0 0 0 / 11%) 0px 10px 10px;
          .m_center{
            width: 95%;
            margin: auto;
            @media screen and (max-width: 1620px) {
              width: 94%;
              margin: auto;
            }
           }
        }
      }
    }

    .section-2{
      width:100%;
      margin:auto;
      text-align: center;
      img{
        width: 100%;
        margin:auto;
      }
      button{
      padding:10px 20px ;
      background: #a98260;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      border-radius: 10px;
      text-transform: uppercase;
      color: #FFFFFF;
      box-shadow: rgb(0 0 0 / 25%) 0px 14px 28px, rgb(0 0 0 / 22%) 0px 10px 10px;
      }
    }

    @media screen and (max-width: 991px) {
      margin-top: 80px;
      h1{
        font-size: 1.5rem;
      }
      .section-1 {
        .content {
          .item {
            .title {
              width: 80%;
              padding: 10px;
              text-align: center;
            }
          }
        }
      }
    }

    @media screen and (max-width: 767px) {  
      .section-1 {
        .content {
          .irem1{
           margin-right: 0;
          }
          .block-1 {
            flex-direction: column;

            .item {
              width: 100%;

              margin-bottom: 40px;
            }
            .item2{
              margin-top: 3rem;
              margin-left: 0;
            }
          }
        }
      }
    }
  }
`