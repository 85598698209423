import React from 'react';
import './index.css';
import Approutes from './Routes'
import './scroll.js'


function App()  {
  return (
    <Approutes /> 
  );
}
<script>
    
</script>

export default App;
