import $ from 'jquery';
$(window).scroll(function(){
        //Fade-in-right
        // $('.fade-in-right').each(function(){        
        //     var point = "0px", side = "150px", scale ="1.5";
            
        //     if(isScrolledIntoView($(this))){        
        //         $(this).css({
        //             'opacity':1,
        //             'visibility':'visible',
        //             '-webkit-transform': 'translateX(' + point + ')',
        //             'transform': 'translateX(' + point + ')' && 'scale(' + scale + ')' , 
        //             'margin-left' : '5rem'          
        //         });
        //     }
        //     else{
        //         $(this).css({
        //             'opacity':1,
        //             'visibility':'visible',
        //             '-webkit-transform': 'translateX(' + side + ')',
        //             'transform': 'translateX(' + side + ')'                  
        //         });
        //     }
        // });

        $('.zoom-in-first').each(function(){        
            var point = "0px", side = "-150px", scale ="1.3";
            
            if(isScrolledIntoView($(this))){        
                $(this).css({
                    'opacity':1,
                    'visibility':'visible',
                    '-webkit-transform': 'translateX(' + point + ')',
                    'transform': 'translateX(' + point + ')' && 'scale(' + scale + ')' ,           
                });
            }
            else{
                $(this).css({
                    'opacity':1,
                    'visibility':'visible',
                    '-webkit-transform': 'translateX(' + side + ')',
                    'transform': 'translateX(' + side + ')'                    
                });
            }
        });
        $('.zoom-in-second').each(function(){        
            var point = "10rem", side = "-100px", scale ="1.3";
            
            if(isScrolledIntoView($(this))){        
                $(this).css({
                    'opacity':1,
                    'visibility':'visible',
                    '-webkit-transform': 'translateX(' + point + ')',
                    'transform': 'translateX(' + point + ')' && 'scale(' + scale + ')' , 
                    'margin-left' : '5rem'          
                });
            }
            else{
                $(this).css({
                    'opacity':1,
                    'visibility':'visible',
                    '-webkit-transform': 'translateX(' + side + ')',
                    'transform': 'translateX(' + side + ')'                    
                });
            }
        });

        $('.zoom-in-thirst').each(function(){        
            var point = "10rem", side = "-100px", scale ="1.3";
            
            if(isScrolledIntoView($(this))){        
                $(this).css({
                    'opacity':1,
                    'visibility':'visible',
                    '-webkit-transform': 'translateX(' + point + ')',
                    'transform': 'translateX(' + point + ')' && 'scale(' + scale + ')' , 
                    'margin-left' : '22rem'          
                });
            }
            else{
                $(this).css({
                    'opacity':1,
                    'visibility':'visible',
                    '-webkit-transform': 'translateX(' + side + ')',
                    'transform': 'translateX(' + side + ')'                    
                });
            }
        });

        $('.zoom-in-center').each(function(){        
            var point = "10rem", side = "-100px", scale ="1.1";
            
            if(isScrolledIntoView($(this))){        
                $(this).css({
                    'opacity':1,
                    'visibility':'visible',
                    '-webkit-transform': 'translateX(' + point + ')',
                    'transform': 'translateX(' + point + ')' && 'scale(' + scale + ')' , 
                    'transition': 'all 1s ease-in-out'
                });
            }
            else{
                $(this).css({
                    'opacity':1,
                    'visibility':'visible',
                    '-webkit-transform': 'translateX(' + side + ')',
                    'transform': 'translateX(' + side + ')'                    
                });
            }
        });
        $('.trans-left').each(function(){        
            var point = "-4rem", side = "200px", scale ="1";
            
            if(isScrolledIntoView($(this))){        
                $(this).css({
                    'opacity':1,
                    'visibility':'visible',
                    '-webkit-transform': 'translateX(' + point + ')',
                    'transform': 'translateX(' + point + ')' && 'scale(' + scale + ')' , 
                    'transition': 'all 1s ease-in-out'
                });
            }
            else{
                $(this).css({
                    'opacity':1,
                    'visibility':'visible',
                    '-webkit-transform': 'translateX(' + side + ')',
                    'transform': 'translateX(' + side + ')'                    
                });
            }
        });


});
  
function isScrolledIntoView(elem){
    var $elem = $(elem);
    var $window = $(window);
  
    var docViewTop = $window.scrollTop();
    var docViewBottom = docViewTop + $window.height();
  
    var elemTop = $elem.offset().top;
    var elemBottom = elemTop + $elem.height();
  
    return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
}