import React, { useRef, useEffect,useState } from 'react'
import { Wrapper } from './styled'
import {
   DIAGRAM01_45,
   DIAGRAM02_45,
   Icon_BlockChain,
   Icon_AI,
   Icon_Sap,
   Icon_Offshore,
   Icon_Web,
   Icon_Business,
   Icon_BigData,
   Icon_Game,
   Image_Bap
} from '@assets';
import ModalImage from './modalImage01';
import useScreenWidth from '../../../Hook/useScreenWidth'

const OurStrengths = () => {
   const divOne = useRef<HTMLInputElement | null>(null);
   const [isShowImage, setIsShowImage] = useState(false);
   const [isShowImage01, setIsShowImage01] = useState(false);
   const scrolLWithUseRef = () => {
      divOne.current?.scrollIntoView({ block: "center", behavior: "smooth" });
   };

   useEffect(() => {
      scrolLWithUseRef()
   }, [])

   const screenWidth = useScreenWidth()
   return (
      <>
         <Wrapper>
            <div className='container_our block text-justify'>
               <div className='section-1' >
                     <h1 className='text-[30px] text-center leading-normal md:leading-relaxed font-bold mb-[3rem]' ref={divOne}>当社の強み<br/>（市場競争力）</h1>
                     <h2 className='title_'>強い結束と高い専門性を持った経営チーム</h2>
                     <div className={`${ (screenWidth > 580) ? 'ml_8 mt-6' : 'ml-4 mt-2'}`}>
                        <div className='flex mt-2'>
                           <p className='text-yellow-400'>■ </p>
                           <div className='block'>
                              <p>社長（父：宮川武揚）と常務（長男：宮川篤盛）は親子であり、強い信頼関係があります。</p>
                              <p>また、当事業を通して、新しいインフラと価値を社会に提供したいという強い使命感を共有しています。</p>
                              <p>二人はそれぞれ高い専門性を持ち、相互に補完し経営を行っていくことができます。</p>
                              <p>これが当社の１つ目の強みです。</p>
                              <div className={`${ (screenWidth > 580) ? 'ml-4 mt-6' : 'ml-0 mt-2'}`}>
                                 <div className='flex'>
                                    <p className='text-[#f4b084]'>● </p>
                                    <div className='block'>
                                       <p>社長（ 宮川 武揚 ）</p>
                                       <div className={`${ (screenWidth > 580) ? 'ml-4 mt-6' : 'ml-0 mt-2'}`}>
                                          <p>日本生命で、国や大企業の超難度案件を数多く担当してきた日生法人営業のレジェンド。</p>
                                          {screenWidth > 580 ? 
                                          <>
                                             <p>年金数理、財政検証システム構築の専門家。金融、経済にも高い専門性を有し、</p>
                                             <p>卓越した営業力と幅広い人脈を擁しています。</p>
                                          </>
                                          :<>
                                             <p>年金数理、財政検証システム構築の専門家。金融、経済にも高い専門性を有し、卓越した営業力と幅広い人脈を擁しています。</p>
                                          </>
                                          }
                                       </div>
                                    </div>
                                 </div>
                                 <div className='flex mt-6'>
                                    <p className='text-[#f4b084]'>● </p>
                                    <div className='block'>
                                       <p>常務（ 宮川 篤盛 ）</p>
                                       <div className={`${ (screenWidth > 580) ? 'ml-4 mt-6' : 'ml-0 mt-2'}`}>
                                          <p>情報工学科でＶＲ技術を専攻。</p>
                                          {screenWidth > 580 ? 
                                          <>
                                             <p>大規模サーバーの保守管理業務から、ゲーム開発エンジンUnityを活用したメタバース</p>
                                             <p>関連業務まで幅広い業務経験を持つ。アニメーション作画もこなすマルチプレイヤー。</p>
                                          </>
                                          :<>
                                             <p>大規模サーバーの保守管理業務から、ゲーム開発エンジンUnityを活用したメタバース関連業務まで幅広い業務経験を持つ。アニメーション作画もこなすマルチプレイヤー。</p>
                                          </>
                                          }
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
               </div>
                 <div className='section-2' >
                     <div className={`flex ${ (screenWidth > 580) ? '' : 'mt-[2rem]'}`}>
                        <div className='content'>
                           <h2 className='title_'>バーチャル３D空間に最先端技術を組み合わせた魅力的な複合サービス</h2>
                           <div className={`${ (screenWidth > 580) ? 'ml_8 mt-6' : 'ml-4 mt-2'}`}>
                              <div className='flex' >
                                 <p className='text-yellow-400'>■ </p>
                                 <div className='block'>
                                    <p >来園者が収益獲得できるサービスを導入します。</p>
                                    <p>楽しみながら副業収入を得られるバーチャルシティとして集客を図り、顧客をＥＣモールに連携します。</p>
                                    <p>商店の活性化につながり、不景気時でも強い集客力を発揮します。これが当社の２つ目の強みです。</p>
                                 </div>
                              </div>
                              <div className='flex mt-6' >
                                 <p className='text-yellow-400'>■ </p>
                                 <div className='block'>
                                    <p>ＥＣ商店に３Ｄアバター店員を配置することで、従来難しかったＥＣ商店での接客販売が可能です。</p>
                                    <p>来園者にとっては、アルバイトの機会につながります。これが当社の３つ目の強みです。</p>
                                 </div>
                              </div>
                              <div className='flex mt-6' >
                                 <p className='text-yellow-400'>■ </p>
                                 <div className='block'>
                                    <p >ＡＩアバター店員（特許審査中）を配置することで、人件費削減も可能です。これが４つ目の強みです。</p>
                                 </div>
                              </div>
                              <div className='flex mt-6' >
                                 <p className='text-yellow-400'>■ </p>
                                 <div className='block'>
                                    <p >当社メタバースで実現できること　（既存のＥＣモール、ＳＮＳとの機能比較）</p>
                                 </div>
                              </div>
                                 <img src={DIAGRAM01_45} alt='' className='m-auto py-8' />
                                       {screenWidth > 991 &&(
                                  <button  onClick={() => setIsShowImage(!isShowImage)} className="bg-[#a98260]">クリックすると拡大</button>
                              )}
                              <div className='flex' >
                                 <p className='text-yellow-400'>■ </p>
                                 <div className='block'>
                                    <p >最先端技術を複合的に組み合わせた当社メタバースの優位性　(同業メタバースとのサービス比較分析)</p>
                                    <div className='ml-4 mt-6'>
                                       {screenWidth > 580 ? 
                                          <>
                                             <p>当社メタバースは、各サービスが相乗的に好循環を生み出しながら顧客満足を向上させていくように</p>
                                             <p>統合的な設計がなされています。その為、単一機能から徐々にサービスを発展・拡張させてきた他社</p>
                                             <p>メタバースと比較すると、機能、質の両面で優位性を獲得しています。</p>
                                          </>
                                          :<>
                                             <p>当社メタバースは、各サービスが相乗的に好循環を生み出しながら顧客満足を向上させていくように統合的な設計がなされています。その為、単一機能から徐々にサービスを発展・拡張させきた他社メタバースと比較すると、機能、質の両面で優位性を獲得しています。</p>
                                          </>
                                          }
                                    </div>
                                 </div>
                              </div>
                              <img src={DIAGRAM02_45} alt=''  className='m-auto pt-8 h_diag02_45'/>
                              <h4 className=' text_16 text-right my-2'>２０２２年４月　当社調査</h4>
                              {screenWidth > 991 &&(
                                 <button  onClick={() => setIsShowImage01(!isShowImage01)} className="bg-[#a98260]">クリックすると拡大</button>
                              )}
                           </div>
                           <h2 className='title_'>最先端複合サービスを生み出す盤石の開発体制</h2>
                           <div className={`${ (screenWidth > 580) ? 'ml_8 mt-6' : 'ml-4 mt-2'}`}>
                              <div className='flex' >
                                 <p className='text-yellow-400'>■ </p>
                                 <div className='block'>
                                    {screenWidth > 580 ? 
                                    <>
                                       <p >最先端技術を複合的に組合せた魅力あるメタバースを創製するにあたり、もっとも大切なのが、</p>
                                       <p>パートナーとなるシステム開発会社です。当社は、候補６００社の中からＢＡＰ社を選定しています。</p>
                                    </>
                                    :<>
                                       <p>最先端技術を複合的に組合せた魅力あるメタバースを創製するにあたり、もっとも大切なのが、パートナーとなるシステム開発会社です。当社は、候補６００社の中からＢＡＰ社を選定しています。</p>
                                    </>
                                    }
                                    <p className='mt-6'>[システム会社選定基準]　以下の１２項目すべての開発経験を持つシステム開発企業を選定しました。</p>
                                    <div className='ml-4 mt-6'>
                                       <p>①ゲーム開発、②ＰＣＷｅｂソフトウェア開発、③スマートフォンアプリ開発、</p>
                                       <p>④ＡＩ（人工知能）開発、⑤ＶＲ開発、⑥ＳＮＳ開発、⑦ＥＣショップ開発、</p>
                                       <p>⑧クラウド開発、⑨ブロックチェーン開発、⑩ＮＦＴシステム開発、</p>
                                       <p>⑪仮想通貨交換機能開発、⑫ＩＣＯ仮想通貨資金調達支援</p>
                                    </div>
                                    <p className='mt-6'>[調査結果]</p>
                                    <div className='ml-4 mt-6'>
                                       <div className='flex'>
                                          <p className='text-[#f4b084]'>● </p>
                                          <div className='block'>
                                             {screenWidth > 580 ? 
                                                <>
                                                   <p>国内企業５００社、オフショア企業１００社のうち、当社選定基準１２項目すぺてにおいて、</p>
                                                   <p>開発実績があるシステム開発会社は１０社未満でした。</p>
                                                </>
                                                :<>
                                                   <p>国内企業５００社、オフショア企業１００社のうち、当社選定基準１２項目すぺてにおいて、開発実績があるシステム開発会社は１０社未満でした。</p>
                                                </>
                                                }
                                          </div>
                                       </div>
                                    </div>
                                    <p className='mt-6'>[選定企業]</p>
                                    <div className='ml-4 mt-6'>
                                       <div className='flex'>
                                          <p className='text-[#f4b084]'>● </p>
                                          <div className='block'>
                                             <p>ＢＡＰグループ</p>
                                             {screenWidth > 580 ? 
                                             <>
                                                <p>ＢＡＰ社は、東京大学、日立、ＮＴＴデータ等から最先端のシステム開発を受注しており、</p>
                                                <p>２０２１ベトナム国家ＩＴ企業ベスト１０においても２部門で表彰を受けている先端優良企業です。</p>
                                             </>
                                             :<>
                                                <p>ＢＡＰ社は、東京大学、日立、ＮＴＴデータ等から最先端のシステム開発を受注しており、２０２１ベトナム国家ＩＴ企業ベスト１０においても２部門で表彰を受けている先端優良企業です。</p>
                                             </>
                                             }
                                             <p>当社のメタバース構想は、ＢＡＰ社から高い評価を受け、契約締結に至っております。</p>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div> 
                  <div className='section-3'>
                  {screenWidth > 580 ? 
                  <>
                  <div className='m-auto content'>
                     <div className='ml_8 offshore-box'>
                        <div className='offshore-container'>
                           <div className='offshore_list'>
                              <div className='block-left w-1/3'>
                                 <ul className='block justify-center'>
                                    <li>
                                       <div className='offshore_list_item'>
                                          <div className='item-thumb'>
                                             <i className='icon-blockchain-technology'><img src={Icon_BlockChain} alt='' /></i>
                                          </div>
                                          <div className='item-content'>BLOCKCHAIN</div>
                                       </div>
                                    </li>
                                    <li>
                                       <div className='offshore_list_item'>
                                          <div className='item-thumb'>
                                             <i className='icon-blockchain-technology'><img src={Icon_AI} alt='' /></i>
                                          </div>
                                          <div className='item-content'>AI/IOT</div>
                                       </div>
                                    </li>
                                    <li>
                                       <div className='offshore_list_item'>
                                          <div className='item-thumb'>
                                             <i className='icon-blockchain-technology'><img src={Icon_Sap} alt='' /></i>
                                          </div>
                                          <div className='item-content'>SAP/ERP</div>
                                       </div>
                                    </li>
                                    <li>
                                       <div className='offshore_list_item'>
                                          <div className='item-thumb'>
                                             <i className='icon-blockchain-technology'><img src={Icon_Offshore} alt='' /></i>
                                          </div>
                                          <div className='item-content'>OFFSHORE DEVELOPMENT</div>
                                       </div>
                                    </li>
                                 </ul>
                              </div>
                              <div className='center-main-block justify-center w-1/3'>
                                 <div className='justify-center'>
                                    <img src='https://bap-software.net/wp-content/uploads/2020/03/logo-bap-software-1.png' className='w-[50%] m-auto' alt='' />
                                 </div>
                                 <ul className='justify-center mt-[-13rem]'>
                                    <li id="main-block">
                                       <div className='offshore_list_item'>
                                          <div className='item-thumb'>
                                             <i className='icon-blockchain-technology'><img src={Icon_Web} alt='' /></i>
                                          </div>
                                          <div className='item-content'>WEBSITE <br />/SMARTPHONE APP</div>
                                       </div>
                                    </li>
                                 </ul>
                              </div>
                              <div className='block-right w-1/3'>
                                 <ul className='block justify-center'>
                                    <li>
                                       <div className='offshore_list_item'>
                                          <div className='item-thumb'>
                                             <i className='icon-blockchain-technology'><img src={Icon_Business} alt='' /></i>
                                          </div>
                                          <div className='item-content'>BUSINESS APPLICATION</div>
                                       </div>
                                    </li>
                                    <li>
                                       <div className='offshore_list_item'>
                                          <div className='item-thumb'>
                                             <i className='icon-blockchain-technology'><img src={Icon_BigData} alt='' /></i>
                                          </div>
                                          <div className='item-content'>BIGDATA</div>
                                       </div>
                                    </li>
                                    <li>
                                       <div className='offshore_list_item'>
                                          <div className='item-thumb'>
                                             <i className='icon-blockchain-technology'><img src={Icon_Game} alt='' /></i>
                                          </div>
                                          <div className='item-content'>GAME</div>
                                       </div>
                                    </li>
                                 </ul>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  </>
                  :
                  <>
                     <img src={Image_Bap} alt='' className='ml-1' />
                  </>}
               </div>
               <div className='imgBap'>
                  <img src={Image_Bap} alt='' />
               </div>
                   <div className='section-2 mb-[2rem]' >
                     <div className='flex'>
                        <div className='content'>
                           <div className={`flex ${ (screenWidth > 580) ? 'ml_8 mt-4' : 'ml-4 mt-2'}`} >
                              <p className='text-yellow-400'>■ </p>
                              <div className='block'>
                              {screenWidth > 580 ? 
                                 <>
                                    <p>当社のメタバース複合サービスを開発できる技術力を持ったシステム会社は非常に少なく、</p>
                                    <p>同等のサービスを提供するのは、容易なことではありません。これが５つ目の強みです。</p>
                                 </>
                                 :<>
                                    <p>当社のメタバース複合サービスを開発できる技術力を持ったシステム会社は非常に少なく、同等のサービスを提供するのは、容易なことではありません。これが５つ目の強みです。</p>
                                 </>
                                 }
                              </div>
                           </div>
                           <h2 className='title_ mt-10'>特許関連技術</h2>
                           <div className={`${ (screenWidth > 580) ? 'ml_8 mt-6' : 'ml-4 mt-2'}`} >
                              <div className='flex' >
                                 <p className='text-yellow-400'>■ </p>
                                 <div className='block'>
                                    <p>当社独自の以下の技術を2つ保有しています。これが、６つ目の強みです。</p>
                                    <div className={`${ (screenWidth > 580) ? 'ml-4' : 'ml-0'}`}>
                                       <div className='flex mt-6'>
                                          <p className='text-[#f4b084]'>● </p>
                                          <div className='block'>
                                             <p className='leading-normal'>ＡＩ３Ｄアバターをカスタマイズし店員として派遣 　　　　　　　<span className='text-[#c01600] ml-[12px]'>※ 特許申請中・審査請求済</span></p>
                                          </div>
                                       </div>
                                       <div className='flex mt-2'>
                                          <p className='text-[#f4b084]'>● </p>
                                          <div className='block'>
                                             <p>ＶＲ座標移動３６０度視覚システム（ＶＲlive viewer for mobile）　※特許出願予定</p>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <h2 className='title_ mt-10'>超一流のパートナー</h2>
                           <div className={`flex ${ (screenWidth > 580) ? 'ml_8 mt-6' : 'ml-4 mt-2'}`} >
                              <div className='flex' >
                                 <p className='text-yellow-400'>■ </p>
                                 <div className='block'>
                                    <p>超一流のビジネスパートナーにご信頼頂いています。これが、７つ目の強みです。</p>
                                    <div className={`${ (screenWidth > 580) ? 'ml-4 mt-6' : 'ml-2 mt-2'}`}>
                                       <div className='flex'>
                                          <p className='text-[#f4b084]'>● </p>
                                          <div className='flex block-2'>
                                             <p>システム開発会社　:　 </p>
                                             <p>ＢＡＰ.Inc、ＢＡＰ Solution Japan</p>
                                          </div>
                                       </div>
                                       <div className='flex'>
                                          <p className='text-[#f4b084]'>● </p>
                                          <div className='flex block-2'>
                                             <p>顧問弁理士事務所　:　</p>
                                             <p>特許業務法人　酒井国際特許事務所<span className={`${ (screenWidth > 991) ? 'ml-8' : ''}`}>{screenWidth < 992 &&(<br/>)}※国内トップ弁理士事務所</span></p>
                                          </div>
                                       </div>
                                       <div className='flex'>
                                          <p className='text-[#f4b084]'>● </p>
                                          <div className='flex block-2'>
                                             <p>顧問弁護士事務所　:　 </p>
                                             <p>法律事務所ZeLo・外国法共同事業<span className={`${ (screenWidth > 991) ? 'ml-[2.8rem]' : ''}`}>{screenWidth < 992 &&(<br/>)}※在籍弁護士４０名</span></p>
                                          </div>
                                       </div>
                                       <div className='flex'>
                                          <p className='text-[#f4b084]'>● </p>
                                          <div className='flex block-2'>
                                             <p>顧問税理士事務所　:　 </p>
                                             <p>ベンチャーサポート税理士法人<span className={`${ (screenWidth > 991) ? 'ml-[4.5rem]' : ''}`}>{screenWidth < 992 &&(<br/>)}※顧問先企業数は国内トップクラス。</span></p>
                                          </div>
                                       </div>
                                       <div className='flex'>
                                          <p className='text-[#f4b084]'>● </p>
                                          <div className='flex block-2'>
                                             <p>取引銀行　　　　　:　</p>
                                             <p>三井住友銀行（本店営業部）、東京信用金庫（練馬支店）</p>
                                          </div>
                                       </div>
                                       <div className='flex'>
                                          <p className='text-[#f4b084]'>● </p>
                                          <div className='flex block-2'>
                                             <p>業務委託先　　　　:　</p>
                                             <p>ＳＭＢＣクラウドサイン株式会社</p>
                                          </div>
                                       </div>
                                       <div className='flex'>
                                          <p className='text-[#f4b084]'>● </p>
                                          <div className='flex block-2'>
                                             <p>業務提携先(予定) 　:　</p>
                                             <p>三井物産株式会社、三井物産デジタルコモディティーズ株式会社</p>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
               </div>
            </div>
            {isShowImage && (
                  <ModalImage 
                  event={() => setIsShowImage(false)}
                  url={DIAGRAM01_45}
                  width="w-[80%]"
                  />
               )}
               {isShowImage01 && (
                  <ModalImage 
                  event={() => setIsShowImage01(false)}
                  url={DIAGRAM02_45}
                  width="w-[90%]"
                  />
               )}
         </Wrapper>
      </>
   )
}
export default OurStrengths
