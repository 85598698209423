import styled from 'styled-components'

export const Wrapper = styled.div`
  height: 100%;
  width: 1100px;
  @media screen and (max-width: 1620px) {
    width: 1000px;
  }
  display: block;
  margin: auto;
  .container-service{
    width: 100%;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    @media screen and (max-width: 991px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    @media screen and (max-width: 600px) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
    @media print{
      padding-top: 2rem;
    }
  }
`
