import styled from 'styled-components'

export const Wrapper = styled.div`
  height: 100%;
  display: block;
  width: 100%;
  .header{
  }
  .bg_slide{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    @media screen {
      height: 100%;
    }
    @media print {
      height: 60%;
    }
    visibility: visible;
    overflow: hidden;
    -moz-animation: fadeIn 1.5s ease;
    -webkit-animation: fadeIn 1.5s ease;
    animation: fadeIn 1.5s ease;
    transition: margin 700ms ease;

    @media screen and (max-width: 991px){
      position: initial;
      margin-top: 80px;
    }
   }
   
   .keen-slider{
    @media print {
      height: 450px;
    }
    @media screen and (max-width: 1580px){
      height: 829px !important;
    }
    @media screen and (max-width: 991px){
      height: 600px !important;
    }
    @media screen and (max-width: 757px){
      height: 500px !important;
    }
    @media screen and (max-width: 500px){
      height: 300px !important;
    }
   }
  .container-home{
    width: 100%;
    margin-top: 2rem;
    .modal-home{
       @media print {
        width : 65%;
        margin-bottom: -2rem;
      }
      width : 1225px;
      @media screen and (max-width: 1620px){
        width: 1100px;
      }
      @media screen and (max-width: 1280px){
          width: 70%;
        }
      @media screen and (max-width: 991px){
        width: 100%;
      }
    }
    @media screen and (max-width: 757px){
      margin-top: 1rem;
     .modal-home {
      margin-bottom: 10px;
     }
    }
      .left{
        width: 100%;
        @media screen and (max-width: 991px){
          display: none;
          width: 0%;
        }
      }
     .right{
        width : 0;
        margin-top: -2rem;
        margin-left: 0.2%;
        z-index: 1;
        @media screen and (max-width: 1280px){
          margin-left: 77%;
        }

        @media screen and (max-width: 1200px){
          margin-left: 70%;
        }

        @media screen and (max-width: 991px){
          margin-left: 0;
          width: 100%;
          // margin-top: 10px;
          margin-top: 0rem;
        }
      }
      .section-1{
        width: 100%;
        background: #E8E2D2;
        display : block;
        padding: 6rem 0;
        @media print {
          padding: 0.5rem 0;
        }
        p{
          font-family: 游ゴシック;
        }
        .content{
          width :1150px;
          @media screen and (max-width: 1620px){
            width: 1100px;
          }
          @media screen{
            .imageVideo{
              display: none;
            }
          }
          @media print {
            width :78%;
            padding: 6rem 0 4rem;
            video{
              display: none;
            }
            .imageVideo{
              height: 260px;
            }
          }
          margin: auto;
          line-height: 1.7rem;
          letter-spacing: 0.04rem;
          @media screen and (max-width: 1280px){
            width: 70%;
          }
          @media screen and (max-width: 991px){
            width: 90%;
          }
          .text{
            width: fit-content;
            margin: auto;
            }
          .text_20{
            font-size: 20px;
              @media print {
                font-size: 14px;
              }
              @media screen and (max-width: 1620px){
                font-size: 18px;
              }
              @media screen and (max-width: 1280px){
                font-size: 16px;
              } 
          }
          h1{
            font-style: normal;
            font-weight: 700;
            font-size: 32px;
            line-height: 32px;
            color: #2F1B11;
          }
          p{
            @media screen {
              font-size: 19px;
            }
            @media print {
              font-size: 14px;
            }
            @media screen and (max-width: 1620px){
              font-size: 18px;
            }
            @media screen and (max-width: 1280px){
              font-size: 16px;
            } 
          }
          .right{
            width: 100%;
            height: 500px;
            margin-top: 2rem;

            @media screen and (max-width: 991px){
              height: 400px;
            }

            @media screen and (max-width: 767px){
              height: 300px;
            }

            &.video-content {
              border : 4px solid white;
                .video{
                  // right: 4%;
                  // width: 100%;
                  // height: 100%;
                  overflow: initial;
                
                  video {
                    // width: 100% !important;
                    // height: 100% !important;
                    left: 0 !important;
                    top: 0 !important;
                    max-width: none !important;
                  }
              }
            }
         }
         button{
            width: 198px;
            height: 50px;
            margin-top :2rem;
            background: #A98260;;
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 24px;
            text-transform: uppercase;
            color: #FFFFFF;
            box-shadow: #4a4a4959 0px 19px 26px, rgb(197 194 194) 12px 15px 45px;
            @media screen and (max-width: 1620px){
              font-size: 18px;
            }
            @media screen and (max-width: 1280px){
              font-size: 16px;
            } 
          }
        }
        .animation {
          color: #fff;
          
          text-align: center;
          margin-left: auto;
          margin-right: auto;
        }
        .fade-in-right {
          -webkit-transform: translateX(10px);
          transform: translateX(0px);
          transfrom : scale(1);
          
          -webkit-transition: all 1s ease-in-out;
          transition: all 1s ease-in-out;
        }
      }
      .section-2{
        width: 100%;
        background: #f4f3ed;
        display : block; 
        @media screen {
          padding: 5rem 2rem 10rem;
        }
        @media print {
          padding: 2rem 0rem 10rem;
        }
        @media screen and (max-width: 767px){
          padding: 5rem 0rem;
        }
        .section-2-child1{
          width: 60%;
          margin: auto;
          display: flex;
          @media print {
            .img_person{
              width: 70%;
            }
          }
          @media screen and (max-width: 991px){
            width: 100%;
          }
          @media screen and (max-width: 501px){
            .text_po{
              font-size: 12px;
            }
          }
        }
        .what_roadmap{
          width: 60%;
          margin: auto;
          display: flex;
          padding: 5rem 0 3rem;
          justify-content: center;
          @media print {
              padding: 2rem 0;
            }
          @media screen and (max-width: 991px){
            width: 100%;
          }
          @media screen and (max-width: 501px){
            .text_po{
              font-size: 12px;
            }
          }
          h3{
            background: #2f1b11;
            padding: 0.6rem 9rem;
            font-size: 24px;
            color: white;
            box-shadow: rgb(0 0 0 / 25%) 0px 14px 28px, rgb(0 0 0 / 22%) 0px 10px 10px;
            @media screen and (max-width: 580px){
              padding: 0.6rem 3rem;
            }
            @media print {
              padding: 0.6rem 2rem;
              font-size: 16px;
            }
          }
        }
        .directory{
          width: 70%;
          margin: auto;
          display: flex;
          padding: 3rem 0;
          .content{
            width: inherit;
            margin: 0 1rem;
            padding:30px 20px;
            text-align: center;
            background: #2f5597;
            border-radius:30px;
            color: white;
            @media screen and (max-width: 580px){
              padding:30px 5px;
            }
            p{
            @media screen {
              font-size: 19px;
            }
            @media print {
              font-size: 14px;
            }
            @media screen and (max-width: 1620px){
              font-size: 18px;
            }
            @media screen and (max-width: 1280px){
              font-size: 16px;
            } 
            @media screen and (max-width: 580px){
                font-size: 12px;
            } 
            span{
              font-size: 23px;
              @media screen and (max-width: 1620px){
                font-size: 19px;
              }
              @media screen and (max-width: 1280px){
                font-size: 18px;
              } 
              @media screen and (max-width: 580px){
                font-size: 12px;
              } 
            }
          }
          }
          @media screen and (max-width: 991px){
            width: 100%;
          }
          img{
            width: 250px;
            height: 250px;
            @media screen and (max-width: 1620px){
              width: 19%;
              height: 19%;
              margin: auto;
            }
            @media screen and (max-width: 768px){
              width: 30%;
              margin-bottom:2rem;
            }
            @media screen and (max-width: 576px){
              width: 50%;
              margin-bottom:2rem;
            }
          }
          @media screen and (max-width: 768px){
              display: block;
              .img02{
                display: none;
              }
              .content{
                margin: 0;
              }
          }
        }
        .roadMap_print{
          @media screen {
             display: none;
            }
          @media print {
          }
        }
        .road_map{
          margin: auto;
          width: 850px;
          @media print {
             width: 800px;
            }
           @media screen and (max-width: 1280px){
            width: 70%;
           }

          .text_r{
            text-align: -webkit-right;
          }
          .mr_{
            margin-right: 2.1rem;
            text-align: initial;
          }
          p{
            
            font-style: normal;
            font-size: 16px;
            line-height: 19px;
            color: #2F1B11;
            text-align: justify;
            @media screen and (max-width: 1280px){
                font-size: 14px;
              }
         }
          h3 {
              background: #2996ff;
              box-shadow: 2px 2px 15px rgb(0 0 0 / 4%);
              display: inline-block;
              color: #fff;
              font-size: 19px;
              line-height: 19px;
              text-align: center;
              width: 356px;
              height: 50px;
              padding: 17px;
              font-weight: 500;
              position: relative;
              border-top-right-radius: 2rem;
              border-bottom-right-radius: 2rem;
              @media screen and (max-width: 1280px){
                font-size: 18px;
              }
          }
          h3:before {
              content: '';
              height: 0;
              width: 0;
              border: 30px solid #ededed00;
              display: inherit;
              position: absolute;
              border-right: 13px solid #24415c;
              bottom: -30px;
              left: -30px;
              z-index: 0;
          }
          h3:after {
              content: '';
              display: inline-block;
              position: absolute;
              width: 15px;
              height: 40px;
              background: #2a96fe;
              left: 0;
              bottom: 0;
          }
          .t-1{
            h3{ background: #0A8A80;}
            h3:before{ border-right: 13px solid #004742;}
            h3:after{ background: #0A8A80;}
          }
          .t-2{
            h3{ background: #F0633D;}
            h3:before{ border-right: 13px solid #B02500;}
            h3:after{ background: #F0633D;}
          }
          .t-3{
            h3{ background: #FAA713;}
            h3:before{ border-right: 13px solid #AB7411;}
            h3:after{ background: #FAA713;}
          }
          .t-4{
            h3{ background: #648209;}
            h3:before{ border-right: 13px solid #2E3D00;}
            h3:after{ background: #648209;}
          }
          .t-5{
            h3{ background: #F60500;}
            h3:before{ border-right: 13px solid #870300;}
            h3:after{ background: #F60500;}
          }#a98260
          .t-6{
            h3{ background: #a98260;}
            h3:before{ border-right: 13px solid #870300;}
            h3:after{ background: #a98260;}
          }
          .item{
            width: 309px;
            background: #E8E2D2;
            margin-left: 14px;
            padding : 23px 13px;
            box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
          }
          .circle{
            width: 25px;
            height: 25px;
            position: absolute;
            border-radius: 50%;
            .child{
              width: 10px;
              height: 10px;
              background: #D9D9D9;
              position: absolute;
              border-radius: 50%;
              margin :8px;
            }
          }
          .showmore{
            text-align: center;
            padding: 5rem 0 0;
            button{
              width: 198px;
              height: 50px;
              background: #A98260;
              font-style: normal;
              font-weight: 700;
              font-size: 19px;
              line-height: 24px;
              text-transform: uppercase;
              color: #FFFFFF;
              box-shadow: rgb(0 0 0 / 25%) 0px 14px 28px, rgb(0 0 0 / 22%) 0px 10px 10px;
              @media screen and (max-width: 1620px){
                font-size: 18px;
              }
              @media screen and (max-width: 1280px){
                font-size: 16px;
              } 
            }
          }
        }
      }
      .section-3{
        width: 100%;
        padding: 3rem 0;
        background: #E8E2D2;
        display:flex;
        .image{
          width:50%;
          margin-top:1rem;
        }
        .content{
          width:50%;
          margin:auto;
          h1{
            
            font-style: normal;
            font-weight: 700;
            font-size: 32px;
            line-height: 30px;
            color: #2F1B11;
          }
          p{
            width:70%;
            
            font-style: normal;
            font-weight: 400;
            font-size: 19px;
            line-height: 30px;
            color: #2F1B11;
            margin-top:1rem;
          }
        }
      }
      .section-4{
        height:450px;
        width:100%;
        .content{
          width:50%;
          margin:auto;
          z-index:99;
          padding-top:6rem;
          @media screen and (min-width: 1800px){
            padding-top:8rem;
          }
          h1{
            
            font-style: normal;
            font-weight: 500;
            font-size: 32px;
            line-height: 36px;
            text-align: center;
            color: #FFFFFF;
          }
          p{
            margin-top:2rem;
            
            font-style: normal;
            line-height: 28px;
            font-size:18px;
            text-align: center;
            color: #FFFFFF;
          }
          button{
            width: 198px;
            height: 50px;
            background: #A98260;
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 24px;
            text-transform: uppercase;
            color: #FFFFFF;
            box-shadow: rgb(0 0 0 / 25%) 0px 14px 28px, rgb(0 0 0 / 22%) 0px 10px 10px;
          }
        }
      }
      .section-5{
        width: 100%;
        height: 835px;
        background: #E8E2D2;
        padding: 5rem 15%;
        h1{
          
          font-style: normal;
          font-weight: 700;
          font-size: 32px;
          line-height: 28px;
          color: #000000;

        }
        .s5_button{
          display :flex;
          margin-top:2.5rem;
          button{
            width: 198px;
            height: 50px;
            
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 24px;
            text-transform: uppercase;
            box-shadow: #4a4a4959 0px 19px 26px, rgb(197 194 194) 12px 15px 45px;
          }
        }
        .s5_image{
          display: flex;
          margin-top:4rem;
          justify-content: center;
          .container{
            width: 374px;
            height: 199px;
            transform: scale(0.9);
            cursor : pointer;
            background : white;
            transition: all 1s ease-in-out;
            box-shadow: rgb(86 90 94) 0px 20px 30px -10px;
            &:hover {
              transform: scale(1.1);
            }
            .title {
            background: #00000033;
            
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            text-align : center;
            line-height: 35px;
            color: white;
            opacity :0;
            height :50px;
            width :96.5%;
            padding-top: 10px;
            transition: all 0.8s ease-in-out;
            position: absolute;
            margin :6px;
            }
          }
          .container:hover .title{
            opacity :1;
            margin-top:-57px;
          }
        }
        .s5_cont{
          display: flex;
          margin-top:4rem;
          .auto-h{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
          }
          h1{
            
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 35px;
            color: #000000;
          }
          p{
            
            font-style: normal;
            font-size: 19px;
            line-height: 35px;
            color: #000000;
          }
          button{
            width: 198px;
            height: 50px;
            margin-top :2rem;
            background: #A98260;;
            
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 24px;
            text-transform: uppercase;
            color: #FFFFFF;
            box-shadow: #4a4a4959 0px 19px 26px, rgb(197 194 194) 12px 15px 45px;
          }
        }
      }
      .start-selling{
        margin : auto;
        width: 58%;
        padding-bottom: 3rem;
        .line-up{
          position: absolute;
          width: 7px;
          margin-left: -4px;
          background: #2F1B11;
          transform: skewY(-52deg);
        }
        .line-down{
          position: absolute;
          width: 1px;
          background: #2F1B11;
        }
        .title{
          
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 35px;
          color: #2F1B11;
        }
        .item_fi{
          width: 100%;
          height: 133px;
          background: rgba(217, 197, 181, 0.3);
        }
        .item_se{
          width: 100%;
          height: 105px;
          background: rgba(217, 197, 181, 0.3);
        }
        .text{
          
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 25px;
          color: black;
        }
      }
  }
  @media screen and (max-width: 480px) {
    
  }

`
