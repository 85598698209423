import React from 'react'
import { Wrapper } from './styled'
import ContactUs from './contactUs'

const QuestionScreen = () => {
   return (
      <>
      <Wrapper id="intro">
         <div className='container-home block'>
            <ContactUs />
         </div>
      </Wrapper>
      </>
   )
}
export default QuestionScreen
