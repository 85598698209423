import React, {useRef, useEffect} from 'react'
//@ts-ignore
import { useForm } from "react-hook-form";
import { Wrapper } from './styled'
import ModalNoti from '@components/modalNoti';
import { ApplicationContactUsInformation } from '../../../APIs';
import { options } from '../../../Utils'
import {Image_FlowRegister,Image_InforCompany} from '@assets'
import useScreenWidth from 'src/Hook/useScreenWidth';
import ModalImage from './modalImage';
const IndividualContractPayerScreen = () => {
     const {
         register,
         handleSubmit,
         setValue,
         formState: { errors }
      } = useForm();
      const [notification, setNotification] = React.useState(false);
      const [check, setCheck] = React.useState(false);
      const [submit, setSubmit] = React.useState(false);
      const [selectedFile1, setSelectedFile1] = React.useState('');
      const [selectedFile2, setSelectedFile2] = React.useState('');
      const [dataForm, setDataForm] = React.useState({
         name:'',
         nameSpelling : '',
         address: '',
         email : '',
         phone :'',
         numberApartment : '',
      });
      const [numberApartment, setNumber] = React.useState('');
      const [isShowImage, setIsShowImage] = React.useState(false);
      const [image1, setImage1] = React.useState<any>(null)
      const [image2, setImage2] = React.useState<any>(null)

      const [file1, setFile1] = React.useState('');
      const [file2, setFile2] = React.useState('');
      const [checkfile1, setchekFile1] = React.useState(true);
      const [checkfile2, setchekFile2] = React.useState(true);
      const [size1, setSize1] = React.useState('');
      const [size2, setSize2] = React.useState('');
      const handleFileSelect1 = (event : any) => {
         setFile1(event.target.value.replace(/^.*\\/,""))
         setSize1(event.target.files[0].size)
         setSelectedFile1(event.target.files[0])
         setImage1(URL.createObjectURL(event.target.files[0]));
      }
      const handleFileSelect2 = (event : any) => {
         setFile2(event.target.value.replace(/^.*\\/,""))
         setSize2(event.target.files[0].size)
         setSelectedFile2(event.target.files[0])
         setImage2(URL.createObjectURL(event.target.files[0]));
      }


      const [isChecked1, setIsChecked1] = React.useState(false);
      const [isChecked3, setIsChecked3] = React.useState(false);

      const onCheck = (data : any) => {
         setDataForm({
            name:`${data.firstname}${data.lastname}`,
            nameSpelling : `${data.firstnamespelling}${data.lastnamespelling}`,
            address: `${data.address}`,
            email : `${data.email}`,
            phone :`${data.phonenumber}`,
            numberApartment : `${data.numberApartment}`,
         })
         if(selectedFile1 && selectedFile2 ){
            setCheck(!check);
         }
         if(!selectedFile1){
            setchekFile1(false)
         }
         if(!selectedFile2 ){
            setchekFile2(false)
         }
      }

      useEffect(()=>{

         const text = `${dataForm.name} 様<br/>
         <br/>このたびは、弊社会議室・マンションの購入申込をいただき、まことにありがとうございます。
         <br/>追って、弊社から契約書、購入いただく部屋の番号、購入代金、代金振込先等をご連絡するメールを、別途送信させていただきます。
         <br/>申込が集中いたしておりますので、一週間程度お時間をいただくことがございます。宜しくお願い申し上げます。
         <br/>なお、先着順となっておりますので、メタバース不動産が完売となり、お申し込みをお受けできない場合もございます。
         <br/>その際は何卒ご容赦賜りたくお願い申し上げます。
         <br/><br/>■記載内容：
         <br/>　　契約者の氏名：${dataForm.name}
         <br/>　　契約者の氏名（フリガナ）：${dataForm.nameSpelling}
         <br/>　　契約者の住所：${dataForm.address}
         <br/>　　契約者のメールアドレス：${dataForm.email}
         <br/>　　契約者の携帯電話番号：${dataForm.phone}
         <br/>　　会議室・マンション販売価格：110万円／戸（税込み）
         <br/>　　購入会議室・マンション戸数：${dataForm.numberApartment}
         <br/>　　購入代金の合計金額：${110*Number(numberApartment)}万円（税込み）
         <br/>　　本人確認書類の表裏写真を添付：添付あり
         <br/><br/>※当メールは、お客様からの購入申込と同時に、システムによりメールを自動返信しております。当メールの受領のみでは契約は完了いたしておりません。ご了承いただきたくお願い申し上げます。
         <br/> ________________________________________________________
         <br/><br/>アートメタバースランド株式会社
         <br/>〒176-0022　東京都練馬区向山３－１５－１９
         <br/>電話番号　０３－６６３６－７８９３
         <br/>メールによるお問い合わせは、弊社ホームページお問い合わせフォームからお願いいたします。
         <br/> ________________________________________________________
         `

         const formDataUser = new FormData();
         formDataUser.append("name", `${dataForm.name}`);
         formDataUser.append("email", `${dataForm.email}`);
         formDataUser.append("data",text);
         formDataUser.append("cc", '');
         formDataUser.append("subject", '会議室・マンション購入の申込を受領いたしました');
         formDataUser.append("attachments", '');

         const formData = new FormData();
         formData.append("name", `${dataForm.name}`);
         formData.append("email", 'maison@artmetaverseland.co.jp')
         formData.append("data",text);
         formData.append("cc", '');
         formData.append("subject", '会議室・マンション購入の申込を受領いたしました');
         formData.append("attachments[]", selectedFile1);
         formData.append("attachments[]", selectedFile2);

         if(submit){
            ApplicationContactUsInformation(formDataUser,setNotification)
            ApplicationContactUsInformation(formData,setNotification)
         }

      },[dataForm, selectedFile1, selectedFile2,submit, numberApartment])

      const divOne = useRef<HTMLInputElement | null>(null);
      const scrolLWithUseRef = () => {
      divOne.current?.scrollIntoView({ block: "center", behavior: "smooth" });
      };
   
      useEffect(() => {
         scrolLWithUseRef()
         if(check){
            scrolLWithUseRef()
         }
      },[check])

      useEffect(() => {
         if(file1){
            setchekFile1(true)
         }
         if(file2){
            setchekFile2(true)
         }
         if(!file1 || size1 > '4000000'){
            setSelectedFile1('')
            setFile1('')
         }
         if(!file2 || size2 > '4000000'){
            setSelectedFile2('')
            setFile2('')
         }
      },[checkfile1,checkfile2,file1,file2,size1,size2])
      const screenWidth = useScreenWidth()
   
   return (
      <>
      <Wrapper id="intro" >
         <div className='container-payer block'>
            <div className='start-selling-payer block'>
            <div className='item_fi flex'>
               {!check ? 
               <>
               <div className='pl-1 block pb-4 w-full'>
                  <div className='block p-2  mt_6'>
                     <h1 className="text-[24px] leading-normal md:leading-relaxed mb-2 font-bold text-center"  ref={divOne}>会議室・マンション購入申込フォーム</h1>
                     <h3 className='title text-center pt-2'>個人契約用</h3>
                  </div>
                  <div className='block p-2 container_ '>
                     <p className='title_ text-[20px] mb-2 pt_6 font_12'>ご契約者様（=代金負担者様）</p>
                     <div className='ml-4'>
                        <form onSubmit={handleSubmit(onCheck)}>
                           <div className="grid">
                              <label className='text mt_6 font-bold'>契約者の氏名</label>
                           </div>
                           <div className='flex w-[93%]'>
                              <div className="grid w-1/2">
                                 <label className='text font-bold'>姓<span className='font-bold text-red-500 font_small'>「必須」</span></label>
                                 <input className='mt-1' type="text" 
                                    {...register("firstname", {
                                       required: true
                                    })} 
                                    onChange={e => setValue("firstname", e.target.value.trimStart())}
                                 />
                              </div>
                              <div className="grid w-1/2">
                                 <label className='text  font-bold'>名<span className='font-bold text-red-500 font_small'>「必須」</span></label>
                                 <input className='mt-1' type="text" 
                                    {...register("lastname", {
                                       required: true
                                    })} 
                                    onChange={e => setValue("lastname", e.target.value.trimStart())}
                                 />
                              </div>
                           </div>
                           <div className="grid">
                              <label className='text mt_6 font-bold'>契約者の氏名（フリガナ）</label>
                           </div>
                           <div className='flex w-[93%]'>
                              <div className="grid w-1/2">
                                 <label className='text font-bold'>姓<span className='font-bold text-red-500 font_small'>「必須」</span></label>
                                 <input className='mt-1' type="text" 
                                    {...register("firstnamespelling", {
                                       required: true
                                    })} 
                                    onChange={e => setValue("firstnamespelling", e.target.value.trimStart())}
                                 />
                              </div>
                              <div className="grid w-1/2">
                                 <label className='text  font-bold'>名<span className='font-bold text-red-500 font_small'>「必須」</span></label>
                                 <input className='mt-1' type="text" 
                                    {...register("lastnamespelling", {
                                       required: true
                                    })} 
                                    onChange={e => setValue("lastnamespelling", e.target.value.trimStart())}
                                 />
                              </div>
                           </div>
                           <div className="grid">
                              <label className='text mt_6 font-bold'>契約者の住所<span className='font-bold text-red-500 font_small'>「必須」</span>　{ screenWidth > 580 ? '' : <br/>}<span className='font-bold text-[#0972c1] text_16'>都道府県から入力してください。郵便番号は不要です。</span></label>
                              <input className='mt-1' type="text"
                                  {...register("address", {
                                    required: true
                                 })} 
                                 onChange={e => setValue("address", e.target.value.trimStart())}
                              />
                           </div>
                           <div className="grid">
                              <label className='text mt_6 font-bold'>契約者のメールアドレス<span className='font-bold text-red-500 font_small'>「必須」</span></label>
                              <input className='mt-1' type="text" 
                                 {...register("email", {
                                    required: true,
                                    pattern:{
                                       value:
                                          /^[A-Z0-9._+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                          message: "無効なメールアドレス",
                                    }
                                 })}
                                 onChange={e => setValue("email", e.target.value.trimStart())}
                              />
                              {errors.email?.message && (
                                 <h4 className="text-red-500 text_16">{errors.email?.message?.toString()}</h4>
                              )}
                           </div>
                           <div className="grid">
                              <label className='text mt_6 font-bold'>契約者の携帯電話番号<span className='font-bold text-red-500 font_small'>「必須」</span></label>
                              <input className='mt-1' type="text" 
                                 {...register("phonenumber", {
                                    required: true
                                  })}
                                  onChange={e => setValue("phonenumber", e.target.value.replace(/\D/,''))}
                              />
                           </div>
                           <div className="grid">
                              <label className='text mt_6 font-bold'>会議室・マンション販売価格（税込み）</label>
                              <input className='mt-1' type="text" 
                                 value='110万円／戸'
                                 disabled
                              />
                           </div>
                           <div className="grid">
                              <label className='text mt_6 font-bold'>購入会議室・マンション戸数<span className='font-bold text-red-500 font_small'>「必須」</span></label>
                               <select 
                                  className='mt-1'
                                  defaultValue=''
                                  {...register("numberApartment", {
                                    required: true
                                 })}
                                   onChange={(e : any)=> {setNumber(e.target.value)}}
                                 >
                                 {options.map((option : any) => (
                                    <option value={option.value}>
                                       {option.value}
                                    </option>
                                 ))}
                               </select>
                           </div>
                           <div className="grid">
                              <label className='text mt_6 font-bold'>購入代金の合計金額（税込み）</label>
                              <input className='mt-1' type="text" 
                                 value={`${110*Number(numberApartment)}万円`}
                                 disabled
                              />
                           </div>
                           <div className="grid">
                              <label className='text mt_6 font-bold'>本人確認書類の表裏写真を添付　{ screenWidth > 580 ? '' : <br/>}<span className='font-bold text-[#0972c1] text_16'>１ファイルのサイズ上限は４ＭＢです。</span></label>
                           </div>
                           <div className='flex w-[93%]'>
                              <div className="grid w-1/2">
                                 <label className='text font-bold'>表面<span className='font-bold text-red-500 font_small'>「必須」</span></label>
                                 <div className='upload_img flex items-center'>
                                    <label htmlFor="img1">{selectedFile1 ? file1 : 'ファイルを選ぶ'}</label>
                                    <input className='mt-1' id='img1' type="file" style={{ display :"none"}} 
                                       {...register("front")}
                                       onChange={(e) => handleFileSelect1(e)}  
                                    />
                                 </div>
                              </div>
                              <div className="grid w-1/2">
                                 <label className='text  font-bold'>裏面<span className='font-bold text-red-500 font_small'>「必須」</span></label>
                                 <div className='upload_img flex items-center'>
                                    <label htmlFor="img2">{selectedFile2 ? file2 : 'ファイルを選ぶ'}</label>
                                    <input className='mt-1' type="file" id='img2' style={{ display :"none"}} 
                                       {...register("after")}
                                       onChange={(e) => handleFileSelect2(e)}
                                    />
                                 </div>
                              </div>
                           </div>
                           <div className='flex w-[93%]'>
                              <div className="grid w-1/2">
                                 {(size1 > '4000000') && (
                                    <h4 className="text-red-500 text_16">添付ファイルの最大サイズは 4 MB です</h4>
                                 )}
                                 {!checkfile1 && (
                                    <h4 className="text-red-500 text_16">ファイルを選択してください</h4>
                                 )}
                              </div>
                              <div className="grid w-1/2">
                                 {(size2 > '4000000') && (
                                    <h4 className="text-red-500 text_16">添付ファイルの最大サイズは 4 MB です</h4>
                                 )}
                                 {!checkfile2 && (
                                    <h4 className="text-red-500 text_16">ファイルを選択してください</h4>
                                 )}
                              </div>
                           </div>
                           <div className="grid">
                              <label className='text mt_6 font-bold text-[#385723]'>【本人確認書類】いずれかひとつの書類の表裏を添付してください。</label>
                              <div className={`mt-2 text-[#385723] font-bold ${screenWidth > 580 ? 'flex' : 'grid grid-cols-2'}`}>
                                 <div className={`block ${screenWidth >580 ? 'ml-8' : 'ml-4'}`}>
                                    <p>運転免許証</p>
                                    <p>パスポート</p>
                                 </div>
                                 <div className={`block ${screenWidth >580 ? 'ml-10' : 'ml-2'}`}>
                                    <p>運転経歴証明書</p>
                                    <p>特別永住者証明書</p>
                                 </div>
                                 <div className={`block ${screenWidth >580 ? 'ml-10' : 'ml-4 mt-2'}`}>
                                    <p>健康保険証</p>
                                    <p>在留カード</p>
                                 </div>
                              </div>
                           </div>
                           <p className='text_16 text-[#0563c1] mt_6'> <a href='../file/Privacy.pdf' target='_blank' className='text_16'>プライバシーポリシーをお読みいただき、同意の上、購入申し込みをしてください。</a></p>
                           <div className='flex radio-box'>
                              <input className='mt-1' type="checkbox"
                                 onChange={() => setIsChecked1(!isChecked1)}
                              />
                              <p className='text mt-2 ml-2 font-bold'>プライバシーポリシーに同意する</p>
                           </div>
                           <p className=' text-[#0972c1] mt-2'>重要なお知らせや関連ニュースを受け取るにチェックし、購入申し込みをしてください。</p>
                           <div className='flex radio-box'>
                              <input className='mt-1' type="checkbox"
                                 onChange={() => setIsChecked3(!isChecked3)}
                              />
                              <p className='text mt-2 ml-2 font-bold'>重要なお知らせや会議室・マンション関連のニュースを受け取ります。</p>
                           </div>
                           {isChecked1 && isChecked3 ?
                              <>
                              <div className="grid mt-3 w-[87%]">
                                 <label></label>
                                 <button type="submit" className='m-auto bg-[#A98260]'>購入を申し込む</button>
                              </div>
                              </>
                              :
                              <>
                              <div className="grid mt-3 w-[87%]">
                                 <label></label>
                                 <button type="submit" className='m-auto opacity-70 bg-[#A98260]' disabled >購入を申し込む</button>
                              </div>
                              </>
                           }
                        </form>
                     </div>
                  </div>
               </div>
               </>
               :<>
               <div className='block w_check m-auto' ref={divOne}>
                  <h2 className='text-center font-bold my-4'>購入申込内容の確認</h2>
                     <div className={`m-auto w-full ${screenWidth > 580 ? 'px-[2rem]' :'px-[1rem]' }`}>
                        <p>契約者の氏名： {dataForm.name}</p>
                        <p>契約者の氏名（フリガナ）：{dataForm.nameSpelling}</p>
                        <p>契約者の住所：{dataForm.address}</p>
                        <p>契約者のメールアドレス：{dataForm.email}</p>
                        <p>契約者の携帯電話番号：{dataForm.phone}</p>
                        <p>会議室・マンション販売価格：110万円／戸（税込み）</p>
                        <p>購入会議室・マンション戸数： {dataForm.numberApartment}</p>
                        <p>購入代金の合計金額： {110*Number(numberApartment)}万円（税込み）</p>
                        <p>本人確認書類の表裏写真を添付：添付あり </p>
                        <div className='my-4 check'>
                           <img src={image1} alt="" className='img_check mr-4 mb-2'/>
                           <img src={image2} alt="" className='img_check'/>
                        </div>
                        <div className='text-center my-4'>
                           <button className='bg-[#4472c4] mb-2'
                            onClick={()=>{
                              setSubmit(!submit)
                              }
                           }
                            >
                              購入申し込みを確定する</button>
                        </div>
                        <div className='my-8 flex '>
                           <div className={`${screenWidth > 580 ? 'w-1/3' : 'w-[18%] mr-4'}`}>
                              <button className='bg-white text_black bt_wh' 
                              onClick={()=>{
                                 setCheck(!check)
                                 setIsChecked1(!isChecked1)
                                 setIsChecked3(!isChecked3)
                                 setSubmit(false)
                                 }}
                              >戻る</button>
                           </div>
                           <div className={`text-right flex justify-end items-center  ${screenWidth > 580 ? 'w-2/3' : 'w-[80%]'}`}>
                              <button className='bg-white text_black bt_wh' onClick={()=>{setIsShowImage(!isShowImage)}}>特定商取引に関する法律に基づく表示</button>
                           </div>
                        </div>
                     </div>
                  </div>
               </>}
               </div>
               {!check ? <>
                  <h2 className="leading-normal text_black md:leading-relaxed mb-2 text-center font-bold mt-[4rem]">マンション（セミナールーム）ご購入に関するお手続きの流れ</h2>
                  <img src={Image_FlowRegister} alt='' className='m-auto' />
                  <h2 className="leading-normal text_black md:leading-relaxed mb-2 font-bold mt-[4rem]">特定商取引に関する法律に基づく表示</h2>
                  <img src={Image_InforCompany} alt='' className='m-auto' />
               </>:<></>}
            </div>
         </div>
         {notification && (
            <ModalNoti 
               event={() => {setNotification(false);
                  window.location.reload();
               }
               }
               title1='当申込フォームを送信いただいた時点では、まだ契約は成立しておりません。'
               title2='この後、当社より、ご購入いただく部屋番号を記載した契約書、購入代金と振込先について、メールにてご案内いたします。返信メールをご確認ください。'
            />
         )}
         {isShowImage && (
            <ModalImage 
            event={() => setIsShowImage(false)}
            />
         )}
      </Wrapper>
      </>
   )
}
export default IndividualContractPayerScreen
