import { Wrapper } from "./styled";
// import { Facebook, Twitter, Instagram, Line } from '@assets'
import { useTranslation } from "react-i18next";
import React from "react";
import { useHistory } from "react-router-dom";
import { FC, useState, useEffect } from "react";
import { items, items_end } from "./item";
import { NavLink } from "react-router-dom";
import useScreenWidth from "../../Hook/useScreenWidth";

interface Prop {
  language: any;
  setLanguage: any;
}
const Footer: FC<Prop> = ({ language, setLanguage }) => {
  const { t } = useTranslation();
  const [isHome, setIsHome] = useState(false);
  const history = useHistory();
  const screenWidth = useScreenWidth();

  // const handleLangChange =(lang :any) => {
  //   i18n.changeLanguage(lang);
  // };
  useEffect(() => {
    if (history.location.pathname === "/") {
      setIsHome(true);
    }
    if (
      history.location.pathname === "/sitemap-page/" ||
      history.location.pathname === "/sitemap-page"
    ) {
      const timer = setTimeout(() => {
        scrollFun("end");
      }, 400);
      return () => clearTimeout(timer);
    }
  }, [history.location.pathname]);

  const handleClick = (item: any) => {
    if (item.path === "/") {
      window.location.replace("/");
    }
    if (item.path === "/RecruitmentInformation") {
      window.location.replace("/RecruitmentInformation");
    }
    if (item.path === "/inquiry") {
      window.location.replace("/inquiry");
    }
    if (item.path === "/Access") {
      window.location.replace("/Access");
    }
  };

  const scrollFun = (id: string) => {
    document
      .querySelector(`#id${id}`)
      ?.scrollIntoView({ block: "center", behavior: "smooth" });
  };

  return (
    <Wrapper className="block">
      <div className="footer flex">
        <div className="w-1/5">
          <div className="policy cursor-pointer">
            <div className="mt-[5px]">
              <a href="../file/Privacy.pdf" target="_blank">
                {t("privacypolicy")}
              </a>
            </div>
          </div>
        </div>
        <div className="w-3/5 flex justify-center">
          <div
            className={`icon flex ${isHome ? "bg-[#f4f3ed]" : "bg-[#f4f3ed]"}`}
          >
            <div className="left"></div>
            <div className="flex contain mt-1 justify-center">
            </div>
            <div className="right"></div>
          </div>
        </div>
        <div className="w-1/5 flex justify-end">
          <div className="policy cursor-pointer">
            <div className="mt-[5px]">
              <a href="../file/Web.pdf" target="_blank" className="mt-[5px]">
                {t("WebsiteTermsofUse")}
              </a>
            </div>
          </div>
          {/* <div className='bt_lang' onClick={() => {setLanguage(!language)}}>
                {language ? 
                <><button className='title' onClick ={() => {handleLangChange("jp")}}> Japanese </button></>
                :<><button className='title'  onClick ={() => {handleLangChange("en")}}>English</button></>}
              </div>  */}
        </div>
      </div>
      <div className="menu_footer flex">
        <div className="w-[8%]">
          <NavLink to="/">
            <p className=" cursor-pointer item font-bold">
              {language ? <> Home</> : <> ホーム</>}
            </p>
          </NavLink>
        </div>
        {items?.map((item, index) => (
          <div
            className={`${
              item.id === "2"
                ? "w-[28%]"
                : item.id === "4"
                ? "w-[16%]"
                : item.id === "3"
                ? "w-[20%]" :"w-[28%]"
            }`}
            onClick={() => handleClick(item)}
            key={index}
          >
            <p className=" cursor-pointer item font-bold">
              {language ? <> {item.nameen}</> : <>{item.namejp}</>}
            </p>
            <div className="block ">
              {item.menu?.map((item, index) => (
                <NavLink to={item.path} key={index}>
                  <p
                    className=" cursor-pointer item mt-3"
                    onClick={() => {
                      scrollFun(item.id);
                    }}
                  >
                    {language ? <> {item.nameen}</> : <>{item.namejp}</>}
                  </p>
                </NavLink>
              ))}
            </div>
          </div>
        ))}
        {items_end?.map((item, index) => (
          <div
            className={`${item.id === "8" ? "w-[10%]" : "w-[9%]"}`}
            onClick={() => handleClick(item)}
            key={index}
          >
            <p className=" cursor-pointer item font-bold text-center">
              {language ? <> {item.nameen}</> : <>{item.namejp}</>}
            </p>
          </div>
        ))}
      </div>
      <div className="menu_print">
        <div className="menu_footer_print block">
          <div className="w-full">
            <NavLink to="/">
              <p className=" cursor-pointer item font-bold">
                {language ? <> Home</> : <> ホーム</>}
              </p>
            </NavLink>
          </div>
          {items?.map((item, index) => (
            <div
              className="w-full"
              onClick={() => handleClick(item)}
              key={index}
            >
              <p className=" cursor-pointer item font-bold mt-2">
                {language ? <> {item.nameen}</> : <>{item.namejp}</>}
              </p>
              <div className="grid grid-cols-3 ml-4">
                {item.menu?.map((item, index) => (
                  <NavLink to={item.path} key={index}>
                    <p
                      className=" cursor-pointer item mt-3"
                      onClick={() => {
                        scrollFun(item.id);
                      }}
                    >
                      {language ? <> {item.nameen}</> : <>{item.namejp}</>}
                    </p>
                  </NavLink>
                ))}
              </div>
            </div>
          ))}
          {items_end?.map((item, index) => (
            <div
              key={index}
              className="w-full"
              onClick={() => handleClick(item)}
            >
              <p className=" cursor-pointer item font-bold mt-2">
                {language ? <> {item.nameen}</> : <>{item.namejp}</>}
              </p>
            </div>
          ))}
        </div>
      </div>
      <div className="text-center text-[20px] leading-normal md:leading-relaxed font-bold ">
        <p id="idend">アートメタバースランド株式会社</p>
        {screenWidth > 991 ? (
          ""
        ) : (
          <>
            {" "}
            <p className="font-bold">
              東京都公安委員会許可　{screenWidth > 580 ? "" : <br />}
              <span className={` ${screenWidth > 580 ? "" : "text-[14px]"}`}>
                ３０５５７２２１７６９７
              </span>
            </p>
          </>
        )}
      </div>
      <div className="policy_down flex">
        <div className="policy_ cursor-pointer block ">
          <div className="flex justify-end">
            <div className="mt-[5px] mr-12">
              <a href="../file/Privacy.pdf" target="_blank">
                {t("privacypolicy")}
              </a>
            </div>
            <a href="../file/Web.pdf" target="_blank" className="mt-[5px]">
              {t("WebsiteTermsofUse")}
            </a>
          </div>
          <div className="flex mt-[1.9rem]">
            <p className="font-bold">
              東京都公安委員会許可　３０５５７２２１７６９７
            </p>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default Footer;
