import React from 'react'
import { Wrapper } from './styled'
import { NavLink } from "react-router-dom";
const OperationVideoPhoto = () => {
   return (
      <>
      <Wrapper>
         <div className='container_video block relative'>
            <div className='content block'>
               <div className="timeline"> 
               <div className="timeline__event  animated fadeInUp delay-3s">
                  <div className="timeline__event__date">ビデオ・フォト鑑賞ルーム<br className='brPrint'/>の運営</div>
                  <div className="timeline__event__content text-center" >
                     <div className="timeline__event__description">
                        <div className='cont text-justify'>
                           <p>　お友達やご家族が同じ部屋に集まって、一緒にＳＮＳを楽しめるビデオ・フォト鑑賞ルームを運営します。</p>
                           <p>　素晴らしいコンテンツ投稿者には、チップを渡せる仕組みを導入します。</p>
                        </div>
                        <NavLink to='/ServiceProduct/OperationVideoPhoto'>
                           <button>詳細</button>
                        </NavLink>
                     </div>
                  </div>
               </div>
               </div>
            </div>
         </div>
       </Wrapper>
      </>
   )
}
export default OperationVideoPhoto
