import React from 'react'
import { Wrapper } from './styled'
const Event = () => {
   return (
      <>
      <Wrapper>
         <div className='container_event block'>
            <div className='content block'>
               <div className="timeline"> 
               <div className="timeline__event  animated fadeInUp delay-3s">
                  <div className="timeline__event__date">イベントホールの賃貸</div>
                  <div className="timeline__event__content " >
                     <div className="timeline__event__description text-justify" id='id2'>
                        <p>　コンサートやイベントや大規模会議が行えるイベントホールを賃貸いたします。</p>
                     </div>
                  </div>
               </div>
               </div>
            </div>
         </div>
         
      </Wrapper>
      </>
   )
}
export default Event
