import React , {useState, useEffect} from 'react'
import { Wrapper } from './styled'
import ModalImage from './modalImage'
import ModalImage01 from './modalImage01'
import VideoNoiThat from '@assets/VideoNoithat.mp4'
import VideoNoiThatMobile from '@assets/Videonoithat_Mobile.mp4'
import { Page01, Image_21, Table02_21, Person_21, Page1_03, Page1_04,Page1_05, Page1_07, DIAGRAM02_45,Person_Family, Image_Video_02,Image_FlowRegister,Image_InforCompany, Table2_03, Table2_04} from '@assets'
import useScreenWidth from '../../../../Hook/useScreenWidth'
import { ApplicationContactUsInformation } from '../../../../APIs';
//@ts-ignore
import { useForm } from "react-hook-form";
import ModalNoti from '@components/modalNoti';

const Condominium = () => {
   const [isShowImage, setIsShowImage] = useState(false);
   const [isShowImage01, setIsShowImage01] = useState(false);
   const [isShowImage02, setIsShowImage02] = useState(false);
   const [isShowImage03, setIsShowImage03] = useState(false);
   const [isCheckRule, setIsCheckRule] = useState(false);

   useEffect(() => {
      window.scrollTo(0, 0)
   },[])

   const scrollFun = () => {
      document
        .querySelector(`#id1`)
        ?.scrollIntoView({ block: "center", behavior: "smooth" });
    }

    const screenWidth = useScreenWidth()
    const {
      register,
      handleSubmit,
      setValue,
      formState: { errors }
   } = useForm();
   const [notification, setNotification] = React.useState(false);

   const onSubmit = (data : any) => {
      ApplicationContactUsInformation({
         name : `${data.firstname}${data.lastname}`,
         email: data.email,
         data: `${data.firstname}${data.lastname} 様<br/>
               <br/>このたびは、弊社会議室・マンション購入申込フォームをご請求いただき、まことにありがとうございます。
               <br/>以下に３つの購入申込フォームのURLを記載いたしますので、お客様の契約内容にあう購入申込フォームを選択いただき購入申込をいただきたくお願い申し上げます。
               <br/><br/>１．個人用（ご契約者様=代金負担者様）　・・・　契約者様ご本人が購入代金を負担される一番シンプルな契約形態です。
               <br/>　　　<a href='https://artmetaverseland.co.jp/SeminarRooms/IndividualContractPayer'>https://artmetaverseland.co.jp/SeminarRooms/IndividualContractPayer</a>
               <br/><br/>２．個人用（ご契約者様≠代金負担者様）　・・・　生前贈与など、契約者様と購入代金負担者様が異なる契約形態です。
               <br/>　　　<a href='https://artmetaverseland.co.jp/SeminarRooms/IndividualContractNoPayer'>https://artmetaverseland.co.jp/SeminarRooms/IndividualContractNoPayer</a>
               <br/><br/>３．法人用　・・・　法人が契約者となり購入代金を負担する契約形態です。
               <br/>　　　<a href='https://artmetaverseland.co.jp/SeminarRooms/CorporateContractsCondominiums'>https://artmetaverseland.co.jp/SeminarRooms/CorporateContractsCondominiums</a>
               <br/> ________________________________________________________
               <br/><br/>アートメタバースランド株式会社
               <br/>〒176-0022　東京都練馬区向山３－１５－１９
               <br/>電話番号　０３－６６３６－７８９３
               <br/>メールによるお問い合わせは、弊社ホームページお問い合わせフォームからお願いいたします。
               <br/> ________________________________________________________
               `,
         cc: '',
         subject: '会議室・マンション購入申込フォームのご連絡および購入申込フォーム入力のお願い',
         attachments: ''
      },setNotification)
      ApplicationContactUsInformation({
         name : `${data.firstname}${data.lastname}`,
         email: 'maison@artmetaverseland.co.jp',
         data: `${data.firstname}${data.lastname} 様<br/>
               <br/>このたびは、弊社会議室・マンション購入申込フォームをご請求いただき、まことにありがとうございます。
               <br/>以下に３つの購入申込フォームのURLを記載いたしますので、お客様の契約内容にあう購入申込フォームを選択いただき購入申込をいただきたくお願い申し上げます。
               <br/><br/>１．個人用（ご契約者様=代金負担者様）　・・・　契約者様ご本人が購入代金を負担される一番シンプルな契約形態です。
               <br/>　　　<a href='https://artmetaverseland.co.jp/SeminarRooms/IndividualContractPayer'>https://artmetaverseland.co.jp/SeminarRooms/IndividualContractPayer</a>
               <br/><br/>２．個人用（ご契約者様≠代金負担者様）　・・・　生前贈与など、契約者様と購入代金負担者様が異なる契約形態です。
               <br/>　　　<a href='https://artmetaverseland.co.jp/SeminarRooms/IndividualContractNoPayer'>https://artmetaverseland.co.jp/SeminarRooms/IndividualContractNoPayer</a>
               <br/><br/>３．法人用　・・・　法人が契約者となり購入代金を負担する契約形態です。
               <br/>　　　<a href='https://artmetaverseland.co.jp/SeminarRooms/CorporateContractsCondominiums'>https://artmetaverseland.co.jp/SeminarRooms/CorporateContractsCondominiums</a>
               <br/><br/>■記載内容：
               <br/>　　氏名：${data.firstname}${data.lastname}
               <br/>　　メール：${data.email}
               <br/> ________________________________________________________
               <br/><br/>アートメタバースランド株式会社
               <br/>〒176-0022　東京都練馬区向山３－１５－１９
               <br/>電話番号　０３－６６３６－７８９３
               <br/>メールによるお問い合わせは、弊社ホームページお問い合わせフォームからお願いいたします。
               <br/> ________________________________________________________
               `,
         cc: '',
         subject: '会議室・マンション購入申込フォームのご連絡および購入申込フォーム入力のお願い',
         attachments: ''
      },setNotification)
   };
   return (
      <>
      <Wrapper>
      <div className='container_con block'>
            <div className='content'>
               <h1 className="text-[30px] leading-normal md:leading-relaxed mb-2 font-bold text-center">高機能メタバース会議室・マンションの分譲販売</h1>
               <div className='flex mt_3r block-1'>
                  <div className='block w-[100%]'>
                     <div className='flex'>
                        <p className='text-yellow-400'>■ </p>
                        <p>アートメタバースランドの海岸線に立つ高機能２７階建て会議室・マンション１０棟（Ａ棟～Ｊ棟）</p>
                     </div>
                     <p className='ml-[1rem] text-[18px]'><span className='text-[#c00000]' >限定１０００戸</span>を先行割引販売します。</p>
                     <div className='flex  mt-4'>
                        <p className='text-yellow-400'>■ </p>
                        <p>法人が所有し、自社施設（オフィス、研修施設）として利用すれば、オフィスコストの大幅な削減が可能です。</p>
                     </div>
                     <p className='ml-[1rem] text-[18px]' >賃料、維持管理費、修繕費、固定資産税等のコスト負担から解放され、働き方改革、DXを推進できます。</p>
                     <div className='flex  mt-4'>
                        <p className='text-yellow-400'>■ </p>
                        <p>個人が所有し、貸会議室として賃貸すれば、高機能収益資産として活用できます。</p>
                     </div>
                     <p className='ml-[1rem] text-[18px]' >転売も可能な未来志向の新しい資産を組み込むことで、資産の多様化、効率化をすすめることができます。</p>
                     <div className='flex mt-4'>
                        <p className='text-yellow-400'>■ </p>
                        <p>１戸の広さは２６０㎡、スクール形式で１００名まで収容可能。ワンフロア４戸、全戸とも同じ間取りです。</p>
                     </div>
                     <div className='flex mt-4'>
                        <p className='text-yellow-400'>■ </p>
                        <p>ご利用開始時期は、アートメタバースランドが開園する２０２４年７月です。</p>
                     </div>
                  </div>
                     {screenWidth > 991 &&(
                        <div className='block absolute ml_bt btNoPrint' >
                           <div className='text-center'>
                              <button onClick={() => scrollFun()} className="bg-[#2f5597]"><p className='my-2'>会議室・マンション<br/>購入を申し込む</p></button>
                           </div>
                           <div className='text-center'>
                              <a href="../file/EXPOチラシ原稿　会議室マンション.pdf" download target='_blank'>
                                 <button className="bg-white text_black">会議室・マンション<br/>広告資料 １<br/>ダウンロード</button>
                              </a>
                           </div>
                           <div className='text-center'>
                              <a href="../file/ArtMetaverseLand.pdf" download target='_blank'>
                                 <button className="bg-white text_black">会議室・マンション<br/>広告資料 ２<br/>ダウンロード</button>
                              </a>
                           </div>
                        </div>
                     )}
               </div>
               {screenWidth < 992 &&(
                  <>
                  <div className='text-center'>
                      <a href="../file/EXPOチラシ原稿　会議室マンション.pdf" download target='_blank'>
                        <button className="bg-white text_black">会議室・マンション広告資料 １ダウンロード</button>
                     </a>
                  </div>
                  <div className='text-center'>
                     <a href="../file/ArtMetaverseLand.pdf" download target='_blank'>
                        <button className="bg-white text_black">会議室・マンション広告資料 ２ダウンロード</button>
                     </a>
                  </div>
                  </>
               )}
               <div className='mt_5r '>
                  <h2 className="leading-normal md:leading-relaxed mb-2 font-bold">会議室・マンション室内の映像</h2>
                  
                     {screenWidth > 991 ? 
                     <>
                        <div className='w-[102%] pr-6 mt-4'>
                           <video autoPlay playsInline controls muted loop  className='border-white border-[5px]'>
                              <source src={VideoNoiThat} type='video/mp4'></source>
                           </video>
                        </div>
                     </>
                     : 
                     <>
                        <div className='w-[102%] pr-[10px] mt-4'>
                           <video autoPlay playsInline controls muted loop  className='border-white border-[5px] my_3r  w-[106%]'>
                              <source src={VideoNoiThatMobile} type='video/mp4'></source>
                           </video>
                        </div>
                     </>
                     }
                     <div className='imageVideo my-[2rem]'>
                         <img src={Image_Video_02} alt='' className='h-full m-auto' />
                     </div>
               </div>
               <div className='mt_5r '>
                  <h2 className="leading-normal md:leading-relaxed mb-2 font-bold">会議室・マンション１０棟（Ａ棟～Ｊ棟）部屋番号</h2>
                  <div className='flex mt-4 block-2'>
                     <div className='noprint w-1/2'>
                        <img src={Page1_03} alt='' className='w-full img_page1_03'/>
                     </div>
                     <div className='print'>
                        <img src={Page1_03} alt='' className='w-full imgprint'/>
                     </div> 
                     <img src={Page01} alt='' className='w-1/2 rounded-xl img2 imgprint' />
                  </div>
               </div>
               <div className='mt_3r '>
                  <h3 className="leading-normal md:leading-relaxed mb-2 font-bold">
                     先行割引販売価格：<span>１００万円（税別）</span>
                  </h3>
                  <div className='block mt_3r '>
                     <h2 className="leading-normal md:leading-relaxed mb-2 font-bold text_black">ご留意頂きたいこと</h2>
                     <div className={`${ (screenWidth > 580) ? 'ml-6' : 'ml-2'}`}>
                        <div className='flex mt-4'>
                           <p className='text-[#f4b084]'>●</p>
                           <p> 販売は申込先着順とさせていただきます。</p>
                        </div>
                        <div className='flex mt-4'>
                           <p className='text-[#f4b084]'>●</p>
                           <p> ご購入いただく棟、部屋番号のご指定はお受けできません。</p>
                        </div>
                        <div className='flex mt-4'>
                           <p className='text-[#f4b084]'>●</p>
                           <p> すべての部屋からの眺望は、２０階の部屋の眺望と同じ景色になります。</p>
                        </div>
                        <div className='flex mt-4'>
                           <p className='text-[#f4b084]'>●</p>
                           <p> お客様お一人あたりの購入上限戸数は４０戸とさせていただきます。</p>
                        </div>
                        <div className='flex mt-4'>
                           <p className='text-[#f4b084]'>●</p>
                           <p> アートメタバースランド開園２０２４年７月以降の正規販売価格は１２０万円（税別）です。</p>
                        </div>
                     </div>
                  </div>
               </div>
               <div className='text-center block-3 mb-[3rem] mt_2r'>
                  <button onClick={() => scrollFun()} className="bg-[#2f5597]">会議室・マンション購入を申し込む</button>
               </div>
               <div className='mt_5r block-4'>
                  <h2 className="leading-normal md:leading-relaxed mb-2 font-bold ">アートメタバースランド全体地図<br/>(２０２４年７月開園時の完成予想図)</h2>
                  <div className='pt-8 text-center'>
                     <div className='noprint'>
                        <img src={Page1_04} alt='' className='m-auto ' />
                     </div>
                     <div className='print'>
                        <img src={Page1_04} alt='' className='m-auto w-[100%] h-[550px]' />
                     </div> 
                  </div>
                  {screenWidth > 991 &&(
                     <div className='text-center mt_2r'>
                        <button  onClick={() => setIsShowImage01(!isShowImage01)} className="bg-[#a98260]">クリックすると拡大</button>
                     </div>
                  )}
               </div>
               <div className='mt_5r '>
                  <div className='block mt_3r '>
                     <h2 className="leading-normal md:leading-relaxed mb-2 font-bold ">当社メタバース会議室・マンションの仕様</h2>
                     <div className={`${ (screenWidth > 580) ? 'ml-6' : 'ml-2'}`}>
                        <div className='flex mt-4'>
                           <p className='text-yellow-400'>■</p>
                           <p> 海岸線に立つ高機能２７階建て会議室・マンション１０棟（Ａ棟～Ｊ棟）</p>
                        </div>
                        <div className='flex mt-4'>
                           <p className='text-yellow-400'>■</p>
                           <p> １戸の広さは２６０㎡、スクール形式で１００名まで収容可能。ワンフロア４戸、全戸とも同じ間取りです。</p>
                        </div>
                        <div className='flex mt-4'>
                           <p className='text-yellow-400'>■</p>
                           <div className='block'>
                              <p> ワンクリック操作で、用途にあわせてレイアウト変更できる機能を装備。</p>
                              <p>会議室　⇨　セミナールーム　⇨　ホール　⇨　リビングルーム</p>
                           </div>
                        </div>
                        <div className='flex mt-4'>
                           <p className='text-yellow-400'>■</p>
                           <div className='block'>
                              <p> 各戸にPDF資料や動画を掲示できるスクリーンを設置。</p>
                              <p>セミナールームや貸会議室などの商業利用時に、力を発揮する必須のツールです。</p>
                           </div>
                        </div>
                        <div className='flex mt-4'>
                           <p className='text-yellow-400'>■</p>
                           <p> 利用可能デバイス は、パソコンWEB、スマートフォン、VRゴーグル</p>
                        </div>
                     </div>
                  </div>
               </div>
               <div className='block mt_3r '>
                  <h2 className="leading-normal md:leading-relaxed mb-2 font-bold text_black">ご留意頂きたいこと</h2>
                  <div className={`${ (screenWidth > 580) ? 'ml-6' : 'ml-2'}`}>
                     <div className='flex mt-4'>
                        <p className='text-[#f4b084]'>●</p>
                        <p> メタバース不動産は、当社のメタバース空間上で不動産として利用できるデータ資産です。</p>
                     </div>
                     <div className='flex mt-4'>
                        <p className='text-[#f4b084]'>●</p>
                        <p> マンション260㎡：メタバース空間上のデータ配置に応じた面積であり、現実の面積ではありません。</p>
                     </div>
                  </div>
               </div>
               <div className='mt_3r block-4'>
                  <div className='noprint'>
                     <img src={Image_21} alt='' className={`m-auto ${ (screenWidth > 580) ? 'w-[65%]' : 'w-full'}`} />
                  </div>
                  <div className='print'>
                     <img src={Image_21} alt='' className= 'w-[50%] m-auto'/>
                  </div> 
                  {screenWidth > 991 &&(
                      <button className='m-auto flex bg-[#a98260]' onClick={() => setIsShowImage(!isShowImage)}>クリックすると拡大</button>
                  )}
               </div>
               <div className='mt_5r '>
                  <div className='block mt_3r '>
                     <h2 className="leading-normal md:leading-relaxed mb-2 font-bold ">当社メタバース会議室・マンションの特徴</h2>
                     <div className={`${ (screenWidth > 580) ? 'ml-6' : 'ml-2'}`}>
                        <div className='flex mt-4'>
                           <p>①</p>
                           <div className='block'>
                              <p>リアル会議室・研修施設の代替施設として使用すれば、オフィスコストの大幅な削減が可能です。</p>
                              <p>また、移動時間の節約も可能となり、働き方改革、DXを推進できます。</p>
                           </div>
                        </div>
                        <div className='flex mt-4'>
                           <p>②</p>
                           <p>購入時に手数料等はかかりません。また、維持修繕費、管理費、固定資産税等もかかりません。</p>
                        </div>
                        <div className='flex mt-4'>
                           <p>③</p>
                           <p>万全な開発リソースにサポートされ、継続的に機能向上が享受できます。</p>
                        </div>
                        <div className='flex mt-4'>
                           <p>④</p>
                           <div className='block'>
                              <p>地理的な商圏に左右されない利便性の高い資産です。商業利用や転貸も可能となっています。</p>
                              <p>当社では、マンション賃貸希望のお客様をマッチングするサービスを検討中です。</p>
                           </div>
                        </div>
                        <div className='flex mt-4'>
                           <p>⑤</p>
                           <div className='block'>
                              <p>高度な開発資源により制作される希少性が高い資産です。今回の開発戸数は限定１０００戸です。</p>
                           </div>
                        </div>
                        <div className='flex mt-4'>
                           <p>⑥</p>
                           <p>転売が可能です。投資対象資産として購入いただけます。</p>
                        </div>
                        <div className='flex mt-4'>
                           <p>⑦</p>
                           <p>利用価値に比べ割安な価格設定です。将来的な価格上昇が期待できます。</p>
                        </div>
                        <div className='flex mt-4'>
                           <p>⑧</p>
                           <p>高機能な建物が付帯する当社メタバース不動産は、土地のみメタバースに比べ高付加価値資産です。</p>
                        </div>
                        <div className='flex mt-4'>
                           <p>⑨</p>
                           <p>多くの集客機能を内包する複合施設ゆえに、単機能メタバースに比べて高付加価値が維持されます。</p>
                        </div>
                        <div className='flex mt-4'>
                           <p>⑩</p>
                           <p>ＩＤとパスワードさえあれば、オーナーは世界中どこからでもアクセス可能な資産です。</p>
                        </div>
                     </div>
                  </div>
               </div>
               <div className='mt_3r block-6 '>
                  <h2 className="leading-normal md:leading-relaxed mb-2 font-bold mt_3r">【ニュースで見るメタバース不動産の販売状況】</h2>
                     <div className={`${ (screenWidth > 580) ? 'ml-6' : 'ml-2'}`}>
                        <p>Game Business.jp記事</p>
                        <a href='https://www.gamebusiness.jp/article/2022/10/18/20942.html' className='underline text-[#4472c4]'>Web3.0型メタバース「XANA」がNFTの土地6億円分を9時間で完売 | GameBusiness.jp</a>
                        <p className='mt-3'>US:Front Line(アメリカの最新ビジネス・生活情報サイト)記事</p>
                        <a href='https://usfl.com/news/131972' className='underline text-[#4472c4]'>メタヴァースの仮想不動産への投資、すでに過熱気味か ～ ２～３ヵ月で500％も急騰した例も | U.S. FrontLine | フロントライン (usfl.com)</a>
                        <p className='mt-3'>三井住友信託銀行ホームページ解説</p>
                        <a href='https://www.smtb.jp/personal/entrustment/entrustment-column/column-02' className='underline text-[#4472c4]'>生前贈与は早く始めるほど効果的！生前贈与のメリットや注意点を解説 | 資産管理・承継 | 三井住友信託銀行 (smtb.jp)</a>
                     </div>
                     <div className='text-center mt_2r'>
                        <button onClick={() => scrollFun()} className="bg-[#2f5597]">会議室・マンション購入を申し込む</button>
                     </div>
               </div>
               <div className='mt_5r block-7'>
                  <h2 className="leading-normal md:leading-relaxed mb-2 font-bold mt-8 text-center">大幅なオフィスコスト削減効果<br/>（法人が所有し自社利用するケース）</h2>
                  <img src={Table2_03} alt='' className='py-6 m-auto'/>
                  <p className='ml-6'>店舗相場TOWN記事</p>
                  <a href='https://souba.builds.jp/tenant/13' className='underline text-[#4472c4] ml-6'>東京都店舗賃料相場 - 店舗相場TOWN (builds.jp)</a>
                  <h2 className="leading-normal md:leading-relaxed mb-2 font-bold mt_5r text-center">当社メタバース会議室・マンションの高い収益性と資産価値</h2>
                  <h4 className='text_18 mt-4'>当社メタバース会議室・マンション（２６０㎡）を貸会議室として賃貸した場合の年間賃料収入の推計（ケース別）。</h4>
                  <div className='w-[90%] m-auto py-4'>
                     <p>・東京山手線内の２６０㎡の貸会議室の平均賃料相場は、１日利用の場合、約２０万円以上。</p>
                     <p>・メタバース会議場の賃料相場は、１日利用の場合、約２０万円以上。</p>
                  </div>
                  <img src={Table2_04} alt='' className='py-6 m-auto'/>
                  <h4 className='text_16'>※年間賃料収入 ＝ １日あたりの賃料収入×３６５日×稼働率</h4>
                  <h4 className='text_16'>※収益還元法（直接還元法）による潜在的な期待価格 ＝ 年間賃料収入（仮定）÷ 収益還元利回り（７％と仮定）</h4>
                  <h4 className='text_16'>（注意）推計期待価格は、あくまで推計であり、将来の価格を保証するものではありません。</h4>
               </div>
               <div className='mt_3r block-8  '>
                  <h2 className="leading-normal md:leading-relaxed mb-2 font-bold mt_3r">【リアル貸会議室とメタバース貸会議室の賃貸料金の例】</h2>
                     <div className={`${ (screenWidth > 580) ? 'ml-6' : 'ml-2'}`}>
                        <p>日本橋プラザ 貸会議室</p>
                        <a href='https://nihonbashiplaza.co.jp/price/' className='underline text-[#4472c4]'>料金表 | 東京駅、地下鉄日本橋駅からすぐ近くの展示ホール・会議室 「日本橋プラザ」 (nihonbashiplaza.co.jp)</a>
                        <p className='mt-3'>Esta青山 貸会議室</p>
                        <a href='https://www.kaigishitu.com/detail/12862/' className='underline text-[#4472c4]'>Esta青山（エスタ アオヤマ） - 青山 東京都 | 会議室.COM (kaigishitu.com)</a>
                        <p className='mt-3'>ZIKUメタバース 貸会議室</p>
                        <a href='https://ziku.inc/#price' className='underline text-[#4472c4]'>ZIKU（ジクウ）：アバターで出会える／つながる／体験するメタバースイベントプラットフォーム｜株式会社ジクウ｜TOP</a>
                     </div>
               </div>
               <div className='mb-8 block-11'>
                  <h2 className="leading-normal md:leading-relaxed mb-2 font-bold mt_2r text-center">曜日や時間で部屋の仕様を変更し部分賃貸する利用例</h2>
                  
                  <div className='noprint'>
                     <img src={Page1_05} alt='' className='w-[65%] m-auto' />
                  </div>
                  <div className='print'>
                     <img src={Page1_05} alt='' className='w-[50%] m-auto' />
                  </div>
               </div>
               <div className='mt_5r block-9'>
                  <h2 className="leading-normal md:leading-relaxed mb-2 font-bold text-center">生前贈与資産として活用した場合のイメージ</h2>
                  <div className='w-full'>
                     <div className='noprint'>
                        <img src={Page1_07} alt='' className='w-full ' />
                     </div>
                     <div className='print'>
                        <img src={Page1_07} alt='' className='w-[80%] m-auto h-[300px]' />
                     </div>
                  </div>
                  <div className={`w-[25%] ${ (screenWidth > 580) ? ' mt-[-7rem] ml-[3rem]' : ' mt-[-3rem]'}`}>
                     <img src={Person_21} alt='' className='m-auto rounded-xl img03Print' />
                  </div>
                  <div className='text-center mt_2r'>
                     <button onClick={() => scrollFun()} className="bg-[#2f5597]">会議室・マンション購入を申し込む</button>
                  </div>
               </div>
               <div className='mt_5r block-10'>
                  <h2 className="leading-normal md:leading-relaxed mb-2 font-bold text-center">バーチャル空間資産とその他資産の特性比較</h2>
                  <img src={Table02_21} alt='' />
                  <h4 className='mt-2 text_16 text-right'>２０２２年９月　当社調査</h4>
                  <h4 className={`text_16 ${screenWidth > 991 ? 'mt-[-1.4rem]' : ''}`}>*1単純な機能の個社メタバースを製作した場合でも5000万円～1億円の費用がかかります。</h4>
                  <h4 className='text_16'>*2バーチャル空間資産を賃貸した場合です。仮想通貨のステーキング、GOLDの消費寄託は、対象に含めていません。</h4>
                  <h4 className='text_16'>*3購入時には消費税の課税対象となりますが、売却時には消費税の受け取りとなります。売却益は課税対象です。</h4>
                  {screenWidth > 991 &&(
                     <div className='text-center mt-4'>
                        <button  onClick={() => setIsShowImage02(!isShowImage02)} className="bg-[#a98260]">クリックすると拡大</button>
                     </div>
                  )}
               </div>
               <div className='mt_3r block-8  '>
                  <h2 className="leading-normal md:leading-relaxed mb-2 font-bold mt_3r">【世界経済の状況】</h2>
                     <div className={`${ (screenWidth > 580) ? 'ml-6' : 'ml-2'}`}>
                        <a href='https://note.com/keith2000xxx/n/n0652d8729e65' className='underline text-[#4472c4]'>これからインフレはどうなる（インフレと国家債務の関係）</a><br/>
                        <a href='https://note.com/keith2000xxx/n/n65351cea8130' className='underline text-[#4472c4] mt-2'>2023年これからどんな経済状況になる？</a>
                     </div>
               </div>
               <div className='mb-8 block-11'>
                  <h2 className="leading-normal md:leading-relaxed mb-2 font-bold mt_5r text-center">当社メタバースの優位性　(同業メタバースとのサービス比較分析)</h2>
                  <h4 className={`text_16 `}>当社メタバースは、各サービスが相乗的に好循環を生み出しながら顧客満足を向上させていくように統合的な設計がなされています。</h4>
                  <h4 className={`text_16 mb-4`}>その為、単一機能から徐々にサービスを発展・拡張させてきた他社メタバースと比較すると、機能、質の両面で優位性を獲得しています。</h4>
                  <div className='noprint'>
                     <img src={DIAGRAM02_45} alt='' className=' w-full h_diag02_45' />
                  </div>
                  <div className='print'>
                     <img src={DIAGRAM02_45} alt='' className=' w-full h-[400px]' />
                  </div>
                  <h4 className='mt-2 text_16 text-right'>２０２２年4月　当社調査</h4>
                  {screenWidth > 991 &&(
                     <div className='text-center mt-4'>
                        <button  onClick={() => setIsShowImage03(!isShowImage03)} className="bg-[#a98260]">クリックすると拡大</button>
                     </div>
                  )}
               </div>
               <div className='h-family'>
                  <img src={Person_Family} alt='' className='rounded-xl h-full w-full' />
               </div>
               <div className='mt_3r '>
                  <h3 className="leading-normal md:leading-relaxed mb-2 font-bold">先行割引販売価格：<span>１００万円（税別）</span></h3>
                  <div className='block mt_3r '>
                     <h2 className="leading-normal md:leading-relaxed mb-2 font-bold text_black">会議室・マンションご購入に当たりご留意頂きたいこと</h2>
                     <div className={`${ (screenWidth > 580) ? 'ml-6' : 'ml-2'}`}>
                        <div className='flex mt-4'>
                           <p className='text-[#f4b084]'>●</p>
                           <p> 販売は申込先着順とさせていただきます。</p>
                        </div>
                        <div className='flex mt-4'>
                           <p className='text-[#f4b084]'>●</p>
                           <p> ご購入いただく棟、部屋番号のご指定はお受けできません。</p>
                        </div>
                        <div className='flex mt-4'>
                           <p className='text-[#f4b084]'>●</p>
                           <p> すべての部屋からの眺望は、２０階の部屋の眺望と同じ景色になります。</p>
                        </div>
                        <div className='flex mt-4'>
                           <p className='text-[#f4b084]'>●</p>
                           <p> お客様お一人あたりの購入上限戸数は４０戸とさせていただきます。</p>
                        </div>
                        <div className='flex mt-4'>
                           <p className='text-[#f4b084]'>●</p>
                           <p> 代金支払いは、後送する契約書を受領後１０日以内に銀行振込にてお願い致します。</p>
                        </div>
                        <div className='flex mt-4'>
                           <p className='text-[#f4b084]'>●</p>
                           <p> アートメタバースランド開園２０２４年７月以降の正規販売価格は１２０万円（税別）です。</p>
                        </div>
                     </div>
                  </div>
               </div>
               <div className='text-center mt-4 mb-[3rem]' id='id1'>
                  <h2 className="leading-normal md:leading-relaxed mb-2 font-bold mt_2r">会議室・マンション購入申込フォームを以下よりご請求ください。</h2>
               </div>
               {isShowImage && (
                  <ModalImage 
                  event={() => setIsShowImage(false)}
                  />
               )}
               {isShowImage01 && (
                  <ModalImage01 
                  event={() => setIsShowImage01(false)}
                  url={Page1_04}
                  width="w-[72%]"
                  />
               )}
               {isShowImage02 && (
                  <ModalImage01 
                  event={() => setIsShowImage02(false)}
                  url={Table02_21}
                  width="w-[90%]"
                  />
               )}
               {isShowImage03 && (
                  <ModalImage01 
                  event={() => setIsShowImage03(false)}
                  url={DIAGRAM02_45}
                  width="w-[90%]"
                  />
               )}
            </div> 
         </div>
         <div className='start-selling-contact block'>
                  <div className='item_fi flex'>
                     <div className='pl-1 block pb-4 w-full'>
                        <div className='block p-2 container_ '>
                           <div className='ml-4'>
                              <form onSubmit={handleSubmit(onSubmit)}>
                                 <div className="grid">
                                    <label className='text mt_6 font-bold'>氏名</label>
                                 </div>
                                 <div className={`flex ${ (screenWidth > 580) ? 'w-[96%]' : 'w-[92%]'}`}>
                                    <div className={`grid w-1/2 ${ (screenWidth > 580) ? '' : 'mr-2'}`}>
                                       <label className='text mt-2 font-bold'>姓<span className='font-bold text-red-500 font_small'>「必須」</span></label>
                                       <input className='mt-1' type="text"
                                          {...register("firstname", {
                                             required: true
                                          })}
                                          onChange={e => setValue("firstname", e.target.value.trimStart())}
                                       />
                                       {/* {errors.firstname && errors.firstname.type === "required" && (
                                          <p className="text-red-500 text-[12px]">姓を空にすることはできません</p>
                                       )} */}
                                    </div>
                                    <div className="grid w-1/2">
                                       <label className='text mt-2 font-bold'>名<span className='font-bold text-red-500 font_small'>「必須」</span></label>
                                       <input className='mt-1' type="text"
                                          {...register("lastname", {
                                             required: true
                                          })}
                                          onChange={e => setValue("lastname", e.target.value.trimStart())}
                                       />
                                    </div>
                                 </div>
                                 <div className="grid">
                                    <label className='text mt_6 font-bold'>メールアドレス<span className='font-bold text-red-500 font_small'>「必須」</span></label>
                                    <input className='mt-1' type="text"
                                       {...register("email", {
                                          required: true,
                                          pattern:{
                                             value:
                                                /^[A-Z0-9._+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                message: "無効なメールアドレス",
                                          }
                                       })}
                                       onChange={e => setValue("email", e.target.value.trimStart())}
                                    />
                                    {errors.email?.message && (
                                       <p className="text-red-500 text_16">{errors.email?.message?.toString()}</p>
                                    )}
                                 </div>
                                 <p className='text_16 text-center text-[#0563c1] mt_6 w-[92%]'> <a href='../file/Privacy.pdf' target='_blank' className='text_16'>プライバシーポリシーをお読みいただき、同意の上、ご請求ください。</a></p>
                                 <div className='flex justify-center w-[92%] radio-box'>
                                    <input className='mt-1' type="radio"
                                       {...register("policy", {
                                          required: true
                                       })}
                                       onChange={() => setIsCheckRule(!isCheckRule)}
                                    />
                                    <p className='text mt-2 ml-2 font-bold'>プライバシーポリシーに同意する</p>
                                 </div>
                                 {isCheckRule ?
                                    <>
                                    <div className="grid mt-3 w-[92%]">
                                       <label></label>
                                       <button type="submit" className='m-auto'>購入申込フォームを請求する</button>
                                    </div>
                                    </>
                                    :
                                    <>
                                    <div className="grid mt-3 w-[92%]">
                                       <label></label>
                                       <button type="submit" className='m-auto opacity-70 ' disabled >購入申込フォームを請求する</button>
                                    </div>
                                    </>
                                 }
                              </form>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <h2 className="leading-normal md:leading-relaxed mb-2 text-center text_black font-bold mt_2r">会議室・マンションご購入に関するお手続きの流れ</h2>
               <img src={Image_FlowRegister} alt='' className='m-auto' />
               <div className='w_infor'>
                  <h2 className="leading-normal md:leading-relaxed mb-2 text_black font-bold mt-[4rem]">特定商取引に関する法律に基づく表示</h2>
                  <img src={Image_InforCompany} alt='' className='mb-[10rem] w-full' />
               </div>
            {notification && (
            <ModalNoti 
               event={() => {setNotification(false);
                  window.location.reload();
               }
               }
               title1='メールの送信に成功しました。'
               title2=''
            />
         )}
      </Wrapper>
      </>
   )
}
export default Condominium
