import React, {useRef, useEffect} from 'react'
import { Wrapper } from './styled'
import {Person01_35, Person02_35} from '@assets'

const OfficersAndOrganization = () => {
   const divOne = useRef<HTMLInputElement | null>(null);
   const scrolLWithUseRef = () => {
   divOne.current?.scrollIntoView({ block: "center", behavior: "smooth" });
   };
 
   useEffect(() => {
    scrolLWithUseRef()
   },[])
   return (
      <>
      <Wrapper>
         <div className='container_off block'>
            <div className='contain_ text-justify'>
               <h1 className='text-[30px] leading-normal md:leading-relaxed mb-2 font-bold text-center' ref={divOne}>経営者プロフィール</h1>
               <div className='block mt-[3rem]'>
                  <div className='flex block-1'>
                     <img src={Person01_35} alt='' className=' w-[16%] h-[20%] rounded-xl mr-4' />
                     <div className='block w-res m-auto'>
                        <p className='text-[20px]' >代表取締役 社長 ＣＥＯ</p>
                        <p className='text-[15px] ml-6'>ﾐﾔｶﾞﾜ　ﾀｹｱｷ</p>
                        <div className='flex ml-7 mb-[2rem]'>
                           <h2 className='mb-2 font-bold text-lg md:text-1xl mr-4'>宮川　武揚</h2>
                           <p className=''>1963年7月8日　生まれ</p>
                        </div>
                        <div className='flex'>
                           <p id='id4' className='w_5r1'>1986年</p>
                           <p>慶應大学　商学部　卒</p>
                        </div>
                        <div className='flex mt-4'>
                           <p className='w_5r1'>　〃　　　</p>
                           <p>日本生命保険相互会社入社</p>
                        </div>
                        <div className='flex mt-4'>
                           <p className='w_5r'>1989年</p>
                           <p>企業年金財政シミュレーション業務、米国会計基準ＦＡＳ８７計算業務に従事。</p>
                        </div>
                        <div className='flex mt-4'>
                           <p className='w_5r'>1993年</p>
                           <div className='block'>
                              <p >中小企業事業団（現　独立行政法人　中小企業基盤整備機構）</p>
                              <p>・小規模共済制度の財政検証システム構築。</p>
                              <p className='ml-8'>日本生命にてシステム要件設計し、富士通総研にてシステム構築</p>
                              <p className='ml-8'>中小企業政策審議会に事務局として出席（制度減額の法律改正支援）</p>
                           </div>
                        </div>
                        <div className='flex mt-4'>
                           <p className='w_5r'>1998年</p>
                           <div className='block'>
                              <p >東日本旅客鉄道、東海旅客鉄道、日本テレコム（現ソフトバンク）</p>
                              <p>・分割民営化後に停滞していた資本政策に貢献（生保の株式保有を推進）</p>
                           </div>
                        </div>
                        <div className='flex mt-4'>
                           <p className='w_5r'>2003年</p>
                           <div className='block'>
                              <p >全日本空輸の年金制度改正を提案・実施。（ＮＨＫクローズアップ現代に出演）</p>
                              <p>・大企業初のＤＣ年金導入。退職給付債務圧縮により業績回復に大きく貢献。</p>
                           </div>
                        </div>
                        <div className='flex mt-4'>
                           <p className='w_5r'>2010年</p>
                           <div className='block'>
                              <p >長野県建設業厚生年金基金</p>
                              <p>・事務局長２4億円横領事件について、総幹事として訴訟収束対応を担当。</p>
                           </div>
                        </div>
                        <div className='flex mt-4'>
                           <p className='w_5r'>2015年</p>
                           <div className='block'>
                              <p >日本電気、三菱電機</p>
                              <p>・（経団連年金部会長）日本電気にヘッジファンドの新しい管理手法提案。</p>
                           </div>
                        </div>
                        <div className='flex mt-4'>
                           <p className='w_5r'>2019年</p>
                           <div className='block'>
                              <p >地方公務員共済組合連合会（地方公務員の公的年金２８兆円の運用機関）</p>
                              <p>・リスク管理手法を提言。</p>
                              <p >国家公務員共済組合連合会（国家公務員の公的年金１０兆円の運用機関）</p>
                              <p>・長期財政シミュレーションシステム開発支援。</p>
                           </div>
                        </div>
                        <div className='flex mt-4'>
                           <p className='w_5r'>2022年</p>
                           <div className='block'>
                              <p >アート メタバース ランド 株式会社 設立 代表取締役社長CEOに就任。</p>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className='flex mt-[4rem] block-1'>
                     <img src={Person02_35} alt='' className=' w-[16%] h-[20%] rounded-xl mr-4' />
                     <div className='block w-res m-auto'>
                        <p className='text-[20px]' >取締役常務 ＣＴＯ</p>
                        <p className='text-[15px] ml-6'>ﾐﾔｶﾞﾜ　ｱﾂﾓﾘ</p>
                        <div className='flex ml-7 mb-[2rem]'>
                           <h2 className='mb-2 font-bold text-lg md:text-1xl mr-4'>宮川　篤盛</h2>
                           <p className=''>1994年4月7日　生まれ</p>
                        </div>
                        <div className='flex'>
                           <p className='w_5r'>2019年</p>
                           <div className='block'>
                              <p>愛媛大学　工学部　情報工学科　卒　　（ＶＲ技術専攻）</p>
                              <div className='flex mt-4'>
                                 <p className='w_100'>・2018年</p>
                                 <div className='block'>
                                    <p >大学対抗システム技術コンテストにて第三位受賞</p>
                                    <p>(ＶＲ面接システムプログラムを作成し出展)</p>
                                    <p>[  enPiTビジネスシステム分野ワークショップ　in 筑波大学  ]</p>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className='flex mt-4'>
                           <p className='w_5r'>2019年</p>
                           <div className='block'>
                              <p>株式会社エイジング入社</p>
                              <p>・ＮＴＴドコモ Ｒ＆Ｄセンタ（横須賀研究開発施設）派遣勤務</p>
                           </div>
                        </div>
                        <div className='flex mt-4'>
                           <p className='w_5r'>2021年</p>
                           <div className='block'>
                              <p >株式会社クリープ入社</p>
                              <p>・ゲーム開発エンジンＵnityを活用し、メタバース関連業務に従事</p>
                              <p>（ＶＲチャット、Zepetoのワールド構築、アバター作成、セッティング）</p>
                              <p className='mt-4'>・ＶＲ座標移動360度視覚システム（ＶＲlive viewer for mobile）開発。</p>
                           </div>
                        </div>
                        <div className='flex mt-4'>
                           <p className='w_5r'>2022年</p>
                           <p>アート メタバース ランド 株式会社 取締役常務CTOに就任。</p>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </Wrapper>
      </>
   )
}
export default OfficersAndOrganization
