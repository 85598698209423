import styled from 'styled-components'

export const Wrapper = styled.div`
width: 100%;
padding: 6rem 0 10rem; 
@media screen and (max-width: 1620px){
  padding: 4rem 0 10rem;
}
@media screen and (max-width: 991px){
  padding: 1rem 0 10rem;
}
@media print{
  padding:0 0 4rem 0;
}
.container_policy{
    width: 97%;
    margin :auto;
    @media screen and (max-width: 1620px) {
      width: 100%;
    }
    @media (max-width: 991px) {
      margin-top: 80px;
    }
    @media print{
      h1{
        font-size: 20px;
      }
    }
    p{
        line-height: 1.6;
        font-size: 19px;
        @media print{
          font-size: 14px;
        }
        @media screen and (max-width: 1620px) {
          font-size: 18px;
        }
        @media screen and (max-width: 1280px) {
          font-size: 16px;
        }
        @media screen and (max-width: 580px) {
          font-size: 14px;
        }
      }
      h2{
        font-size: 24px;
        color: #4472c4;
        @media screen and (max-width: 1620px) {
          font-size: 20px;
        }
        @media print{
          font-size: 16px;
        }
      }
    img{
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }
    i{
    font-style: inherit;
    }
    .img1{
      @media screen and (max-width: 991px){
        width: 70%;
        h1{
          font-size: 1.5rem;
        }
      }
      @media screen and (max-width: 400px){
        width: 90%;
      }
    }


}

`