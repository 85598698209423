import React , {useRef, useEffect,useState} from 'react'
import { Wrapper } from './styled'
import { Table_43 } from '@assets';
import ModalImage from './modalImage';
import useScreenWidth from '../../../Hook/useScreenWidth'

const ManagementGoalsandBenchmarks = () => {
   const divOne = useRef<HTMLInputElement | null>(null);
   const scrolLWithUseRef = () => {
   divOne.current?.scrollIntoView({ block: "center", behavior: "smooth" });
   };
   const [isShowImage, setIsShowImage] = useState(false);
 
   useEffect(() => {
    scrolLWithUseRef()
   },[])
   const screenWidth = useScreenWidth()
   return (
      <>
      <Wrapper>
         <div className='container_g block text-justify'>
            <div className='section-1 relative'>
               <div className='content'>
                  <h1 className='text-[30px] leading-normal md:leading-relaxed font-bold text-center ' ref={divOne}>経営目標とベンチマーク</h1>
                  <h2 className='text-[18px] my-[2rem]'>市 場 分 析</h2>
                  <div className='flex mb-8 mt-16 block-1'>
                     <div className='p-2 w-1/2 my-[1rem]  item mr-[0.5rem] item1'>
                        <div className='title'>
                           <p className='text font-bold'>ＳＮＳ市場</p>
                        </div>
                        <div id='id2' >
                           <div className='m_center'>
                              <div className='flex'>
                                 <p className='text-yellow-400'>■ </p>
                                 <div className='block'>
                                    {screenWidth > 580 ? 
                                    <>
                                       <p>ワールドワイドでサービスを提供しているLine（韓国）、</p>
                                       <p className='mt-2'>You Tube（米国）、Twitter（米国）、Instagram（米国）、</p>
                                       <p className='mt-2'>Facebook（米国）、TikTok（中国）外資系６大SNSが、</p>
                                       <p className='mt-2'>大きな存在感を示している。</p>
                                    </>
                                    :
                                    <>
                                      <p>ワールドワイドでサービスを提供しているLine（韓国）、You Tube（米国）、Twitter（米国）、Instagram（米国）、Facebook（米国）、TikTok（中国）外資系６大SNSが、大きな存在感を示している。</p>
                                    </>
                                    }
                                 </div>
                              </div>
                              <div className={`flex ${screenWidth > 580 ? 'mt-16' : ' mt-4'}`}>
                                 <p className='text-yellow-400'>■ </p>
                                 <div className='block'>
                                    {screenWidth > 580 ? 
                                    <>
                                        <p>当社は、ｎｏｔｅ、ツイキャス、Pocochaの３社を経営上</p>
                                       <p className='mt-2'>のベンチマークとする。主要な指標はMAU（マンスリー</p>
                                       <p className='mt-2'>アクティブユーザー数）とする。</p>
                                    </>
                                    :
                                    <>
                                      <p>当社は、ｎｏｔｅ、ツイキャス、Pocochaの３社を経営上のベンチマークとする。主要な指標はMAU（マンスリーアクティブユーザー数）とする。</p>
                                    </>
                                    }
                                 </div>
                              </div>
                            </div>
                        </div>
                     </div>
                     <div className='p-2 w-1/2 my-[1rem] item ml-[0.5rem] item2'>
                        <div className='title'>
                           <p className='text font-bold'>ＥＣモール市場</p>
                        </div>
                        <div >
                           <div className='m_center'>
                              <div className='flex'>
                                 <p className='text-yellow-400'>■ </p>
                                 <div className='block'>
                                    {screenWidth > 580 ? 
                                    <>
                                       <p>商品の物流を伴う事業の為、日本国内に経営基盤を持つ</p>
                                       <p className='mt-2'>ＥＣモール、楽天市場、Amazon、Yahoo!ショッピング</p>
                                       <p className='mt-2'>の存在感が大きい。外資系企業の参入障壁は高く、日本</p>
                                       <p className='mt-2'>企業が善戦している。</p>
                                    </>
                                    :
                                    <>
                                      <p>商品の物流を伴う事業の為、日本国内に経営基盤を持つＥＣモール、楽天市場、Amazon、Yahoo!ショッピングの存在感が大きい。外資系企業の参入障壁は高く、日本企業が善戦している。</p>
                                    </>
                                    }
                                 </div>
                              </div>
                              <div className={`flex ${screenWidth > 580 ? 'mt-8' : ' mt-4'}`}>
                                 <p className='text-yellow-400'>■ </p>
                                 <div className='block'>
                                    {screenWidth > 580 ? 
                                    <>
                                       <p>各社の出店者に対する手数料体系は、</p>
                                       <p className='mt-2'>楽天市場は「固定費５万円／月+売上×一定割合」、</p>
                                       <p className='mt-2'>Amazon、Yahoo!ショッピングは「売上×一定割合」のみ</p>
                                       <p className='mt-2'>を採用している。この結果、楽天は少数精鋭の商店構成と</p>
                                       <p className='mt-2'>なっている。当社は楽天型の手数料体系を採用する。</p>
                                    </>
                                    :
                                    <>
                                      <p>各社の出店者に対する手数料体系は、楽天市場は「固定費５万円／月+売上×一定割合」、Amazon、Yahoo!ショッピングは「売上×一定割合」のみを採用している。この結果、楽天は少数精鋭の商店構成となっている。当社は楽天型の手数料体系を採用する。</p>
                                    </>
                                    }
                                 </div>
                              </div>
                              <div className={`flex ${screenWidth > 580 ? 'mt-8' : ' mt-4'}`}>
                                 <p className='text-yellow-400'>■ </p>
                                 <div className='block'>
                                    {screenWidth > 580 ? 
                                    <>
                                       <p>当社は、楽天市場、ポンパレモールを経営上のベンチ</p>
                                       <p className='mt-2'>マークとする。主要な指標は年間アクセス数とする。</p>
                                    </>
                                    :
                                    <>
                                      <p>当社は、楽天市場、ポンパレモールを経営上のベンチマークとする。主要な指標は年間アクセス数とする。</p>
                                    </>
                                    }
                                 </div>
                              </div>
                            </div>
                        </div>
                     </div>
                  </div>
                  <h2 className='mt-[6rem] mb-[1rem]'>経営目標・シナリオ</h2>
               </div>
            </div>
            <div className='section-2' >
               <img src={Table_43} alt='' />
               {screenWidth > 991 &&(
                  <button  onClick={() => setIsShowImage(!isShowImage)} className="bg-[#a98260] mt-4">クリックすると拡大</button>
               )}
            </div>
         </div>
         {isShowImage && (
            <ModalImage 
            event={() => setIsShowImage(false)}
            />
         )}
      </Wrapper>
      </>
   )
}
export default ManagementGoalsandBenchmarks
