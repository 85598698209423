import React from 'react'
import { Wrapper } from './styled'
import Condominium from './condominium'
import ECMall from './ECMall'
import OperationStory from './operationStory'
import OperationVideoPhoto from './operationVideoPhoto'
import OperationMarriage from './operationMarriage'
import Event from './Event'
import AIAvatar from './AIAvatar'
import Providing from './providing'
import Avatar from './Avatar'

const ServiceProductScreen = () => {
   return (
      <>
      <Wrapper id="intro">
         <div className='container-service flex-wrap grid w-full pt-[3rem] pb-[10rem]'>
            <Condominium/>
            <ECMall/>
            <Event/>
            <OperationStory/>
            <OperationVideoPhoto/>
            <OperationMarriage/>
            <Avatar/>
            <AIAvatar/>
            <Providing/>
         </div>
      </Wrapper>
      </>
   )
}
export default ServiceProductScreen
