import React from 'react'
import { Wrapper } from './styled'
import { NavLink } from "react-router-dom";
const AIAvatar = () => {
   return (
      <>
      <Wrapper>
         <div className='container_ai block'>
            <div className='content block'>
               <div className="timeline"> 
               <div className="timeline__event  animated fadeInUp delay-3s">
                  <div className="timeline__event__date">ＡＩアバター店員の賃貸</div>
                  <div className="timeline__event__content text-center" >
                     <div className="timeline__event__description">
                        <div className='cont text-justify'>
                           <p className="text-1xl md:text-1xl leading-normal md:leading-relaxed mb-2 text-center text-[#c01600] font-bold">※ 特許申請中・審査請求済 </p>
                           <p>　ＡＩを搭載し、自律的にお客様対応できるアバター店員を開発いたしました。</p>
                           <p>　ＥＣモールに出店頂いている事業主様に賃貸いたします。店舗運営にかかる店員の人件費を大幅に削減することが可能です。</p>
                        </div>
                        <NavLink to='/ServiceProduct/AIAvatar'>
                           <button>詳細</button>
                        </NavLink>
                     </div>
                  </div>
               </div>
               </div>
            </div>
         </div>
      </Wrapper>
      </>
   )
}
export default AIAvatar
