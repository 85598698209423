import styled from 'styled-components'

export const Wrapper = styled.div`

.title_{
   font-style: normal;
   font-weight: 700;
   line-height: 35px;
   color: #2F1B11;
   letter-spacing: 1px;
 }
 .image_{
   @media screen and (min-width: 1920px){
     margin-left: 5rem;
     margin-top : -20px;
   }
 }
 .img_house{
   width : 80%;
   margin-left : 65%;
   @media screen and (min-width: 1920px){
      width :90%;
   }
 }
 .img_person{
   width : 70%;
   margin-left : 30%;
 }
 .img_unit{
   width :128%;
   max-width :128% ;
   margin-left : 50px;
 }
 th, td{
   border : 0.4px solid #b28e70;
   padding : 7px 45px;
   text-align: center;
 }
 th,td{
  background: #ede5d4;
 }
 p{
    line-height: 1.6;
    font-size: 19px;
    @media print{
      font-size: 14px;
    }
    @media screen and (max-width: 1620px) {
      font-size: 18px;
    }
    @media screen and (max-width: 1280px) {
      font-size: 16px;
    }
    @media screen and (max-width: 580px) {
      font-size: 14px;
    }
  }
  .w_first{
    width: 105px;
    @media screen and (max-width: 1620px) {
      width: 90px;
    }
    @media screen and (max-width: 1280px) {
      width: 90px;
    }
  }
.container_about{
    width: 100%;
    padding: 6rem 0 10rem;
    @media screen and (max-width: 1620px){
      padding: 4rem 0 10rem;
    }
    @media screen and (max-width: 991px){
    padding: 1rem 0 10rem;
    }
    @media print{
      padding:2rem 0 0rem;
      h1{
        font-size: 20px;
      }
      .ml_print01{
        margin-left: -40px;
      }
      .ml_print03{
        margin-left: -50px;
      }
    }
      .section-1{
        width: 85%;
        margin: auto;
          h1{
            color: #2F1B11;
          }
        @media screen and (max-width: 991px) {
          width: 100%;
        }
        }
        .text-custom {
          span {
            &:nth-child(2) {
              padding-left: 10px;
            }
            &:nth-child(3) {
              padding-left: 15px;
            }
          }

          @media screen and (max-width: 991px) {
            span {
              &:nth-child(3) {
                display: block;
                padding-left: 0;
              }
            }
          }

          @media screen and (max-width: 480px) {
            margin-left: 2px;
            span {
              &:nth-child(2) {
                padding-left: 5px;
              }
            }
          }
        }

        @media screen and (max-width: 991px) {
          margin-top: 80px;
          h1{
          font-size: 1.5rem;
        }
        }
        @media screen and (max-width: 645px) {
            .flex {
              flex-direction: column;

              .block-1, .block-2 {
                width: 100%;
              }
            }
            .img-box {
              flex-direction: column-reverse;

              >div {
                &:first-child {
                  width: 100%;
                }
                &:last-child {
                  width: 40%;
                  margin: 0 auto;
                }
              }

              .block {
                .flex {
                  display: block;

                  &:nth-child(3) {
                    .block {
                      margin-left: 10px;
                    }
                  }

                  p {
                    width: 100%;
                  }
                }
              }
            }
        }
      }
      .section-2{
        width: 85%;
        margin: auto;
        display : flex; 
        padding-top: 2rem;
        @media print{
          display: block;
          margin-bottom: 3rem;
          img{
            margin: 2rem 0;
          }
        }
        @media screen and (max-width: 991px) {
          flex-direction: column;
          margin-top: 20px;

          >div {
            width: 100%;

            &:last-child {
              margin-top: 30px;
            }

            img {
              padding-left: 0;
            }
          }
        }
        @media screen and (max-width: 650px) {
          >div {
            &:first-child {
              div {
                width: 100%;
              }
            }
          }
          table {
            width: 100%;
            thead {
              tr {
                th {
                  font-size: 12px;
                  padding: 5px;
                }
              }
            }
            tbody {
              tr {
                td {
                  padding: 5px;
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
      .animation {
        color: #fff;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        }
      .trans-left{
        -webkit-transition: all 1s ease-in-out;
        transition: all 1s ease-in-out;
    }
    
  @media screen and (max-width: 480px) {
    .leading-normal {
      span {
        display: block;
      }
    }
  }
 
`