import React, {useRef, useEffect} from 'react'
import { Image_build} from '@assets';
import { Wrapper } from './styled'
import useScreenWidth from '../../../Hook/useScreenWidth'
const AboutUs = () => {
   const divOne = useRef<HTMLInputElement | null>(null);
   const scrolLWithUseRef = () => {
   divOne.current?.scrollIntoView({ block: "center", behavior: "smooth" });
   };
 
   useEffect(() => {
    scrolLWithUseRef()
   },[])
   const screenWidth = useScreenWidth()
   return (
      <>
      <Wrapper>
         <div className='container_about block'>
            <div className='section-1 block' >
               <h1 className='text-[30px] leading-normal md:leading-relaxed font-bold text-center' ref={divOne}>会社概要</h1>
               <div className='flex text-justify mt-[3rem]'>
                  <div className='block w-full block-1 '>
                     <div className='flex pt-3'>
                        <p className='title_ w_first'>商号　</p>
                        <p className={`pt-1 ml_print01 leading-normal ${ (screenWidth > 580) ? '' : 'ml-2'}`}>アートメタバースランド株式会社　<span>（　ART METAVERSE LAND, Inc.　）</span></p>
                     </div>
                     <div className='flex pt-2' >
                        <p className='title_ w_first'>所在地</p>
                        {/* <p className={`pt-1 ${ (screenWidth > 580) ? '' : 'ml-2'}`}>
                           〒176-0022　東京都練馬区向山３丁目１５－１９　　　　電話番号　０３－６６３６－７８９３
                        </p> */}
                        <p className={`pt-1 text-custom ${ (screenWidth > 580) ? '' : 'ml-2'}`}>
                           <span>〒176-0022</span>
                           <span>東京都練馬区向山３丁目１５－１９</span>
                           <span>電話番号　０３－６６３６－７８９３</span>
                        </p>
                     </div>
                     <div className='flex pt-2'>
                        <p className='title_ w_first'>定款目的</p>
                        <div className={`block ${ (screenWidth > 580) ? '' : 'ml-2'}`}>
                           <p className='pt-2 '>(１)メタバース（仮想都市空間）を利用した商業施設サイトの運営及び管理</p>
                           <p className='pt-2'>(２)仮想通貨の取得、売却、保有及び運用 </p>
                           <p className='pt-2'>(３)トークン、ＮＦＴ（非代替性トークン）の企画、管理及び運用</p>
                           <p className='pt-2'>(４)ＶＲ（バーチャルリアリティー）技術、各種機器の企画、開発及び販売</p>
                           <p className='pt-2'>(５)各種アプリケーションソフトの企画、開発、制作、配信、保守、管理、運営、販売及びそれらの受託</p>
                           <p className='pt-2'>(６)デジタルアート、美術品の販売及び仲介</p>
                           <p className='pt-2'>(７)不動産、貴金属の保有及び管理</p>
                           <p className='pt-2'>(８)古物営業法に基づく古物営業及び古物競りあっせん業</p>
                           <p className='pt-2'>(９)前各号に附帯関連する一切の事業</p>
                           <p className='text-[13px] pt-2'>（注1）弊社では、暗号資産交換業は行いません。</p>
                           {screenWidth > 580 ? 
                           <>
                              <p className='text-[13px] pt-2'>（注2）独自のブロックチェーンを持つ暗号資産を仮想通貨、イーサリアム等に依存し、</p>
                              <p className='text-[13px] pt-2 ml-11'>そのブロックチェーンを使用するものをトークンとして区分しています。</p>
                           </>
                           :
                           <>
                              <p className='text-[13px] pt-2'>（注2）独自のブロックチェーンを持つ暗号資産を仮想通貨、イーサリアム等に依存し、そのブロックチェーンを使用するものをトークンとして区分しています。</p>
                           </> 
                           }
                        </div>
                     </div>
                  </div>
               </div>
               <div className='flex pt-2'>
                  <p className='title_ w_first'>株式総数</p>
                  <p className={`pt-1 text ml_print03 ${ (screenWidth > 580) ? '' : 'ml-2'}`}>３００，０００，０００株</p>
               </div>
               <div className='flex pt-2'>
                     <p className='title_ w_first'>資本金</p>
                     <p className={`pt-1 text ml_print03 ${ (screenWidth > 580) ? '' : 'ml-2'}`}>３，０００，０００円</p>
               </div>
               <div className='flex pt-2'>
                     <p className='title_ w_first'>決算日</p>
                     <p className={`pt-1 text ml_print03 ${ (screenWidth > 580) ? '' : 'ml-2'}`}>３月３１日</p>
               </div>
               <div className='flex pt-2'>
                     <p className='title_ w_first'>設立日</p>
                     <p className={`pt-1 text ml_print03 ${ (screenWidth > 580) ? '' : 'ml-2'}`}>２０２２年４月１日</p>
               </div>
               <div className='flex pt-2'>
                     <p className='title_ w_first'>役員</p>
                     <div className='block'>
                        <p className={`pt-1 text leading-normal ${ (screenWidth > 580) ? '' : 'ml-2'}`}>代表取締役社長ＣＥＯ　　宮川　武揚　<span>（Ｍｉｙａｇａｗａ　Ｔａｋｅａｋｉ）</span></p>
                        <p className={`pt-1 text leading-normal ${ (screenWidth > 580) ? '' : 'ml-2'}`}>取締役常務ＣＴＯ　　　　宮川　篤盛　<span>（Ｍｉｙａｇａｗａ　Ａｔｓｕｍｏｒｉ）</span></p>
                     </div>
               </div>
            </div>
            <div className='section-2 mt-[3rem]'>
               <div className='w-1/2 '>
                  <div className='block w-[530px] m-auto '>
                     <p className='title_ w-[150px]'>【株主構成】</p>
                     <table className="table-fixed">
                     <thead>
                        <tr>
                           <th>株主氏名</th>
                           <th>保有株数</th>
                           <th>保有率</th>
                        </tr>
                     </thead>
                     <tbody>
                        <tr>
                           <td>宮川　武揚 </td>
                           <td>234,000,000株</td>
                           <td>78%</td>
                        </tr>
                        <tr>
                           <td>宮川　篤盛</td>
                           <td>45,000,000株</td>
                           <td>15%</td>
                        </tr>
                        <tr>
                           <td>家族株主５名</td>
                           <td>21,000,000株</td>
                           <td>7%</td>
                        </tr>
                        <tr>
                           <td>合　計</td>
                           <td>300,000,000株</td>
                           <td>100%</td>
                        </tr>
                     </tbody>
                     </table>
                  </div>
               </div>
               <div className='m-auto'>
                  <img src={Image_build} alt='' className=' pl-[6rem]'/>
               </div>
            </div>
         </div>
      </Wrapper>
      </>
   )
}
export default AboutUs
