import React, {useEffect, useRef} from 'react'
import { Wrapper } from './styled'
import { Image_diag1, Image_diag2, Image_diag3 } from '@assets';
import useScreenWidth from '../../../Hook/useScreenWidth'

const ManageBalancePlan = () => {
   const divOne = useRef<HTMLInputElement | null>(null);
   const scrolLWithUseRef = () => {
   divOne.current?.scrollIntoView({ block: "center", behavior: "smooth" });
   };
 
   useEffect(() => {
    scrolLWithUseRef()
   },[])

   const screenWidth = useScreenWidth()
   return (
      <>
      <Wrapper>
         <div className='container_plan block text-justify'>
            <h1 className='text-[30px] text-center mb-[3rem] leading-normal md:leading-relaxed font-bold ' ref={divOne}>経営収支計画</h1>
            <h2 className='mb-[2rem]' id='id3'>基本シナリオⅠの収支計画</h2>
            <div className='content'>
               <div className='flex pb-8 items-center'>
                  <div className='w-[55%] block pr-6'>
                     <div className='flex' >
                        <p className='text-yellow-400'>■ </p>
                        <div className='block'>
                           {screenWidth > 580 ? 
                           <>
                              <p >メタバースランドが開園する第三事業年度からは</p>
                              <p className='mt-2'>営業黒字となり、以降、利益は増大し、開園5年後の</p>
                              <p className='mt-2'>2028年度は１０億6890万円の営業利益となる計画です。</p>
                           </>
                           :
                           <>
                              <p>メタバースランドが開園する第三事業年度からは営業黒字となり、以降、利益は増大し、開園5年後の2028年度は１０億6890万円の営業利益となる計画です。</p>
                           </>
                           }
                        </div>
                     </div>
                  </div>
                  <div className='w-[45%] '>
                     <img src={Image_diag1} alt='' className={`w-[100%] m-auto ${ (screenWidth > 580) ? 'h-[300px]' : 'h-[200px]'}`} />
                  </div>
               </div>
               <div className='flex pb-8 items-center'>
                  <div className='w-[55%] block pr-6'>
                     <p className='title_ font-bold text-[14px]'>[基本シナリオⅠの内容]</p>
                     <div className='ml-4 mt-4 text-[12px]'>
                        <div className='flex mt-6' >
                           <p className='text-[#f4b084]'>●</p>
                           <div className='block'>
                              <p>ＳＮＳ系事業：</p>
                              <p className={`${ (screenWidth > 580) ? 'ml-6' : 'ml-0'}`}>設立５年目2028年度にPocochaと同等の水準を目指す。</p>
                           </div>
                        </div>
                        <div className='flex mt-6' >
                           <p className='text-[#f4b084]'>●</p>
                           <div className='block'>
                              <p>ＥＣモール事業：</p>
                              <p className={`${ (screenWidth > 580) ? 'ml-6' : 'ml-0'}`}>設立9年目2032年にホンパレモールの半分の水準を目指す。</p>
                              <div className={` mt-6 ${ (screenWidth > 580) ? 'ml-6' : 'ml-0'}`}>
                                 {screenWidth > 580 ? 
                                    <>
                                       <p>具体的には、開園５年目2028年度に、ＳＮＳ系事業の</p>
                                       <p>ＭＡＵ（月間アクティブユーザー）が、サービス開始</p>
                                       <p>５年目のPocochaと同水準となるよう推計。</p>
                                    </>
                                    :
                                    <>
                                       <p>具体的には、開園５年目2028年度に、ＳＮＳ系事業のＭＡＵ（月間アクティブユーザー）が、サービス開始５年目のPocochaと同水準となるよう推計。</p>
                                    </>
                                    }
                                    {screenWidth > 580 ? 
                                    <>
                                       <p>この時、ＥＣモール事業は、年間アクセス回数が、</p>
                                       <p>開園９年目2032年にサービス開始９年目のポンパレ</p>
                                       <p>モールの半分の水準に到達します。</p>
                                    </>
                                    :
                                    <>
                                       <p>この時、ＥＣモール事業は、年間アクセス回数が、開園９年目2032年にサービス開始９年目のポンパレモールの半分の水準に到達します。</p>
                                    </>
                                    }
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className='w-[45%] block image'>
                     <img src={Image_diag2} alt='' className={`w-[100%] m-auto ${ (screenWidth > 580) ? 'h-[300px]' : 'h-[200px]'}`}/>
                  </div>
               </div>
               <div className='flex pb-8 items-center'>
                  <div className='w-[55%] block pr-6'>
                     <p className='title_ font-bold text-[14px]'>[収支計画の前提]</p>
                     <div className='ml-4 mt-4 text-[12px]'>
                        <div className='flex mt-6' >
                        <p className='text-[#f4b084]'>●</p>
                           <div className='block'>         
                              {screenWidth > 580 ? 
                              <>
                                  <p >基本シナリオⅠの推計にあたり、当社の主力事業である</p>
                                 <p>「ＳＮＳ系事業」と「ＥＣモール事業」に分け、</p>
                                 <p>「ＳＮＳ系事業」は、Pocochaをベンチマークとし、</p>
                                 <p>また、「ＥＣモール事業」は、ポンパレモールをベンチ</p>
                                 <p>マークとしました。</p>
                              </>
                              :
                              <>
                                 <p>基本シナリオⅠの推計にあたり、当社の主力事業である「ＳＮＳ系事業」と「ＥＣモール事業」に分け、「ＳＮＳ系事業」は、Pocochaをベンチマークとし、また、「ＥＣモール事業」は、ポンパレモールをベンチマークとしました。</p>
                              </>
                              }
                           </div>
                        </div>
                        <div className='flex mt-6' >
                        <p className='text-[#f4b084]'>●</p>
                           <div className='block'>
                              {screenWidth > 580 ? 
                              <>
                                 <p >１次登録者（無料サービス利用者）と２次登録者</p>
                                 <p>有料サービス利用者）の人数を推計しました。</p>
                              </>
                              :
                              <>
                                 <p>１次登録者（無料サービス利用者）と２次登録者有料サービス利用者）の人数を推計しました。</p>
                              </>
                              }
                              <p>次に２次登録者数から各サービス利用者を推計しました。</p>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className='w-[45%] '>
                     <img src={Image_diag3} alt='' className={`w-[100%] m-auto ${ (screenWidth > 580) ? 'h-[300px]' : 'h-[200px]'}`}/>
                  </div>
               </div>
            </div>
         </div>
      </Wrapper>
      </>
   )
}
export default ManageBalancePlan
