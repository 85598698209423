import React, {useRef, useEffect} from 'react'
import { Wrapper } from './styled'
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
const Condominium = () => {
   const { t} = useTranslation();
   const divOne = useRef<HTMLInputElement | null>(null);
   const scrolLWithUseRef = () => {
   divOne.current?.scrollIntoView({ block: "center", behavior: "smooth" });
   };
   
   useEffect(() => {
      scrolLWithUseRef()
   },[])
   return (
      <>
      <Wrapper>
         <div className='container_condominium block'>
            
            <div className='content block'>
               <div className="timeline"> 
               <div className="timeline__event  animated fadeInUp delay-3s">
                  <div className="timeline__event__date">会議室・マンション<br className='brPrint'/>の販売</div>
                  <div className="timeline__event__content text-center " >
                     <div className='cont'>
                        <div className="timeline__event__description text-justify">
                           <p>　多用途に利用できる多機能な会議室・マンションをご用意いたしました。</p>
                           <p>　オフィスコストの大幅削減が可能です。個人所有も可能です。</p>
                           <p className='text-[#b70202] font-bold text-center' ref={divOne}>{t("pageServiceProduct.condominium.limited")}１０００{t("pageServiceProduct.condominium.units")}{t("pageServiceProduct.condominium.text_03")}</p>
                        </div>
                     </div>
                     <NavLink
                     to='/SeminarRooms'>
                        <button > {t("pageServiceProduct.condominium.details")}</button>
                     </NavLink>
                  </div>
               </div>
               </div>
            </div>
         </div>
      </Wrapper>
      </>
   )
}
export default Condominium
