import styled from 'styled-components'

export const Wrapper = styled.header`
   position: absolute;
   width: 100%;
   background: rgb(139 198 225);
   padding-bottom: 2rem;
   font-size: 16px;
   @media screen and (max-width: 1620px){
      font-size: 14px;
    }
   @media screen and (max-width: 1280px){
      font-size: 11px;
    }
  .footer{
    width: 1100px;
    margin: auto;
    @media screen {
      width: 90%;
    }
    @media screen and (max-width: 1620px){
      a{
        font-size: 12px;
      }
    }
    @media screen and (max-width: 1280px){
        width: 70%;
    }
    .policy{
    height: 20px;
    padding: 1.5rem 0;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    text-decoration-line: underline;
    display: flex;
    
    @media print {
      display: none;
    }
    @media screen and (min-width: 992px){
          display: none;
     }
     @media screen and (max-width: 580px) {
      display: block;
     }
    }
    .bt_lang{
    box-sizing: border-box;
    width: 126px;
    height: 30px;
    opacity: 0.8;
    border: 1px solid #FFFFFF;
    right :7rem;
    margin-top: 18px;
    @media screen and (min-width: 992px){
          display: none;
     }
     .title{
      width: 100%;
      height: 100%;

      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;

          }
    }
    .icon{
    width: 50%;
    @media print {
      width: 80%;
    }
      .contain{
        width: 100%;
        @media screen and (max-width: 1280px){
          margin-left: 0px;
        }
        .mr_img{
          margin-right: 2rem;
          @media screen and (max-width: 500px) {
            margin-right: 0.3rem;
          }
        }
      }
      .left{
        border-right: 60px solid transparent;
	      border-bottom: 50px solid rgb(139 198 225);
        margin-left: -1px;
        margin-bottom: -1px;
      }
      .right{
        border-left: 60px solid transparent;
	      border-bottom: 50px solid rgb(139 198 225);
        margin-right: -1px;
        margin-bottom: -1px;
      }
    }

    @media screen and (max-width: 991px) {
      >div {
        flex: 1;

        &.policy {
          flex-direction: column;

          a {
            font-size: 13px;
          }
        }

        &:nth-child(2) {
          flex: 2;

          .icon {
            width: 92%;
          }
        }
      }
    }

    @media screen and (max-width: 767px) {
      width: 100%;
      padding: 0 20px;

      >div {

        &.policy {
          div {
            margin-right: 0;
          }

          a {
            font-size: 12px;
          }
        }

        &:nth-child(2) {
          flex: 2;

          .icon {
            width: 92%;
          }
        }
      }
    }

    @media screen and (max-width: 580px) {
      img {
        margin-right: 10px;
      }
    }

    @media screen and (max-width: 480px) {
      img {
        margin-right: 5px;
      }
    }

    @media screen and (max-width: 400px) {
      img {
        width: 17px;
        height: 17px;
      }
    }
  }
  .menu_print{
    .menu_footer_print{
    padding: 1rem 0 0rem;
    width: 90%;
    margin: auto;
    @media screen and (max-width: 991px){
      padding: 1.2rem 0 0rem;

      >div {
        display: none;
      }
    }

    @media screen and (max-width: 560px){
      padding: 40px 0;
    }
  }
    @media screen {
    display: none;
    }
    @media print {
      font-size: 12px;
    }
  }
  .menu_footer{
    padding: 4rem 0 0rem;
    @media print {
      display: none;
    }
    width: 1250px;
    margin: auto;
    @media screen and (max-width: 1620px){
        width: 1100px;
    }
    @media screen and (max-width: 1280px){
        width: 70%;
    }
    @media screen and (max-width: 991px){
      padding: 1.2rem 0 0rem;

      >div {
        display: none;
      }
    }

    @media screen and (max-width: 560px){
      padding: 40px 0;
    }
  }
  .policy_down{
      width: 90%;
      margin: auto;
      justify-content: right;
      @media screen and (max-width: 1620px){
        a{
          font-size: 16px;
        }
      }
      @media screen and (max-width: 1280px){
        a{
          font-size: 12px;
        }
        }
      @media screen and (max-width: 991px){
          display: none;
      }
      @media print{
        width: 90%;
      }
      .policy_{
      margin-top: -5rem;
      height: 20px;
      padding-bottom: 3rem;

      font-style: normal;
      font-weight: 300;
      font-size: 18px;
      line-height: 20px;
      a{
        text-decoration-line: underline;
      }
      @media print {
        margin-top: 1rem;
      }
    }
  }
`
